import type { FC } from 'react'
import type { IssuerName, PaymentMethod } from '@mobi/api-types'
import {
  PaymentMethodsSelfServeSchema,
  type DepositSource,
  type PaymentMethodsAll,
  type PaymentMethodsSelfServe,
} from './types'
import {
  CreditCardLogo,
  PaypalLogo,
  ApplePayLogo,
  GooglePayLogo,
  Visa,
  Mastercard,
  AmericanExpress,
} from './Icons'

export const depositSourceMap: Record<DepositSource, FC> = {
  CreditCard: CreditCardLogo,
  PayPal: PaypalLogo,
  ApplePay: ApplePayLogo,
  GooglePay: GooglePayLogo,
} as const

export const cardIssuerNameMap: Record<IssuerName, FC> = {
  VISA: Visa,
  MASTER_CARD: Mastercard,
  AMEX: AmericanExpress,
} as const

export const nameCardIssuerMap: Record<IssuerName, string> = {
  VISA: 'Visa',
  MASTER_CARD: 'Mastercard',
  AMEX: 'Amex',
}

export function formatCardText({
  issuerName,
  lastFour,
}: Pick<PaymentMethod, 'issuerName' | 'lastFour'>) {
  return `${nameCardIssuerMap[issuerName]} .... ${lastFour}`
}

export function isSameCard(card: PaymentMethod, otherCard: PaymentMethod) {
  return card.token === otherCard.token
}

export const isCardValid = ({ isExpired, isDebitCard }: PaymentMethod) => isDebitCard && !isExpired

export function isSelfServePaymentMethod(
  paymentMethod: PaymentMethodsAll
): paymentMethod is PaymentMethodsSelfServe {
  return PaymentMethodsSelfServeSchema.safeParse(paymentMethod).success
}
