import React from 'react'
import { observeImmutable } from '@core/Components/HOCs'
import { OverflowEllipsis } from '@core/Components/OverflowEllipsis'
import { Icon } from '@mobi/component-library/Common/Icon'
import { isToteSportsDetails, ToteSportsDetails } from '@core/Data/Betting/selectionDetails'
import { state$ as quickbetState$, QuickbetState } from '@core/Areas/Quickbet/driver'
import { isToteSportsSelection, ToteSportsSelection } from '@core/Data/Betting/selections'
import {
  LineContainerStyled,
  LineInfoStyled,
  SubheaderStyled,
  SubheaderTextStyled,
  TextContainerStyled,
  ToteLineContainerStyled,
} from './ToteSportsHeader.styles'
import { HeaderStyled } from './ToteSportsHeader.styles'

export const ToteSportsHeader: React.ComponentClass = observeImmutable(
  quickbetState$,
  ({ record }) => {
    const { selection, selectionDetails } = record.toJS() as QuickbetState
    if (isToteSportsDetails(selectionDetails) && isToteSportsSelection(selection)) {
      return <ToteSportsHeaderComponent selectionDetails={selectionDetails} selection={selection} />
    }
    return null
  }
)

type ToteSportsHeaderComponentProps = {
  selection: ToteSportsSelection
  selectionDetails: ToteSportsDetails
}

export function ToteSportsHeaderComponent({
  selection,
  selectionDetails,
}: ToteSportsHeaderComponentProps): JSX.Element {
  const selectionsByGame = selection.betSelections.split('/')
  return (
    <>
      <HeaderStyled>
        <Icon type={'TIPPING'} data-tid-quickbet-tipping-icon='' />
        Sports Tipping
      </HeaderStyled>
      <SubheaderStyled>
        <SubheaderTextStyled data-tid-quickbet-header-tipping-pool-name=''>
          {selectionDetails.poolName}
        </SubheaderTextStyled>
      </SubheaderStyled>
      <div>
        {selectionDetails.games.map((game, index) => {
          return (
            <div key={`selectionDetails-${index}`}>
              <ToteLineContainerStyled
                key={`game-${index}`}
                data-tid-quickbet-header-tipping-team-names=''
              >
                <TextContainerStyled>
                  <span>Game {index + 1}</span>
                </TextContainerStyled>
                <LineInfoStyled>
                  <OverflowEllipsis>{game.homeTeamName}</OverflowEllipsis>
                  &nbsp;v&nbsp;
                  <OverflowEllipsis>{game.awayTeamName}</OverflowEllipsis>
                </LineInfoStyled>
              </ToteLineContainerStyled>
              <LineContainerStyled key={`selection-${index}`}>
                <TextContainerStyled />
                <LineInfoStyled data-tid-quickbet-header-tipping-selection=''>
                  {selectionTypeDescriptions(selectionsByGame[index])}
                </LineInfoStyled>
              </LineContainerStyled>
            </div>
          )
        })}
      </div>
    </>
  )
}

const selectionTypeDescription = (selectionType: string) =>
  selectionType === 'H' ? 'Home' : selectionType === 'D' ? 'Draw' : 'Away'

const selectionTypeDescriptions = (gameSelection: string) => {
  return gameSelection.split('.').map(selectionTypeDescription).join(', ')
}
