import React from 'react'
import {
  getLegStatus,
  shouldUseFallbackIcon,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { Ticket } from '@core/Areas/AccountActivity/types'
import { StatusSilkStyled } from '../StatusSilks.styles'
import { SilkFallback } from '@core/Areas/AccountActivity/Components/Ticket/Components/Common'
import { meetingIdToContestType } from '@core/Areas/Racing/helpers'
import { SilkImageContained } from '@core/Components/SilkImage/SilkImageContained'
import { PlanSeq } from '@core/Data/betting'
import { BetStatusIcon } from '../BetStatusIcon/BetStatusIcon'

export const LegStatusSilks: React.FC<{ legs: Ticket['legs']; planSeq: PlanSeq }> = ({
  legs,
  planSeq,
}) => {
  return (
    <>
      {legs.map(leg => {
        const shouldHideSilk = shouldUseFallbackIcon(leg, planSeq)
        const betStatus = getLegStatus(leg.result)
        const shouldRenderStatusIcon = betStatus && ['Won', 'Lost'].includes(betStatus)

        return shouldRenderStatusIcon ? (
          <BetStatusIcon betStatus={betStatus} key={leg.header} />
        ) : (
          <StatusSilkStyled isFallback={shouldHideSilk} key={leg.header}>
            {shouldHideSilk ? (
              <SilkFallback contestType={meetingIdToContestType(leg.fixtureId)} isSmall />
            ) : (
              <SilkImageContained
                fixtureDate={leg.fixtureDate}
                fixtureId={leg.fixtureId}
                raceNumber={leg.selections[0].raceNumber || leg.raceNumber}
                acceptorNumber={leg.selections[0].selectionDetails[0].acceptorDetails[0].number}
                isSmall
              />
            )}
          </StatusSilkStyled>
        )
      })}
    </>
  )
}
