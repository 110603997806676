import styled from '@emotion/styled'
import { Money } from '@core/Components/Text/Money'
import { hexColors } from '@mobi/settings'

export const BalanceContainerStyled = styled('div')({
  display: 'flex',
  marginRight: '0.5rem',
  cursor: 'pointer',
})

type BalanceSectionProps = {
  hasOffers: boolean
  hasBonusBalance: boolean
  isBalanceVisible: boolean
}

export const BalanceSectionStyled = styled('div')(({
  hasOffers,
  hasBonusBalance,
  isBalanceVisible,
}: BalanceSectionProps) => {
  return {
    backgroundColor: hexColors.white,
    color: hexColors.studio,
    display: 'inline-block',
    fontFamily: 'Arial, sans-serif',
    fontWeight: '400',
    textAlign: 'end',
    borderRadius: hasOffers ? '0.4rem 0 0 0.4rem' : '0.4rem',
    padding: isBalanceVisible ? '0.25rem 1rem' : '0.5rem',
    fontSize: hasBonusBalance ? '1.2rem' : '1.4rem',
    lineHeight: hasBonusBalance || !isBalanceVisible ? '1.5rem' : '3rem',
  }
})

export const BonusSectionStyled = styled('div')({
  borderRadius: '0 0.4rem 0.4rem 0',
  backgroundColor: hexColors.studio,
  color: hexColors.white,
  display: 'inline-block',
  padding: '0.9rem 0.6rem 0 0.6rem',
  fontFamily: '"PF Square Sans Pro", Arial, sans-serif',
  fontSize: '1.6rem',
  fontWeight: '700',
})

export const BalanceMoneyStyled = styled
  .div({
    margin: 'unset',
    display: 'block',
  })
  .withComponent(Money)

export const BonusBalanceMoneyStyled = styled
  .div({
    color: hexColors.orange,
    margin: 'unset',
    display: 'block',
    whiteSpace: 'nowrap',
  })
  .withComponent(Money)
