import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  overflow: 'hidden',
})

export const BigPlayButtonWrapperStyled = styled.div({
  zIndex: 1,
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',

  '> button': {
    flex: 1,
    width: '100%',
    background: 0,
    fontSize: '4rem',
  },
})

const ControlBarStyled = styled.div({
  zIndex: 2,
  position: 'absolute',
  left: 0,
  right: 0,
  height: '4rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transform: 'translateY(0)',
  willChange: 'transform',
  color: hexColors.white,
  fontSize: '2.4rem',
  pointerEvents: 'none',
  transition: 'transform ease-in-out 0.2s',
})

export const TopBarStyled = styled(ControlBarStyled)({
  top: 0,
  padding: '0.5rem 0.5rem 0',

  '& > div': {
    minWidth: '3.6rem',
  },

  '> div:nth-of-type(1), > div:nth-of-type(3)': {
    flex: 1,
  },

  '> div:nth-of-type(3)': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

export const BotttomBarStyled = styled(ControlBarStyled)({
  bottom: 0,
  padding: '0 0.5rem 0.5rem',
})

export const PlayerDragHandleStyled = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
})
