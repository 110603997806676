import React from 'react'
import { PlanSeq } from '@core/Data/betting'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { getTicketLayoutType } from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { HeaderSelectionInfoStyled } from '@core/Areas/AccountActivity/Components/Ticket/Components/Header/Header.styles'
import {
  FavouriteNumbers,
  Multi,
  SameRaceMulti,
  SingleMultiple,
  SingleSingle,
  Sports,
  Tipping,
} from './Components'

export const SelectionInfo: React.FC<{ ticket: Ticket }> = React.memo(
  ({ ticket }) => {
    if (PlanSeq.FavouriteNumbers === ticket.planSeq) {
      return <FavouriteNumbers ticket={ticket} />
    }

    const ticketLayoutType = getTicketLayoutType(ticket)

    if (ticketLayoutType === 'same-race-multi') return <SameRaceMulti ticket={ticket} />

    if (ticketLayoutType === 'single-single') return <SingleSingle ticket={ticket} />

    if (ticketLayoutType === 'single-multiple') return <SingleMultiple ticket={ticket} />

    if (ticketLayoutType === 'multi') return <Multi ticket={ticket} />

    if (ticketLayoutType === 'sports') return <Sports ticket={ticket} />

    if (ticketLayoutType === 'tipping') return <Tipping ticket={ticket} />

    return (
      <HeaderSelectionInfoStyled>
        <h3>Selection info not matched</h3>
      </HeaderSelectionInfoStyled>
    )
  },
  (prev, next) => prev.ticket.ticketSequence === next.ticket.ticketSequence
)
