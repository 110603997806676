import { z } from 'zod'

// https://github.com/colinhacks/zod/issues/2241
export { ZodError } from 'zod'

export const initialDataSchema = z.object({
  paymentMethods: z
    .object({
      number: z.string(),
      issuerName: z.enum(['VISA', 'MASTER_CARD', 'AMEX']),
      /**
       * Expiry date in MM/YY format
       */
      formattedExpiryDate: z.string().regex(/^\d{2}\/\d{2}/),
      isDefault: z.boolean(),
      isExpired: z.boolean(),
      isDebitCard: z.boolean(),
      token: z.string(),
      bin: z.string(),
      lastFour: z.string(),
      paypalPayerId: z.string().optional(),
      paypalEmail: z.string().optional(),
    })
    .array(),
  depositLimits: z.object({
    minimum: z.number(),
    maximum: z.number(),
  }),
  clientToken: z.string(),
  clientTokenThreeDSecure: z.string(),
  canDeposit: z.boolean(),
  transactionId: z.string(),
  isSecurityCompatibleClient: z.boolean(),
  liabilityShiftExempt: z.boolean(),
  minimumLiabilityShiftThreshold: z.number(),
})

export type InitialData = z.infer<typeof initialDataSchema>
export type PaymentMethod = InitialData['paymentMethods'][number]

export const paymentNonceSchema = z.object({
  token: z.string(),
  amount: z.number(),
})

export type PaymentNonce = z.infer<typeof paymentNonceSchema>

export const paymentNonceResponseSchema = z.object({
  isSuccess: z.boolean(),
  message: z.string().nullable(),
  nonce: z.string(),
})

export type PaymentNonceResponse = z.infer<typeof paymentNonceResponseSchema>

export const nonTransactionalCreditCardResponse = z.object({
  isSuccess: z.boolean(),
  message: z.string().nullable(),
  failureCode: z.string().nullish(),
  failureMessage: z.string().nullish(),
})

export type NonTransactionalCreditCardResponse = z.infer<typeof nonTransactionalCreditCardResponse>

export const depositResponseSchema = nonTransactionalCreditCardResponse.extend({
  transactionId: z.string().nullable(),
})

export type DepositResponse = z.infer<typeof depositResponseSchema>

export type SetDefaultCardRequest = {
  paymentMethodToken: string
}

export type DeleteCardRequest = SetDefaultCardRequest

export const createPaymentMethodSchema = z.object({
  paymentMethodNonce: z.string(),
  expirationMonth: z.string(),
  expirationYear: z.string(),
})

export type CreatePaymentMethodRequest = z.infer<typeof createPaymentMethodSchema>
