import React from 'react'
import ReactDOM from 'react-dom'

import { MysteryDataTransferObject } from '@classic/Betting-v2/DataTransferObjects/MysteryDataTransferObject'

import { BalanceInfoBar } from '@core/Areas/Quickbet/Components/BalanceBar/BalanceBar'
import { CustomizedMysteryOption } from '@core/Areas/Racing/Components/Mystery/MysteryTypes'
import { BetType } from '@core/Data/Betting/selections'

import { ReviewSelectionsDrawer } from '@core/Components/BettingDrawer/ReviewSelectionsDrawer'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'

import { BetAmountButtons } from './BetAmountButtons'
import { MysteryRaceDetailsHeader } from './MysteryRaceDetailsHeader'
import {
  BiggerTextStyled,
  FullScreenOverlayContainerStyled,
  FullScreenContentsStyled,
  CustomizeSpendInputContainerStyled,
  CustomizeSpendInputStyled,
  FullScreenTopSectionStyled,
  MysteryBetCloseButtonStyled,
} from '../MysteryBetPage.styles'
import {
  getAsNumber,
  getBetTypeText,
  getFlexi,
  getTotalCost,
  toDisplayMoney,
  ZERO_DOLLARS,
} from '../Data/MysteryUtils'
import { Icon } from '@mobi/component-library/Common/Icon'

enum ActiveInput {
  Win,
  Place,
  Spend,
}

interface CustomizeSpendProps {
  open: boolean
  mysteryData: MysteryDataTransferObject
  betType: keyof typeof BetType
  option: CustomizedMysteryOption
  numberOfBets: number
  isRaceClosed: boolean
  onClosed: () => void
  onReviewDetails: (winStake: number, placeStake: number) => void
}

export const CustomizeSpend = ({
  open,
  mysteryData,
  betType,
  option,
  numberOfBets,
  isRaceClosed,
  onClosed,
  onReviewDetails,
}: CustomizeSpendProps): React.ReactPortal | null => {
  const [winStake, setWinStake] = React.useState(ZERO_DOLLARS)
  const [placeStake, setPlaceStake] = React.useState(ZERO_DOLLARS)
  const [spendPerBet, setSpendPerBet] = React.useState(ZERO_DOLLARS)
  const [activeInput, setActiveInput] = React.useState(ActiveInput.Win)
  const isWinPlace = betType === 'WinPlace'

  const isAmountSelected = isWinPlace
    ? getAsNumber(winStake) > 0 || getAsNumber(placeStake) > 0
    : getAsNumber(spendPerBet) > 0

  React.useEffect(() => {
    setActiveInput(isWinPlace ? ActiveInput.Win : ActiveInput.Spend)
  }, [isWinPlace])

  if (!open) {
    return null
  }

  const betTypeText = getBetTypeText(betType, option, winStake, placeStake)

  const addAmount = (amount: number): void => {
    switch (activeInput) {
      case ActiveInput.Win:
        setWinStake(toDisplayMoney((getAsNumber(winStake) + amount).toString()))
        break
      case ActiveInput.Place:
        setPlaceStake(toDisplayMoney((getAsNumber(placeStake) + amount).toString()))
        break
      case ActiveInput.Spend:
        setSpendPerBet(toDisplayMoney((getAsNumber(spendPerBet) + amount).toString()))
        break
    }
  }

  const isWinActive = !isRaceClosed && activeInput === ActiveInput.Win
  const isPlaceActive = !isRaceClosed && activeInput === ActiveInput.Place
  const isSpendActive = !isRaceClosed && activeInput === ActiveInput.Spend

  const clearAmounts = (): void => {
    setWinStake(ZERO_DOLLARS)
    setPlaceStake(ZERO_DOLLARS)
    setSpendPerBet(ZERO_DOLLARS)
  }

  const contents = (
    <FullScreenOverlayContainerStyled data-tid-customize-spend>
      <FullScreenContentsStyled>
        <FullScreenTopSectionStyled>
          <BalanceInfoBar />
          <MysteryBetCloseButtonStyled onClick={onClosed}>
            <Icon type='cross' size='inherit' />
          </MysteryBetCloseButtonStyled>
          <Grid>
            <MysteryRaceDetailsHeader
              mysteryData={mysteryData}
              betTypeText={betTypeText}
              isRaceClosed={isRaceClosed}
            />
            <GridRow>
              <GridCell padding='2rem 0 1rem 0'>
                <BiggerTextStyled>
                  {option?.numberOfCombinations && (
                    <>
                      <strong>
                        <big>x{option.numberOfCombinations}</big>
                      </strong>{' '}
                      Combo
                    </>
                  )}
                </BiggerTextStyled>
              </GridCell>
              <GridCell padding='2rem 0 1rem 0' align='right'>
                <BiggerTextStyled>
                  <strong>
                    <big>x{numberOfBets}</big>
                  </strong>{' '}
                  Bet
                </BiggerTextStyled>
              </GridCell>
            </GridRow>
            {isWinPlace && (
              <GridRow>
                <GridCell padding='0 0.5rem 0 0'>
                  <CustomizeSpendInputContainerStyled infoText='Win' isSelected={isWinActive}>
                    <CustomizeSpendInputStyled
                      value={winStake}
                      id='mystery-bet-win-stake'
                      disabled={isRaceClosed}
                      onClick={() => setActiveInput(ActiveInput.Win)}
                      isSelected={isWinActive}
                    >
                      {winStake}
                    </CustomizeSpendInputStyled>
                  </CustomizeSpendInputContainerStyled>
                </GridCell>
                <GridCell padding='0 0 0 0.5rem'>
                  <CustomizeSpendInputContainerStyled infoText='Place' isSelected={isPlaceActive}>
                    <CustomizeSpendInputStyled
                      value={placeStake}
                      id='mystery-bet-place-stake'
                      disabled={isRaceClosed}
                      onClick={() => setActiveInput(ActiveInput.Place)}
                      isSelected={isPlaceActive}
                    >
                      {placeStake}
                    </CustomizeSpendInputStyled>
                  </CustomizeSpendInputContainerStyled>
                </GridCell>
              </GridRow>
            )}
            {!isWinPlace && (
              <GridRow>
                <GridCell>
                  <CustomizeSpendInputContainerStyled
                    infoText='Spend per bet'
                    isSelected={isSpendActive}
                  >
                    <CustomizeSpendInputStyled
                      value={spendPerBet}
                      id='mystery-bet-spend-per-bet'
                      disabled={isRaceClosed}
                      onClick={() => setActiveInput(ActiveInput.Spend)}
                      isSelected={isSpendActive}
                    >
                      {spendPerBet}
                    </CustomizeSpendInputStyled>
                  </CustomizeSpendInputContainerStyled>
                </GridCell>
              </GridRow>
            )}
            <GridRow padding='1rem 0'>
              <GridCell flexBasis='auto' flexGrow={0} data-tid-flexi-display>
                {!isWinPlace && (
                  <BiggerTextStyled>
                    <strong>
                      <big>
                        {getFlexi(option?.numberOfCombinations || 1, getAsNumber(spendPerBet))}
                      </big>
                    </strong>{' '}
                    Flexi
                  </BiggerTextStyled>
                )}
              </GridCell>
              <GridCell align='right' data-tid-total-cost-display>
                <BiggerTextStyled>
                  Total Cost{' '}
                  <strong>
                    <big>
                      {getTotalCost(
                        winStake,
                        placeStake,
                        spendPerBet,
                        numberOfBets,
                        option?.numberOfCombinations
                      )}
                    </big>
                  </strong>
                </BiggerTextStyled>
              </GridCell>
            </GridRow>
          </Grid>
        </FullScreenTopSectionStyled>
        <BetAmountButtons
          isDisabled={isRaceClosed}
          onAmountSelected={addAmount}
          onClear={clearAmounts}
        />

        {!isRaceClosed && (
          <ReviewSelectionsDrawer
            open={!isRaceClosed}
            canReviewSelections={isAmountSelected}
            onEditBet={onClosed}
            onReviewSelections={() => {
              onReviewDetails(
                isWinPlace ? getAsNumber(winStake) : getAsNumber(spendPerBet),
                getAsNumber(placeStake)
              )
              onClosed()
            }}
          />
        )}
      </FullScreenContentsStyled>
    </FullScreenOverlayContainerStyled>
  )

  return ReactDOM.createPortal(contents, document.body)
}
