import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BettingHeader, SimpleBettingHeader } from '@core/Components/BettingHeader/BettingHeader'
import { betslipBettingHeader } from '@core/Components/BettingHeader/themes'
import { RaceCountdown } from '@core/Components/RaceCountdown'
import type { BetslipItem } from '@core/Areas/Betslip/driver'
import { isFobSportsSelection } from '@core/Data/Betting/selections'
import { isFobSportsDetails } from '@core/Data/Betting/selectionDetails'

export const SportBetInfo: React.FC<{
  item: BetslipItem
  isSimpleView: boolean
  isBusy?: boolean
  hasProposed?: boolean
  hasFatalError?: boolean
  removeHandler?: () => void
}> = ({ item, hasProposed, isBusy, hasFatalError, isSimpleView, removeHandler }) => {
  if (!isFobSportsSelection(item.selection) || !isFobSportsDetails(item.selectionDetails))
    return null

  const { sportName, tournamentName, eventName, marketName, advertisedStartTime } =
    item.selectionDetails
  const { propositionName } = item.selection

  const selection = hasFatalError ? null : <span data-tid-proposition=''>{propositionName}</span>

  return isSimpleView ? (
    <SimpleBettingHeader heading={eventName} selection={selection} />
  ) : (
    <BettingHeader
      icon={<Icon size='3rem' type='tab' />}
      theme={betslipBettingHeader}
      removeHandler={removeHandler}
      displayPill={false}
      pilled={marketName ? <span>{marketName}</span> : null}
      isEditable={!hasProposed && !isBusy}
      selection={selection}
      timeInfo={
        !hasFatalError &&
        advertisedStartTime && <RaceCountdown raceStartTime={new Date(advertisedStartTime)} />
      }
      heading={
        <span>
          {sportName} - {tournamentName}
        </span>
      }
      subheading={eventName !== marketName ? <span data-tid-event=''>{eventName}</span> : null}
    />
  )
}
