import React from 'react'
import { useDispatch } from 'react-redux'
import { setEnhancedBetslipSetting } from '@core/Areas/Settings/Store'
import { updateDataLayer } from '@classic/Foundation/Analytics/Analytics'

/** Automatically clear the enhanced betslip setting if the toggle is off */
export const useResetOnEnhancedBetslipFeatureOff = (isFeatureActive: boolean) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!isFeatureActive) {
      dispatch(setEnhancedBetslipSetting(false))
      updateDataLayer({ user: { isEnhancedBetslipEnabled: false } })
    }
  }, [dispatch, isFeatureActive])
}
