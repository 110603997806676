import styled from '@emotion/styled'
import { type Provider, themes } from '../Common/PaymentMethodIconThemes'

type Props = {
  provider?: Provider
  disabled?: boolean
}

export const PaymentMethodButtonStyled = styled.button<Props>(
  {
    alignItems: 'center',
    border: 'none',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    height: '40px',
    width: '100%',

    '& > :not(:last-child)': {
      marginRight: '1.8rem',
    },
  },
  ({ provider, disabled }) => {
    const themeName: Provider = provider ?? 'default'
    const { textColor, backgroundColor, svgWidth, svgHeight } = themes[themeName]

    return {
      backgroundColor,
      color: textColor,
      cursor: disabled ? 'disabled' : 'pointer',
      filter: disabled ? 'opacity(0.6)' : undefined,

      '& svg': {
        width: svgWidth,
        height: svgHeight,
      },

      '&:hover': disabled || {
        filter: 'brightness(0.95)',
      },
    }
  }
)
