import React from 'react'
import { NextRace } from '@core/Data/Racing/nextRaces'
import {
  HomeSuperPicksAnimationContainer,
  HomeNextSuperPicksContainerStyled,
  HomeNextSuperPicksHeaderStyled,
} from './HomeNextSuperPicks.styles'
import { HomeNextSuperPicksList } from './HomeNextSuperPicksList'
import { Icon } from '@mobi/component-library/Common/Icon'
import { RemoveJumpedRaces, superBoostRacesState$ } from './HomeNextSuperPickDriver'
import { useConfig, useFeature } from '@core/Utils/hooks'
import { addRawSuperBoostMeetings } from './SuperBoostSourceData'
import { state$ as featureState$ } from '@core/State/LaunchDarklyFeatures/driver'
import { useInducementService } from '@core/Utils/hooks/useInducementService'

export const HomeNextSuperPicks: React.FC = () => {
  const canShowInducements = useInducementService()
  const isSuperBoostFeatureActive = !!useFeature('HOMEPAGE_SUPERPICKS')
  if (!isSuperBoostFeatureActive || !canShowInducements) {
    return null
  }

  return <HomeNextSuperPicksComponent />
}

const HomeNextSuperPicksComponent = (): JSX.Element | null => {
  const [races, setRaces] = React.useState<NextRace[]>([])
  const serverEnvironment = useConfig('serverEnvironment', null)

  React.useEffect(() => {
    if (!serverEnvironment) return undefined

    const subscription = featureState$.subscribe(record => {
      const features = record.features.toJS()

      addRawSuperBoostMeetings(serverEnvironment, features)
    })

    return () => {
      subscription.dispose()
    }
  }, [serverEnvironment])

  React.useEffect(() => {
    const raceSubscription = superBoostRacesState$.distinctUntilChanged().subscribe(state => {
      if (state && state.superBoostRaces) {
        const races = state.superBoostRaces
          .sort((a: NextRace, b: NextRace) => {
            return +new Date(a.AdvertisedStartTime) - +new Date(b.AdvertisedStartTime)
          })
          .slice(0, 20)

        setRaces(races)
      }
    })

    const updateRacesTimer = window.setInterval(() => {
      RemoveJumpedRaces()
    }, 30000)

    return () => {
      window.clearInterval(updateRacesTimer)
      raceSubscription.dispose()
    }
  }, [])

  if (!races || races.length === 0) {
    return null
  }

  return (
    <HomeSuperPicksAnimationContainer>
      <HomeNextSuperPicksHeaderStyled>
        <div>
          <Icon type='specials' />
          &nbsp; Next BoostPlus+ Races
        </div>
      </HomeNextSuperPicksHeaderStyled>
      <HomeNextSuperPicksContainerStyled>
        <HomeNextSuperPicksList races={races} />
      </HomeNextSuperPicksContainerStyled>
    </HomeSuperPicksAnimationContainer>
  )
}
