import React from 'react'
import { LogoStyled } from './logo.styles'

export function SkyOneLogo() {
  return (
    <LogoStyled
      x='0px'
      y='0px'
      width='250px'
      height='111.76px'
      viewBox='0 0 250 111.76'
      enable-background='new 0 0 250 111.76'
    >
      <title>Sky One</title>
      <g fill='#EB0141'>
        <path d='M123.54 108.79h30.32L118.9 64.92l17.82-22.39v-.06l21.17 28.62v37.66h24.93V71.14l12.99-17.42-13.76-17.19-12.23 15.94-18.84-25.72-21.41-.03h-7.23L92.49 63.45V26.72H67.63v82.11h24.33l.01-.02V70.36l31.57 38.43zM64.27 85.16c0-4.63-1.09-8.5-3.25-11.58a29.92 29.92 0 0 0-8.94-8.2 150.25 150.25 0 0 0-15.22-8.02c-3.57-1.69-6.05-3.14-7.43-4.31a5.35 5.35 0 0 1-2.07-4.21c0-1.72.68-3.19 2.07-4.37 1.38-1.2 3.61-1.79 6.73-1.79 4.72 0 9.14.96 13.28 2.84 4.13 1.9 7.9 4.06 11.26 6.45V32.74c-.97-.64-2.7-1.49-5.22-2.62a51 51 0 0 0-9.67-2.99 62.03 62.03 0 0 0-12.77-1.33c-6.44 0-11.91 1.07-16.4 3.2-4.48 2.14-7.8 4.99-10 8.56a22.75 22.75 0 0 0-3.25 11.98c0 4.59 1.08 8.57 3.24 11.9a28.11 28.11 0 0 0 8.34 8.38 75.36 75.36 0 0 0 11.9 6.19c4.09 1.64 7.21 3.2 9.34 4.72 2.15 1.51 3.21 3.37 3.21 5.59 0 1.22-.62 2.55-1.87 3.95-1.25 1.4-4.01 2.09-8.24 2.09-4.6 0-8.91-.85-12.93-2.55a90.04 90.04 0 0 1-10.22-5.17l19.8 25.03.83.08c2.47.21 4.57.32 6.26.32 6.1 0 11.53-.99 16.25-3a24.9 24.9 0 0 0 11.02-8.61c2.64-3.74 3.95-8.17 3.95-13.3zM239.39 92.38V5.11l-33.38.02-10.17 13.6 13.49 17.06 4.54-6.06v62.65z' />
      </g>
    </LogoStyled>
  )
}
