import { state$ as quickbetState$ } from '@core/Areas/Quickbet/driver'
import { QuickbetActionsStyled } from '@core/Areas/Quickbet/Quickbet.styles'
import { trackQuickDepositShown } from '@core/Areas/QuickDeposit/analytics'
import { QuickDepositActions } from '@core/Areas/QuickDeposit/Components/QuickDepositActions'
import { QuickDepositBody } from '@core/Areas/QuickDeposit/Components/QuickDepositBody'
import { QuickDepositUnavailableActions } from '@core/Areas/QuickDeposit/Components/QuickDepositUnavailableActions'
import { useQuickDepositValidation } from '@core/Areas/QuickDeposit/Hooks/useQuickDepositValidation'
import { selectIsBusy } from '@core/Areas/QuickDeposit/Store/selectors'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { SpinnerStandalone } from '@mobi/component-library/Common/Spinner'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { useObservableProperties } from '@core/Utils/hooks'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { LoadingContainerStyled, QuickbetDepositBodyStyled } from './QuickDeposit.styles'
import { ProcessingMessage } from '@mobi/deposit/Components/ProcessingMessage'
import { QuickDepositUnavailableReason } from './QuickDepositUnavailableReason'
import { useSentryScope } from '@mobi/utils'

export const QuickDeposit = () => {
  const { accountNumber } = useObservableProperties(userAccountState$, ['accountNumber'], {
    accountNumber: null,
  })
  const { shortfall } = useObservableProperties(quickbetState$, ['shortfall'], {
    shortfall: 0,
  })
  const isBusy = useSelector(selectIsBusy)
  // TODO: fix later using multiple selectors here will re-render component
  // & status becomes 'idle' which renders the NoticeBox.
  // not good as user will be able to click on deposit button again
  // const isDepositing = useSelector(selectIsDepositing)

  const result = useQuickDepositValidation(accountNumber, shortfall)

  const showNotice = !isBusy && (result.canDeposit || result.reason !== 'loading')

  useSentryScope({
    areaTag: 'quickbet-deposit',
    userId: accountNumber,
  })

  useEffect(() => {
    trackQuickDepositShown()
  }, [])

  return (
    <>
      <QuickbetDepositBodyStyled centered={isBusy}>
        {showNotice && (
          <NoticeBox
            title={`Your account has insufficient funds`}
            noticeBoxType={NoticeBoxTypes.Info}
            hasBorder
            role='alert'
            aria-label={`Your account has insufficient funds`}
          />
        )}

        {result.canDeposit && accountNumber !== null && (
          <>
            {isBusy && (
              <LoadingContainerStyled>
                <SpinnerStandalone />
                <ProcessingMessage
                  isDepositing={false}
                  depositSource={result.lastUsedPaymentMethod}
                />
              </LoadingContainerStyled>
            )}

            <QuickDepositBody
              lastUsedPaymentMethod={result.lastUsedPaymentMethod}
              creditCard={result.creditCard}
              minimumDepositAmount={result.minimumDepositAmount}
              willDepositMinimumAmount={result.willDepositMinimumAmount}
              depositAmount={result.depositAmount}
              initialData={result.initialData}
              isBusy={isBusy}
              accountNumber={accountNumber}
            />
          </>
        )}

        {!result.canDeposit && (
          <QuickDepositUnavailableReason
            shortfall={shortfall}
            depositLimits={result.initialData?.depositLimits}
            reason={result.reason}
          />
        )}
      </QuickbetDepositBodyStyled>

      <QuickbetActionsStyled>
        {result.canDeposit ? (
          <QuickDepositActions
            lastUsedPaymentMethod={result.lastUsedPaymentMethod}
            isBusy={isBusy}
          />
        ) : (
          <QuickDepositUnavailableActions
            accountDepositAvailable={result.isAccountDepositAvailable}
            unavailabilityReason={result.reason === 'loading' ? undefined : result.reason}
          />
        )}
      </QuickbetActionsStyled>
    </>
  )
}
