import { dayjs } from '@mobi/utils'
import type { Tabs } from '@core/Areas/AccountActivity/types'

/** DO NOT EXPORT - Compiler will remove reference */
const enum LocalConstants {
  accountBase = 'account',
  accountActivityBase = 'account-activity',
  primaryAccountHolderDetailsBase = 'primary-account-holder-details',
  blackbookRunnersBase = 'blackbook-runners',
  racePageFormBase = 'race-page-form',
  racePageCompleteBase = 'race-page-complete',
  raceReplyBase = 'race-replay',
  braintreeBase = 'braintree',
}

export const queryKeys = {
  accountBase: LocalConstants.accountBase,
  accountContactDetails: (accountNumber: number) =>
    `${LocalConstants.accountBase}-contact-${accountNumber}`,
  accountPreferences: (accountNumber: number) =>
    `${LocalConstants.accountBase}-preferences-${accountNumber}`,

  accountActivityBase: LocalConstants.accountActivityBase,
  accountActivityData: (tab: Tabs, isOrderByEvent: boolean, userSelectedDate?: string) => [
    LocalConstants.accountBase,
    LocalConstants.accountActivityBase,
    tab,
    isOrderByEvent,
    userSelectedDate,
  ],

  primaryAccountHolderDetails: (accountNumber: number) =>
    `${LocalConstants.primaryAccountHolderDetailsBase}-${accountNumber}`,

  activityStatementsAccountDetails: 'account-details',
  activityStatementsAccountHolders: 'account-holders',

  banner: 'banner',
  raceCardPromotion: 'race-card-promotion',

  blackbookComments: 'blackbook-comments',
  blackbookDetails: 'blackbook-details',
  blackbookRunnersBase: LocalConstants.blackbookRunnersBase,
  blackbookRunners: (account: number | null) => [LocalConstants.blackbookRunnersBase, account],
  blackbookRunnersForJockey: (jockeyName: string) => ['blackbook-jockey-rides', jockeyName],

  countryList: 'country-list',
  eventDetails: 'event-details',

  feedBackContacts: 'contacts',
  feedBackSubjects: 'subjects',

  getBankAccount: 'getBankAccount',
  inducement: 'inducement',
  kambiPendingTicketsCount: 'kambi-pending-tickets-count',
  meetingPreview: 'meeting-preview',
  meetings: (date: string) => `meetings-${date}`,
  raceList: (meeting: string, date: string) => [meeting.toLowerCase(), date],

  mysteryBetDetails: 'mystery-bet-details',
  mysteryQuickPickPropose: 'mystery-quick-pick-propose',

  nextRaces: 'nextRaces',
  nextSkyRaces: 'next-sky-races',

  notifications: 'notifications',

  pendingTicketsCount: LocalConstants.accountBase + 'pending-tickets-count',

  racePendingTickets: (raceKey: string) => [LocalConstants.accountBase, 'race-tickets', raceKey],
  raceResultedTickets: (
    meetingId: string,
    meetingDate: string,
    raceNumber: string,
    hasFixedResulted: boolean
  ) => [
    LocalConstants.accountBase,
    'race-tickets',
    meetingId,
    meetingDate,
    raceNumber,
    hasFixedResulted,
  ],

  precommitmentDetails: 'precommitment-page-details',

  // Used in RaceCard to invalidate cache due to legacy implementation
  racePageCompleteBase: LocalConstants.racePageCompleteBase,
  racePageFormBase: LocalConstants.racePageFormBase,
  racePageStartersForRaces: 'race-page-starters-for-races',

  racePageForm: (meetingId: string, meetingDate: Date | string, raceNumber: number) => [
    LocalConstants.racePageFormBase,
    meetingId.toLowerCase(),
    dayjs(meetingDate).format('YYYY-MM-DD'),
    raceNumber,
  ],
  racePageFieldSummary: (
    racecourseSeq: number | undefined,
    meetingDate: string | undefined,
    raceNumber: number | undefined
  ) => [
    LocalConstants.racePageFormBase,
    racecourseSeq,
    dayjs(meetingDate).format('YYYY-MM-DD'),
    raceNumber,
    'racePageFieldSummary',
  ],
  racePageFieldFOOForm: (
    racecourseSeq: number | undefined,
    meetingDate: string | undefined,
    raceNumber: number | undefined
  ) => [
    LocalConstants.racePageFormBase,
    racecourseSeq,
    dayjs(meetingDate).format('YYYY-MM-DD'),
    raceNumber,
    'racePageFieldFOOForm',
  ],
  racePageComplete: (meetingId: string, meetingDate: Date | string, raceNumber: number) => [
    LocalConstants.racePageCompleteBase,
    meetingId.toLowerCase(),
    dayjs(meetingDate).format('YYYY-MM-DD'),
    raceNumber,
  ],
  raceReplayRequest: (meetingId: string, meetingDate: Date | string, raceNumber: number) => [
    LocalConstants.raceReplyBase,
    meetingId.toLowerCase(),
    dayjs(meetingDate).format('YYYY-MM-DD'),
    raceNumber,
  ],

  sameRaceMultiGetSelectionPrice: 'sameRaceMultiGetSelectionPrice',
  signUpContactDetails: 'sign-up-contact-details',
  specials: 'specials',
  specialsTerms: 'specials-terms',
  takeABreak: 'take-a-break',

  toteSportsCurrentPools: 'tote-sports-current-pools',
  toteSportsPool: 'tote-sports-pool',
  toteSportsResultedPools: 'tote-sports-resulted-pools',

  trendingBets: 'trendingBets',
  videoPreview: 'video-preview',

  depositInitialData: 'deposit/initial-data',

  userContactDetails: 'user-contact-details',

  betAccount: 'bet-account',
} as const
