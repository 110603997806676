import React from 'react'
import styled from '@emotion/styled'
import { Icon } from '@mobi/component-library/Common/Icon'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { SkyTvBadge } from '@mobi/component-library/Racing/SkyTvIndicator'
import { NextRace as NextRaceData } from '@core/Data/Racing/nextRaces'
import { HomeNextRacesListItemStyled } from './HomeNextRaces.styles'
import { TimeToJumpComponent } from '@core/Areas/NextEvents/Components/TimeToJump/TimeToJump'
import { getNextRaceTimeDisplayOptions, buildToteRaceUri } from '@core/Areas/Racing/helpers'
import { fontFamilies } from '@core/Settings'

interface IconTypes {
  Races: keyof typeof iconPaths
  Trots: keyof typeof iconPaths
  Dogs: keyof typeof iconPaths
}

const IconTypes: IconTypes = {
  Races: 'races',
  Trots: 'trot',
  Dogs: 'dog',
}

export function HomeNextRacesTile({ race }: { race: NextRaceData }): JSX.Element {
  const [shouldShowDay, setShouldShowDay] = React.useState<boolean>(
    () => getNextRaceTimeDisplayOptions(race.AdvertisedStartTime).showDay
  )

  const [shouldShowCountdown, setShouldShowCountdown] = React.useState<boolean>(
    () => getNextRaceTimeDisplayOptions(race.AdvertisedStartTime).showCountDown
  )

  React.useEffect(() => {
    const interval = window.setInterval(() => {
      const { showCountDown, showDay } = getNextRaceTimeDisplayOptions(race.AdvertisedStartTime)
      setShouldShowCountdown(current => (showCountDown !== current ? showCountDown : current))
      setShouldShowDay(current => (showDay !== current ? showDay : current))
    }, 1_000)

    return () => {
      window.clearInterval(interval)
    }
  }, [race.AdvertisedStartTime])

  const href = buildToteRaceUri({
    meetingId: race.MeetingID,
    raceNumber: race.RaceNumber,
    meetingDate: race.MeetingDate,
  })

  const startTime = new Date(race.AdvertisedStartTime)

  return (
    <HomeNextRacesListItemStyled data-tid-home-next-events='home-next-events-item' href={href}>
      <HomeNextRacesListContainer>
        <HomeNextEventNameEventStyled>
          <div>{race.MeetingName}</div>
          <div>R{race.RaceNumber}</div>
        </HomeNextEventNameEventStyled>

        {race.SkyTvChannelId != null && (
          <HomeNextEventRaceSkyStyled>
            <SkyTvBadge channel={race.SkyTvChannelId === 'SR1' ? 1 : 2} />
          </HomeNextEventRaceSkyStyled>
        )}

        <HomeNextEventRaceTimeStyled>
          <Icon size='2rem' type={IconTypes[race.RaceType as keyof IconTypes]} />
          <TimeToJumpComponent
            size='1.2rem'
            advertisedStartTime={startTime}
            showCountDown={shouldShowCountdown}
            showDay={shouldShowDay}
          />
        </HomeNextEventRaceTimeStyled>
      </HomeNextRacesListContainer>
    </HomeNextRacesListItemStyled>
  )
}

// ======
// Styles
// ======

const HomeNextRacesListContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  maxHeight: '100%',
})

const HomeNextEventNameEventStyled = styled.div({
  fontSize: '1.1rem',
  fontFamily: fontFamilies.brand,
  lineHeight: 1.1,

  div: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
})

const HomeNextEventRaceTimeStyled = styled.div({
  fontSize: '1.2rem',
  justifyContent: 'space-between',
  display: 'flex',

  '& > span': {
    display: 'flex',
    alignItems: 'center',

    'div:last-of-type': {
      paddingLeft: '0.2rem',
    },
  },
})

const HomeNextEventRaceSkyStyled = styled.div({
  display: 'flex',
  justifyContent: 'right',
  marginTop: '-0.75rem',
  marginBottom: '-0.5rem',
})
