import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BettingHeader, SimpleBettingHeader } from '@core/Components/BettingHeader/BettingHeader'
import { betslipBettingHeader } from '@core/Components/BettingHeader/themes'
import { RaceDetails } from '@core/Data/Betting/selectionDetails'
import { RaceRecord, AcceptorRecord, Acceptor } from '@core/Data/Racing'
import { BettingType } from '@core/Data/betting'
import {
  ToteSelection,
  isRunnerVsFieldSelection,
  isConcessionSelection,
  isHeadToHeadSelection,
  isFobMatchedSelection,
  Selection,
  isFobPropositionSelection,
} from '@core/Data/Betting/selections'
import { SelectionDisplay } from '@core/Areas/Quickbet/Components/Selection/Selection'
import { RaceCountdown } from '@core/Components/RaceCountdown'
import { BetslipItem } from '@core/Areas/Betslip/driver'
import {
  getMarketTitle,
  getFixedOrToteDisplay,
} from '@core/Areas/Quickbet/Components/RaceInfo/RaceInfo'
import { addAcceptorNameToSingleSelection } from '@core/Areas/Quickbet/Components/Selection/helpers'
import { FixedOddsMarketDisplayNames } from '@core/Areas/RaceCard/constants'

enum iconTypes {
  Races = 'races',
  Trots = 'trot',
  Dogs = 'dog',
}

export const RaceBetInfo: React.FC<{
  item: BetslipItem
  isSimpleView: boolean
  isBusy?: boolean
  hasProposed?: boolean
  hasFatalError?: boolean
  removeHandler?: () => void
}> = ({ item, hasProposed, isBusy, hasFatalError, isSimpleView, removeHandler }) => {
  const { selection } = item
  const bettingType = item.bettingType === null ? BettingType.ToteRacing : item.bettingType
  const { races = [], acceptors = [] } = (item.selectionDetails || {}) as RaceDetails

  const isEditable = !hasProposed && !isBusy

  let heading = <span data-tid-meeting-name=''>Race information unavailable</span>
  let raceStartTime: string | null = null
  let racingIconCode: string | undefined
  if (races && races.length && races[0]) {
    const { raceNumber, meetingName, raceTime, meetingCode } = races[0] as RaceRecord
    heading = (
      <span data-tid-meeting-name=''>
        {meetingName + ': R' + raceNumber}
        {isSimpleView && getFixedOddsMarketName(selection, true)}
      </span>
    )
    raceStartTime = raceTime
    racingIconCode = meetingCode
  }

  if (acceptors && acceptors.length && races[0]) {
    const { type } = acceptors[0] as AcceptorRecord
    racingIconCode = type
  }

  const fixedOrToteDisplay = getFixedOrToteDisplay(selection as Selection, bettingType)
  const selectionType =
    getMarketTitle(selection as ToteSelection) +
    (fixedOrToteDisplay.length > 0 ? ` ${fixedOrToteDisplay}` : '')

  const iconType = iconTypes[racingIconCode as keyof typeof iconTypes]

  return isSimpleView ? (
    <SimpleBettingHeader
      heading={heading}
      selection={getFobSelectionText(selection as Selection, acceptors)}
    />
  ) : (
    <BettingHeader
      icon={<Icon size='3rem' type={racingIconCode && iconType ? iconType : 'trot'} />}
      heading={heading}
      selection={
        hasFatalError ? null : (
          <SelectionDisplay
            selection={selection}
            races={races}
            acceptors={acceptors}
            displayPill={false}
          />
        )
      }
      subheading={getFixedOddsMarketName(selection)}
      timeInfo={
        !hasFatalError && raceStartTime && <RaceCountdown raceStartTime={new Date(raceStartTime)} />
      }
      theme={betslipBettingHeader}
      removeHandler={removeHandler}
      displayPill={false}
      isEditable={isEditable}
      pilled={<span>{selectionType}</span>}
    />
  )
}

// =============
// Local Helpers
// =============

function getFixedOddsMarketName(selection: Selection | null, isMultiDisplay: boolean = false) {
  // Only display market name to add clarity where starter can appear in multiple markets
  const shouldDisplay =
    (isMultiDisplay && isConcessionSelection(selection)) ||
    isRunnerVsFieldSelection(selection) ||
    isHeadToHeadSelection(selection)

  const marketName =
    shouldDisplay &&
    (FixedOddsMarketDisplayNames[
      selection.marketCode as keyof typeof FixedOddsMarketDisplayNames
    ] ||
      selection.marketName)

  return shouldDisplay ? `${isMultiDisplay ? ' - ' : ''}${marketName}` : null
}

function getFobSelectionText(
  selection: Selection,
  acceptors: Acceptor[]
): string | null | undefined {
  if (isFobMatchedSelection(selection)) {
    return addAcceptorNameToSingleSelection(`${selection.acceptorNumber}`, acceptors)
  }

  if (!isFobPropositionSelection(selection)) {
    return null
  }

  const { number: sNumber, name } = selection.selectedAcceptors?.[0] || {}
  if (sNumber && name) {
    return `${sNumber} - ${name}`
  }

  return selection.displayName
}
