import React from 'react'
import type { CreditCardButton } from '../types'
import { PaymentMethodButtonStyled } from '../PaymentMethodButton.styled'
import { CreditCardDisplay } from './CreditCardDisplay'
import { makeAccessibleName } from './helpers'
import { AmericanExpress } from '../../Icons'

const issuerName = 'American Express'

export const AmericanExpressButton: CreditCardButton = ({ lastFour, disabled, onClick }) => {
  return (
    <PaymentMethodButtonStyled
      provider='americanExpress'
      aria-label={makeAccessibleName(issuerName, lastFour)}
      disabled={disabled}
      onClick={onClick}
    >
      <AmericanExpress />
      <CreditCardDisplay issuerName={issuerName} lastFour={lastFour} />
    </PaymentMethodButtonStyled>
  )
}
