import React from 'react'
import { matchPath, useLocation } from 'react-router'
import type { Contract } from '@mobi/web-native-comms'
import { isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'
import { AppRoutes } from '@core/AppRoutes'
import { isRaceRoute } from '@core/State/Navigation/helpers/isRaceRoute'

export const useActiveTab = () => {
  const { pathname } = useLocation()

  const activeTab = getActiveTabName(pathname)

  React.useEffect(() => {
    if (!isReactNativeApp) return
    sendToNative('SET_ACTIVE_TAB', { activeTab })
  }, [activeTab])

  return activeTab
}

// =============
// Local Helpers
// =============

function getActiveTabName(pathname: string): Contract['SET_ACTIVE_TAB']['activeTab'] {
  const homePageMatch = matchPath(pathname, AppRoutes.Home)
  if (homePageMatch?.isExact && homePageMatch.path === '/') {
    return 'Home'
  }

  if (
    matchPath(pathname, AppRoutes.SportsRoot)?.path ||
    Boolean(matchPath(pathname, AppRoutes.Sports))
  ) {
    return 'Sports'
  }

  if (isRaceRoute(pathname)) {
    return 'Races'
  }

  if (pathname === AppRoutes.RaceCardSky) {
    return 'SkyRacing'
  }

  return undefined
}
