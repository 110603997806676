import React from 'react'
import styled from '@emotion/styled'
import { colors, font, measurements, spacing, layering } from '@mobi/component-library/Theme/Common'
import { Button } from '@mobi/component-library/Common/V2'
import { useLogon } from '@core/Utils/hooks'
import { useExpandToggle } from '@mobi/utils/hooks/useExpandToggle'
import { showLogin } from '@core/Areas/Login/helpers'
import { store } from '@core/Store'
import { useAppSelector } from '@core/Store/hooks'
import {
  selectIsDockedSkyVideoPlayerOpen,
  selectIsSkyVideoPlayerOpen,
} from '@core/Areas/SkyVideoPlayer/Store/selectors'
import {
  setCloseSkyVideoPlayer,
  setSkyDockedPlayerHeight,
  setSkyVideoPlayerStatus,
} from '@core/Areas/SkyVideoPlayer/Store'
import { VideoPlayer } from '@core/Areas/SkyVideoPlayer/Components/VideoPlayer/VideoPlayer'
import { DOCKED_EXPAND_ANIMATION_MS } from './constants'
import { useAutoFullscreenOnNativeLandscape } from './Hooks/useAutoFullscreenOnNativeLandscape'

const enum LocalConstants {
  LoginPromptClass = 'js-sky-docked__login',
}

export const DockedPlayer: React.FC<{ isOnSkyRacePage?: boolean }> = ({ isOnSkyRacePage }) => {
  useAutoFullscreenOnNativeLandscape()
  const isOnSkyRacePageRef = React.useRef(isOnSkyRacePage)
  const isDockedPlayerOpen = useAppSelector(selectIsDockedSkyVideoPlayerOpen)

  const { wrapperRef, contentRef, shouldRender, isExpanded, toggleExpanded } = useExpandToggle(
    DOCKED_EXPAND_ANIMATION_MS
  )

  const { isLoggedIn } = useLogon()

  // Control sky video show/hide animation
  React.useEffect(() => {
    toggleExpanded(isDockedPlayerOpen)
  }, [isDockedPlayerOpen, toggleExpanded])

  React.useEffect(() => {
    if (isLoggedIn === null) return
    if (isOnSkyRacePageRef.current) {
      store.dispatch(setSkyVideoPlayerStatus({ playerStatus: 'docked' }))
    }
    return () => {
      if (!isLoggedIn) {
        store.dispatch(setCloseSkyVideoPlayer({ shouldTrack: false }))
      } else if (isLoggedIn && selectIsSkyVideoPlayerOpen(store.getState())) {
        store.dispatch(setSkyVideoPlayerStatus({ playerStatus: 'floating' }))
      }
    }
  }, [isLoggedIn])

  // Update docked player height in store, used in pinned elements
  React.useEffect(() => {
    if (!contentRef.current || !isExpanded) {
      store.dispatch(setSkyDockedPlayerHeight(null))
      return
    }

    const el = contentRef.current
    const setHeight = () =>
      store.dispatch(setSkyDockedPlayerHeight(el.getBoundingClientRect().height))
    setHeight()
    // Wrap w/ setTimeout to prevent uncaught error "ResizeObserver loop completed with undelivered notifications"
    const observer = new ResizeObserver(() => setTimeout(setHeight, 0))
    observer.observe(el)
    return () => observer.disconnect()
  }, [isExpanded, contentRef])

  if (!isExpanded && !shouldRender) return null

  return (
    <WrapperStyled ref={wrapperRef}>
      <div ref={contentRef}>
        <>
          {isLoggedIn ? (
            <VideoPlayer />
          ) : (
            <div className={LocalConstants.LoginPromptClass}>
              <div>
                <h4>Please log in</h4>
                <span>Watch live racing for free with your account.</span>
                <Button color='secondary_color' onClick={() => showLogin()}>
                  Log in / Join
                </Button>
              </div>
            </div>
          )}
        </>
      </div>
    </WrapperStyled>
  )
}

const WrapperStyled = styled.div({
  position: 'sticky',
  top: -1,
  zIndex: layering.pagePopoverElements + 1,
  maxWidth: measurements.mobi.maxWidth,
  margin: '0 auto',
  background: colors.black,

  overflow: 'hidden',
  transition: `height ${DOCKED_EXPAND_ANIMATION_MS}ms ease`,
  willChange: 'height',
  height: 0,

  '> div': {
    margin: '0 auto',
    minWidth: '20rem',

    '@supports (aspect-ratio: 16 / 9)': {
      maxHeight: '35vh',
      aspectRatio: '16 / 9',
    },

    '@supports not (aspect-ratio: 16 / 9)': {
      // padding % is based on parent width, hence maxWidth on parent here
      // video player aspect ratio fallback uses padding ratio technique
      maxWidth: '60vh',
    },
  },

  [`.${LocalConstants.LoginPromptClass}`]: {
    position: 'relative',
    overflow: 'hidden',

    height: 0,
    paddingTop: 'min(56.25%, 100vh)', // 16:9

    '@supports (aspect-ratio: 16 / 9)': {
      height: 'auto',
      aspectRatio: '16 / 9',
      paddingTop: 0,
    },

    '> div': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      backgroundColor: colors.lavender[900],

      h4: {
        fontFamily: font.family.primary,
        fontSize: font.size.xl2.fontSize,
        letterSpacing: font.size.xl2.letterSpacing,
        lineHeight: font.size.xl2.lineHeight,
        fontWeight: font.weight.semibold,
        color: colors.white,
        margin: 0,
        marginBottom: spacing.md,
      },

      '> span': {
        fontFamily: font.family.primary,
        fontSize: font.size.md.fontSize,
        letterSpacing: font.size.md.letterSpacing,
        lineHeight: font.size.md.lineHeight,
        fontWeight: font.weight.regular,
        color: colors.neutral[300],
        marginBottom: spacing.big,
      },
    },
  },
})
