import type { MutableRefObject } from 'react'
import { Client } from 'braintree-web'
import { useMutation, useQueryClient } from 'react-query'
import type { DepositRequest, DepositResponse } from '@mobi/api-types'
import { deposit } from '../Utils/api'
import { getDeviceData } from '../Utils/braintree'
import { reportErrorIfNeeded } from '../Utils/sentry'
import { queryKeys } from '../constants'

export type UseDepositProps = {
  braintreeClient: MutableRefObject<Client | undefined>
}

export type DepositRequestWithoutDeviceData<T extends DepositRequest> = Omit<T, 'deviceData'>

/**
 * Hook for calling the deposit function, ensuring initial data cache is invalidated
 */
export function useDeposit<T extends DepositRequest>({ braintreeClient }: UseDepositProps) {
  const queryClient = useQueryClient()

  if (!braintreeClient) {
    const message = `Braintree client is uninitialized`
    reportErrorIfNeeded({ message })
    throw new Error(message)
  }

  const depositMutation = useMutation<DepositResponse, Error, DepositRequestWithoutDeviceData<T>>({
    mutationFn: async ({ amount, paymentMethodNonce, ...args }) => {
      const client = braintreeClient.current

      if (!client) {
        throw new Error('Braintree client is uninitialized')
      }

      const deviceData = await getDeviceData(client)

      return deposit({ ...args, amount, paymentMethodNonce, deviceData })
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.depositInitialData)
    },
  })

  return {
    depositMutation,
  }
}
