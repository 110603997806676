/* istanbul ignore file */
import React from 'react'
import { getFromLocalStorage, removeFromLocalStorage } from '@mobi/utils/storage'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { setMigrateIsBalanceVisible } from '../Store'

const enum LocalConstants {
  LegacyBalanceStoreKey = 'ACCOUNT_BALANCE_VISIBILITY',
}

export const useMigrateBalanceVisible = () => {
  const dispatch = useAppDispatch()
  const hasMigratedIsBalanceVisible = useAppSelector(
    state => state.appHeader.hasMigratedIsBalanceVisible
  )

  React.useEffect(() => {
    if (hasMigratedIsBalanceVisible) return

    const isVisibile = !!JSON.parse(
      getFromLocalStorage(LocalConstants.LegacyBalanceStoreKey) || 'true'
    )
    removeFromLocalStorage(LocalConstants.LegacyBalanceStoreKey)
    dispatch(setMigrateIsBalanceVisible(isVisibile))
  }, [dispatch, hasMigratedIsBalanceVisible])
}
