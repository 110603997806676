import React from 'react'
import { observeImmutable } from '@core/Components/HOCs'
import { isMysteryDetails, MysteryDetails } from '@core/Data/Betting/selectionDetails'
import { ErrorMessage } from '@core/Components/Messages'
import type { Race } from '@core/Data/Racing'
import { type QuickbetState, state$ as quickbetState$ } from '@core/Areas/Quickbet/driver'
import { MysteryInfo } from '@core/Areas/Quickbet/Components/MysteryInfo/MysteryInfo'
import type { MysterySelection } from '@core/Data/Betting/selections'
import { MysteryDisplaySelections } from '@core/Areas/Quickbet/Components/Selection/MysteryDisplaySelections'
import { isMysterySelection } from '@core/Data/Betting/selections'

type MysteryHeaderProps = {
  race: Race
  isRaceClosed?: boolean
  selectionDetails: MysteryDetails
} & Required<Pick<QuickbetState, 'selection'>>

function MysteryHeaderComponent({
  race,
  selection,
  selectionDetails,
  isRaceClosed,
}: MysteryHeaderProps) {
  // we only ever show information for one race. for win/place/trifecta, etc. that is the _only_ race
  // for double/quaddie/all-up, we show the first race, as that is when the pool will close

  const mysterySelection = selection as MysterySelection
  const mysteryDetails = selectionDetails as MysteryDetails

  return (
    <>
      {!!race && (
        <MysteryInfo
          key={race.key}
          race={race}
          selectionDetails={mysteryDetails}
          isRaceClosed={isRaceClosed}
        />
      )}

      <MysteryDisplaySelections selection={mysterySelection} details={mysteryDetails} />
    </>
  )
}
export const MysteryHeader: React.ComponentClass = observeImmutable(
  quickbetState$,
  ({ record }) => {
    const {
      selection,
      selectionDetails,
      // notificationType,
    } = record.toJS() as QuickbetState

    if (!isMysterySelection(selection)) {
      return !PRODUCTION ? (
        <ErrorMessage>
          Selection details provided to MysteryHeader was not compatible with MysteryDetails
        </ErrorMessage>
      ) : null
    }

    if (!isMysteryDetails(selectionDetails)) {
      return !PRODUCTION ? (
        <ErrorMessage>
          Selection details provided to MysteryHeader was not compatible with MysteryDetails
        </ErrorMessage>
      ) : null
    }
    return (
      <MysteryHeaderComponent
        race={selectionDetails.race}
        selection={selection}
        selectionDetails={selectionDetails}
      />
    )
  }
)
MysteryHeader.displayName = 'MysteryHeader'
