import React from 'react'
import dayjs from 'dayjs'
import { navigateToDepositLimitsView } from '@classic/AppUtils/Framework/Intent/navigation'
import { injectGlobal } from '@emotion/css'
import { ThemeProvider } from '@emotion/react'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { Paragraph } from '@core/Components/Text/Paragraph'

import {
  DepositLimitsContainerStyled as DepositLimitsContainer,
  AmountInputStyled as AmountInput,
  ListItemSubtitleStyled as ListItemSubtitle,
  OrderedListStyled as OrderedList,
  OrderedListItemStyled,
  ButtonBlockThemed,
  FieldSetStyled as FieldSet,
} from './DepositLimits.styles'
import { savePrecommitments } from '@core/Data/Account/contactDetails'
import { PreCommitmentDetails, DepositLimitFrequency } from '@mobi/api-types'
import { CheckboxInput } from '@mobi/component-library/Common/Input'
import { RadioInput } from '@mobi/component-library/Common/Input'
import {
  CoolingOffNoticeBox,
  CurrentFrequencyNoticeBox,
  CurrentAmountNoticeBox,
  ErrorPopupNoticeBox,
} from './DepositLimitsComponents'
import { AccountHolder } from '@core/Data/Account/accountHolder'
import { getDepositLimitTheme, DepositLimitThemes, DepositLimitComponentTheme } from './themes'

interface DepositLimitsEditProps {
  accountHolder: AccountHolder
  isDesktop?: boolean
  isSignup?: boolean
  onCancel?(): void
  onSubmit?(): void
}

export interface DepositLimitsEditState {
  accountHolderNumber: number
  precommitmentDetails: PreCommitmentDetails
  maxDeposit: {
    amount: number | null
    frequency: DepositLimitFrequency
  }
  amountEntry: string
  allowAmountEntry: boolean
  amountValidationError: string | false
  confirmCheckboxState: boolean
  isSubmitEnabled: boolean
  showError: boolean
  coolingOffPeriodRequired: boolean
}

export class DepositLimitsEdit extends React.Component<
  DepositLimitsEditProps,
  DepositLimitsEditState
> {
  private MIN_DEPOSIT_AMOUNT: number
  private MAX_DEPOSIT_AMOUNT: number

  constructor(props: DepositLimitsEditProps) {
    super(props)

    this.MIN_DEPOSIT_AMOUNT = 1
    this.MAX_DEPOSIT_AMOUNT = 99999

    const { isSignup } = props
    const { BetAccountHolderNumber, PreCommitmentDetails: details } = props.accountHolder
    const { MaxDepositAmount, MaxDepositFrequency } = details

    this.state = {
      accountHolderNumber: BetAccountHolderNumber,
      precommitmentDetails: details,
      maxDeposit: {
        amount: MaxDepositAmount,
        frequency: MaxDepositFrequency,
      },
      amountEntry: MaxDepositAmount ? MaxDepositAmount.toString() : '',
      allowAmountEntry: !!MaxDepositFrequency,
      amountValidationError: false,
      confirmCheckboxState: isSignup || false,
      isSubmitEnabled: false,
      showError: false,
      coolingOffPeriodRequired: false,
    }
  }

  public componentDidUpdate(): void {
    if (this.state.showError) {
      window.scrollTo(0, 0)
    }
  }

  public render(): JSX.Element {
    const { isDesktop, isSignup } = this.props
    const {
      isSubmitEnabled,
      allowAmountEntry,
      amountValidationError,
      maxDeposit: { frequency },
      amountEntry,
      precommitmentDetails,
      coolingOffPeriodRequired,
    } = this.state

    /*
      FOR MOBI:
      The Sign-Up process has it's own set of (global) styles in (S)CSS. Making it difficult
      for our React components to look good in that context. As all of the form components
      used in the Deposit Limit Edit view are based off the HTML (root-element) `font-size`
      we need to inject global styles that match mobi in order for our components to scale
      correctly.
    */
    if (isSignup && !isDesktop) {
      injectGlobal`html { font-size: 62.5%; }` // 62.5% matches `_core.scss`.
    }

    let theme: DepositLimitComponentTheme
    if (isSignup && isDesktop) {
      theme = getDepositLimitTheme(DepositLimitThemes.desktopSignup)
    } else if (isSignup && !isDesktop) {
      theme = getDepositLimitTheme(DepositLimitThemes.signUp)
    } else {
      theme = getDepositLimitTheme(DepositLimitThemes.default)
    }

    return (
      <ThemeProvider theme={theme}>
        <MainContainer forceSizing={!isSignup} data-tid-deposit-limits-edit-container=''>
          {!isSignup && !isDesktop ? <ContentHeader title='Deposit Limits' /> : null}
          <DepositLimitsContainer>
            <Paragraph>
              TABtouch supports responsible wagering. You can control the amount you deposit by
              setting your preferred limits below:
            </Paragraph>

            <form noValidate={true} onSubmit={this.handleFormSubmit}>
              <FieldSet>
                <strong>Select the frequency of your limit</strong>

                {!isSignup && precommitmentDetails.MaxDepositFrequency && (
                  <CurrentFrequencyNoticeBox frequency={precommitmentDetails.MaxDepositFrequency} />
                )}

                <OrderedList>
                  <OrderedListItemStyled>
                    <RadioInput
                      name='frequency'
                      checked={frequency === null}
                      value='None'
                      onChange={this.handleFrequencyChange}
                      data-tid-frequency='none'
                      label='No Limit'
                      id='deposit-limit-radio-none'
                    />
                    <ListItemSubtitle>You can deposit without restriction.</ListItemSubtitle>
                  </OrderedListItemStyled>
                  <OrderedListItemStyled>
                    <RadioInput
                      name='frequency'
                      checked={frequency === 'Daily'}
                      value='Daily'
                      onChange={this.handleFrequencyChange}
                      data-tid-frequency='daily'
                      label='Daily'
                      id='deposit-limit-radio-daily'
                    />
                    <ListItemSubtitle>
                      Restriction lasts until 12:00am the following day.
                    </ListItemSubtitle>
                  </OrderedListItemStyled>
                  <OrderedListItemStyled>
                    <RadioInput
                      name='frequency'
                      checked={frequency === 'Weekly'}
                      value='Weekly'
                      onChange={this.handleFrequencyChange}
                      data-tid-frequency='weekly'
                      label='Weekly'
                      id='deposit-limit-radio-weekly'
                    />
                    <ListItemSubtitle>
                      Restriction starts Monday and resets the following Sunday.
                    </ListItemSubtitle>
                  </OrderedListItemStyled>
                  <OrderedListItemStyled>
                    <RadioInput
                      name='frequency'
                      checked={frequency === 'Fortnightly'}
                      value='Fortnightly'
                      onChange={this.handleFrequencyChange}
                      data-tid-frequency='fortnightly'
                      label='Fortnightly'
                      id='deposit-limit-radio-fortnightly'
                    />
                    <ListItemSubtitle>
                      Restriction starts Monday and will reset two weeks after on the Sunday.
                    </ListItemSubtitle>
                  </OrderedListItemStyled>
                  <OrderedListItemStyled>
                    <RadioInput
                      name='frequency'
                      checked={frequency === 'Monthly'}
                      value='Monthly'
                      onChange={this.handleFrequencyChange}
                      data-tid-frequency='monthly'
                      label='Monthly'
                      id='deposit-limit-radio-monthly'
                    />
                    <ListItemSubtitle>
                      Restrictions are based on the calendar month.
                    </ListItemSubtitle>
                  </OrderedListItemStyled>
                </OrderedList>
              </FieldSet>
              <label>
                <strong>
                  Set the dollar amount you wish to be limited to based on the frequency selected:
                </strong>
              </label>

              {!isSignup && precommitmentDetails.MaxDepositAmount && (
                <CurrentAmountNoticeBox amount={precommitmentDetails.MaxDepositAmount} />
              )}

              <AmountInput
                type='number'
                pattern='\d*'
                min={this.MIN_DEPOSIT_AMOUNT}
                max={this.MAX_DEPOSIT_AMOUNT}
                value={allowAmountEntry ? amountEntry : ''}
                onChange={this.handleAmountChange}
                errorMessage={allowAmountEntry && amountValidationError}
                testId='amount'
                disabled={!allowAmountEntry}
                placeholder={allowAmountEntry ? 'Please enter an amount' : 'N/A'}
              />

              {!isSignup && (
                <Paragraph data-tid-confirm-message=''>
                  <strong>By Proceeding&#8230;</strong>
                  <br />I understand that once my deposit limit is set, there will be a 7 day
                  cooling off period should I choose to increase the amount or frequency of the
                  limit. Any limit decrease will be effective immediately.
                </Paragraph>
              )}

              {!isSignup && coolingOffPeriodRequired && (
                <CoolingOffNoticeBox coolingOffEndDate={this.calculateCoolingOffPeriodEndDate()} />
              )}

              {!isSignup && (
                <CheckboxInput
                  label='I understand and accept.'
                  id='confirm-changes-checkbox'
                  onChange={this.handleConfirmCheckboxChange}
                  data-tid-confirm=''
                />
              )}

              <Grid padding='0.25rem'>
                <GridCell data-tid-cancel-button=''>
                  <ButtonBlockThemed
                    id={isSignup ? 'deposit-limit-skip-button' : 'deposit-limit-cancel-button'}
                    color='secondary'
                    type='button'
                    onClick={this.handleCancelClick}
                  >
                    {isSignup ? 'Skip' : 'Cancel'}
                  </ButtonBlockThemed>
                </GridCell>
                <GridCell data-tid-save-button=''>
                  <ButtonBlockThemed
                    id={isSignup ? 'deposit-limit-confirm-button' : 'deposit-limit-save-button'}
                    type='submit'
                    onClick={this.handleFormSubmit}
                    disabled={!isSubmitEnabled}
                  >
                    {isSignup ? 'Confirm' : 'Save'}
                  </ButtonBlockThemed>
                </GridCell>
              </Grid>
            </form>
          </DepositLimitsContainer>
          {this.state.showError && <ErrorPopupNoticeBox onClose={this.closePopup} />}
        </MainContainer>
      </ThemeProvider>
    )
  }

  private handleFormSubmit = (
    event: React.FormEvent<HTMLFormElement> | React.FormEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
    if (this.state.isSubmitEnabled) {
      this.saveDepositLimits()
    }
  }

  private handleCancelClick = () => {
    if (this.props.onCancel) {
      this.props.onCancel()
    } else {
      navigateToDepositLimitsView({
        isDesktop: this.props.isDesktop,
        saveSuccess: false,
        accountHolderNumber: this.state.accountHolderNumber,
      })
    }
  }

  private saveDepositLimits = async () => {
    // Disable the proceed button to stop people from 'double-tapping' it.
    this.setState({ isSubmitEnabled: false })

    const {
      precommitmentDetails: {
        ProposedOptOutDate,
        MaxBetAmount,
        ProposedMaxBetAmount,
        ProposedMaxBetDate,
        OptInMarketingIndicator,
        UserCreditCardBlockedIndicator,
        ProposedUserCreditCardBlockedDate,
        BlockPayPalIndicator,
        ProposedPayPalReleaseDate,
      },
      maxDeposit: { frequency, amount },
      accountHolderNumber,
    } = this.state

    // the CRM API requires these transformations or it will delete any proposed values :'(
    const newMaxBetAmount =
      ProposedMaxBetDate || ProposedOptOutDate ? ProposedMaxBetAmount : MaxBetAmount
    const newOptInIndicator = !!newMaxBetAmount || !!frequency
    const newUserCreditCardBlockerIndicator =
      !ProposedUserCreditCardBlockedDate && UserCreditCardBlockedIndicator
    const newBlockPayPaylIndicator = !ProposedPayPalReleaseDate && BlockPayPalIndicator

    try {
      await savePrecommitments({
        BetAccountNumber: accountHolderNumber,
        OptInIndicator: newOptInIndicator,
        MaxBetAmount: newMaxBetAmount,
        MaxWeeklyDepositLimit: '',
        OptInMarketingIndicator,
        UserCreditCardBlockedIndicator: newUserCreditCardBlockerIndicator,
        BlockPayPalIndicator: newBlockPayPaylIndicator,
        AccountHolderId: accountHolderNumber,
        PreCommitmentMaxDepositAmount: frequency && amount,
        PreCommitmentMaxDepositFrequency: frequency,
      })

      this.setState({ showError: false })

      if (this.props.onSubmit) {
        this.props.onSubmit()
      } else {
        navigateToDepositLimitsView({
          isDesktop: this.props.isDesktop,
          saveSuccess: true,
          accountHolderNumber: this.state.accountHolderNumber,
        })
      }
    } catch (error) {
      this.setState({ showError: true, isSubmitEnabled: true })
    }
  }

  private handleFrequencyChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    const frequency =
      value === 'Daily' || value === 'Weekly' || value === 'Fortnightly' || value === 'Monthly'
        ? value
        : null

    this.setState(state => ({
      allowAmountEntry: !!frequency,
      maxDeposit: {
        ...state.maxDeposit,
        frequency,
      },
    }))

    this.isCoolingOffPeriodRequired()
    this.canProceedToSaveDepositLimits()
  }

  private handleAmountChange = (event: React.FormEvent<HTMLInputElement>) => {
    const amountEntry = event.currentTarget.value
    this.setState({ amountEntry })

    this.validateAmount()
    this.isCoolingOffPeriodRequired()
    this.canProceedToSaveDepositLimits()
  }

  private validateAmount = () => {
    this.setState(state => {
      const { amountEntry } = state
      const newAmount = Number(amountEntry)
      const invalidMessage = `Please enter a whole number between ${this.MIN_DEPOSIT_AMOUNT} and ${this.MAX_DEPOSIT_AMOUNT}`
      const isValid =
        !Number.isNaN(newAmount) &&
        Number.isInteger(newAmount) &&
        newAmount >= this.MIN_DEPOSIT_AMOUNT &&
        newAmount <= this.MAX_DEPOSIT_AMOUNT
      return {
        maxDeposit: {
          ...state.maxDeposit,
          amount: isValid ? newAmount : null,
        },
        amountValidationError: !isValid && invalidMessage,
      }
    })
  }

  private isCoolingOffPeriodRequired = () => {
    this.setState(state => {
      const coolingOffPeriodRequired =
        state.maxDeposit.frequency && state.amountValidationError
          ? false
          : this.evaluateCoolingOffPeriodRequired(
              state.precommitmentDetails.MaxDepositFrequency,
              state.precommitmentDetails.MaxDepositAmount,
              state.maxDeposit.frequency,
              state.maxDeposit.amount
            )

      return {
        coolingOffPeriodRequired,
      }
    })
  }

  private evaluateCoolingOffPeriodRequired = (
    oldFrequency: DepositLimitFrequency,
    oldAmount: number | null,
    newFrequency: DepositLimitFrequency,
    newAmount: number | null
  ) => {
    if (!oldFrequency || !oldAmount) {
      return false
    }

    if (!newFrequency || !newAmount) {
      return true
    }

    if (newAmount > oldAmount) {
      return true
    }

    const divisors: { [K in NonNullable<DepositLimitFrequency>]: number } = {
      Daily: 1,
      Weekly: 7,
      Fortnightly: 14,
      Monthly: 30.4375,
    }

    const oldDayRate = oldAmount / divisors[oldFrequency]
    const newDayRate = newAmount / divisors[newFrequency]

    if (newDayRate > oldDayRate) {
      return true
    }

    return false
  }

  private calculateCoolingOffPeriodEndDate(): Date {
    return dayjs().startOf('day').add(7, 'days').toDate()
  }

  private handleConfirmCheckboxChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget
    this.setState({ confirmCheckboxState: checked }, this.canProceedToSaveDepositLimits)
  }

  private canProceedToSaveDepositLimits() {
    this.setState(state => {
      const {
        maxDeposit: { frequency, amount },
        confirmCheckboxState,
        amountValidationError,
      } = state
      const isSubmitEnabled =
        (!frequency || (!!amount && !amountValidationError)) && confirmCheckboxState
      return { isSubmitEnabled }
    })
  }

  private closePopup = () => {
    this.setState({ showError: false })
  }
}
