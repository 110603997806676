import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEnhancedBetslipSetting } from '@core/Areas/Settings/Store/selectors'
import { setEnhancedBetslipSetting } from '@core/Areas/Settings/Store'
import { SettingItem } from '../SettingItem'

export const EnhancedBetslipSetting = () => {
  const dispatch = useDispatch()
  const isSettingEnabled = useSelector(getEnhancedBetslipSetting)

  return (
    <SettingItem
      isEnabled={isSettingEnabled}
      label='Smart Betslip'
      desciption='Automatically add runners to your betslip rather than quickbet. This allows you to
      create multis faster than ever by adding each additional selection directly to your
      betslip'
      onToggle={() => {
        dispatch(setEnhancedBetslipSetting(!isSettingEnabled))
      }}
    />
  )
}
