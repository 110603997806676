import {
  AllUpFormula,
  AllUpSelectionDetails,
  SameRaceMultiSelectionAcceptor,
} from '@core/Data/Betting/selections'

export type LoadBetDetails = BetDetails | FOOBetDetails

export const enum LoadBetErrors {
  Default = "Sorry, we're unable to create bet",
  BettingClosed = 'Betting Closed',
}

export interface BetDetails {
  fixtureId: string
  fixtureDate: Date
  races: number[]
  selectionString: string
  planSeq: number
  numberOfCombinations?: number
  betType: string | null

  // All Up
  details?: AllUpSelectionDetails[]
  formulas?: AllUpFormula[]

  // Same Race Multi
  srmSelections?: SameRaceMultiSelectionAcceptor[]
}

export interface FOOBetDetails {
  competitionName: string
  competitionSeq: string
  propositionSeq: number
  betType: string | null
  sportName: string | null
  eventStartDateTime: string
}
