import { get } from './ApiService'

export * from '@classic/Foundation/Services/ServerConfig'

var currentConfig: Promise<IConfig> | null = null
var expiry: number = -1

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TABTOUCH: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    LDFlags: any
  }
  interface IConfig {
    serverEnvironment: string
    enableSentryIo: boolean
    enableJsConsoleLogging: boolean
    accountRecoveryHost: string
    rwwaPreferencesHost: string
    sentryIoUrl: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    androidSettings: any
    enableAnalytics: boolean
    homepageBannerFallbackImage: string
    homepageBannerFallbackUrl: string
    isInternalRequest: boolean
    launchDarklyClientID: string
    hubUrl: string
    eventNotificationApi: {
      url: string
      region: string
      identityPoolId: string
    }
    metricsCollectionApi: {
      url: string
      region: string
      identityPoolId: string
    }
    accountBalancePollingIntervalMilliseconds: number
  }
}

export function getConfig(): Promise<IConfig> {
  const now = Date.now()
  if (currentConfig && now < expiry) {
    return currentConfig
  }
  expiry = now + 1000 * 60 * 60 /* 1 hour */
  return (currentConfig = get<IConfig>({ url: '/api/config' }))
}
