import * as immutable from 'immutable'
import { BetslipItem } from '../driver'
import { BetLegType, BettingType } from '@core/Data/betting'
import { v1 as createUuid } from 'uuid'
import { defaultInvestmentState } from '@core/Areas/Quickbet/Components/BetInvestment/betInvestmentDriver'
import {
  isToteSelection,
  Selection,
  isAllUpSelection,
  isStartingPriceSelection,
} from '@core/Data/Betting/selections'
import { EventDetails } from '@core/Data/Betting/selectionDetails'
import { getNumCombinationsSelected } from '@core/Areas/Quickbet/Components/Formula/driver'

interface CreateItemForBetslip {
  bettingType: BettingType
  isEachWayAvailable: boolean
  selection: Selection
  selectionDetails: EventDetails | null
  shouldAllowPlaceInvestment: boolean
  tags?: string[]
  betSource?: string
}

export const createItemForBetslip = ({
  bettingType,
  isEachWayAvailable,
  selection,
  selectionDetails,
  shouldAllowPlaceInvestment,
  tags,
  betSource,
}: CreateItemForBetslip): BetslipItem => {
  const isAllowedInMulti = !isToteSelection(selection) && !isStartingPriceSelection(selection)
  return {
    id: createUuid(),
    bettingType,
    isEachWayAvailable,
    isEachWay: false,
    investment: {
      win: {
        ...defaultInvestmentState.win,
        value: 0,
      },
      place: {
        ...defaultInvestmentState.place,
        value: 0,
      },
      bonusBet: undefined,
    },
    isSuperPickAvailable: false,
    selection,
    selectionDetails,
    shouldAllowPlaceInvestment,
    numberOfCombinations: isAllUpSelection(selection)
      ? getNumCombinationsSelected(immutable.List(selection.formulas))
      : 1,
    errorMessage: '',
    selectedSuperPickOffer: null,
    isInMulti: isAllowedInMulti,
    multiLegBetType: isAllowedInMulti ? BetLegType.Win : undefined,
    multiBetLegError: null,
    specialOffers: [],
    hasIotSubscription: false,
    tags,
    betSource,
  }
}
