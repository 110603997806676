import React from 'react'
import {
  AccountBalanceStyled,
  AccountBalanceMoneyStyled,
  AccountBalanceTouchableStyled,
  BonusBalanceMoneyStyled,
} from './AccountBalance.styles'
import { close as modalClose } from '@classic/AppUtils/Framework/Intent/modal'
import { openDeposit } from '@core/Areas/Deposit'
import {
  UpdateAccountBalance,
  state$ as userAccountState$,
} from '@core/State/UserAccount/userAccountDriver'
import {
  BetslipDepositModalClosed,
  ConfirmAllBetsSuccessful,
  OnClose,
  RefreshBetslipSuccessful,
} from '../../signals'
import { BetslipResponse } from '@core/Data/betslip'
import { useObservableImmutable } from '@core/Utils/hooks'
import { fetchAccountBalanceAsync } from '@core/State/UserAccount/async-signals'
import { hasValue } from '@mobi/utils'
import { OverlayClose } from '@core/Components/Overlay'

export const AccountBalance = () => {
  const {
    accountBalance: balance,
    bonusBetBalance,
    bonusCashBalance,
  } = useObservableImmutable(userAccountState$, [
    'accountBalance',
    'bonusBetBalance',
    'bonusCashBalance',
  ])

  React.useEffect(() => {
    const sideEffectConfirmAllBetsSuccessful = ConfirmAllBetsSuccessful.signal$.subscribe(
      ({ data }: { data: BetslipResponse[] }) => {
        let shouldUpdateBalance = false
        const accountBalancesFromResponse = data.reduce((arr: number[], item) => {
          if (item.success && item.receipt && item.receipt.accountBalance >= 0) {
            shouldUpdateBalance = true
            return arr.concat(item.receipt.accountBalance)
          }
          return arr
        }, [])
        if (shouldUpdateBalance) {
          accountBalancesFromResponse.sort((a, b) => a - b)
          UpdateAccountBalance({ balance: accountBalancesFromResponse[0] })
        }
      }
    )

    const sideEffectRefreshBetslip =
      RefreshBetslipSuccessful.signal$.subscribe(fetchAccountBalanceAsync)

    const legacyModalSubscription = modalClose.signal$.throttle(200).subscribe(() => {
      BetslipDepositModalClosed()
    })

    return function cleanUp() {
      sideEffectConfirmAllBetsSuccessful.dispose()
      sideEffectRefreshBetslip.dispose()
      legacyModalSubscription.dispose()
    }
  }, [])

  const bonusBalanceToDisplay = (bonusBetBalance ?? 0) + (bonusCashBalance ?? 0)

  const handleDepositClick = () => {
    OverlayClose()
    OnClose()
    openDeposit()
  }

  return hasValue(balance) ? (
    <AccountBalanceStyled>
      <AccountBalanceTouchableStyled onClick={handleDepositClick}>
        <AccountBalanceMoneyStyled amount={balance} />
      </AccountBalanceTouchableStyled>
      {!!bonusBalanceToDisplay && (
        <BonusBalanceMoneyStyled prefix={'Bonus: '} amount={bonusBalanceToDisplay} />
      )}
    </AccountBalanceStyled>
  ) : null
}
