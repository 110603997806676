import React from 'react'
import * as immutable from 'immutable'
import { observeImmutable } from '@core/Components/HOCs'
import { state$, BetslipItem, BetslipExpandableTypes } from '../../driver'
import { ToggleBetslipExpandable } from '../../signals'
import { BetInfo } from '../BetInfo/BetInfo'
import { BetInvestment } from '../BetInvestment/BetInvestment'
import { BetslipExpandablePanel } from '../BetslipExpandablePanel/BetslipExpandablePanel'
import {
  ItemStyled,
  ListStyled,
  SingleItemNoticeWrapper,
  TotalCostMoneyStyled,
  TotalCostSectionStyled,
} from './SingleItemList.styles'
import { BetslipReceipt } from '../BetslipReceipt/BetslipReceipt'
import {
  isToteSelection,
  isNoveltyBetType,
  ToteSelection,
  isAllUpSelection,
  AllUpSelection,
  isMysterySelection,
  isMysteryComboBetType,
} from '@core/Data/Betting/selections'
import { RefreshBetslipButton } from '../Buttons/RefreshButton'
import {
  ReceiptHeaderStyled,
  ReceiptHeaderTextStyled,
} from '../BetslipReceipt/BetsliptReceipt.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import {
  isFatalErrorType,
  isNonHandledError,
  hasInvestment,
  hasErrors,
  hasBeenPlaced,
} from '../../helpers/state'
import Exception from '@classic/Betting-v2/Exceptions/Exception'
import { SingleAllUpFormula } from '../SingleAllUpFormula/SingleAllUpFormula'
import { isRaceDetails } from '@core/Data/Betting/selectionDetails'

interface ItemListProps {
  items: immutable.List<BetslipItem>
  isExpanded: boolean
  hasProposed: boolean
  isBusy: boolean
}

const handleExpandablePanelClick = () => ToggleBetslipExpandable(BetslipExpandableTypes.single)

export function SingleItemListComponent({ items, isExpanded, hasProposed, isBusy }: ItemListProps) {
  let filteredItems = items.concat().toList() // Create new iterable before mutating

  if (hasProposed) {
    filteredItems = filteredItems
      .filter(item => hasErrors(item) || hasBeenPlaced(item) || hasInvestment(item))
      .toList()
  }

  if (!items || filteredItems.isEmpty()) {
    return null
  }

  // check if the local storage is the latest or throw expeption
  const hasOldSchema =
    items
      .toArray()
      .filter(
        item =>
          item.selectionDetails !== null &&
          React.hasOwnProperty.call(item.selectionDetails, 'raceKeys')
      ).length > 0

  if (hasOldSchema) {
    throw new Exception('Old schema please refresh')
  }

  const itemCount = filteredItems.count()

  return (
    <BetslipExpandablePanel
      title='Single'
      count={itemCount}
      headerClicked={handleExpandablePanelClick}
      expanded={isExpanded}
      refreshButton={<RefreshBetslipButton />}
    >
      <ListStyled>
        {filteredItems.toArray().map(item => {
          const { id, receipt } = item

          const isTote = isToteSelection(item.selection)
          const isMystery = isMysterySelection(item.selection)
          const isMysteryCombo = isMystery && isMysteryComboBetType(item.selection)
          const isNovelty = isTote
            ? isNoveltyBetType((item.selection as ToteSelection).betType)
            : false
          const isAllUp = isAllUpSelection(item.selection)
          const isWinPlaceBet = !isAllUp
          const isNotDefaultAllUp =
            isAllUp &&
            (item.selection as AllUpSelection).formulas
              .slice(0, -1)
              .some(formula => formula.isSelected)
          const isRace = isRaceDetails(item.selectionDetails)

          const allUpTotalCost = isAllUp
            ? item.investment.win.value * (item.numberOfCombinations || 0)
            : 0

          let itemSelectionTag: string = ''

          if (isRace && isWinPlaceBet && !isNovelty) {
            if (item.investment.win.value > 0 && item.investment.place.value > 0) {
              itemSelectionTag = 'Win & Place'
            } else if (item.investment.win.value > 0) {
              itemSelectionTag = 'Win'
            } else if (item.investment.place.value > 0) {
              itemSelectionTag = 'Place'
            }
          }

          const hasFatalError = isFatalErrorType(item.betErrorType) || false

          return (
            <ItemStyled key={id || ''} hasFatalError={hasFatalError}>
              {!!receipt && (
                <ReceiptHeaderStyled>
                  <Icon type='tickCircled' size='2.5rem' />{' '}
                  <ReceiptHeaderTextStyled>Your bet has been placed</ReceiptHeaderTextStyled>
                </ReceiptHeaderStyled>
              )}

              <BetInfo
                item={item}
                isSimpleView={false}
                hasFatalError={hasFatalError}
                hasProposed={hasProposed}
                isBusy={isBusy}
              />

              {isNonHandledError(item.betErrorType) && (
                <SingleItemNoticeWrapper>
                  <NoticeBox
                    title={item.errorMessage}
                    noticeBoxType={NoticeBoxTypes.Error}
                    hasBorder={true}
                  />
                </SingleItemNoticeWrapper>
              )}

              {!hasFatalError && <BetInvestment id={id} />}

              {isAllUp && isNotDefaultAllUp && (
                <SingleAllUpFormula selection={item.selection as AllUpSelection} />
              )}

              {isAllUp && allUpTotalCost > 0 && !receipt && (
                <TotalCostSectionStyled>
                  Total Cost
                  <TotalCostMoneyStyled>${allUpTotalCost.toFixed(2)}</TotalCostMoneyStyled>
                </TotalCostSectionStyled>
              )}

              <BetslipReceipt
                receipt={receipt}
                isTote={isTote}
                isMystery={isMystery}
                isMysteryCombo={isMysteryCombo}
                isNoveltyBetType={isNovelty}
                itemSelectionTag={itemSelectionTag}
              />
            </ItemStyled>
          )
        })}
      </ListStyled>
    </BetslipExpandablePanel>
  )
}

export const SingleItemList = observeImmutable(state$, ({ record }) => (
  <SingleItemListComponent
    items={record.items}
    isExpanded={record.isSingleExpanded}
    hasProposed={record.hasProposed}
    isBusy={record.isBusy}
  />
))
