import { createSignal } from 'rwwa-rx-state-machine'
import { ToteRaceStatusChangedPushEvent } from 'tabtouch-push-contract'
import {
  BettingType,
  ToteBetResponse,
  FobProposeResponse,
  FobCommitResponse,
  FobSingleErrorResponse,
  MysteryProposeResponse,
  MysteryCommitResponse,
  BetError,
} from '../../Data/betting'
import { Selection } from '../../Data/Betting/selections'
import { EventDetails } from '../../Data/Betting/selectionDetails'
import { BonusBet, InvestmentType } from './Components/BetInvestment/betInvestmentDriver'
import { BetslipItem } from '../Betslip/driver'
import { BetslipResponse } from '@core/Data/betslip'
import { NotificationType } from './Components/Notifications/NotificationTypes'

export interface QuickbetSelection {
  bettingType: BettingType
  selection: Selection
  selectionDetails: EventDetails | null
  isEachWayAvailable: boolean
  shouldAllowPlaceInvestment: boolean
  presetInvestment?: boolean
  promptForInvestment?: boolean
  tags?: string[]
  betSource?: string
  keepSelections?: (_?: boolean) => boolean | undefined
}

export interface QuickbetNotificationData {
  type: NotificationType
  title?: string
  subtitle?: string
}

export const SetInvalidInvestmentNotification = createSignal<QuickbetNotificationData>(
  'SetInvalidInvestmentNotification'
)
export const QuickbetLoadSelection = createSignal<QuickbetSelection>('LoadQuickbetSelection')
export const QuickbetClosed = createSignal('QuickbetClosed')
export const SetAllowInvestmentState = createSignal<{
  shouldAllowPlaceInvestment: boolean
  shouldAllowWinInvestment: boolean
}>('SetAllowInvestmentState')
export const ChangeInvestment = createSignal<InvestmentType>('ChangeInvestment')
export const SetActiveInvestment = createSignal<InvestmentType>('SetActiveInvestment')
export const ToggleEachWay = createSignal<void>('ToggleEachWay')
export const ToggleBonusBetUsage = createSignal<boolean | undefined>('ToggleBonusBetUsage')
export const ToggleBonusCashUsage = createSignal<boolean | undefined>('ToggleBonusCashUsage')
export const SetBonusBet = createSignal<BonusBet | null>('SetBonusBet')
export const ProposeBet = createSignal<void>('ProposeBet')
export const ProposeBetSucceeded = createSignal<
  ToteBetResponse | FobProposeResponse | MysteryProposeResponse
>('ProposeBetSucceeded')
export const ProposeBetslipBetsSucceeded = createSignal('ProposeBetslipBetsSucceeded')
export const ProposeBetFailed = createSignal<FobSingleErrorResponse>('ProposeBetFailed')
export const ConfirmBet = createSignal<void>('ConfirmBet')
export const ConfirmBetSucceeded = createSignal<
  ToteBetResponse | FobCommitResponse | MysteryCommitResponse
>('ConfirmBetSucceeded')
export const ConfirmBetslipBetsSucceeded = createSignal<BetslipResponse[]>(
  'ConfirmBetslipBetsSucceeded'
)
export const ConfirmBetFailed = createSignal<FobSingleErrorResponse>('ConfirmBetFailed')
export const ConfirmBetslipBetsFailed = createSignal<BetError[]>('ConfirmBetslipBetFailed')
export const DepositFundsDisplayed = createSignal<void>('DepositFundsDisplayed')
export const EditBetslipItem = createSignal<BetslipItem>('SetBetslipItemBeingEdited')
export const AddingToBetslip = createSignal('AddingToBetslip')
export const RaceClosedEventReceived = createSignal<{
  event: ToteRaceStatusChangedPushEvent
  toastId?: string
}>('RaceClosedEventReceived')
export const SetMysteryQuickPickPresetInvestment = createSignal<number>(
  'SetMysteryQuickPickPresetInvestment'
)
export const InsufficientFundsForBet = createSignal<{ betCost: number; shortfall: number }>(
  'InsufficientFundsForBet'
)
export const ClearInsufficientFundsForBet = createSignal('ClearInsufficientFundsForBet')
