import React from 'react'
import { Observable } from 'rx'
import { state$ as receiptState$ } from './driver'
import { immutableState$ as quickbetState$ } from '@core/Areas/Quickbet/driver'
import { observe } from '@core/Components/HOCs'
import {
  isToteSelection,
  isNoveltyBetType,
  ToteSelection,
  isMysterySelection,
  isMysteryComboBetType,
} from '@core/Data/Betting/selections'
import { QuickbetReceipt } from './QuickbetReceipt'
import { BetslipReceipt } from './BetslipReceipt'

const quickbetAndReceiptState$ = Observable.combineLatest(
  quickbetState$,
  receiptState$,
  (quickbetState, receiptState) => ({
    betPlaced: quickbetState.betPlaced,
    selection: quickbetState.selection,
    receiptState,
  })
)

export const Receipt: React.ComponentClass = observe(
  quickbetAndReceiptState$,
  ({ betPlaced, selection, receiptState }) => {
    if (!betPlaced) {
      return null
    }

    if (receiptState.betslipReceipt) {
      return <BetslipReceipt responses={receiptState.betslipReceipt} />
    }

    const isMysteryBet = isMysterySelection(selection)
    return (
      <QuickbetReceipt
        isToteBet={isToteSelection(selection)}
        isMysteryBet={isMysteryBet}
        isMysteryCombo={isMysteryBet && isMysteryComboBetType(selection)}
        isNoveltyBet={isNoveltyBetType((selection as ToteSelection).betType)}
        accountBalance={receiptState.accountBalance}
        ticketNumber={receiptState.ticketNumber}
        betCost={receiptState.betCost}
        winInvestment={receiptState.winInvestment}
        placeInvestment={receiptState.placeInvestment}
        projectedWinPayout={receiptState.projectedWinPayout}
        projectedPlacePayout={receiptState.projectedPlacePayout}
        specialOffers={receiptState.specialOffers}
        divXAnnotations={receiptState.divXAnnotations}
        campaignActivatedInd={receiptState.campaignActivatedInd}
        campaignType={receiptState.campaignType}
        bonusBet={receiptState.bonusBet}
        amountPaid={receiptState.amountPaid}
        showBalance={false}
      />
    )
  }
)

Receipt.displayName = 'Receipt'
