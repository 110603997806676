import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { isHapticFeedbackSettingEnabled } from '@core/Areas/Settings/Store/selectors'
import { setHapticFeedbackSetting } from '@core/Areas/Settings/Store'
import { SettingItem } from '../SettingItem'

export const isHapticsSupported = isReactNativeApp || Boolean(window.navigator.vibrate)

export const HapticFeedbackSetting: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const isHapticFeedbackEnabled = useSelector(isHapticFeedbackSettingEnabled)

  return (
    <SettingItem
      isEnabled={isHapticFeedbackEnabled}
      label='Allow Haptic Feedback'
      desciption='Enables a subtle vibration to trigger on important events, such as successful bet placement.'
      onToggle={() => {
        dispatch(setHapticFeedbackSetting(!isHapticFeedbackEnabled))
      }}
    />
  )
}
