import { store } from '@core/Store'
import { track } from './Analytics'
import {
  keys,
  AnalyticsBetData,
  defaultData,
  MysteryBetData,
  BetConstruction,
} from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { BetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'
import {
  Selection,
  isToteSelection,
  isFobPropositionSelection,
  isAllUpSelection,
  isFobMatchedSelection,
  ToteSelection,
  FobMatchedSelection,
  AllUpSelection,
  FobPropositionSelection,
  isMysterySelection,
  MysterySelection,
  isFavouriteNumbersSelection,
  isSameRaceMultiSelection,
  isStartingPriceSelection,
} from '@core/Data/Betting/selections'
import {
  EventDetails,
  isToteSportsDetails,
  isRaceDetails,
  isMysteryDetails,
  MysteryDetails,
  RaceDetails,
} from '@core/Data/Betting/selectionDetails'
import type { FeatureFlags } from '@core/State/LaunchDarklyFeatures/driver'
import { BettingType } from '@core/Data/betting'
import { selectIsSkyVideoPlayerOpen } from '@core/Areas/SkyVideoPlayer/Store/selectors'

export const trackBetConfirmation = (
  ticketNumber: number,
  selection: Selection | null,
  selectionDetails: EventDetails | null,
  specialOffers: BetSpecialOffer[],
  betConstruction: BetConstruction,
  betCost: number,
  winInvestment: number,
  placeInvestment: number,
  features: FeatureFlags,
  betBatchId: string | null,
  tags: string[] | null,
  betSource: string | null = null,
  bonusCashAmount: number = 0
): void => {
  const isToteBet = isToteSelection(selection)
  const isSportsBet = isToteSportsDetails(selectionDetails)
  const isMysteryBet = isMysteryDetails(selectionDetails)
  const isFavouriteNumbersBet = isFavouriteNumbersSelection(selection)

  const isVisionOpen = selectIsSkyVideoPlayerOpen(store.getState())

  let meetingCode: string | null = null
  let meetingName: string | null = null
  const races = (selectionDetails as RaceDetails)?.races
  if (races && races.length) {
    meetingCode = races[0].meetingCode
    meetingName = races[0].meetingName
  }

  const sportsOrRacingData = {
    [isSportsBet ? 'sports' : 'racing']: {
      [isToteBet || isMysteryBet || isFavouriteNumbersBet ? 'tote' : 'fixed']: {
        cost:
          isToteBet || isMysteryBet || isFavouriteNumbersBet
            ? betCost
            : winInvestment + placeInvestment,
        numberOfTickets: 1,
        bonusCashUsed: bonusCashAmount > 0,
        bonusCashAmount,
      },
      info: (selection as ToteSelection | FobMatchedSelection | MysterySelection).fixtureId,
      selection: {
        ...selection, // assist GA by storing all properties of all the different selection Typescript
        betSource,
        raceCode: meetingCode,
        meetingName,
      },
    },
  }

  const betType = getBetType(selection, selectionDetails)
  const betSpecialOffers = specialOffers.length > 0 ? specialOffers.map(so => so.title) : null

  let mystery: MysteryBetData | null = null
  if (isMysteryBet) {
    const mysteryDetails = selectionDetails as MysteryDetails
    mystery = {
      isQuickPick: mysteryDetails.betType === BettingType.MysteryQuickPick,
      mysteryType: mysteryDetails.betTypeName,
    }
  }

  const bet: AnalyticsBetData = {
    ...defaultData.bet,
    ...sportsOrRacingData,
    id: ticketNumber,
    betType,
    productType: 'single',
    placementMethod: 'account',
    errorMessage: null,
    construction: betConstruction,
    specialOffers: betSpecialOffers as string[],
    betBatchId,
    mystery,
    tags,
  }

  const userFeatures = Object.keys(features)
    .filter(key => features[key] === true)
    .sort()
    .join('|')

  track(keys.betAccountProcessed, {
    user: { featureToggles: userFeatures, isVisionOpen },
    bet,
  })
}

const getBetType = (
  selection: Selection | null,
  selectionDetails: EventDetails | null
): string | null => {
  if (isSameRaceMultiSelection(selection)) {
    return 'Same Race Multi'
  }

  if (isMysterySelection(selection)) {
    return 'Mystery'
  }

  if (isFavouriteNumbersSelection(selection)) {
    return 'Favourite Numbers'
  }

  if (isToteSelection(selection)) {
    return (selection as ToteSelection).betType.toString()
  }

  if (isFobMatchedSelection(selection)) {
    return 'Win & Place Fixed'
  }

  if (isStartingPriceSelection(selection)) {
    return 'Starting Price'
  }

  if (isAllUpSelection(selection)) {
    return (selection as AllUpSelection).betType.toString()
  }

  if (isFobPropositionSelection(selection)) {
    const marketName = (selection as FobPropositionSelection).marketName
    if (marketName) {
      return marketName
    }

    if (isRaceDetails(selectionDetails)) {
      return 'Race Fixed'
    }
  }

  return null
}
