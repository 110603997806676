import * as immutable from 'immutable'
import { attachDriver, Signal } from 'rwwa-rx-state-machine'
import { makeTypedFactory, TypedRecord } from 'typed-immutable-record'
import { BetResponseCode, BetErrorType, BetLegType } from '@core/Data/betting'
import {
  AddSingleToBetslip,
  EditBets,
  ClearBetslip,
  ConfirmAllBets,
  ConfirmAllBetsFailed,
  ConfirmAllBetsSuccessful,
  ProposeAllBets,
  ProposeAllBetsFailed,
  ProposeAllBetsSuccessful,
  RemoveSingleBet,
  ToggleBetslipExpandable,
  UpdateItemInBetslip,
  DeselectSuperPick,
  UpdateMultiBetSpend,
  ToggleMultiBet,
  ToggleMultiLegBetType,
  RefreshBetslip,
  RefreshBetslipSuccessful,
  RefreshBetslipFailed,
  BetslipDepositModalClosed,
  KeepBets,
  ScrollTo,
  UpdateBetslipItemPrice,
  OnOpen,
  OnClose,
  ToggleMultiFormulaExpanded,
  AddSinglesToBetslip,
  UpdateBetslipItemPriceData,
  RefreshBetslipSuccessfulData,
  RemoveBonusBetsFromBetslip,
  UpdateMultiBetSpendData,
  CloseBetslipExpandable,
} from './signals'
import {
  getBetsToPlace,
  hasNotBeenPlaced,
  getBetsInMulti,
  isFatalErrorType,
  clearNonFatalErrors,
  clearNonFatalMultiBetLegError,
  getMultibetErrorDescription,
  hasTooFewMultiLegs,
  hasTooManyMultiLegs,
  getMultiBetResponse,
  setInvalidLegOnMultiItem,
  hasInvalidLegsOnMulti,
  hasBeenPlaced,
  hasNoFatalErrors,
  hasWinBoostedSuperPick,
  hasPlaceBoostedSuperPick,
} from './helpers/state'
import { mapResponse } from './helpers/mapResponse'
import { getStateFromLocalStorage, setStateToLocalStorage } from '@core/Utils/state/state'
import { BetslipResponse, FobBetCommitResponse, BetslipErrorResponse } from '@core/Data/betslip'
import {
  betslipCommit as commit,
  betslipPropose as propose,
  betslipRefresh as refresh,
} from '@core/Data/betting'
import { buildBetslipRequest } from './helpers/requestBuilder'
import { BetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'
import { QuickbetState } from '../Quickbet/driver'
import {
  InvestmentState,
  SingleInvestment,
} from '@core/Areas/Quickbet/Components/BetInvestment/betInvestmentDriver'
import {
  isToteSelection,
  isAllUpSelection,
  isFobSelection,
  FobSelection,
} from '@core/Data/Betting/selections'
import { hydrateBetslip, betslipStateToPersisted } from './helpers/localStorage'
import { KeypadModes } from '@core/Components/Keypad/KeyPress'
import { fetchCampaignsAsync } from '@core/State/UserAccount/async-signals'
import { Campaign } from '@core/State/UserAccount/userAccountDriver'
import { startIotSubscription, stopIotSubscription } from './helpers/iotSubscriptions'
import { QuickbetReceiptState } from '../Quickbet/Components/Receipt/driver'
import { trackOptimoveEvent } from '@core/Services/Optimove/optimove'
import { triggerHapticFeedback } from '@core/Utils/hapticFeedback/hapticFeedback'
import { trackAddToBetslip } from './analytics'

export enum BetslipExpandableTypes {
  multi = 'multi',
  single = 'single',
}

const BETSLIP_STORAGE_KEY = 'betslip'

export type BetslipInvestment = Pick<InvestmentState, 'win' | 'place' | 'bonusBet'>

export type BetslipItem = Pick<
  QuickbetState,
  | 'id'
  | 'bettingType'
  | 'isEachWay'
  | 'isEachWayAvailable'
  | 'selectionDetails'
  | 'shouldAllowPlaceInvestment'
  | 'selection'
  | 'tags'
> & {
  investment: BetslipInvestment
  selectedSuperPickOffer?: BetSpecialOffer | null
  isSuperPickAvailable?: boolean
  receipt?: ReceiptItem
  numberOfCombinations?: number
  betErrorType?: BetErrorType
  errorMessage: string
  isInMulti: boolean
  multiLegBetType?: BetLegType.Win | BetLegType.Place
  multiBetLegError: MultiBetError | null
  specialOffers: BetSpecialOffer[]
  hasIotSubscription: boolean
  campaign?: Campaign
  isUsingBonusCash?: boolean
  betSource?: string
}

export interface MultiBetError {
  betErrorType: BetErrorType
  errorMessage: string
}

export interface ReceiptItem extends QuickbetReceiptState {
  legsCount?: number
}

export interface MultiInvestment extends SingleInvestment {
  f1: number
  f2: number
  f3: number
  f4: number
  f5: number
  bonusBetId?: number | null
  isBonusBet?: boolean
}

export type MultiInvestmentKey = keyof MultiInvestment & `f${number}`

export interface MultiInvestmentRecord
  extends TypedRecord<MultiInvestmentRecord>,
    MultiInvestment {}

export type BetslipScrollPosition = 'multi' | 'top'

export interface BetslipState {
  isOpen: boolean
  apiErrorMessage: string | null
  hasProposed: boolean
  isMultiExpanded: boolean
  isSingleExpanded: boolean
  isBusy: boolean
  items: immutable.List<BetslipItem>
  multiBetError: MultiBetError | null
  multiInvestment: MultiInvestment
  multiReceipt: ReceiptItem | null
  scrollPosition: BetslipScrollPosition
  isMultiFormulaExpanded: boolean
}

export const defaultBetslipState: Readonly<BetslipState> = {
  isOpen: false,
  apiErrorMessage: null,
  isSingleExpanded: true,
  isMultiExpanded: true,
  hasProposed: false,
  isBusy: false,
  items: immutable.List<BetslipItem>(),
  multiBetError: null,
  multiInvestment: {
    value: 0,
    f1: 0,
    f2: 0,
    f3: 0,
    f4: 0,
    f5: 0,
    lastKeyPressed: { mode: KeypadModes.Numeric, value: 0 },
    secondLastKeyPressed: { mode: KeypadModes.Numeric, value: 0 },
    bonusBetId: null,
    isBonusBet: false,
  },
  multiReceipt: null,
  scrollPosition: 'top',
  isMultiFormulaExpanded: false,
}

export interface BetslipStateRecord extends TypedRecord<BetslipStateRecord>, BetslipState {}
export const BetslipStateFactory = makeTypedFactory<BetslipState, BetslipStateRecord>(
  defaultBetslipState
)

export function betslipDriver(
  state = BetslipStateFactory(hydrateBetslip(getStateFromLocalStorage(BETSLIP_STORAGE_KEY))),
  signal: Signal
): BetslipStateRecord {
  switch (signal.tag) {
    case ClearBetslip:
      state.items.forEach(item => stopIotSubscription(item))
      return BetslipStateFactory().merge({
        ...defaultBetslipState,
      })

    case EditBets: {
      const itemsWithFobPriceChangeNone = state.items.map(item => {
        const shouldClearInsufficientFundsError = item.betErrorType ===
          BetErrorType.InsufficientFunds && {
          errorMessage: '',
          betErrorType: undefined,
        }
        if (!item.selection || isToteSelection(item.selection)) {
          return { ...item, ...shouldClearInsufficientFundsError }
        }
        return {
          ...item,
          ...shouldClearInsufficientFundsError,
          selection: {
            ...item.selection,
          },
        }
      })
      return state.merge({
        hasProposed: false,
        items: itemsWithFobPriceChangeNone,
      })
    }

    case ToggleBetslipExpandable: {
      if (signal.data === BetslipExpandableTypes.multi) {
        return state.merge({ isMultiExpanded: !state.isMultiExpanded })
      }
      if (signal.data === BetslipExpandableTypes.single) {
        return state.merge({ isSingleExpanded: !state.isSingleExpanded })
      }
      return state
    }

    case CloseBetslipExpandable: {
      if (signal.data === BetslipExpandableTypes.multi) {
        return state.merge({ isMultiExpanded: false })
      }
      if (signal.data === BetslipExpandableTypes.single) {
        return state.merge({ isSingleExpanded: false })
      }
      return state
    }

    case ScrollTo: {
      const newScrollPosition = signal.data as BetslipScrollPosition
      return state.merge({
        scrollPosition: newScrollPosition,
        isMultiExpanded: newScrollPosition === 'multi' || state.isMultiExpanded,
      })
    }

    case BetslipDepositModalClosed: {
      const clearInsufficientFundsErrors = (item: BetslipItem) =>
        item.betErrorType !== BetErrorType.InsufficientFunds
          ? item
          : {
              ...item,
              errorMessage: '',
              betErrorType: undefined,
            }
      const items: immutable.List<BetslipItem> = state.items
        .map(clearInsufficientFundsErrors)
        .toList()
      let { multiBetError } = state.toJS() as BetslipState
      if (multiBetError && multiBetError.betErrorType === BetErrorType.InsufficientFunds) {
        multiBetError = null
      }
      return state.merge({
        items,
        multiBetError,
      })
    }

    case AddSingleToBetslip: {
      // TODO check that propositions are unique
      const newItem = { ...signal.data } as BetslipItem
      const item = startIotSubscription(newItem)
      const items = state.items.push(item)
      const multiItems = getBetsInMulti(items)
      const multiInvestment =
        hasTooFewMultiLegs(multiItems) || hasTooManyMultiLegs(multiItems)
          ? defaultBetslipState.multiInvestment
          : (state.toJS() as BetslipState).multiInvestment
      triggerHapticFeedback('impact-light')
      trackAddToBetslip(newItem)

      return state.merge({
        items,
        multiInvestment,
      })
    }

    case AddSinglesToBetslip: {
      const newItems = [...signal.data] as BetslipItem[]
      const subscribedItems = newItems.map(newItem => startIotSubscription(newItem))
      const items = state.items.push(...subscribedItems)
      return state.merge({
        items,
        multiInvestment: defaultBetslipState.multiInvestment,
      })
    }

    case UpdateBetslipItemPrice: {
      if (state.hasProposed) {
        // disregard 'push' price changes during bet review/placement, proposal/commit will return price change indication
        return state
      }
      const {
        propositionId,
        winPrice: newWinPrice,
        placePrice: newPlacePrice,
        priceSource: newPriceSource,
      }: UpdateBetslipItemPriceData = signal.data

      const refreshItems: BetslipItem[] = []

      const items = state.items.map((item: BetslipItem) => {
        if (isFobSelection(item.selection) && item.selection.propositionSeq === propositionId) {
          const { winPrice: previousWinPrice, placePrice: previousPlacePrice } = item.selection

          const selection: FobSelection = {
            ...item.selection,
            winPrice: newWinPrice,
            placePrice: newPlacePrice,
            priceSource: newPriceSource,
          }

          const hasWinPriceChange = previousWinPrice !== newWinPrice
          const hasPlacePriceChange = previousPlacePrice !== newPlacePrice

          if (state.isOpen) {
            if (
              (hasWinPriceChange && hasWinBoostedSuperPick(item)) ||
              (hasPlacePriceChange && hasPlaceBoostedSuperPick(item))
            ) {
              refreshItems.push({ ...item, selection: { ...item.selection, priceSource: 'api' } })
              return item
            }

            if (hasWinPriceChange) selection.winPriceLastSeen = previousWinPrice
            if (hasPlacePriceChange) selection.placePriceLastSeen = previousPlacePrice
          }

          return { ...item, selection }
        }
        return item
      })

      if (refreshItems.length > 0) {
        const request = buildBetslipRequest('refresh', immutable.List(refreshItems))
        refresh(request)
          .then(response => RefreshBetslipSuccessful({ response, ignorePriceChanges: false }))
          .catch(error => RefreshBetslipFailed(error))
        return state.merge({
          isBusy: true,
          apiErrorMessage: null,
          items,
        })
      }

      return state.merge({
        items,
      })
    }

    case UpdateItemInBetslip: {
      const data: BetslipItem = signal.data
      return state.merge({
        items: state.items.map((item: BetslipItem) => {
          if (item.id === data.id) {
            const itemWithNoFatalErrors = clearNonFatalErrors(item)
            item = {
              ...itemWithNoFatalErrors,
              // Only updating selection when needed
              selection: isAllUpSelection(itemWithNoFatalErrors.selection)
                ? data.selection
                : itemWithNoFatalErrors.selection,
              investment: { ...data.investment },
              numberOfCombinations: data.numberOfCombinations,
              specialOffers: data.specialOffers,
              selectedSuperPickOffer: data.selectedSuperPickOffer
                ? { ...data.selectedSuperPickOffer }
                : null,
              isSuperPickAvailable: data.isSuperPickAvailable,
              isEachWay: data.isEachWay,
              campaign: data.campaign,
              isUsingBonusCash: data.isUsingBonusCash,
            }
          }
          return item
        }),
      })
    }

    case DeselectSuperPick: {
      const deselectedItem: BetslipItem = signal.data
      return state.merge({
        items: state.items.map((item: BetslipItem) => {
          if (item.id === deselectedItem.id) {
            item = {
              ...item,
              selectedSuperPickOffer: null,
              betErrorType:
                item.betErrorType === BetErrorType.SpecialsError ? undefined : item.betErrorType,
              errorMessage:
                item.betErrorType === BetErrorType.SpecialsError ? '' : item.errorMessage,
            }
          }
          return item
        }),
      })
    }

    case RemoveSingleBet: {
      const { id: betslipItemIdToRemove } = signal.data
      const items = state.items
        .map(item => (item.id === betslipItemIdToRemove ? stopIotSubscription(item) : item))
        .filter(item => item.id !== betslipItemIdToRemove)
        .map(item => (item.multiBetLegError ? { ...item, multiBetLegError: null } : item))
        .toList()
      const multiItems = getBetsInMulti(items)
      const multiInvestment =
        hasTooFewMultiLegs(multiItems) || hasTooManyMultiLegs(multiItems)
          ? defaultBetslipState.multiInvestment
          : state.get('multiInvestment')
      return state.merge({
        items,
        multiInvestment,
        multiBetError: null,
      })
    }

    case ToggleMultiBet: {
      const id: string | null = signal.data
      const items = state.items
        .map(item => (item.id === id ? { ...item, isInMulti: !item.isInMulti } : item))
        .map(clearNonFatalMultiBetLegError)
        .map((value, key, updatedItems) => setInvalidLegOnMultiItem(value, updatedItems.toList()))
        .toList()
      const hasMultiBetError = items.some(item => !!item.multiBetLegError && item.isInMulti)
      const hasInvalidMultiLegs = hasInvalidLegsOnMulti(items)
      const multiError =
        (hasMultiBetError ? state.multiBetError : null) || hasInvalidMultiLegs
          ? { betErrorType: 0, errorMessage: 'Selections cannot be combined on the same race' }
          : null
      const multiInvestment =
        hasTooFewMultiLegs(items) || hasTooManyMultiLegs(items)
          ? defaultBetslipState.multiInvestment
          : (state.toJS() as BetslipState).multiInvestment
      return state.merge({
        items,
        multiInvestment,
        multiBetError: multiError,
      })
    }

    case ToggleMultiLegBetType: {
      const id: string | null = signal.data
      const items = state.items
        .map(item =>
          item.id === id
            ? ({
                ...item,
                multiLegBetType:
                  item.multiLegBetType === BetLegType.Win ? BetLegType.Place : BetLegType.Win,
              } as BetslipItem)
            : item
        )
        .map(clearNonFatalMultiBetLegError) // clear ALL non-fatal leg errors because "the multi" has changed
        .map((value, key, updatedItems) => setInvalidLegOnMultiItem(value, updatedItems.toList()))
        .toList()

      const hasMultiBetError = items.some(item => !!item.multiBetLegError && item.isInMulti)
      const hasInvalidMultiLegs = hasInvalidLegsOnMulti(items)
      const multiError =
        (hasMultiBetError ? state.multiBetError : null) || hasInvalidMultiLegs
          ? { betErrorType: 0, errorMessage: 'Selections cannot be combined on the same race' }
          : null

      return state.merge({
        items,
        multiBetError: multiError,
      })
    }

    case UpdateMultiBetSpend: {
      const shouldKeepError = hasInvalidLegsOnMulti(state.items)
      const data = signal.data as UpdateMultiBetSpendData
      const multiInvestment: MultiInvestment = {
        ...(state.toJS() as BetslipState).multiInvestment,
        [data.field]: data.value,
        isBonusBet: !!data.bonusBetId,
        isBonusCash: !!data.isBonusCash,
        bonusBetId: data.bonusBetId,
      }
      if (multiInvestment.bonusBetId) {
        multiInvestment.f1 =
          multiInvestment.f2 =
          multiInvestment.f3 =
          multiInvestment.f4 =
          multiInvestment.f5 =
            0
      }

      return state.merge({
        items: shouldKeepError ? state.items : state.items.map(clearNonFatalMultiBetLegError),
        multiInvestment,
        multiBetError:
          shouldKeepError ||
          isFatalErrorType(state.multiBetError ? state.multiBetError.betErrorType : undefined)
            ? state.multiBetError
            : null,
      })
    }

    case OnOpen: {
      // Only display non-placed items when opening
      const nonPlacedItems = state.items
        .filter(hasNotBeenPlaced)
        .map(clearNonFatalErrors)
        .map(clearNonFatalMultiBetLegError)
      const hasMultiBetError = nonPlacedItems.some(
        item => item.isInMulti && !!item.multiBetLegError
      )
      const newItems =
        state.hasProposed && state.multiReceipt
          ? state.items.filter(x => !x.isInMulti)
          : state.items.filter(hasNotBeenPlaced)
      const itemsToRetain = state.hasProposed ? newItems : nonPlacedItems
      return state.merge({
        isOpen: true,
        items: itemsToRetain,
        multiReceipt: null,
        apiErrorMessage: null,
        hasProposed: false,
        multiBetError: hasMultiBetError ? state.multiBetError : null,
      })
    }

    case OnClose: {
      // Only display non-placed items when opening
      const nonPlacedItems = state.items
        .filter(hasNotBeenPlaced)
        .map(clearNonFatalErrors)
        .map(clearNonFatalMultiBetLegError)
      const hasMultiBetError = nonPlacedItems.some(
        item => item.isInMulti && !!item.multiBetLegError
      )
      const newItems =
        state.hasProposed && state.multiReceipt
          ? state.items.filter(x => !x.isInMulti)
          : state.items.filter(hasNotBeenPlaced)
      const itemsToRetain = state.hasProposed ? newItems : nonPlacedItems

      // remove subscriptions for placed bets
      state.items.forEach(item => {
        if ((state.hasProposed && state.multiReceipt && item.isInMulti) || hasBeenPlaced(item)) {
          stopIotSubscription(item)
        }
      })

      // record last seen prices
      const itemsToRetainWithResetLastSeenPrices = itemsToRetain
        .map(item => {
          if (isFobSelection(item.selection)) {
            item.selection.winPriceLastSeen = item.selection.winPrice
            item.selection.placePriceLastSeen = item.selection.placePrice
          }
          return item
        })
        .toList()

      return state.merge({
        isOpen: false,
        items: itemsToRetainWithResetLastSeenPrices,
        multiReceipt: null,
        apiErrorMessage: null,
        hasProposed: false,
        multiBetError: hasMultiBetError ? state.multiBetError : null,
      })
    }

    case RemoveBonusBetsFromBetslip: {
      const currentState = state.toJS() as BetslipState
      const newState = {
        items: state.items.map(item => ({
          ...item,
          investment: {
            ...item.investment,
            bonusBet: undefined,
            win: {
              ...item.investment.win,
              value: item.investment.win.isBonusBet ? 0 : item.investment.win.value,
              isBonusBet: false,
            },
            place: {
              ...item.investment.place,
              value: item.investment.place.isBonusBet ? 0 : item.investment.place.value,
              isBonusBet: false,
            },
          },
        })),
        multiInvestment: {
          ...currentState.multiInvestment,
          value: currentState.multiInvestment.isBonusBet ? 0 : currentState.multiInvestment.value,
          bonusBetId: null,
          isBonusBet: false,
        },
      } as BetslipState

      return state.merge(newState)
    }

    case RefreshBetslip: {
      if (state.isBusy) {
        return state
      }

      const { betslipItemIds = [] } = signal.data ?? {}
      const refreshSpecificBetslipItemsOnly = betslipItemIds.length > 0

      const predicate = refreshSpecificBetslipItemsOnly
        ? (item: BetslipItem) => betslipItemIds.includes(item.id) && hasNoFatalErrors(item)
        : (item: BetslipItem) => hasNoFatalErrors(item)

      const betsToRefresh = state.items.filter(predicate).toList()

      if (betsToRefresh.count() === 0) {
        return state
      }

      const betsInMulti = !refreshSpecificBetslipItemsOnly ? getBetsInMulti(state.items) : undefined
      const request = buildBetslipRequest('refresh', betsToRefresh, betsInMulti)
      refresh(request)
        .then(response => RefreshBetslipSuccessful({ response, ignorePriceChanges: true }))
        .catch(error => RefreshBetslipFailed(error))

      return state.merge({
        isBusy: true,
        apiErrorMessage: null,
      })
    }

    case RefreshBetslipSuccessful: {
      const { response, ignorePriceChanges }: RefreshBetslipSuccessfulData = signal.data

      const multiBetResponse = getMultiBetResponse(response)
      const responses = immutable.List(response)

      return state.merge({
        items: state.items.map(item =>
          mapResponse(item, responses, multiBetResponse, true, ignorePriceChanges)
        ),
        isBusy: false,
        multiBetError: getMultibetErrorDescription(multiBetResponse),
      })
    }

    case RefreshBetslipFailed: {
      const errorResponse: BetslipErrorResponse = signal.data
      if (errorResponse.code === BetResponseCode.Unauthorized) {
        return state.merge({
          isBusy: false,
        })
      }
      const apiErrorMessage =
        errorResponse.response && errorResponse.response.message
          ? errorResponse.response.message
          : 'Unable to refresh betslip - Please try again'
      return state.merge({
        isBusy: false,
        apiErrorMessage,
      })
    }

    case ProposeAllBets: {
      if (state.isBusy) {
        return state
      }
      const currentState = state.toJS() as BetslipState
      const multiItems = !state.multiReceipt ? getBetsInMulti(state.items) : undefined
      const request = buildBetslipRequest(
        'propose',
        getBetsToPlace(state.items),
        multiItems,
        currentState.multiInvestment,
        currentState.multiBetError
      )
      propose(request)
        .then(response => ProposeAllBetsSuccessful(response))
        .catch(error => ProposeAllBetsFailed(error))
      return state.merge({
        isBusy: true,
        apiErrorMessage: null,
      })
    }

    case ProposeAllBetsSuccessful: {
      const data: BetslipResponse[] = signal.data
      const multiBetResponse = getMultiBetResponse(data)
      const responses = immutable.List(data as BetslipResponse[])
      const newItems = state.items.map(item => mapResponse(item, responses, multiBetResponse))
      const multiBetError = getMultibetErrorDescription(multiBetResponse)
      const multiInvestment =
        multiBetError && multiBetError.betErrorType === BetErrorType.Unspecified
          ? defaultBetslipState.multiInvestment
          : (state.toJS() as BetslipState).multiInvestment

      return state.merge({
        items: newItems,
        isBusy: false,
        hasProposed: true,
        multiBetError,
        multiInvestment,
      })
    }

    case ProposeAllBetsFailed: {
      const errorResponse: BetslipErrorResponse = signal.data
      if (errorResponse.code === BetResponseCode.Unauthorized) {
        return state.merge({
          isBusy: false,
        })
      }
      const apiErrorMessage =
        errorResponse.response && errorResponse.response.message
          ? errorResponse.response.message
          : 'Unable to verify bet(s) - Please try again'
      return state.merge({
        isBusy: false,
        apiErrorMessage,
      })
    }

    case ConfirmAllBets: {
      if (state.isBusy) {
        return state
      }
      const currentState = state.toJS() as BetslipState
      const multiItems = !state.multiReceipt ? getBetsInMulti(state.items) : undefined
      const request = buildBetslipRequest(
        'confirm',
        getBetsToPlace(state.items),
        multiItems,
        currentState.multiInvestment,
        currentState.multiBetError
      )
      commit(request)
        .then(response => ConfirmAllBetsSuccessful(response))
        .catch(error => ConfirmAllBetsFailed(error))
      return state.merge({
        isBusy: true,
        apiErrorMessage: null,
      })
    }

    case ConfirmAllBetsSuccessful: {
      const data: BetslipResponse[] = signal.data
      const multiBetResponse = getMultiBetResponse(data)
      const responses = immutable.List(data as BetslipResponse[])
      const newItems = state.items.map(item => mapResponse(item, responses, multiBetResponse))
      const multiBetError = getMultibetErrorDescription(multiBetResponse)
      let newMultiReceipt
      if (multiBetResponse && multiBetResponse.success) {
        const multiBetCommitResponse = multiBetResponse as BetslipResponse
        const receipt = multiBetCommitResponse.receipt as FobBetCommitResponse
        newMultiReceipt = {
          ...receipt,
          legsCount: getBetsInMulti(state.items).count(),
          specialOffers: receipt.specialOffers || immutable.List([]),
          formulaResponse: receipt.formulaResponse,
        }

        trackOptimoveEvent({
          eventName: 'bet_placed',
          data: { type: 'betslip' },
        })
      }

      const singleBetCampaignActivatedInd = newItems.some(x =>
        x.receipt ? !!x.receipt.campaignActivatedInd : false
      )
      const multiBetCampaignActivatedInd = newMultiReceipt && newMultiReceipt.campaignActivatedInd

      if (singleBetCampaignActivatedInd || multiBetCampaignActivatedInd) {
        fetchCampaignsAsync()
      }

      return state.merge({
        items: newItems,
        isBusy: false,
        multiReceipt: newMultiReceipt || state.multiReceipt,
        multiBetError: multiBetError || state.multiBetError,
      })
    }

    case ConfirmAllBetsFailed: {
      const errorResponse: BetslipErrorResponse = signal.data
      if (errorResponse.code === BetResponseCode.Unauthorized) {
        return state.merge({
          isBusy: false,
        })
      }
      const apiErrorMessage =
        errorResponse.response && errorResponse.response.message
          ? errorResponse.response.message
          : 'Unable to place bet(s) - Please check pending bets and try again'

      return state.merge({
        isBusy: false,
        apiErrorMessage,
      })
    }

    case KeepBets: {
      const cleanedItems = state.items
        .map(clearNonFatalErrors)
        .map(clearNonFatalMultiBetLegError)
        .map(item => ({
          ...item,
          investment: {
            ...item.investment,
            bonusBet: null,
            win: {
              ...item.investment.win,
              value: item.investment.win.isBonusBet ? 0 : item.investment.win.value,
              isBonusBet: false,
            },
            place: {
              ...item.investment.place,
              value: item.investment.place.isBonusBet ? 0 : item.investment.place.value,
              isBonusBet: false,
            },
          },
          receipt: undefined,
        }))

      const multiInvestment = (state.toJS() as BetslipState).multiInvestment
      if (multiInvestment) {
        multiInvestment.value = multiInvestment.isBonusBet ? 0 : multiInvestment.value
        multiInvestment.bonusBetId = null
        multiInvestment.isBonusBet = false
      }
      return state.merge({
        items: cleanedItems,
        multiReceipt: null,
        apiErrorMessage: null,
        hasProposed: false,
        multiBetError: null,
        multiInvestment: multiInvestment,
      })
    }

    case ToggleMultiFormulaExpanded: {
      return state.merge({
        isMultiFormulaExpanded: !state.isMultiFormulaExpanded,
      })
    }

    default: {
      return state
    }
  }
}
export const state$ = attachDriver<BetslipStateRecord>({ path: 'betslip', driver: betslipDriver })

setStateToLocalStorage(
  BETSLIP_STORAGE_KEY,
  state$.distinctUntilChanged().debounce(1000).map(betslipStateToPersisted)
)
