import React from 'react'
import { hexColors } from '@mobi/settings'

export const SkyTvSvg: React.FC<{
  width: string
  channel: 'skyTv1' | 'skyTv2'
  colorOverride?: string
}> = ({ channel, width, colorOverride }) => {
  const isSky1 = channel === 'skyTv1'
  const skyColor = isSky1 ? hexColors.skyTvRed : hexColors.skyTvBlue
  return (
    <svg data-testid='SkyTvSvg' height='auto' width={width} viewBox='0 0 233 78' role='img'>
      <title>{isSky1 ? 'Sky TV 1' : 'Sky TV 2'}</title>
      <path fill={colorOverride || skyColor} d={isSky1 ? skyTv1Path : skyTv2Path} />
    </svg>
  )
}

const skyTv1Path =
  'M25.07 24.54c-.57-5.35 2.86-5.91 6.14-6.26 7.5-.77 19.07 6.19 21.64 8.16-.14-5.07 0-13.65 0-15.13 0 0-6.21-6.69-23.14-6.69-16.07.07-22 8.73-23.07 11.96-1.07 3.17-4.43 13.72 7.07 23.01 7.57 6.12 21.78 7.95 20.93 14.5-.71 5.07-8.36 4.57-12.71 3.94C18.36 57.4 11.07 54.37 7 51.2c0 3.73-.07 15.55-.07 15.55s11.36 7.46 28.42 5.77c15.14-1.48 20.57-12.46 20.57-16.68 0-6.54-1.21-10.84-6.36-16.4-6-6.4-23.93-9.64-24.5-14.92ZM142.84 27 127.91 6h-24.8S86.25 27.18 80 35.13V6H59v66h21V42.2L105.34 73h26L101.2 36.43l14.85-19 15.94 23.43v32.13h21V41.56l27.33-36.57h-22.28l-15.21 22Zm51.82-22-11.35 14.91 11.05 13.27 6.63-7.17V73h21V5h-27.34Z'

const skyTv2Path =
  'M25.08 24.59c-.57-5.35 2.86-5.91 6.14-6.26 7.5-.77 19.08 6.19 21.65 8.16-.14-5.07 0-13.65 0-15.13 0 0-6.22-6.69-23.15-6.69-16.08.07-22.01 8.73-23.08 11.96-1.07 3.17-4.43 13.72 7.07 23.01 7.57 6.12 21.79 7.95 20.94 14.5-.71 5.07-8.36 4.57-12.72 3.94-3.57-.63-10.86-3.66-14.93-6.83 0 3.73-.07 15.55-.07 15.55s11.36 7.46 28.44 5.77c15.15-1.48 20.58-12.46 20.58-16.68 0-6.54-1.21-10.84-6.36-16.4-6-6.4-23.94-9.64-24.51-14.92Zm117.61 2.46L127.76 6h-24.65S86.25 27.18 80 35.13V6H59v66h21V42.25L105.18 73h26.01l-30.15-36.52 14.86-19L132 40.91V73h21V41.61L180.2 5h-22.29l-15.22 22.05Zm75.74 15.51S228 30.68 228 22.36c0-12.94-9.85-21.4-22.8-21.4-10.5 0-19.2 11.73-19.2 11.73l10.79 13.23 5.54-6.33s3.24-3.91 6.83-.36c3.38 3.34 1.08 5.9 1.08 5.9L186 57.06v15.93h42v-16h-21l11.44-14.44Z'
