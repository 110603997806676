import React from 'react'
import {
  MysterySelection,
  isMysteryNoveltyBetType,
  isMysterySelection,
  isMysteryComboBetType,
} from '@core/Data/Betting/selections'
import { MysteryNoveltySelection } from './MysteryNoveltySelection'
import { MysteryFlexDisplay } from '../FlexiDisplay/FlexiDisplay'
import { MysteryBetInvestmentStyled } from './MysteryDisplaySelections.styles'
import { SingleSelectionStyled } from './Selection.styles'
import { MysteryComboSelection } from './MysteryComboSelection'
import { MysteryDetails } from '@core/Data/Betting/selectionDetails'
import { MysterySpendDisplay } from '../SpendDisplay/SpendDisplay'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'

interface MysteryDisplaySelectionProps {
  selection: MysterySelection
  details: MysteryDetails
}

export function MysteryDisplaySelections({ selection, details }: MysteryDisplaySelectionProps) {
  if (!isMysterySelection(selection)) {
    return null
  }

  if (!selection.bets) {
    return null
  }

  const bet = selection.bets[0]
  const isCombo = isMysteryComboBetType(selection)
  let selectionComponent

  if (isCombo) {
    selectionComponent = <MysteryComboSelection selection={selection} displayPill={true} />
  } else if (isMysteryNoveltyBetType(selection)) {
    selectionComponent = <MysteryNoveltySelection selection={selection} displayPill={true} />
  } else {
    selectionComponent = (
      <SingleSelectionStyled data-tid-mystery-selection=''>
        Mystery Selections <br />
        {bet.betLines[0].selections}
      </SingleSelectionStyled>
    )
  }

  return (
    <>
      {selectionComponent}
      {!isCombo && (
        <MysteryBetInvestmentStyled>
          <Grid padding='0.4rem'>
            <GridCell width='50%'>
              <MysterySpendDisplay
                data-tid-quickbet-mystery-spend=''
                investment={details.investmentWin}
              />
            </GridCell>
            <GridCell width='50%'>
              <MysteryFlexDisplay
                data-tid-quickbet-mystery-flexi=''
                divXAnnotation={bet.divXAnnotations}
              />
            </GridCell>
          </Grid>
        </MysteryBetInvestmentStyled>
      )}
    </>
  )
}
