import React, { useMemo, type ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import { isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'
import { HostProvider, type HostApi } from '@mobi/deposit/HostContext'
import { ClearInsufficientFundsForBet } from '@core/Areas/Quickbet/signals'
import { state$ as featureState$ } from '@core/State/LaunchDarklyFeatures/driver'
import { fetchAccountBalanceAsync } from '@core/State/UserAccount/async-signals'
import { trackOptimoveEvent } from '@core/Services/Optimove/optimove'

type Params = {
  history: ReturnType<typeof useHistory>
}

const createHostApi = ({ history }: Params): HostApi => ({
  features: {
    hasFeature: (featureName: string) => {
      return featureState$
        .take(1)
        .map(state => {
          const featureValue = state.features.get(featureName)
          return typeof featureValue === 'boolean' && !!featureValue
        })
        .toPromise(Promise)
    },
  },
  navigation: {
    navigate: url => {
      switch (url) {
        case 'find-a-tab':
          if (isReactNativeApp) {
            sendToNative('REQUEST_NATIVE_NAVIGATION', { location: 'TAB Locator' })
            return
          }
          history.push('/tablocator')
          break
      }
    },
  },
  events: {
    onDepositSuccess: (amount: number) => {
      fetchAccountBalanceAsync().then(() => {
        ClearInsufficientFundsForBet() // close Quick Deposit
      })

      trackOptimoveEvent({
        eventName: 'money_deposited',
        data: {
          amount,
        },
      })
    },
  },
  channel: 'mobile',
})

type Props = {
  children: MaybeArray<ReactNode>
}

export const HostContextMobile = ({ children }: Props): JSX.Element => {
  const history = useHistory()

  // TODO: Remove this when CreditCardForm is more resilient
  // This is a memo to ensure the `analytics` object (see above) remains the same
  // as that is checked when adding a card with Braintree. Not having this can
  // cause Braintree's hosted fields to crash when anything in the parent
  // has changed.
  const api = useMemo(
    () =>
      createHostApi({
        history,
      }),
    [history]
  )

  return <HostProvider api={api}>{children}</HostProvider>
}
