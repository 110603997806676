import React from 'react'
import ko from 'knockout'
import { Redirect, Link } from 'react-router-dom'
import { ToolboxViewModel } from './viewModel'
import { useConfig } from '@core/Utils/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@core/Store'
import { Toggle } from '@core/Components/Toggle/Toggle'
import { toggleReactQueryDevTools } from './Store'
import { LocalStorageManager } from './Components/LocalStorageManager/LocalStorageManager'
import { LastPaymentMethod } from '@core/Areas/Toolbox/Components/LastPaymentMethod/LastPaymentMethod'
import { Sentry } from '@core/Areas/Toolbox/Components/Sentry/Sentry'

export const Toolbox = () => {
  const toolboxElRef = React.useRef<HTMLDivElement>(null)
  const isInternalRequest = useConfig<boolean>('isInternalRequest', null)

  const dispatch = useDispatch()
  const isReactQueryDevToolsEnabled = useSelector(
    (state: RootState) => state.toolbox.isReactQueryDevToolsEnabled
  )

  React.useEffect(() => {
    if (!isInternalRequest || !toolboxElRef.current) return undefined

    const viewModel = new ToolboxViewModel()
    ko.applyBindings(viewModel, toolboxElRef.current)

    return () => {
      viewModel.cleanup()
    }
  }, [isInternalRequest])

  if (isInternalRequest === null) return null
  if (isInternalRequest === false) return <Redirect to='/' />

  const shouldShowCiInfo = Boolean(CI_COMMIT_SHORT_SHA)

  return (
    <div ref={toolboxElRef}>
      {shouldShowCiInfo && (
        <>
          <h3>Git Info</h3>
          <p>Commit hash: {CI_COMMIT_SHORT_SHA}</p>
          <p>
            <a
              href={`https://gitlab.com/rwwa/channels/tabtouch/tabtouch-mobi/-/pipelines/${CI_PIPELINE_ID}`}
              target='_blank'
              rel='noreferrer'
            >
              Go to GitLab Pipeline
            </a>
          </p>
        </>
      )}

      <h3>
        <Link to='design-system' style={{ color: 'white' }}>
          Design System
        </Link>
      </h3>

      <h3>
        React-Query DevTools{' '}
        <Toggle
          onToggle={() => dispatch(toggleReactQueryDevTools())}
          toggledOn={isReactQueryDevToolsEnabled}
          isDisabled={false}
        />
      </h3>

      <h3>Set a Cookie</h3>
      <p>
        <label>
          Presets{' '}
          <select data-bind='value: selectedCookieName'>
            <option value=''>Custom</option>
            <option value='tt-sg'>tt-sg</option>
            <option value='CanarySegment'>CanarySegment</option>
            <option value='androidapp'>androidapp</option>
            <option value='iosapp'>iosapp</option>
          </select>
        </label>
      </p>

      <p data-bind='visible: !selectedCookieName()'>
        <label>
          Cookie Name: <input data-bind='value: customCookieName' />{' '}
        </label>
      </p>

      <p>
        <label>
          Cookie Value: <input data-bind='value: cookieValue' />
        </label>
      </p>

      <p>
        <label>
          Cookie Expiry
          <select data-bind="options: cookieExpiryOptions, optionsText: 'label', optionsValue: 'value', value: selectedExpiryPreset"></select>
        </label>
      </p>

      <p data-bind='visible: customExpirySelected'>
        <label>
          Custom
          <input type='datetime-local' data-bind='value: customCookieExpiry' />{' '}
        </label>
      </p>

      <p>
        <button data-bind='click: clearCookie' className='fz-12 nue-button nue-secondary'>
          Clear Cookie
        </button>
        &nbsp;&nbsp;
        <button data-bind='click: setCookie' className='fz-12 nue-button nue-primary'>
          Set Cookie
        </button>
      </p>

      <p data-bind='visible: cookieResultMessage, text: cookieResultMessage'></p>

      <h3>GPS and Location</h3>
      <p>
        <button
          data-bind='click: updateLastKnownLocation'
          className='fz-12 nue-button nue-secondary'
        >
          Get GPS Coords
        </button>
      </p>
      <p>
        GPS Coords: <span data-bind='text: lastKnownLocationText'></span>
        <br />
        <span data-bind='text: dateLastKnownLocationUpdated'></span>
      </p>
      <hr />
      <p>
        <button
          data-bind='click: getCurrentVenueLocation'
          className='fz-12 nue-button nue-secondary'
        >
          Get Venue/Location
        </button>
      </p>

      <p>
        Current Venue/Location: <span data-bind='text: currentVenueLocation'></span>
        <br />
        <span data-bind='text: currentVenueLocationUpdatedAt'></span>
      </p>

      <h3>Cookie Information</h3>
      <dl className='fz-14 pl1em'>
        <dt>Device</dt>
        <dd className='ml1em'>
          <span data-bind='text: deviceId'></span>
        </dd>
        <dt>First Load</dt>
        <dd className='ml1em'>
          <span data-bind='text: firstLoad'></span>
        </dd>
        <dt>Segment:</dt>
        <dd className='ml1em'>
          <span data-bind='text: segment'></span>
        </dd>
      </dl>

      <h3>Feature Toggles</h3>
      <dl className='fz-14 pl1em'>
        <dt>
          <strong>AppCapability</strong>
        </dt>
        <dd className='ml1em'>
          <span data-bind='text: appCapability'></span>
        </dd>
        <dt>
          <strong>Launch Darkly Features</strong>
        </dt>
        <div data-bind='foreach: ldFeatures'>
          <dd className='ml1em'>
            <span data-bind='text: $data[0]'></span>:
            <span data-bind="text: $data[1], style: { color: $data[1] ? 'green' : 'red' }"></span>
          </dd>
        </div>
      </dl>

      <h3>Notification</h3>
      <dl className='fz-14 pl1em'>
        <dt>authorization:</dt>
        <dd className='ml1em'>
          <span data-bind='text: authStatus'></span>
        </dd>
        <dt>fcmToken:</dt>
        <dd className='ml1em'>
          <span data-bind='text: fcmToken'></span>
        </dd>
        <button data-bind='click: getPushAuthStatus' className='fz-12 nue-button nue-secondary'>
          Get Auth Status
        </button>
      </dl>

      <LocalStorageManager />

      <LastPaymentMethod />

      <Sentry />
    </div>
  )
}
