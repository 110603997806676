import { PlanSeq } from '@core/Data/betting'
import { BetType } from '@core/Data/Betting/selections'

export function getToteBetType(productPlanSequence: PlanSeq): BetType | undefined {
  switch (productPlanSequence) {
    case PlanSeq.Win:
    case PlanSeq.Place:
    case PlanSeq.WinAndPlace:
      return BetType.WinPlace

    case PlanSeq.Exacta:
    case PlanSeq.ExactaWithRovingBanker:
      return BetType.Exacta

    case PlanSeq.Quinella:
      return BetType.Quinella

    case PlanSeq.Trifecta:
    case PlanSeq.TrifectaWithRovingBanker:
      return BetType.Trifecta

    case PlanSeq.First4:
    case PlanSeq.First4WithRovingBanker:
      return BetType.First4

    case PlanSeq.Double:
      return BetType.Double

    case PlanSeq.Quaddie:
      return BetType.Quaddie

    case PlanSeq.AllUp:
      return BetType.AllUp
  }

  return undefined
}
