import { Race } from '@core/Data/Racing'
import { BettingType } from '@core/Data/betting'
import { BetType, MysteryType } from '@core/Data/Betting/selections'

export const MysteryBetType = {
  Combo: 'Combo',
  Trifecta: 'Trifecta',
  First4: 'First4',
}

export interface MysteryQuickPickOption {
  uniqueKey: string
  label: string
  displayPrice: number
  investment: number
  productCode: string //  Passed to the API
  mysteryType: MysteryType
  isAlways: boolean
  mysteryOption: number
  productSeq: number // Not currently used by Quickbet/API but have been included to help match the options to the database records
  productPlanSeq: number // Not currently used by Quickbet/API but have been included to help match the options to the database records
  displayOrder: number
}

export interface CustomizedMysteryBetType {
  type: keyof typeof BetType
  name: string
  options: CustomizedMysteryOption[]
  defaultOptionNumber: number
}

export interface CustomizedMysteryOption {
  optionNumber: number
  numberOfCombinations: number
  description: string
}

export const MysteryQuickPickOptions: MysteryQuickPickOption[] = [
  {
    uniqueKey: '$3 Combo',
    displayPrice: 3,
    label: 'Combo',
    investment: 1,
    productCode: MysteryBetType.Combo,
    mysteryType: MysteryType.Combo,
    isAlways: false,
    mysteryOption: 3,
    displayOrder: 1,
    productSeq: 60,
    productPlanSeq: 60,
  },
  {
    uniqueKey: '$1 Trifecta Classic',
    displayPrice: 1,
    label: 'Trifecta Classic',
    investment: 1,
    productCode: MysteryBetType.Trifecta,
    mysteryType: MysteryType.Novelty,
    isAlways: false,
    mysteryOption: 2,
    displayOrder: 2,
    productSeq: 11,
    productPlanSeq: 13,
  },
  {
    uniqueKey: '$10 Combo',
    displayPrice: 10,
    label: 'Combo',
    investment: 1,
    productCode: MysteryBetType.Combo,
    mysteryType: MysteryType.Combo,
    isAlways: false,
    mysteryOption: 10,
    displayOrder: 3,
    productSeq: 61,
    productPlanSeq: 61,
  },
  {
    uniqueKey: '$3 Mystery Trifecta',
    displayPrice: 3,
    label: 'Mystery Trifecta',
    investment: 3,
    productCode: MysteryBetType.Trifecta,
    mysteryType: MysteryType.Novelty,
    isAlways: true,
    mysteryOption: 3,
    displayOrder: 4,
    productSeq: 11,
    productPlanSeq: 14,
  },
  {
    uniqueKey: '$1 First 4 Classic',
    displayPrice: 1,
    label: 'First 4 Classic',
    investment: 1,
    productCode: MysteryBetType.First4,
    mysteryType: MysteryType.Novelty,
    isAlways: false,
    mysteryOption: 2,
    displayOrder: 5,
    productSeq: 35,
    productPlanSeq: 35,
  },
  {
    uniqueKey: '$6 Mystery Trifecta',
    displayPrice: 6,
    label: 'Mystery Trifecta',
    investment: 6,
    productCode: MysteryBetType.Trifecta,
    mysteryType: MysteryType.Novelty,
    isAlways: true,
    mysteryOption: 3,
    productSeq: 11,
    displayOrder: 6,
    productPlanSeq: 14,
  },
]

export const CustomMysteryBetTypes: CustomizedMysteryBetType[] = [
  {
    type: 'WinPlace',
    name: BetType.WinPlace,
    defaultOptionNumber: 1,
    options: [
      {
        optionNumber: 1,
        numberOfCombinations: 1,
        description: 'Tote only, 1 starter, Win and/or Place investment',
      },
      {
        optionNumber: 2,
        numberOfCombinations: 2,
        description: 'Tote only, 2 starters, Win and/or Place investment',
      },
      {
        optionNumber: 3,
        numberOfCombinations: 3,
        description: 'Tote only, 3 starters, Win and/or Place investment',
      },
      {
        optionNumber: 4,
        numberOfCombinations: 4,
        description: 'Tote only, 4 starters, Win and/or Place investment',
      },
      {
        optionNumber: 5,
        numberOfCombinations: 5,
        description: 'Tote only, 5 starters, Win and/or Place investment',
      },
    ],
  },
  {
    type: 'Quinella',
    name: BetType.Quinella,
    defaultOptionNumber: 1,
    options: [
      {
        optionNumber: 1,
        numberOfCombinations: 1,
        description: '2 starters any order',
      },
      {
        optionNumber: 2,
        numberOfCombinations: 2,
        description: '1 starter for 1st, 1 of 2 starters for 2nd',
      },
      {
        optionNumber: 3,
        numberOfCombinations: 3,
        description: '3 starters any order',
      },
      {
        optionNumber: 4,
        numberOfCombinations: 6,
        description: '4 starters any order',
      },
      {
        optionNumber: 5,
        numberOfCombinations: 10,
        description: '5 starters any order',
      },
      {
        optionNumber: 6,
        numberOfCombinations: 15,
        description: '6 starters any order',
      },
      {
        optionNumber: 7,
        numberOfCombinations: 21,
        description: '7 starters any order',
      },
    ],
  },
  {
    type: 'Exacta',
    name: BetType.Exacta,
    defaultOptionNumber: 1,
    options: [
      {
        optionNumber: 1,
        numberOfCombinations: 1,
        description: '2 starters in order',
      },
      {
        optionNumber: 2,
        numberOfCombinations: 2,
        description: '1 starter with 2 starters',
      },
      {
        optionNumber: 3,
        numberOfCombinations: 6,
        description: '3 starters any order',
      },
      {
        optionNumber: 4,
        numberOfCombinations: 12,
        description: '4 starters any order',
      },
      {
        optionNumber: 5,
        numberOfCombinations: 20,
        description: '5 starters any order',
      },
      {
        optionNumber: 6,
        numberOfCombinations: 30,
        description: '6 starters any order',
      },
      {
        optionNumber: 7,
        numberOfCombinations: 42,
        description: '7 starters any order',
      },
    ],
  },
  {
    type: 'Trifecta',
    name: BetType.Trifecta,
    defaultOptionNumber: 2,
    options: [
      {
        optionNumber: 1,
        numberOfCombinations: 1,
        description: '1st, 2nd and 3rd in order',
      },
      {
        optionNumber: 2,
        numberOfCombinations: 2,
        description: '1 first, 2 and 3 any order',
      },
      {
        optionNumber: 3,
        numberOfCombinations: 6,
        description: '1, 2, 3 any order',
      },
      {
        optionNumber: 4,
        numberOfCombinations: 24,
        description: '3 of 4 any place or order',
      },
      {
        optionNumber: 5,
        numberOfCombinations: 60,
        description: '3 of 5 any place or order',
      },
      {
        optionNumber: 6,
        numberOfCombinations: 120,
        description: '3 of 6 any place or order',
      },
      {
        optionNumber: 7,
        numberOfCombinations: 36,
        description: '1 of 2 starters 1st, 1 of 4 2nd, 1 of 8 3rd',
      },
    ],
  },
  {
    type: 'First4',
    name: BetType.First4,
    defaultOptionNumber: 1,
    options: [
      {
        optionNumber: 1,
        numberOfCombinations: 1,
        description: '1st, 2nd, 3rd and 4th in order',
      },
      {
        optionNumber: 2,
        numberOfCombinations: 2,
        description: '1 starter 1st, 1 starter 2nd, 2 other starters 3rd or 4th in any order',
      },
      {
        optionNumber: 3,
        numberOfCombinations: 6,
        description: '1 starter 1st, 3 other starters 2nd, 3rd or 4th in any order',
      },
      {
        optionNumber: 4,
        numberOfCombinations: 24,
        description: '1st, 2nd, 3rd and 4th in any order',
      },
      {
        optionNumber: 5,
        numberOfCombinations: 120,
        description: 'Any 4 of 5 starters to come 1st, 2nd, 3rd and 4th in any order',
      },
      {
        optionNumber: 6,
        numberOfCombinations: 360,
        description: 'Any 4 of 6 starters to come 1st, 2nd, 3rd and 4th in any order',
      },
      {
        optionNumber: 7,
        numberOfCombinations: 36,
        description: '2 of 3 starters to come 1st or 2nd and 2 of 3 starters to come 3rd or 4th',
      },
    ],
  },
  {
    type: 'Quaddie',
    name: BetType.Quaddie,
    defaultOptionNumber: 1,
    options: [
      {
        optionNumber: 1,
        numberOfCombinations: 1,
        description: '1 starter in all four legs',
      },
      {
        optionNumber: 2,
        numberOfCombinations: 2,
        description: '2 starters in first leg, 1 starter in remaining three legs',
      },
      {
        optionNumber: 3,
        numberOfCombinations: 8,
        description: '2 starters in first 3 legs, 1 starter in remaining fourth leg',
      },
      {
        optionNumber: 4,
        numberOfCombinations: 16,
        description: '2 starters in all four legs',
      },
      {
        optionNumber: 5,
        numberOfCombinations: 36,
        description: '3 starters in first two legs, 2 starters in remaining two legs',
      },
      {
        optionNumber: 6,
        numberOfCombinations: 81,
        description: '3 starters in all four legs',
      },
      {
        optionNumber: 7,
        numberOfCombinations: 256,
        description: '4 starters in all four legs',
      },
    ],
  },
]

export interface CreateMysterySelection {
  productCode: string
  mysteryOption: number
  mysteryType: string
  isAlways: boolean
  investmentWin: number
  investmentPlace: number
  betTypeName: string
  raceInformation: Race
  betType: BettingType
}
