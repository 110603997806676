import React from 'react'

const DesignSystemRoutes = React.lazy(() => import('./Routes'))

const DesignSystemRoutesPreview = () => null

export const DesignSystemRoutesLazy = (): JSX.Element => (
  <React.Suspense fallback={<DesignSystemRoutesPreview />}>
    <DesignSystemRoutes />
  </React.Suspense>
)
