import React from 'react'
import { SkyTvSvg } from '../../Common/Svg'
import { StatusBadge } from '../../Common/StatusBadge'

export const SkyTvBadge: React.FC<{ channel: 1 | 2; size?: 'S' | 'M' }> = ({
  channel,
  size = 'S',
}) => (
  <StatusBadge color={channel === 1 ? 'red' : 'blue'}>
    <SkyTvSvg
      channel={channel === 1 ? 'skyTv1' : 'skyTv2'}
      width={size == 'S' ? '2rem' : '2.4rem'}
    />
  </StatusBadge>
)
