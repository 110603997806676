import React, { useState } from 'react'

import { Tabs, TabList, TabPanel, Tab } from '@core/Components/Tabs'

import { CustomizeBet } from './CustomizeBet'
import { QuickPick } from './QuickPick'

export const MysteryBetTabs = (): JSX.Element => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  return (
    <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
      <TabList colorScheme='dark' fontWeight='bold'>
        <Tab data-tid-mystery-tab='QUICK PICK'>Quick Pick</Tab>
        <Tab data-tid-mystery-tab='CUSTOMISE BET'>Customise Bet</Tab>
      </TabList>
      <TabPanel>
        <QuickPick />
      </TabPanel>
      <TabPanel>
        <CustomizeBet />
      </TabPanel>
    </Tabs>
  )
}
