import React from 'react'
import { Acceptor } from '@core/Data/Racing'
import { ToteSelection } from '@core/Data/Betting/selections'
import {
  NoveltyPlacePillStyled,
  NoveltyListStyled,
  NoveltyPlaceStyled,
  NoveltyStarterStyled,
  AbbrStyled,
  NoveltyPlaceTextStyled,
} from './Selection.styles'
import { parseNoveltySelectionString } from './helpers'

interface PlaceTextStyledProps {
  displayPill: boolean
  text?: string
  abbrStyled?: React.ReactNode
}

export function PlaceTextStyled({ displayPill, text, abbrStyled }: PlaceTextStyledProps) {
  if (displayPill) {
    return (
      <NoveltyPlacePillStyled>
        {abbrStyled}
        {text}
      </NoveltyPlacePillStyled>
    )
  } else {
    return (
      <NoveltyPlaceTextStyled>
        {abbrStyled}
        {text}
      </NoveltyPlaceTextStyled>
    )
  }
}

interface NoveltySelectionProps {
  selection: ToteSelection
  acceptors: Acceptor[]
  displayPill: boolean
}

export function NoveltySelection({ selection, acceptors, displayPill }: NoveltySelectionProps) {
  const parsed = parseNoveltySelectionString(selection, acceptors)
  return (
    <NoveltyListStyled data-tid-novelty-selection=''>
      {parsed['1st'] && [
        <NoveltyPlaceStyled key='__1st'>
          <PlaceTextStyled text='1st' displayPill={displayPill} />
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled data-tid-first-selection='' key='1st'>
          {parsed['1st']}
        </NoveltyStarterStyled>,
      ]}

      {parsed['2nd'] && [
        <NoveltyPlaceStyled key='__2nd'>
          <PlaceTextStyled text='2nd' displayPill={displayPill} />
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled data-tid-second-selection='' key='2nd'>
          {parsed['2nd']}
        </NoveltyStarterStyled>,
      ]}

      {parsed['3rd'] && [
        <NoveltyPlaceStyled key='__3rd'>
          <PlaceTextStyled text='3rd' displayPill={displayPill} />
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled data-tid-third-selection='' key='3rd'>
          {parsed['3rd']}
        </NoveltyStarterStyled>,
      ]}

      {parsed['4th'] && [
        <NoveltyPlaceStyled key='__4ths'>
          <PlaceTextStyled text='4th' displayPill={displayPill} />
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled data-tid-fourth-selection='' key='4th'>
          {parsed['4th']}
        </NoveltyStarterStyled>,
      ]}

      {parsed.ALLWAYS && [
        <NoveltyPlaceStyled key='__ALLWAYS'>
          <PlaceTextStyled text='Allways' displayPill={displayPill} />
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled data-tid-all-ways-selection='' key='ALLWAYS'>
          {parsed.ALLWAYS}
        </NoveltyStarterStyled>,
      ]}

      {parsed['LEG-IN'] && [
        <NoveltyPlaceStyled key='__LEG-IN'>
          <PlaceTextStyled text='Leg-In' displayPill={displayPill} />
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled data-tid-leg-in-selection='' key='LEG-IN'>
          {parsed['LEG-IN']}
        </NoveltyStarterStyled>,
      ]}

      {parsed.RB && [
        <NoveltyPlaceStyled key='__RB'>
          <PlaceTextStyled
            abbrStyled={<AbbrStyled title='Roving Banker'>RB</AbbrStyled>}
            displayPill={displayPill}
          />
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled data-tid-rb-selection='' key='RB'>
          {parsed.RB}
        </NoveltyStarterStyled>,
      ]}

      {parsed.WITH && [
        <NoveltyPlaceStyled key='__WITH'>
          <PlaceTextStyled text='With' displayPill={displayPill} />
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled data-tid-with-selection='' key='WITH'>
          {parsed.WITH}
        </NoveltyStarterStyled>,
      ]}
    </NoveltyListStyled>
  )
}
