import { type BetAccountRecord, BetAccountKey, BetAccountRepo } from '@core/Data/Account/betAccount'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getFromRepo } from '@core/Utils/repository'
import { useQuery, type UseQueryOptions } from 'react-query'

type Options = UseQueryOptions<BetAccountRecord>

type UseBetAccountProps = Pick<Options, 'enabled' | 'onSuccess'>

export function useBetAccount(options?: UseBetAccountProps) {
  return useQuery({
    queryKey: queryKeys.betAccount,
    queryFn: () => getFromRepo(BetAccountRepo, BetAccountKey),
    cacheTime: 0, // Defer to rwwa-data-access for caching
    enabled: options?.enabled,
    onSuccess: options?.onSuccess,
    refetchOnWindowFocus: false,
  })
}
