import { coerceIntoError, unwrapErrorMessage } from '@mobi/utils'
import { isPaymentProcessorErrorCode } from '../Utils/braintree'
import type { DepositResponse } from '@mobi/api-types'

export type Reason =
  | 'generic_failure'
  | 'unable_to_retrieve_tokens'
  | 'sufficient_balance'
  | 'unsecure_client'
  | 'exceeds_deposit_limit'
  | 'minimum_deposit_exceeds_deposit_limit'
  | 'no_previous_payment_method'
  | 'no_suitable_card'
  | 'selected_card_is_a_credit_card'
  | 'cannot_deposit'
  | 'card_verification_failed'
  | 'unsupported_payment_method'
  | 'unable_load_payment_method'
  | 'unable_deposit_non_tokenized_card'
  | 'user_canceled'
  | 'deposit_failed'
  | 'declined_by_payment_processor'
  | 'paypal_initialization_failed'
  | 'tokenization_failed'

export const messages: Readonly<Record<Reason, string>> = {
  generic_failure: 'We were unable to complete the deposit',
  unable_to_retrieve_tokens: 'We were unable to retrieve tokens for this deposit',
  exceeds_deposit_limit: 'This deposit would exceed your deposit limit',
  minimum_deposit_exceeds_deposit_limit: 'The minimum deposit would exceed your deposit limit',
  no_previous_payment_method: 'No previous payment method has been used on this device',
  no_suitable_card: 'No suitable debit card could be found',
  selected_card_is_a_credit_card:
    "Please select 'Other Deposit' and set a new primary debit card in the 'Manage Cards' section",
  sufficient_balance: 'Your account balance is high enough to cover the bet',
  unsecure_client: 'This device could not securely perform the transaction',
  cannot_deposit: 'You are not able to deposit at this time',
  card_verification_failed: 'Your debit card could not be verified',
  unsupported_payment_method: 'Payment method not supported',
  unable_load_payment_method: 'Payment method currently not available',
  unable_deposit_non_tokenized_card: 'You may need to add your debit card to Google Wallet',
  user_canceled: 'Transaction was canceled by the user',
  deposit_failed: 'The deposit was not successful',
  declined_by_payment_processor:
    'Your bank has declined this payment. Please contact your bank directly for details.',
  paypal_initialization_failed:
    'We were unable to load PayPal, try depositing using a different payment method by clicking "Other Deposit" below',
  tokenization_failed: 'We were unable to verify your transaction',
}

export class DepositError extends Error {
  public constructor(
    public readonly reason: Reason,
    public readonly transactionId: string | null,
    public readonly code: string | undefined,
    message: string
  ) {
    super(message ?? 'An unknown error has occured')
  }

  public get wasCanceled(): boolean {
    return this.reason === 'user_canceled'
  }

  public get displayMessage(): string {
    return messages[this.reason]
  }

  public static get defaultError(): string {
    return 'An unknown error has occured'
  }

  public static coerce(error: unknown, transactionId: string | null) {
    if (error instanceof DepositError) {
      return error
    }

    return new DepositError(
      'generic_failure',
      transactionId,
      undefined,
      unwrapErrorMessage(coerceIntoError(error))
    )
  }

  public static canceled(transactionId: string | null) {
    return new DepositError(
      'user_canceled',
      transactionId,
      undefined,
      'The user canceled the transaction'
    )
  }

  public static fromErrorDetails(
    details: Pick<DepositResponse, 'transactionId' | 'message' | 'failureCode' | 'failureMessage'>
  ) {
    const reason =
      typeof details.failureCode === 'string' && isPaymentProcessorErrorCode(details.failureCode)
        ? 'declined_by_payment_processor'
        : 'generic_failure'

    const message = details.message ?? details.failureMessage ?? DepositError.defaultError

    return new DepositError(
      reason,
      details.transactionId,
      details.failureCode ?? undefined,
      message
    )
  }
}
