import React from 'react'
import { LogoStyled } from './logo.styles'

export function SkyTwoLogo() {
  return (
    <LogoStyled
      x='0px'
      y='0px'
      width='250px'
      height='111.76px'
      viewBox='0 0 250 111.76'
      enable-background='new 0 0 250 111.76'
    >
      <title>Sky Two</title>
      <g fill='#01BEFF'>
        <path d='M123.38 108.94h30.5l-35.17-44.13 17.92-22.52v-.06l21.3 28.79v37.89h25.08V71.06l13.06-17.52-13.84-17.29-12.31 16.04-18.95-25.87-21.54-.03h-7.27L92.14 63.33V26.38H67.13v82.6H91.6l.01-.02V70.29l31.77 38.65zM63.75 85.17c0-4.66-1.09-8.55-3.26-11.65a30.07 30.07 0 0 0-9-8.25 148.06 148.06 0 0 0-15.31-8.06c-3.59-1.7-6.08-3.16-7.47-4.34a5.37 5.37 0 0 1-2.08-4.23c0-1.73.68-3.21 2.08-4.4 1.39-1.2 3.63-1.8 6.77-1.8 4.74 0 9.19.96 13.36 2.86a70.89 70.89 0 0 1 11.33 6.49V32.44c-.97-.64-2.71-1.5-5.25-2.63a51.4 51.4 0 0 0-9.73-3.01 62.8 62.8 0 0 0-12.85-1.34c-6.48 0-11.99 1.08-16.5 3.22-4.5 2.15-7.85 5.02-10.06 8.61A22.88 22.88 0 0 0 2.5 49.35c0 4.61 1.08 8.62 3.26 11.98a28.22 28.22 0 0 0 8.39 8.43 74.6 74.6 0 0 0 11.97 6.22c4.12 1.65 7.25 3.22 9.4 4.75 2.16 1.52 3.23 3.39 3.23 5.62 0 1.23-.62 2.56-1.88 3.98-1.26 1.41-4.03 2.1-8.29 2.1-4.62 0-8.96-.85-13-2.56a92.07 92.07 0 0 1-10.29-5.2l19.91 25.18.83.08c2.49.21 4.6.32 6.3.32 6.14 0 11.6-1 16.35-3.02 4.74-2 8.42-4.88 11.09-8.66 2.66-3.78 3.98-8.23 3.98-13.4zM239.2 48.57s1.72-2.15 3.51-5.59a26.32 26.32 0 0 0-43.57-29.53 72.52 72.52 0 0 1-3.34 4.93l13.67 17.14 7-9.07a4.82 4.82 0 1 1 7.79 5.64l-.13.19-27.75 37.64.03 19.5 51 .06V70.9l-24.23-.03 16.02-22.3z' />
      </g>
    </LogoStyled>
  )
}
