import styled, { type CSSObject } from '@emotion/styled'
import { colors, font, radius, shadow, spacing } from '../../../Theme/Common'
import { gapX } from '../../../Utils/gap'
import type { ToastType } from './types'

type Props = {
  type: ToastType
}

const iconColorDefinition: Record<ToastType, CSSObject> = {
  success: {
    backgroundColor: colors.success[50],
    color: colors.success[600],
  },
  warning: {
    backgroundColor: colors.warning[50],
    color: colors.warning[600],
  },
  error: {
    backgroundColor: colors.error[50],
    color: colors.error[600],
  },
}

export const ToastStyled = styled.div<Props>(({ type }) => [
  {
    alignItems: 'center',
    backgroundColor: colors.white,
    border: `1px solid ${colors.neutral[200]}`,
    borderRadius: radius.lgx2,
    boxShadow: shadow.md,
    display: 'flex',
    fontFamily: font.family.primary,
    fontWeight: font.weight.medium,
    padding: `${spacing.sm} ${spacing.md}`,
    ...font.size.lg,

    '> div:first-of-type': {
      alignItems: 'center',
      borderRadius: radius.full,
      display: 'flex',
      height: '3.2rem',
      justifyContent: 'center',
      minWidth: '3.2rem',
      width: '3.2rem',
      ...iconColorDefinition[type],
    },

    '> div:not(:first-of-type):not(:last-of-type)': {
      flex: '1 1 auto',
    },
  },
  gapX(spacing.sm),
])
