import React from 'react'
import {
  BettingOptionsSelectionButtonStyled,
  BettingOptionsSelectionInnerStyled,
  BettingOptionsSelectionLabelStyled,
} from './BettingOptionsSelection.styles'
import { PriceChangeDisplay } from '../PriceChangeDisplay'

interface BettingOptionsSelection {
  isSelected: boolean
  isDisabled: boolean
  onClick: () => void
  price: string
  label: string
}

export const BettingOptionsSelection = ({
  isSelected,
  isDisabled,
  onClick,
  price,
  label,
}: BettingOptionsSelection) => (
  <BettingOptionsSelectionButtonStyled
    isSelected={isSelected}
    isDisabled={isDisabled}
    disabled={isDisabled}
    onClick={onClick}
    {...{ [`data-tid-starters-${label.toLowerCase()}`]: '' }}
  >
    <BettingOptionsSelectionInnerStyled>
      <BettingOptionsSelectionLabelStyled>{label}</BettingOptionsSelectionLabelStyled>
      <PriceChangeDisplay
        priceWin={price}
        isSelected={isSelected}
        arrowLocation='IN'
        containerStyle={{ maxWidth: '6.5rem' }}
      />
    </BettingOptionsSelectionInnerStyled>
  </BettingOptionsSelectionButtonStyled>
)
