import React from 'react'
import dayjs from 'dayjs'
import type { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import {
  HeaderCountdownAndArrowStyled as CountdownAndArrow,
  TicketHeaderStyled as TicketHeader,
} from './Header.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { RaceCountdown } from '@core/Components/RaceCountdown'
import {
  getStatusText,
  isTippingTicket,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { StatusSilks } from './Components/StatusSilks/StatusSilks'
import { PlanSeq } from '@core/Data/betting'
import { SelectionInfo } from './Components'
import { StatusPillStyled } from '../Common/StatusPill.styles'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'

export const Header: React.FC<HeaderProps> = ({ ticket, status, onClick, isExpanded }) => {
  const isPreviewMode = React.useContext(TicketDisplayContext).type === 'share-preview'

  const legs = ticket.legs
  if (legs.length === 0 || legs[0].selections.length === 0) {
    return <strong data-testid='empty-list'>Sections list empty!</strong>
  }

  const isPendingTab = ticket.status === 'Pending'
  const isTipping = isTippingTicket(ticket)
  const isMulti = !isTipping && legs.length > 1
  const isFavouriteNumbers = ticket.planSeq === PlanSeq.FavouriteNumbers

  const countdownDate =
    (isPendingTab || isPreviewMode) &&
    !isFavouriteNumbers &&
    !isTipping &&
    getCountdownDate(ticket, isMulti)

  return (
    <div onClick={onClick} data-testid='ticket-header'>
      <TicketHeader>
        <div data-testid='ticket-header-selection'>
          <SelectionInfo ticket={ticket} />
        </div>

        <CountdownAndArrow>
          <div>
            {countdownDate &&
              (isPreviewMode ? (
                !isMulti && (
                  <div data-testid='share-bet-event-time'>
                    <span>{dayjs(countdownDate).format('ddd D MMM YYYY')}</span>
                    <br />
                    <span>{dayjs(countdownDate).format('h:mm A')}</span>
                  </div>
                )
              ) : (
                <RaceCountdown displaySize='1.2rem' raceStartTime={new Date(countdownDate)} />
              ))}

            {!isPreviewMode && (
              <Icon
                size='1.4rem'
                type={isExpanded ? 'arrowup' : 'arrowdown'}
                data-testid='ticket-header-expand-icon'
              />
            )}
          </div>

          {status && (
            <div>
              <StatusPillStyled status={status} size='large' data-testid='ticket-status'>
                {getStatusText(status)}
              </StatusPillStyled>
            </div>
          )}
        </CountdownAndArrow>
      </TicketHeader>

      {!isPreviewMode && <StatusSilks ticket={ticket} />}
    </div>
  )
}

// =======
// Helpers
// =======

function getCountdownDate(ticket: Ticket, isMulti: boolean): string {
  if (!isMulti) return ticket.legs[0].advertisedStartTime

  const currentTime = dayjs()
  const timesFromNow = ticket.legs.map(leg => {
    const diff = currentTime.diff(leg.advertisedStartTime)
    // Allow time if within 2min
    if (diff < 1000 * 60 * 2) return diff
    return -Infinity
  })

  const closestTime = Math.max(...timesFromNow)
  const indexOfClosest = timesFromNow.findIndex(time => time === closestTime)

  if (indexOfClosest === -1) return ticket.legs[0].advertisedStartTime

  return ticket.legs[indexOfClosest].advertisedStartTime
}

// =====
// Types
// =====

type HeaderProps =
  | {
      ticket: Ticket
      status: TicketStatus
      onClick: () => void
      isExpanded: boolean
      isPreviewMode?: never
    }
  // Preview
  | {
      ticket: Ticket
      status: TicketStatus
      onClick?: never
      isExpanded?: never
    }
