import { trackEvent, trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import type { Reason } from '@mobi/deposit/Errors'
import type { PaymentMethods } from '@mobi/component-library/Deposit/types'

export const trackQuickDepositShown = () => trackKey(keys.quickDepositShown)

export const trackQuickDepositStarted = (paymentMethod: PaymentMethods) =>
  trackEvent(keys.quickDepositStarted, { depositMethod: paymentMethod })

type QuickDepositSucceededParams = {
  paymentMethod: PaymentMethods
  accountNumber: number
  depositAmount: string
  isFirstDeposit: boolean
  isSecondDeposit: boolean
}

export const trackQuickDepositSucceeded = ({
  depositAmount,
  accountNumber,
  paymentMethod,
  isFirstDeposit,
  isSecondDeposit,
}: QuickDepositSucceededParams) =>
  trackEvent(keys.quickDepositSucceeded, {
    depositMethod: paymentMethod,
    accountNumber,
    depositAmount,
    isFirstDeposit,
    isSecondDeposit,
  })

export const trackQuickDepositFailed = (paymentMethod: PaymentMethods, failureReason: Reason) =>
  trackEvent(keys.quickDepositFailed, {
    depositMethod: paymentMethod,
    failureReason,
  })

export const trackQuickDepositCancelled = (paymentMethod: PaymentMethods) =>
  trackEvent(keys.quickDepositCancelled, { depositMethod: paymentMethod })

export const trackQuickDepositOtherDepositClicked = (paymentMethod: PaymentMethods) =>
  trackEvent(keys.quickDepositOtherDepositClicked, { depositMethod: paymentMethod })

export const trackQuickDepositEditBetClicked = (paymentMethod: PaymentMethods) =>
  trackEvent(keys.quickDepositEditBetClicked, {
    depositMethod: paymentMethod,
  })

export const trackQuickDeposit3dSecureUserDataFetched = (
  success: boolean,
  accountNumber: number
) => {
  trackEvent(
    success ? keys.quickDeposit3dSecureUserDataSuccess : keys.quickDeposit3dSecureUserDataFail,
    {
      accountNumber,
    }
  )
}
