export const RacePageApiPath = `/api/meetings/{meetingId}/{meetingDate}/race/{raceNumber}`

export enum ScratchType {
  Standard = 'Standard',
  Late = 'Late',
}

export const FixedOddsMarketCodes = {
  OddsEvens: 'EO',
  InsideOutside: 'IO',
  HeadToHead: 'H2H',
  HalfVsHalf: 'HVH',
  RunnerVsField: 'RVF',
  Concession: 'CON',
  FavouriteOut: 'FAV',
  StartingPrice: 'SP',
} as const

export type MarketCodesType = (typeof FixedOddsMarketCodes)[keyof typeof FixedOddsMarketCodes]

export enum PoolStatus {
  Open,
  Closed,
  Interim,
  Released,
  Abandoned,
}

export enum RaceStatus {
  Open = 'Open',
  Abandoned = 'Abandoned',
  Released = 'Released',
  Interim = 'Interim',
  Closed = 'Closed',
}

export type RacePageDTO = RacePageDataTransferObject | ResultsPageDataTransferObject

export type AcceptorDTO = StarterDataTransferObject | FinisherDataTransferObject

export type PageDataTransferObject = (PageDataBaseTagStarters | PageDataBaseTagResults) &
  PageDataBase

type PageDataBaseTagStarters = { Tag: 'Starters' }
type PageDataBaseTagResults = { Tag: 'Results' }
type PageDataBase = {
  AvailablePools: Array<PoolDataTransferObject>
  MeetingInformation: MeetingInformationDataTransferObject
  FixedOddsMarkets: Array<MarketDataTransferObject>
  IsMultiInProgress: boolean
  NumberOfMultiLegs: number
  FirstLegsOfQuaddiesInMeeting: number[]
  RacecourseSeq: number
}

export type RacePageDataTransferObject = PageDataBaseTagStarters &
  PageDataBase & {
    BetType: string
    AvailableBetTypes: Array<BetTypeDataTransferObject>
    RaceStarters: Array<{ RaceKey: RaceKey; Starters: Array<StarterDataTransferObject> }>
    SpecialOffersInfo: Array<SpecialOfferInfo>
    GiddyUpRaceKey: string
    GiddyUpFixtureKey: string
    HasDoubleProvDivs: boolean
    HasQuaddieProvDivs: boolean
    SkyTvChannelId: 'SR1' | 'SR2'
  }

export type ResultsPageDataTransferObject = PageDataBaseTagResults &
  PageDataBase & {
    SimplePlacings: string
    Finishers: Array<FinisherDataTransferObject>
    RaceStatus: RaceStatus
    ProtestStatus: string
    NoveltyDividends: Array<NoveltyDividendDataTransferObject>
    AvailableSubstitutePools: Array<SubstitutePoolDataTransferObject>
    AvailableScratchings: Array<ScratchingDataTransferObject>
    RaceReplay: RaceReplayDataTransferObject
    GiddyUpRaceKey: string
    GiddyUpFixtureKey: string
  }

export type RaceReplayDataTransferObject = {
  HasVideo: boolean
  VideoKey: string
  RaceLocation: string
  Date: string
}

export type ScratchingDataTransferObject = {
  StarterNumber: string
  StarterName: string
  IsFixedOdds: boolean
  ScratchedDateTime: string
  IsRaceResulted: boolean
  WinDivDeduction: number
  PlaceDivDeduction: number
}

export type SubstitutePoolDataTransferObject = {
  RaceNumber: number
  Name: string
  Legs: Array<number>
  AvailableSubstitutesInPool: Array<SubstituteDataTransferObject>
}

export type SubstituteDataTransferObject = {
  LegNumber: number
  StarterNumber: number
  Name: string
  IsWinner: boolean
  IsLoser: boolean
  IsAbandoned: boolean
  IsUnallocated: boolean
}

export type NoveltyDividendDataTransferObject = {
  PoolName: string
  RaceNumbers: Array<number>
  Dividends: Array<DividendDataTransferObject>
}

export type DividendDataTransferObject = {
  Name: string
  Dividend: string
}

export type MeetingInformationDataTransferObject = {
  MeetingCode: string
  MeetingCodeText: 'Races' | 'Trots' | 'Dogs'
  MeetingId: string
  MeetingName: string
  MeetingDate: string
  NextRaceNumber: number
  PreviousRaceNumber: number
  HasNextRace: boolean
  HasPreviousRace: boolean
  TrackCondition: string
  WeatherAtLocation: string
  SelectedRace: RaceDataTransferObject
  Races: Array<RaceDataTransferObject>
}

export type RaceDataTransferObject = {
  Key: string
  Distance: number
  Tips: string
  Tipster: string
  RaceNumber: number
  RaceName: string
  RaceTime: string
  RaceType: string
  /** @deprecated Use PayoutInformation instead */
  PayoutInfomation?: string
  PayoutInformation: string
  ProvDivUpdateTime: string
  FixedPlacesPaying: number
  IsFixedOddsRace: boolean
  IsSpAvailable: boolean
  FixedOddsInfo: FixedOddsRaceInformationDataTransferObject
  HasSpecials: boolean
  HasJackpots: boolean
  HasPlacePool: boolean
  // TODO: Change this to RaceStatus. Legacy code is treating this interchangeably between a string and an int
  RaceStatus: keyof typeof RaceStatus | (string & {})
  RaceStatusText: keyof typeof PoolStatus
}

export type FixedOddsRaceInformationDataTransferObject = {
  BettingConditions: string
  BettingRules: string
  BettingTerms: string
  IsSuspended: boolean
  IsClosed: boolean
  IsResulted: boolean
  IsPlaceAvailable: boolean
  IsFixedOddsOnly: boolean
  IsRaceAvailable: boolean
  PayoutInformation: string
  MarketSequence: number
}

export type MarketDataTransferObject = {
  FixtureDate: Date
  FixtureId: string
  ContestNumber: number
  IsResulted: boolean
  IsSuspended: boolean
  MarketName: string
  MarketSequence: number
  BettingConditions: string
  BettingRules: string
  Propositions: Array<PropDataTransferObject>
  MarketCode: MarketCodesType
}

export type PropDataTransferObject = {
  PlayerNo: number
  Description: string
  Price: number | null
  IsAvailable: boolean
  IsScratched: boolean
  PriceToDisplay: string
  PropositionSequence: number
  Number?: number
}

export type RaceKey = {
  Key: string
  RaceNumber: number
  Leg: number
  StartTime: string
  Tips: string
  Tipster: string
  Distance: string
  Name: string
  Pay: string
  PoolInfo: RaceKeyPoolInfoDataTransferObject
  AllUpPools: Array<PoolDataTransferObject>
  IsDoubleLeg: boolean
  IsQuaddieLeg: boolean
}

export type RaceKeyPoolInfoDataTransferObject = {
  HasPlacePool: boolean
  HasQuinellaPool: boolean
  HasWinPool: boolean
  HasWinPlacePool: boolean
}

export type SpecialOfferInfo = {
  Title: string
  Description: string
  MaxRewardAmount: number
  Sequence: number
}

export type PoolDataTransferObject = {
  Name: string
  Total: string
  HasJackpot: boolean
  JackpotTotal: string
  IsAbandoned: boolean
  PoolingIcon: string
  RaceNumbers: Array<number>
  JurisdictionDescription: string
}

export type BetTypeDataTransferObject = {
  Type: string
  DisplayName: string
  PoolCode: string
  HasJackpot: boolean
  IsAbandoned: boolean
}

export type AcceptorDataTransferObject = {
  Type: string
  Number: number
  Name: string
  SilkImages: SilkImagesDataTransferObject
  IsScratched: boolean
  ScratchType: ScratchType
  HasFixedOdds: boolean
  IsScratchedToteAndFob: boolean
  IsFixedOddsStarter: boolean
  IsFavourite: boolean
  DisplayWinDividend: string
  DisplayPlaceDividend: string
  StarterPersonName: string
  TrainerName: string | null
  IsEmergency: boolean
  MeetingCode: string

  EmergencyNumber: number
  HasPlacePool: boolean
  Rating: number
  IsFormDataEmpty: boolean
  IsRiderChanged: boolean

  // Race
  JockeyWeight: string
  LastFour: string
  Barrier: number

  // Dog
  Box: number
  StripeCount: number

  // Trot
  Handicap: string
}

export type StarterDataTransferObject = AcceptorDataTransferObject & {
  IsToteEnabled: boolean
  ProvDivPools: ProvDivPoolDataTransferObject[]
  MarketMovers: MarketMoversDataTransferObject[]
  HasMarketMovers: boolean
  /** @deprecated use FixedOddsInfo property */
  FixedOddsStarterInfo: FixedOddsStarterInfoDataTransferObject
  FixedOddsInfo?: FixedOddsStarterInfoDataTransferObject
}

export type FinisherDataTransferObject = AcceptorDataTransferObject & {
  Placing: number
  IsCountBack: boolean
  ToteWinDiv: number
  TotePlaceDiv: number
  ToteWinProvDiv: number
  TotePlaceProvDiv: number
  DisplayWinDividend: string
  DisplayPlaceDividend: string
  ShouldDisplayPlaceDividend: boolean
  /** @deprecated use FixedOddsInfo property */
  FixedOddsFinisherInfo: FixedOddsFinisherInfoDataTransferObject
  FixedOddsInfo?: FixedOddsFinisherInfoDataTransferObject
  SpWinPrice: number
}

export type FixedOddsAcceptorInfoDTO =
  | FixedOddsStarterInfoDataTransferObject
  | FixedOddsFinisherInfoDataTransferObject

export type FixedOddsInfoDataTransferObject = {
  DisplayPlaceDividend: string
  DisplayWinDividend: string
  WinDividendDeduction: number | null
  PlaceDividendDeduction: number | null
  IsScratched: boolean
  IsSuspended: boolean
  IsFavourite: boolean
  ScratchedTime: string | null
  PropositionSequence: number
}

export type FixedOddsStarterInfoDataTransferObject = FixedOddsInfoDataTransferObject & {
  FixedFlucs: FixedFlucsDataTransferObject
  HasPlaceDividend: boolean
  HasPlacePool: boolean
  HasWinDividend: boolean
  PlayerNo: number
  SameRaceMultiPrices?: SameRaceMultiPrices
}

export type FixedOddsFinisherInfoDataTransferObject = FixedOddsInfoDataTransferObject & {
  PlaceDiv: number
  ShouldDisplayPlaceDividend: boolean
  WinDiv: number
}

export type MarketMoversDataTransferObject = {
  MinutesToAst: number
  Dividend: number | null
}

export type ProvDivPoolDataTransferObject = {
  Name: string
  ProvDivs: ProvDivDataTransferObject[]
  QuaddieProvDivLegNums: number[]
}

export type ProvDivDataTransferObject = {
  Div: string
  Outcome: number[]
}

export type SilkImagesDataTransferObject = {
  small: SilkImageDataTransferObject
  large: SilkImageDataTransferObject
  larger: SilkImageDataTransferObject
  largest: SilkImageDataTransferObject
}

export type SilkImageDataTransferObject = {
  url: string
  size: SilkImageSizeDataTransferObject
}

export type SilkImageSizeDataTransferObject = {
  width: number
  height: number
  y: number
}

export type SameRaceMultiPrices = {
  Win?: number
  Top2?: number
  Top3?: number
  Top4?: number
  IsFavourite: boolean
}

export type FixedFlucsDataTransferObject = {
  WinOpeningDividend: number
  WinHighDividend: number
  WinLowDividend: number
  WinDividends: number[]
}
