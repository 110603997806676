import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { useLogon } from '@core/Utils/hooks'
import { fetchTakeABreakDetails, saveTakeABreak, TakeABreakResponse } from './api'
import TakeABreakError from './Components/TakeABreakError'
import TakeABreakActive from './Components/TakeABreakActive'
import { Spinner } from '@mobi/component-library/Common/Spinner'
import { dayjs } from '@mobi/utils'
import {
  trackTakeABreakSubmitSucceeded,
  trackTakeABreakSubmitFailed,
  trackTakeABreakCancel,
  trackTakeABreakSubmitSelection,
} from '@classic/Foundation/Analytics/GoogleTagManagerService'
import TakeABreakInfo from './Components/TakeABreakInfo'
import TakeABreakForm from './Components/TakeABreakForm'
import { setBackUrl } from '@classic/Foundation/Navigation/Nav'
import { queryKeys } from '@core/Data/ReactQuery/constants'

const TakeABreak = (): JSX.Element => {
  const { accountNumber } = useLogon()
  const queryClient = useQueryClient()
  const queryKey = [queryKeys.takeABreak, accountNumber]
  const [userHasAgreedToBreakTerms, setUserHasAgreedToBreakTerms] = React.useState(false)
  const justPostedAccountToTakeABreak = React.useRef(false)

  React.useEffect(() => {
    window.setTimeout(() => {
      setBackUrl('#account')
    }, 100)
  }, [])

  const getTakeABreakDetails = useQuery(queryKey, fetchTakeABreakDetails, {
    // set disabled and clear cache when accountNumber initially has undefined state
    cacheTime: accountNumber ? 300000 : 0,
    enabled: accountNumber ? true : false,
  })

  const setTakeABreakDetails = useMutation(saveTakeABreak, {
    onSuccess: (data: TakeABreakResponse, variables) => {
      queryClient.setQueryData(queryKey, { ...data })
      justPostedAccountToTakeABreak.current = true
      trackTakeABreakSubmitSucceeded({ variables, accountNumber })
    },
    onError: (_, variables) => {
      justPostedAccountToTakeABreak.current = true
      trackTakeABreakSubmitFailed({ variables, accountNumber })
    },
  })

  const onPostAccountToTakeABreakHandler = (breakDuration: number) => {
    trackTakeABreakSubmitSelection({ accountNumber, breakDuration })
    setTakeABreakDetails.mutate({
      accountNumber: accountNumber ?? 0,
      endDate: dayjs().add(breakDuration, 'days').toDate(),
    })
  }

  const isLoading = getTakeABreakDetails.isLoading || !accountNumber
  const inError = getTakeABreakDetails.isError || setTakeABreakDetails.isError
  const takeABreakIsActive = getTakeABreakDetails.isSuccess && getTakeABreakDetails.data?.inProgress
  const takeABreakIsNotActive =
    getTakeABreakDetails.isSuccess && !getTakeABreakDetails.data?.inProgress

  React.useEffect(() => {
    return () => {
      if (takeABreakIsNotActive && !justPostedAccountToTakeABreak.current) {
        // user opts not to take a break send GA
        // only trigger event if inactive and NOT after a post to set tab
        trackTakeABreakCancel({ accountNumber })
      }
    }
  }, [takeABreakIsNotActive, accountNumber])

  return (
    <MainContainer background='white' data-tid-take-a-break>
      <ContentHeader title='Take a Break' />

      {isLoading && <Spinner data-testid='loading' />}

      {inError && <TakeABreakError data-testid='error' />}

      {takeABreakIsActive && (
        <TakeABreakActive
          accountNumber={accountNumber ?? 0}
          endDate={getTakeABreakDetails.data?.endDate}
          justPostedAccountToTakeABreak={justPostedAccountToTakeABreak.current}
        />
      )}

      {takeABreakIsNotActive &&
        (!userHasAgreedToBreakTerms ? (
          <TakeABreakInfo
            setUserHasAgreedToBreakTerms={setUserHasAgreedToBreakTerms}
            accountNumber={accountNumber ?? 0}
          />
        ) : (
          <TakeABreakForm
            onPostAccountToTakeABreakHandler={onPostAccountToTakeABreakHandler}
            isPostingAccountToTakeABreakBusy={setTakeABreakDetails.isLoading}
            accountNumber={accountNumber ?? 0}
          />
        ))}
    </MainContainer>
  )
}

export default TakeABreak
