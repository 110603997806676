import { AppRoutes } from '@core/AppRoutes'
import { useBetAccount, useLogon } from '@core/Utils/hooks'
import React from 'react'
import { isLoading, isErrored, LoadingInstance, ErroredInstance } from 'rwwa-data-access'
import { connect } from '../../Components/HOCs/connect'
import { observeImmutable } from '../../Components/HOCs/observe'
import { MainContainer } from '../../Components/Containers'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { Spinner, DesktopSpinner } from '@mobi/component-library/Common/Spinner'
import { ErrorMessage } from '../../Components/Messages'
import { ContentHeader } from '../../Components/ContentHeader'
import { CheckboxInput } from '@mobi/component-library/Common/Input'
import { AdditionalDetails } from './Components/AdditionalDetails'
import { InputField } from '@mobi/component-library/Common/Input'
import {
  ContactDetails as ContactDetailsRepo,
  ContactDetailsKey,
} from '../../Data/Account/contactDetails'
import type { BetAccountHolder } from '@mobi/api-types'
import {
  ShowManualAddressForm,
  ShowManualPostalAddressForm,
} from './Components/ManualAddressEntry/driver'

import {
  state$,
  type ContactDetailsFields,
  type ContactDetailsStateRecord,
  ChangeValue,
  ChangeResidentialAddress,
  ChangePostalAddress,
  UpdateFields,
  SaveButtonClicked,
  type ContactDetailsState,
  isFieldValidationSuccess,
  ValidateField,
} from './driver'

import {
  ContactDetailsContainer,
  ContactDetailsPreferredNameStyled,
  ContactDetailsItemStyled,
  ContactDetailsTitleLabelStyled,
  ContactDetailsEditLabelStyled,
  ContactDetailsManualAddressButton,
} from './ContactDetails.styles'

import { AddressAutocomplete } from './AddressAutocomplete'
import { FailedSaveMessage } from './Components/FailedSaveMessage/FailedSaveMessage'
import { getContactDetailsFields } from './data-transforms'
import { navigateHistoryBack } from '@classic/AppUtils/Framework/Intent/navigation'
import { ManualAddressEntry } from './Components/ManualAddressEntry'
import { ContactDetailsReverificationSuccess } from '../Reverification/Components/ReverificationComplete'
import { NameDateOfBirthChangedNotice } from './Components/NameDateOfBirthChangedNotice/NameDateOfBirthChangedNotice'
import { Reset } from '@core/Areas/ContactDetails/driver'
import { useHistory, useParams } from 'react-router-dom'
import { CustomerServiceTelephone } from '@mobi/component-library/Common/V2'

export interface ContactEditDetailsProps {
  isDesktop?: boolean
  betAccountHolderNumber?: string | null
  navigateOnUpdateSuccess: () => void
  navigateOnUnverified: () => void
}

export function ContactDetailsEditMobiWrapper() {
  const { accountNumber } = useParams<{ accountNumber?: string }>()
  const history = useHistory()

  return (
    <ContactDetailsEdit
      isDesktop={false}
      betAccountHolderNumber={accountNumber}
      navigateOnUpdateSuccess={() => history.push('/account/contactdetails')}
      navigateOnUnverified={() => history.push(AppRoutes.Verification)}
    />
  )
}

export function ContactDetailsEdit({
  isDesktop,
  betAccountHolderNumber,
  navigateOnUpdateSuccess,
  navigateOnUnverified,
}: ContactEditDetailsProps) {
  return (
    <ConnectContactDetails
      contactDetails={betAccountHolderNumber || ContactDetailsKey}
      isDesktop={isDesktop}
      navigateOnUpdateSuccess={navigateOnUpdateSuccess}
      navigateOnUnverified={navigateOnUnverified}
    />
  )
}

const ConnectContactDetails = connect({
  contactDetails: ContactDetailsRepo,
})<ContactEditDetailsProps>(ContactDetailsEditLoader)

export function ContactDetailsEditLoader({
  contactDetails,
  isDesktop,
  navigateOnUpdateSuccess,
  navigateOnUnverified,
}: {
  contactDetails: typeof LoadingInstance | typeof ErroredInstance | BetAccountHolder
} & ContactEditDetailsProps) {
  const { accountNumber, isLoggedIn } = useLogon()
  const betAccountQuery = useBetAccount({
    enabled: accountNumber !== null && isLoggedIn === true,
    onSuccess: ({ IsIDVerified: isIdVerified }) => {
      if (!isIdVerified) {
        navigateOnUnverified()
      }
    },
  })

  React.useEffect(() => Reset(), [])

  if (isLoading(contactDetails) || betAccountQuery.isLoading) {
    return isDesktop ? <DesktopSpinner /> : <Spinner />
  }

  if (isErrored(contactDetails) || betAccountQuery.isError) {
    return <ErrorMessage>An error occurred while loading your contact details.</ErrorMessage>
  }

  // Catch non-verified users
  if (betAccountQuery.data?.IsIDVerified === false) {
    return null
  }

  const props = getContactDetailsFields(contactDetails)
  return (
    <ContactDetailsEditSetup
      {...props}
      isDesktop={isDesktop}
      navigateOnUpdateSuccess={navigateOnUpdateSuccess}
    />
  )
}

type ContactDetailsEditSetupProps = ContactDetailsFields &
  Pick<ContactEditDetailsProps, 'isDesktop' | 'navigateOnUpdateSuccess'>

class ContactDetailsEditSetup extends React.Component<ContactDetailsEditSetupProps> {
  public componentDidMount() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isDesktop, navigateOnUpdateSuccess, ...fields } = this.props
    UpdateFields(fields)
  }

  public render() {
    const { isDesktop, navigateOnUpdateSuccess } = this.props
    return (
      <ContactDetailsEditObserver
        isDesktop={isDesktop}
        navigateOnUpdateSuccess={navigateOnUpdateSuccess}
      />
    )
  }
}

const ContactDetailsEditObserver = observeImmutable<
  ContactDetailsStateRecord,
  Pick<ContactEditDetailsProps, 'isDesktop'> &
    Pick<ContactEditDetailsProps, 'navigateOnUpdateSuccess'>
>(state$, ({ record, isDesktop, navigateOnUpdateSuccess }) => {
  const state: ContactDetailsState = record.toJS()

  if (state.updateSuccess) {
    navigateOnUpdateSuccess()
    return null
  }

  return <ContactDetailsEditComponent {...state} isDesktop={isDesktop} />
})

const changeAddressResidential = (value: string) =>
  ChangeResidentialAddress({ addressResidentialMoniker: value })
const changeAddressPostal = (value: string) => ChangePostalAddress({ addressPostalMoniker: value })
const changeAddressPostalSame = (event: React.FormEvent<HTMLInputElement>) =>
  ChangeValue({ field: 'addressPostalSame', value: event.currentTarget.checked })
const changeNameDateOfBirthChanged = (event: React.FormEvent<HTMLInputElement>) =>
  ChangeValue({ field: 'nameDateOfBirthChanged', value: event.currentTarget.checked })
function changeInputField(field: keyof ContactDetailsFields) {
  return (event: React.FormEvent<HTMLInputElement>) =>
    ChangeValue({ field, value: event.currentTarget.value })
}
function blurInputField(field: keyof ContactDetailsFields) {
  return () => ValidateField({ field })
}

export function ContactDetailsEditComponent({
  fullName,
  preferredName,
  dateOfBirth,
  email,
  phoneMobile,
  phoneHome,
  phoneWork,
  addressResidentialMoniker,
  addressPostalMoniker,
  addressPostalSame,
  validationErrors,
  updateFailed,
  loading,
  isAdditionalDetailsChanged,
  agreeToVerifyAdditionalDetails,
  isDesktop,
  nameDateOfBirthChanged,
  nameDateOfBirthChangedInitial,
  isInternationalCustomer,
}: ContactDetailsState & Pick<ContactEditDetailsProps, 'isDesktop'>) {
  const fieldValidationSucceeded = isFieldValidationSuccess(
    addressPostalSame,
    isAdditionalDetailsChanged,
    !!agreeToVerifyAdditionalDetails,
    validationErrors
  )

  const nameDateOfBirthChangedMessage = isInternationalCustomer
    ? 'For name, date of birth or international address changes'
    : 'For name or date of birth changes'
  const nameDateOfBirthChangedLabel = (
    <React.Fragment>
      {nameDateOfBirthChangedMessage} please select the tick box and a customer representative will
      contact you. Alternatively call{' '}
      <CustomerServiceTelephone isInternational={isInternationalCustomer} />.
    </React.Fragment>
  )

  return (
    <MainContainer>
      <ContactDetailsReverificationSuccess navigateToHistoryBack={true} />
      {!isDesktop && <ContentHeader title='Edit Contact Details' />}
      <ContactDetailsContainer>
        {preferredName && (
          <ContactDetailsPreferredNameStyled>{preferredName}</ContactDetailsPreferredNameStyled>
        )}
        <ContactDetailsItemStyled>{fullName}</ContactDetailsItemStyled>

        <ContactDetailsTitleLabelStyled>Date of Birth</ContactDetailsTitleLabelStyled>
        <ContactDetailsItemStyled>{dateOfBirth}</ContactDetailsItemStyled>

        <form>
          <fieldset>
            <InputField
              type='email'
              testId='email-edit'
              value={email || ''}
              onChange={changeInputField('email')}
              onBlur={blurInputField('email')}
              name='Email'
              required={true}
              errorMessage={validationErrors.email}
            />

            <InputField
              type='tel'
              testId='mobile-edit'
              value={phoneMobile || ''}
              onChange={changeInputField('phoneMobile')}
              onBlur={blurInputField('phoneMobile')}
              name='Mobile'
              required={true}
              errorMessage={validationErrors.phoneMobile}
            />

            {isDesktop && (
              <InputField
                type='tel'
                testId='home-edit'
                value={phoneHome || ''}
                onChange={changeInputField('phoneHome')}
                onBlur={blurInputField('phoneHome')}
                name='Home'
                errorMessage={validationErrors.phoneHome}
              />
            )}

            {isDesktop && (
              <InputField
                type='tel'
                testId='work-edit'
                value={phoneWork || ''}
                onChange={changeInputField('phoneWork')}
                onBlur={blurInputField('phoneWork')}
                name='Work'
                errorMessage={validationErrors.phoneWork}
              />
            )}

            {isInternationalCustomer ? (
              <ReadonlyAddress
                addressResidentialMoniker={addressResidentialMoniker}
                addressPostalMoniker={addressPostalMoniker}
                addressPostalSame={addressPostalSame}
              />
            ) : (
              <EditableAddress
                addressResidentialMoniker={addressResidentialMoniker}
                addressPostalMoniker={addressPostalMoniker}
                addressPostalSame={addressPostalSame}
                validationErrors={validationErrors}
              />
            )}

            <CheckboxInput
              id='contact-centre-checkbox'
              data-tid-contact-centre-checkbox=''
              checked={nameDateOfBirthChanged}
              disabled={nameDateOfBirthChangedInitial}
              onChange={changeNameDateOfBirthChanged}
              label={nameDateOfBirthChangedLabel}
            />
          </fieldset>
        </form>

        <ManualAddressEntry />

        <NameDateOfBirthChangedNotice />

        <AdditionalDetails />

        <Grid padding='0.25rem'>
          <GridCell data-tid-cancel-button=''>
            <ButtonBlock color='secondary' onClick={navigateHistoryBack}>
              Cancel
            </ButtonBlock>
          </GridCell>
          <GridCell data-tid-save-button=''>
            <ButtonBlock
              onClick={SaveButtonClicked}
              disabled={!fieldValidationSucceeded || loading}
            >
              Save
            </ButtonBlock>
            {loading ? <Spinner /> : null}
          </GridCell>
        </Grid>

        <FailedSaveMessage showFailedMessage={updateFailed} />
      </ContactDetailsContainer>
    </MainContainer>
  )
}

function ReadonlyAddress({
  addressPostalSame,
  addressResidentialMoniker,
  addressPostalMoniker,
}: Pick<
  ContactDetailsState,
  'addressPostalSame' | 'addressResidentialMoniker' | 'addressPostalMoniker'
>) {
  return (
    <div>
      <ContactDetailsTitleLabelStyled>Residential Address</ContactDetailsTitleLabelStyled>
      <ContactDetailsItemStyled>{addressResidentialMoniker}</ContactDetailsItemStyled>

      {!addressPostalSame && (
        <ContactDetailsTitleLabelStyled>Postal Address</ContactDetailsTitleLabelStyled>
      )}
      {!addressPostalSame && (
        <ContactDetailsItemStyled>{addressPostalMoniker}</ContactDetailsItemStyled>
      )}
    </div>
  )
}

function EditableAddress({
  addressPostalSame,
  addressResidentialMoniker,
  addressPostalMoniker,
  validationErrors,
}: Pick<
  ContactDetailsState,
  'addressPostalSame' | 'addressResidentialMoniker' | 'addressPostalMoniker' | 'validationErrors'
>) {
  return (
    <div>
      <ContactDetailsManualAddressButton type='button' onClick={ShowManualAddressForm}>
        Manually Enter Address
      </ContactDetailsManualAddressButton>
      <ContactDetailsEditLabelStyled htmlFor='contact-address'>
        Residential Address<span>*</span>
      </ContactDetailsEditLabelStyled>

      <AddressAutocomplete
        id='contact-address-autocomplete'
        inputId='contact-address'
        value={addressResidentialMoniker}
        onSelection={changeAddressResidential}
        errorMessage={validationErrors.addressResidentialMoniker}
      />

      <CheckboxInput
        id='postal-address-checkbox'
        data-tid-postal-address-checkbox=''
        checked={addressPostalSame}
        onChange={changeAddressPostalSame}
        label='This is my postal address.'
      />

      {addressPostalSame ? null : (
        <ContactDetailsManualAddressButton type='button' onClick={ShowManualPostalAddressForm}>
          Manually Enter Address
        </ContactDetailsManualAddressButton>
      )}
      {addressPostalSame ? null : (
        <ContactDetailsEditLabelStyled htmlFor='contact-address-postal'>
          Postal address<span>*</span>
        </ContactDetailsEditLabelStyled>
      )}
      {addressPostalSame ? null : (
        <AddressAutocomplete
          id='contact-address-postal-autocomplete'
          inputId='contact-address-postal'
          value={addressPostalMoniker}
          onSelection={changeAddressPostal}
          autoFocus={!!validationErrors.addressPostalMoniker}
          errorMessage={validationErrors.addressPostalMoniker}
        />
      )}
    </div>
  )
}
