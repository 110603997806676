import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { AppRoutes } from '@core/AppRoutes'
import {
  BotttomBarStyled,
  WrapperStyled,
  PlayerDragHandleStyled,
  BigPlayButtonWrapperStyled,
  TopBarStyled,
} from './VideoPlayerControls.styles'
import { PlayerButton, ResizeIcon } from './Components/Buttons'
import { PendingBetsDrawerButton } from './Components/PendingBetsDrawerButton'
import { SkyChannelSelector } from './Components/SkyChannelSelector'
import {
  setIsFullscreen,
  setIsMuted,
  setIsPlaying,
  setCloseSkyVideoPlayer,
  setSkyVideoPlayerStatus,
  setIsControlShowing,
} from '@core/Areas/SkyVideoPlayer/Store'
import { useControlToggle } from './helpers/useControlToggle'
import { useFeature } from '@core/Utils/hooks'
import { findAndStartChildVideoElement } from '../../helpers'

export const VISION_DRAG_HANDLE_CLASSNAME = 'js-vision-floating-draggable'

export const VideoPlayerControls: React.FC<{
  parentContainer?: React.RefObject<HTMLDivElement>
}> = ({ parentContainer }) => {
  const dispatch = useAppDispatch()
  const playerStatus = useAppSelector(state => state.skyVideoPlayer.playerStatus)
  const isPlaying = useAppSelector(state => state.skyVideoPlayer.isPlaying)
  const isMuted = useAppSelector(state => state.skyVideoPlayer.isMuted)
  const isFullscreen = useAppSelector(state => state.skyVideoPlayer.isFullscreen)
  const isControlShowing = useAppSelector(state => state.skyVideoPlayer.isControlShowing)
  const selectedChannel = useAppSelector(state => state.skyVideoPlayer.selectedChannel)
  const history = useHistory()
  const { pathname } = useLocation()

  const topBarRef = React.useRef(null)
  const bottomBarRef = React.useRef(null)

  const isFullscreenUpdateEnabled = useFeature('VISION_FULL_SCREEN_UPDATE')

  useControlToggle({
    isPlaying,
    isMuted,
    isFullscreen,
    isControlShowing,
    selectedChannel,
    topBarElement: topBarRef.current,
    bottomBarElement: bottomBarRef.current,
  })

  const handleFullscreenClick = (isFullscreen: boolean) => {
    isFullscreenUpdateEnabled
      ? dispatch(setSkyVideoPlayerStatus({ playerStatus: isFullscreen ? 'fullscreen' : undefined }))
      : dispatch(setIsFullscreen(isFullscreen))
  }

  const handleCloseSkyPlayer = () => {
    dispatch(setCloseSkyVideoPlayer())
  }

  const handleSkyRacePageClose = () => {
    handleCloseSkyPlayer()
    history.push(AppRoutes.Home)
  }

  const handleToggleControls = () => {
    if (playerStatus !== 'floating') {
      dispatch(setIsControlShowing(!isControlShowing))
      findAndStartChildVideoElement(parentContainer?.current ?? undefined)
    }
  }

  const isOnSkyRacePage = pathname === AppRoutes.RaceCardSky
  const isPlayerFullscreen = playerStatus === 'fullscreen'
  const isPlayerFloating = playerStatus === 'floating'
  const isPlayerDocked = playerStatus === 'docked'

  const shouldRenderResizeButton = isPlayerFloating && !isFullscreen

  return (
    <WrapperStyled onClick={handleToggleControls}>
      <BigPlayButtonWrapperStyled>
        {!isPlaying && <PlayerButton type='play' onClick={() => dispatch(setIsPlaying(true))} />}
        <PlayerDragHandleStyled className={VISION_DRAG_HANDLE_CLASSNAME} />
      </BigPlayButtonWrapperStyled>

      {isPlaying && (
        <>
          <TopBarStyled ref={topBarRef}>
            <div>
              {shouldRenderResizeButton && <ResizeIcon />}

              {(isPlayerDocked || isPlayerFullscreen) && (
                <PlayerButton
                  onClick={isOnSkyRacePage ? handleSkyRacePageClose : handleCloseSkyPlayer}
                  type='close'
                />
              )}
            </div>

            <div>
              <SkyChannelSelector selectedChannel={selectedChannel} />
            </div>

            <div>
              {isPlayerFloating &&
                (isOnSkyRacePage ? (
                  <PlayerButton
                    type='docked'
                    onClick={() => dispatch(setSkyVideoPlayerStatus({ playerStatus: 'docked' }))}
                  />
                ) : (
                  <PlayerButton type='close' onClick={handleCloseSkyPlayer} />
                ))}

              {isPlayerDocked && (
                <PlayerButton
                  type='floating'
                  onClick={() => dispatch(setSkyVideoPlayerStatus({ playerStatus: 'floating' }))}
                />
              )}

              {isPlayerFullscreen && <PendingBetsDrawerButton />}
            </div>
          </TopBarStyled>

          <BotttomBarStyled ref={bottomBarRef}>
            {isMuted ? (
              <PlayerButton onClick={() => dispatch(setIsMuted(false))} type='mute' />
            ) : (
              <PlayerButton onClick={() => dispatch(setIsMuted(true))} type='volume' />
            )}

            {!isPlayerFullscreen && !isFullscreen ? (
              <PlayerButton onClick={() => handleFullscreenClick(true)} type='fullscreen' />
            ) : (
              <PlayerButton onClick={() => handleFullscreenClick(false)} type='exitfullscreen' />
            )}
          </BotttomBarStyled>
        </>
      )}
    </WrapperStyled>
  )
}
