import { CustomizedMysteryOption } from '@core/Areas/Racing/Components/Mystery/MysteryTypes'
import { BetType } from '@core/Data/Betting/selections'
import { toMoney } from '@mobi/utils/money'

export const ZERO_DOLLARS = '$0.00'

export function getFlexi(numberOfCombinations: number, spendPerBet: number): string {
  const percentage = (1 / numberOfCombinations) * spendPerBet * 100
  let roundedValue = parseFloat(percentage.toFixed(2))
  if (roundedValue > percentage) {
    roundedValue -= 0.01
  }

  return `${Number.isInteger(roundedValue) ? roundedValue : roundedValue.toFixed(2)}%`
}

export function getBetTypeText(
  betType: keyof typeof BetType | undefined,
  option: CustomizedMysteryOption | undefined,
  winStake: string | number,
  placeStake: string | number
): string {
  if (betType == null) {
    return 'Mystery'
  }

  if (betType === 'WinPlace') {
    if (getAsNumber(placeStake) === 0) {
      return 'Mystery Win'
    }

    if (getAsNumber(winStake) === 0) {
      return 'Mystery Place'
    }

    return 'Mystery Win & Place'
  }

  if (betType === 'Quinella') {
    return option?.optionNumber === 2 && option?.numberOfCombinations === 2
      ? 'Mystery Quinella - Leg In'
      : 'Mystery Quinella - Allways'
  }

  if (betType === 'Exacta') {
    return (option?.optionNumber || 0) <= 2
      ? 'Mystery Exacta - In Order'
      : 'Mystery Exacta - Allways'
  }

  if (betType === 'Trifecta') {
    const TRIFECTA_IN_ORDER = 'Mystery Trifecta - In Order'
    const TRIFECTA_ALLWAYS = 'Mystery Trifecta - Allways'
    if (!option?.numberOfCombinations) {
      return getAsNumber(winStake) === 1 ? TRIFECTA_IN_ORDER : TRIFECTA_ALLWAYS
    }

    const inOrderTrifectaCombos = [1, 2, 36] as Array<number | undefined>
    return inOrderTrifectaCombos.includes(option.numberOfCombinations)
      ? TRIFECTA_IN_ORDER
      : TRIFECTA_ALLWAYS
  }

  if (betType === 'First4') {
    const inOrderFirst4Combos = [1, 2, 6, 36] as Array<number | undefined>
    return !option || inOrderFirst4Combos.includes(option.numberOfCombinations)
      ? 'Mystery First 4 - In Order'
      : 'Mystery First 4 - Allways'
  }

  return 'Mystery ' + betType
}

export function getAsNumber(value: string | number): number {
  if (typeof value === 'number') {
    return value
  }

  return parseFloat(value.replace('$', '').replace(/,/g, ''))
}

export function toDisplayMoney(value: string | number): string {
  let number = getAsNumber(value)
  if (isNaN(number) || number < 0.5) {
    return ZERO_DOLLARS
  }

  const MAX_BET = 99_999
  if (number > MAX_BET) {
    number = MAX_BET
  }

  return toMoney(number, { decimalPlaces: 2 })
}

export function getTotalCost(
  winStake: string | number,
  placeStake: string | number,
  spendPerBet: string | number,
  numberOfBets: number,
  numberOfCombinations?: number
): string {
  const winStakeNumber = getAsNumber(winStake)
  const placeStakeNumber = getAsNumber(placeStake)
  const spendPerBetNumber = getAsNumber(spendPerBet)
  if (winStakeNumber > 0 || placeStakeNumber > 0) {
    return toMoney(
      (winStakeNumber + placeStakeNumber) * numberOfBets * (numberOfCombinations || 1),
      { decimalPlaces: 2 }
    )
  }

  return toMoney(spendPerBetNumber * numberOfBets, { decimalPlaces: 2 })
}
