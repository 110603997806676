import styled from '@emotion/styled'
import { ButtonStyled } from '../../Common/V2/Buttons'
import { colors, radius, spacing } from '../../Theme/Common'
import { gapX } from '../../Utils/gap'

export const CreditCardListStyled = styled.ul({
  listStyle: 'none',
  margin: 0,
  padding: 0,

  '&:empty': {
    display: 'none',
  },

  li: [
    {
      display: 'flex',
    },
    gapX(spacing.sm),
  ],

  'li:not(:last-child)': {
    marginBottom: spacing.smx1,
  },
})

export const CreditCardListItemStyled = styled(ButtonStyled)(
  {
    borderRadius: radius.lgx1,
    color: colors.neutral[900],
    boxShadow: 'none',

    '&:disabled': {
      backgroundColor: colors.surface['100'],
      color: colors.neutral[900],
    },

    '@media (max-width: 350px)': {
      padding: spacing.smx1,
    },
  },
  ({ color }) => {
    if (color === 'secondary_color') {
      return {
        border: `0.1rem solid ${colors.lavender[500]}`,
      }
    }
    return {}
  }
)
