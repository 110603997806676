import { useQuery, type UseQueryOptions } from 'react-query'
import { type ContactDetails, contactDetailsSchema, ContactDetailsApiPath } from '@mobi/api-types'
import { queryKeys } from '../constants'
import type { DepositFlow } from '../Utils/types'
import { get } from '../Utils/network'
import { track } from '../Utils/analytics'

type Params = {
  accountNumber: number
  flow: DepositFlow
} & Pick<UseQueryOptions<ContactDetails, Error>, 'onError' | 'onSuccess'>

const enum LocalConstants {
  TTL = 1000 * 60 * 60 * 8, // 8 hours
}

export function useUserContactDetails({ accountNumber, flow, ...options }: Params) {
  const { userContactDetails: queryKey } = queryKeys
  return useQuery({
    queryKey,
    queryFn,
    ...options,
    staleTime: LocalConstants.TTL,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    onSuccess: () => {
      track('deposit-3d-secure-user-data-success', {
        accountNumber,
        depositVariant: flow,
      })
    },
    onError: () => {
      track('deposit-3d-secure-user-data-fail', {
        accountNumber,
        depositVariant: flow,
      })
    },
  })
}

const queryFn = async () => {
  const parser = contactDetailsSchema.parse
  return await get<ContactDetails>(ContactDetailsApiPath, parser)
}
