import React from 'react'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { useBackNav } from '@core/Utils/hooks/useBackNav'
import { useFeature } from '@core/Utils/hooks'
import { Message } from '@core/Components/Messages'
import {
  EnhancedBetslipSetting,
  useResetOnEnhancedBetslipFeatureOff,
} from './Components/EnhancedBetslip'
import { HapticFeedbackSetting, isHapticsSupported } from './Components/HapticFeedback'
import { PayoutNotification } from './Components/PayoutNotification'
import { NewSportsSetting } from './Components/NewSports/NewSports'

export const Settings: React.FC = () => {
  useBackNav()

  const isEnhancedBetslipFeatureToggleActive = useFeature('ENHANCED_BETSLIP')
  const isHapticFeedbackFeatureActive = useFeature('HAPTIC_FEEDBACK')
  const isNewSportsFeatureActive = useFeature('FIXED_ODDS_SPORTS')

  const shouldRenderHapticsSetting = isHapticFeedbackFeatureActive && isHapticsSupported

  useResetOnEnhancedBetslipFeatureOff(isEnhancedBetslipFeatureToggleActive)

  return (
    <MainContainer data-tid-settings-container>
      <ContentHeader title='Settings' />

      <SettingsContainer>
        {isNewSportsFeatureActive && <NewSportsSetting />}

        {isEnhancedBetslipFeatureToggleActive && <EnhancedBetslipSetting />}

        {shouldRenderHapticsSetting && <HapticFeedbackSetting />}

        {isReactNativeApp && <PayoutNotification />}
      </SettingsContainer>
    </MainContainer>
  )
}

// ===============
// Local Component
// ===============

const SettingsContainer: React.FC<{}> = ({ children }) => {
  if (!React.Children.toArray(children).some(child => !!child))
    return (
      <Message>
        <b>Coming soon!</b>
        <br />
        This feature will come in a future release.
      </Message>
    )

  return <>{children}</>
}
