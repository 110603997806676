import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackEvent, trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { QuickbetClosed } from '@core/Areas/Quickbet/signals'
import { QuickbetState, state$ as quickbetState$ } from '@core/Areas/Quickbet/driver'
import { ConfirmAllBetsSuccessful, OnClose } from '@core/Areas/Betslip/signals'
import { state$ as receiptState$ } from '@core/Areas/Quickbet/Components/Receipt/driver'
import { EventDetails, isRaceDetails } from '@core/Data/Betting/selectionDetails'
import { BetslipState, state$ as betslipState$ } from '@core/Areas/Betslip/driver'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { state$ as navigationState$ } from '@core/State/Navigation/driver'

export function trackShareBetLinkOpen(data: {
  origin: string
  betType: 'single' | 'multi' | 'multi-partial' | 'closed'
}) {
  trackEvent(keys.shareBetLinkOpen, data)
  if (data.betType == 'closed') return
  data.betType.includes('multi') ? setupBetslipListeners() : setupQuickbetListeners()
}

// Local Helpers

const trackShareBetLinkPlaced = (data: {
  amountPaid: number
  hasSpecialOffer: boolean
  legs: number
  meetingCode: string
}) => {
  trackEvent(keys.shareBetLinkPlaced, data)
}

function setupQuickbetListeners() {
  const cleanupSignupSubscription = listenForLoginOrAcquisition()
  QuickbetClosed.signal$
    .take(1)
    .withLatestFrom(quickbetState$, receiptState$, (_: unknown, quickbetState, receiptState) => ({
      quickbetState,
      receiptState,
    }))
    .subscribe(({ quickbetState, receiptState }) => {
      cleanupSignupSubscription()
      const quickbetStateJs: QuickbetState = quickbetState.toJS()
      if (!quickbetStateJs.betPlaced) return

      const amountPaid = receiptState.amountPaid || 0
      const hasSpecialOffer = !!receiptState.hasSpecialOffer
      const meetingCode = getMeetingCode(quickbetStateJs.selectionDetails)

      trackShareBetLinkPlaced({ amountPaid, hasSpecialOffer, legs: 1, meetingCode })
    })
}

function setupBetslipListeners() {
  const cleanupSubscription = listenForLoginOrAcquisition()

  const betPlacedSubscription = ConfirmAllBetsSuccessful.signal$
    .withLatestFrom(betslipState$, (_: unknown, betslipState) => ({
      betslipState,
    }))
    .subscribe(({ betslipState }) => {
      const betslipStateJS: BetslipState = betslipState.toJS()
      const amountPaid = betslipStateJS.multiReceipt?.amountPaid || 0
      const hasSpecialOffer = !!betslipStateJS.multiReceipt?.hasSpecialOffer

      const meetingCode = betslipStateJS.items.reduce((prev, curr) => {
        const currMeetingCode = getMeetingCode(curr.selectionDetails)
        return !prev || prev === currMeetingCode ? currMeetingCode : 'other'
      }, '')

      trackShareBetLinkPlaced({
        amountPaid,
        hasSpecialOffer,
        legs: betslipState.items.size,
        meetingCode,
      })
    })

  OnClose.signal$.take(1).subscribe(() => {
    betPlacedSubscription.dispose()
    cleanupSubscription()
  })
}

function listenForLoginOrAcquisition() {
  let hasTracked = false
  const loginSubscription = userAccountState$
    .map(state => state.isLoggedIn)
    .pairwise()
    .distinctUntilChanged()
    .subscribe(([prevIsLoggedIn, newIsLoggedIn]) => {
      if (!prevIsLoggedIn && newIsLoggedIn && !hasTracked) {
        trackKey(keys.shareBetLinkLogin)
        hasTracked = true
      }
    })

  const navSubscription = navigationState$.subscribe(navState => {
    const currentUrl = navState.get('currentUrl')
    if (currentUrl && currentUrl.toLowerCase().includes('signup')) {
      trackKey(keys.shareBetLinkAcquisition)
    }
  })

  const cleanupSubscriptions = () => {
    loginSubscription.dispose()
    setTimeout(() => {
      navSubscription.dispose()
    }, 3000)
  }
  return cleanupSubscriptions
}

function getMeetingCode(selectionDetails: EventDetails | null): string {
  if (!selectionDetails || !isRaceDetails(selectionDetails)) return 'other'
  return selectionDetails.races[0]?.meetingCode || 'other'
}
