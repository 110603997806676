import { useDispatch, useSelector } from 'react-redux'
import { SettingItem } from '../SettingItem'
import React from 'react'
import { getNewSportsSetting } from '../../Store/selectors'
import { setNewSportsSetting } from '../../Store'

export const NewSportsSetting = () => {
  const dispatch = useDispatch()
  const isSettingEnabled = useSelector(getNewSportsSetting)

  return (
    <SettingItem
      isEnabled={isSettingEnabled}
      label='New Sports'
      desciption='Enables new sports feature'
      onToggle={() => {
        dispatch(setNewSportsSetting(!isSettingEnabled))
      }}
    />
  )
}
