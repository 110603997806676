import React from 'react'
import { getStartupParameters } from '@core/Areas/Kambi/config/startupParameters'
import customerSettings from '@core/Areas/Kambi/config/customerSettings'
import widgetSettings from '@core/Areas/Kambi/config/widgetSettings'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import {
  isUserResidentOfSA,
  isUserAccountStatePending,
  waitForWapi,
  toggleKambiVisiblity,
  BootstrapJurisdiction,
  handleScriptLoading,
  setInitialVisibilityState,
  kambiLog,
  handleKambiLogin,
  handleKambiLogout,
  handleClientSubscriptions,
  isKambiSupportedBrowser,
} from '@core/Areas/Kambi/helpers'
import { defineNavigationSectionWidget } from '@core/Areas/Kambi/widgets/NavigationSection.widget'
import { state$ as kambiState$, UnsupportedBrowser } from '@core/Areas/Kambi/driver'
import { getConfig } from '@classic/Foundation/Services/ConfigService'

let hasLoadedScript = false

export const useKambiClient = (): void => {
  const bootstrapJurisdictionRef = React.useRef<BootstrapJurisdiction>()

  // ============
  // Load Scripts
  // ============
  React.useEffect(() => {
    if (hasLoadedScript || bootstrapJurisdictionRef.current) return

    if (!isKambiSupportedBrowser()) {
      UnsupportedBrowser()
      return
    }

    hasLoadedScript = true

    getConfig().then(({ serverEnvironment }) => {
      window._kc = getStartupParameters(serverEnvironment)
      window.customerSettings = customerSettings
      window.widgetSettings = widgetSettings

      // Loads the widget to embed inside the Kambi client
      defineNavigationSectionWidget()

      handleScriptLoading()
        .then(bootstrapJurisdiction => {
          bootstrapJurisdictionRef.current = bootstrapJurisdiction
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('Error loading Kambi scripts', err)
        })
    })
  }, [])

  // ==================================
  // Register WAPI when widget is ready
  // ==================================
  React.useEffect(() => {
    if (window.wapi) return

    waitForWapi().then(wapi => {
      // Set initial betslip state minimized + sets up subscription
      wapi.set(wapi.BETSLIP_MAXIMIZED, { maximized: false })
      wapi.request(wapi.USER_DATA)

      setInitialVisibilityState(wapi)

      wapi.subscribe(response => {
        kambiLog('client event', response)
        handleClientSubscriptions(response)
      })
    })
  }, [])

  // ================================================
  // Handle Authentication and Bootstrap Jurisdiction
  // ================================================
  React.useEffect(() => {
    let shouldTryLogout: boolean

    const subscription = userAccountState$
      .skipWhile(isUserAccountStatePending)
      .map(userAccountState => ({
        accountNumber: userAccountState.accountNumber,
        isLoggedIn: userAccountState.isLoggedIn,
        residentialDetail: userAccountState.residentialDetail,
      }))
      .distinctUntilChanged(
        s => s,
        (a, b) => JSON.stringify(a) === JSON.stringify(b)
      )
      .subscribe(({ residentialDetail, isLoggedIn, accountNumber }) => {
        shouldTryLogout = !!isLoggedIn

        isLoggedIn && accountNumber && residentialDetail
          ? handleKambiLogin(accountNumber, residentialDetail)
          : handleKambiLogout()

        if (!bootstrapJurisdictionRef.current) return

        const requiredBootstrap: BootstrapJurisdiction = isUserResidentOfSA(residentialDetail)
          ? 'SA'
          : 'WA'

        if (requiredBootstrap !== bootstrapJurisdictionRef.current) {
          window.location.reload()
        }
      })

    return () => {
      if (shouldTryLogout) handleKambiLogout()
      subscription.dispose()
    }
  }, [])

  // =======================
  // Visibility Subscription
  // =======================
  React.useEffect(() => {
    const subscription = kambiState$
      .map(x => x.get('isVisible'))
      .distinctUntilChanged()
      .subscribe(toggleKambiVisiblity)

    return () => {
      subscription.dispose()
    }
  }, [])
}
