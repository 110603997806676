import { colors } from '../../Common'
import type { ColorDefinition } from '../types'

export const inputColors: InputColors = {
  background: colors.white,
  border: colors.neutral['300'],
  text: colors.black,
  placeholder: colors.neutral['500'],
  caret: colors.studio['500'],
  helpText: colors.neutral['600'],
  focused: {
    outline: colors.lavender['50'],
    border: colors.lavender['300'],
  },
  invalid: {
    background: colors.error['50'],
    border: colors.error['600'],
    helpText: colors.error['600'],
  },
  disabled: {
    background: colors.surface['100'],
    text: colors.neutral['400'],
    placeholder: colors.neutral['400'],
    helpText: colors.neutral['400'],
  },
}

export const radioColors: RadioColors = {
  background: colors.white,
  border: colors.neutral['300'],
  icon: colors.transparent,
  label: colors.neutral['900'],
  helpText: colors.neutral['600'],
  checked: {
    background: colors.lavender['50'],
    border: colors.lavender['500'],
    icon: colors.lavender['500'],
  },
  disabled: {
    background: colors.neutral['200'],
    border: colors.neutral['300'],
    icon: colors.neutral['300'],
  },
}

// Types

type InputColor = {
  text: string
  placeholder: string
  background: string
  border: string
  outline?: string
  caret: string
  helpText: string
}

type InputState = 'focused' | 'disabled' | 'invalid'

export type InputColors = ColorDefinition<InputState, InputColor>

type RadioState = 'checked' | 'disabled'

/**
 * Color definitions for checkboxes and radios
 */
type RadioColor = {
  background: string
  border: string
  icon: string
  label: string
  helpText: string
}

export type RadioColors = ColorDefinition<RadioState, RadioColor>
