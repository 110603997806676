import React from 'react'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Race } from '@core/Data/Racing'
import { BettingType } from '@core/Data/betting'
import {
  Selection,
  ToteSelection,
  FobPropositionSelection,
  BetType,
  isNoveltyBetType,
  SameRaceMultiSelection,
  isSameRaceMultiSelection,
} from '@core/Data/Betting/selections'
import { RaceCountdown } from '@core/Components/RaceCountdown'
import {
  MarketStyled,
  MarketPillStyled,
  MarketNameStyled,
  MarketTypeStyled,
} from '@core/Areas/Quickbet/Components/Header/Components/Common/Header.styles'
import { getIconTypeFromMeetingCode } from '../../helpers/IconTypeHelper'
import { TitleStyled, CountdownPillStyled } from '../Common.styles'

interface RaceInfoProps {
  race: Race
  selection: Selection
  bettingType: BettingType
  isRaceClosed?: boolean
}

export function getMarketTitle(selection: Selection) {
  if ((selection as ToteSelection).betType) {
    return (selection as ToteSelection).betType
  }
  if ((selection as FobPropositionSelection).marketCode) {
    return (selection as FobPropositionSelection).marketName
  }

  return BetType.WinPlace
}

export function getFixedOrToteDisplay(selection: Selection, bettingType: BettingType) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (isNoveltyBetType((selection as any).betType)) {
    return ''
  }
  if (
    bettingType === BettingType.FixedOddsRacing &&
    !(selection as FobPropositionSelection).marketCode
  ) {
    return 'Fixed'
  }
  if (bettingType === BettingType.ToteRacing) {
    return 'Tote'
  }
  return ''
}

export function RaceInfo({ race, selection, bettingType, isRaceClosed = false }: RaceInfoProps) {
  const { raceNumber, meetingName, raceTime } = race

  const isSameRaceMulti =
    selection != null && isSameRaceMultiSelection(selection as SameRaceMultiSelection)
  const fixedOrToteDisplay = isSameRaceMulti ? '' : getFixedOrToteDisplay(selection, bettingType)
  const marketName = isSameRaceMulti ? 'Same Race Multi' : getMarketTitle(selection)

  const iconType = getIconTypeFromMeetingCode(race.meetingCode)
  return (
    <Grid>
      <GridRow padding='0 0 0.5rem 0'>
        {iconType && (
          <GridCell width='3.5rem'>
            <Icon size='3rem' type={iconType} />
          </GridCell>
        )}
        <GridCell valign='middle'>
          <TitleStyled>
            {meetingName}: R{raceNumber}
          </TitleStyled>
        </GridCell>
      </GridRow>
      <GridRow>
        <GridCell valign='middle'>
          <MarketStyled>
            <MarketPillStyled>
              {marketName && <MarketNameStyled>{marketName}</MarketNameStyled>}
              {fixedOrToteDisplay.length > 0 && (
                <MarketTypeStyled>{fixedOrToteDisplay}</MarketTypeStyled>
              )}
            </MarketPillStyled>
          </MarketStyled>
        </GridCell>
        <GridCell minWidth='fit-content' flexGrow={0} valign='middle' align='right'>
          <CountdownPillStyled>
            {isRaceClosed ? (
              <span>Closed</span>
            ) : (
              <RaceCountdown displaySize='1.2rem' raceStartTime={new Date(raceTime)} />
            )}
          </CountdownPillStyled>
        </GridCell>
      </GridRow>
    </Grid>
  )
}
