import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'
import { Signal, attachDriver } from 'rwwa-rx-state-machine/dist/stream-driver'
import { ConfirmBetslipBetsSucceeded, ConfirmBetSucceeded } from '../../signals'
import { BetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'
import { FormulaResponse, BetslipResponse } from '@core/Data/betslip'

export interface QuickbetReceiptState {
  accountBalance: number | null
  ticketNumber: number | null
  betPlacedTime: Date | null
  campaignActivatedInd: boolean | null
  campaignId: number | null
  campaignType: 'BonusBetReward' | 'BonusCashReward' | 'CashReward' | 'Reward' | null
  bonusBet: number | null
  amountPaid: number | null
  numberOfCombinations: number | null
  // Fixed Odds betting
  projectedPlacePayout: number | null
  projectedWinPayout: number | null
  placeInvestment: number | null
  winInvestment: number | null
  hasSpecialOffer: boolean | null
  specialOffers: BetSpecialOffer[]
  formulaResponse: FormulaResponse | null
  // Tote betting
  betCost: number | null
  divXAnnotations: string | null
  selectionAnnotations: string | null
  // Betslip API bets
  betslipReceipt: BetslipResponse[] | null
}

const defaultQuickbetReceiptState: QuickbetReceiptState = {
  accountBalance: null,
  ticketNumber: null,
  betPlacedTime: null,
  campaignActivatedInd: null,
  campaignId: null,
  campaignType: null,
  bonusBet: null,
  amountPaid: null,
  numberOfCombinations: null,
  // Fixed Odds betting
  projectedPlacePayout: null,
  projectedWinPayout: null,
  placeInvestment: null,
  winInvestment: null,
  hasSpecialOffer: false,
  specialOffers: [],
  // Tote betting
  betCost: null,
  divXAnnotations: null,
  selectionAnnotations: null,
  formulaResponse: null,
  betslipReceipt: null,
}

export interface QuickbetReceiptStateRecord
  extends TypedRecord<QuickbetReceiptStateRecord>,
    QuickbetReceiptState {}

export const QuickbetReceiptStateFactory = makeTypedFactory<
  QuickbetReceiptState,
  QuickbetReceiptStateRecord
>(defaultQuickbetReceiptState)

export function driver(
  state = QuickbetReceiptStateFactory(),
  signal: Signal
): QuickbetReceiptStateRecord {
  switch (signal.tag) {
    case ConfirmBetSucceeded: {
      return state.merge({
        ...defaultQuickbetReceiptState,
        ...signal.data,
      })
    }
    case ConfirmBetslipBetsSucceeded: {
      return state.merge({
        ...defaultQuickbetReceiptState,
        betslipReceipt: signal.data,
      })
    }
    default: {
      return state
    }
  }
}

export const state$ = attachDriver<QuickbetReceiptStateRecord>({
  path: 'quickbet-receipt',
  driver,
}).map(state => state.toJS() as Immutable<QuickbetReceiptState>)
