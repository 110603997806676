import React from 'react'
import { navigateToDepositLimitsEdit } from '@classic/AppUtils/Framework/Intent/navigation'
import { PreCommitmentDetails } from '@mobi/api-types'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { toMoney } from '@mobi/utils/money'
import {
  ViewTitleStyled as ViewTitle,
  EditButtonContainer,
  OrderedListStyled as OrderedList,
  OrderedListItemStyled as OrderListItem,
  ViewValueStyled as ViewValue,
} from './DepositLimits.styles'
import {
  CoolingOffNoticeBox,
  CurrentFrequencyNoticeBox,
  CurrentAmountNoticeBox,
  SuccessMessage,
} from './DepositLimitsComponents'
import { AccountHolder } from '@core/Data/Account/accountHolder'

const NoLimit = 'No Limit'
const NoAmount = <abbr>N/A</abbr>

interface DepositLimitsViewAccountHolderProps {
  accountHolder: AccountHolder
  isDesktop?: boolean
  saveSuccess?: boolean
}

export function DepositLimitsViewAccountHolder(
  props: DepositLimitsViewAccountHolderProps
): JSX.Element {
  const { saveSuccess, accountHolder, isDesktop } = props
  const {
    BetAccountHolderNumber: accountHolderNumber,
    PreCommitmentDetails: preCommitmentDetails,
  } = accountHolder

  const {
    MaxDepositAmount,
    MaxDepositFrequency,
    ProposedMaxDepositAmount,
    ProposedMaxDepositFrequency,
    ProposedOptOutDate,
    ProposedMaxDepositDate,
  } = preCommitmentDetails

  const isOptingOut = !!(
    ProposedOptOutDate ||
    (ProposedMaxDepositDate && !ProposedMaxDepositFrequency && !ProposedMaxDepositAmount) ||
    (!MaxDepositFrequency === null &&
      !ProposedMaxDepositFrequency &&
      !MaxDepositAmount &&
      !ProposedMaxDepositAmount)
  )

  const frequencyDisplay = isOptingOut
    ? NoLimit
    : ProposedMaxDepositFrequency || MaxDepositFrequency || NoLimit
  const amount = ProposedMaxDepositAmount || MaxDepositAmount
  const amountDisplay = isOptingOut
    ? NoAmount
    : amount
      ? toMoney(amount, { decimalPlaces: 0 })
      : NoAmount

  const isLimitProposed = !!(ProposedOptOutDate || ProposedMaxDepositDate)
  const displayCurrentFrequency =
    isLimitProposed && ProposedMaxDepositFrequency !== MaxDepositFrequency
  const displayCurrentAmount = isLimitProposed && ProposedMaxDepositAmount !== MaxDepositAmount

  return (
    <React.Fragment>
      <HeaderMessage preCommitmentDetails={preCommitmentDetails} saveSuccess={saveSuccess} />
      <OrderedList>
        <OrderListItem hasUnderline={true}>
          <ViewTitle>Frequency Selected</ViewTitle>
          <ViewValue data-tid-frequency=''>{frequencyDisplay}</ViewValue>
          {displayCurrentFrequency && <CurrentFrequencyNoticeBox frequency={MaxDepositFrequency} />}
        </OrderListItem>
        <OrderListItem hasUnderline={true}>
          <ViewTitle>For Amount</ViewTitle>
          <ViewValue data-tid-amount=''>{amountDisplay}</ViewValue>
          {displayCurrentAmount && <CurrentAmountNoticeBox amount={MaxDepositAmount} />}
        </OrderListItem>
      </OrderedList>
      <EditButtonContainer>
        {accountHolderNumber && (
          <ButtonBlock
            onClick={() => navigateToDepositLimitsEdit({ isDesktop, accountHolderNumber })}
            testId='edit-button'
          >
            Edit
          </ButtonBlock>
        )}
      </EditButtonContainer>
    </React.Fragment>
  )
}

interface HeaderMessageProps {
  preCommitmentDetails: PreCommitmentDetails
  saveSuccess?: boolean
}

export function HeaderMessage(props: HeaderMessageProps): JSX.Element | null {
  const {
    preCommitmentDetails: { MaxDepositFrequency, ProposedMaxDepositDate, ProposedOptOutDate },
    saveSuccess,
  } = props

  if (MaxDepositFrequency && (ProposedMaxDepositDate || ProposedOptOutDate)) {
    const coolingOffDate = ProposedMaxDepositDate || ProposedOptOutDate
    if (coolingOffDate) {
      return <CoolingOffNoticeBox coolingOffEndDate={coolingOffDate} />
    }
  }

  if (saveSuccess) {
    return <SuccessMessage />
  }

  return null
}
