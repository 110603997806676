import { captureException } from '@sentry/react'
import { getConfig } from './ConfigService'

export function logDebug(message: string | Error, details?: unknown): void {
  getConfig().then(
    config => {
      if (config.enableJsConsoleLogging && console) {
        if (message instanceof Error) {
          if (details) {
            // eslint-disable-next-line no-console
            console.error(message, details)
          } else {
            // eslint-disable-next-line no-console
            console.error(message)
          }
        } else {
          if (details) {
            // eslint-disable-next-line no-console
            console.log(message, details)
          } else {
            // eslint-disable-next-line no-console
            console.log(message)
          }
        }
      }
    },
    () => null /* don't care if there is no configuration */
  )
}

export function logError(message: string | Error, details?: unknown): void {
  logDebug(message, details)
  getConfig().then(config => {
    if (config.enableSentryIo && message instanceof Error) {
      captureException(message)
    }
  })
}
