import React from 'react'
import {
  MysteryBet,
  MysterySelection,
  MysteryBetLine,
  MysteryAcceptor,
} from '@core/Data/Betting/selections'
import {
  ComboBetLinesStyled,
  MysteryComboSelectionRowStyled,
  MysteryComboCodeStyled,
  MysteryComboSelectionStyled,
} from './Selection.styles'
import { PlaceTextStyled } from './NoveltySelection'
import { getAcceptorsFromMysteryBetLine, parseMysterySelectionString } from './helpers'
import { PoolCodes } from '@core/Data/Racing/poolcode'

const ALLWAYS = ' (Allways)'
const INORDER = ' (In Order)'

interface MysteryNoveltySelectionProps {
  selection: MysterySelection
  displayPill: boolean
}

export function MysteryComboSelection({
  selection,
  displayPill,
}: MysteryNoveltySelectionProps): JSX.Element | null {
  if (!selection || !selection.bets || selection.bets.length === 0) {
    return null
  }

  const bet: MysteryBet = selection.bets[0]

  const betlines = bet.betLines.map(betline => {
    return (
      <MysteryComboBetLineSelection
        mysteryOption={selection.mysteryOption}
        betLine={betline}
        displayPill={displayPill}
        key={betline.poolCode}
      />
    )
  })

  return <ComboBetLinesStyled>{betlines}</ComboBetLinesStyled>
}

interface MysteryComboBetLineSelectionProps {
  mysteryOption: number
  betLine: MysteryBetLine
  displayPill: boolean
}

function MysteryComboBetLineSelection({
  mysteryOption,
  betLine,
  displayPill,
}: MysteryComboBetLineSelectionProps): JSX.Element {
  const isAlways =
    betLine.poolCode === PoolCodes.Quinella ||
    (betLine.poolCode === PoolCodes.Trifecta && mysteryOption === 10)

  const acceptors: MysteryAcceptor[] = getAcceptorsFromMysteryBetLine(betLine)

  const parsed = parseMysterySelectionString(betLine.selections, isAlways, acceptors)

  let displayValue = <span>{betLine.selections}</span>
  let suffix = ''

  if (betLine.poolCode === PoolCodes.Win) {
    displayValue = <span>{parsed['1st']}</span>
  } else if (betLine.poolCode === PoolCodes.Trifecta) {
    if (mysteryOption === 10) {
      suffix = ALLWAYS
      displayValue = <span>{parsed['ALLWAYS']}</span>
    } else {
      suffix = INORDER
    }
  } else if (betLine.poolCode === PoolCodes.Exacta) {
    suffix = INORDER
  }

  return (
    <MysteryComboSelectionRowStyled>
      <MysteryComboCodeStyled>
        <PlaceTextStyled text={betLine.poolCode} displayPill={displayPill} />
      </MysteryComboCodeStyled>

      <MysteryComboSelectionStyled data-tid-second-selection='' key={betLine.poolCode}>
        {displayValue}
        {suffix}
      </MysteryComboSelectionStyled>

      <span>
        {betLine.poolCode === PoolCodes.Win ? (
          <span>Div x{betLine.dividend}</span>
        ) : (
          <span>Flexi {betLine.dividend * 100}%</span>
        )}
      </span>
    </MysteryComboSelectionRowStyled>
  )
}
