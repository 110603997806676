import React from 'react'
import { getConfig } from '@classic/Foundation/Services/ConfigService'

export const useConfig = <T>(
  prop: keyof IConfig,
  defaultVal: T | null | undefined
): T | null | undefined => {
  const [state, setState] = React.useState(defaultVal)

  React.useEffect(() => {
    getConfig().then(serverConfig => {
      const value = serverConfig[prop]
      if (value !== undefined && value !== null) {
        setState(value)
      }
    })
  }, [prop])

  return state
}
