import React from 'react'
import { observeImmutable } from '@core/Components/HOCs'
import { state$ } from '../../driver'
import { Swiper } from '@core/Components/Swiper'
import { Keypad } from '@core/Components/Keypad'
import { KeypadModes } from '@core/Components/Keypad/KeyPress'
import { isToteSelection, isToteSportsSelection, Selection } from '@core/Data/Betting/selections'
import { QuickbetKeypadWrapperStyled } from './Keypad.styles'
import { modalXPadding } from '../../Quickbet.styles'
import { useFeature } from '@core/Utils/hooks'

interface QuickbetKeypadComponentProps {
  canChangeInvestment: boolean
  isBusy: boolean
  selection: Selection
  is50cIncrementActive: boolean
}

export function QuickbetKeypadComponent({
  canChangeInvestment,
  isBusy,
  selection,
  is50cIncrementActive,
}: QuickbetKeypadComponentProps): JSX.Element | null {
  const isTote = isToteSelection(selection) || isToteSportsSelection(selection)
  const allowDecimals = isTote ? true : is50cIncrementActive
  return (
    <>
      {canChangeInvestment && (
        <QuickbetKeypadWrapperStyled>
          <Swiper usePagination={true}>
            <Keypad
              disabled={isBusy}
              allowDecimals={allowDecimals}
              horizontalPadding={modalXPadding}
              showHighStake={!isTote}
            />
            <Keypad
              disabled={isBusy}
              allowDecimals={allowDecimals}
              keypadMode={KeypadModes.Numeric}
              horizontalPadding={modalXPadding}
              isIncrementAvailable={is50cIncrementActive && !isTote}
            />
          </Swiper>
        </QuickbetKeypadWrapperStyled>
      )}
    </>
  )
}

export const QuickbetKeypad: React.ComponentClass = observeImmutable(state$, ({ record }) => {
  const is50cIncrementActive = useFeature('CENTS_INCREMENTS_BET')

  return <QuickbetKeypadComponent {...record.toJS()} is50cIncrementActive={is50cIncrementActive} />
})

QuickbetKeypad.displayName = 'QuickbetKeypad'
