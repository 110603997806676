import { toMoney } from '@mobi/utils/money'
import React, { type HTMLAttributes } from 'react'
import { Observable } from 'rx'

import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackBonusBetEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'

import {
  Selection,
  isToteSelection,
  isNoveltyBetType,
  isAllUpSelection,
  isToteSportsSelection,
  isFobSportsSelection,
} from '@core/Data/Betting/selections'
import { state$ as superPickState$, SuperPickState } from '@core/Components/SuperPick/driver'
import {
  Campaign,
  state$ as userAccountState$,
  UserAccountState,
} from '@core/State/UserAccount/userAccountDriver'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { observeImmutable } from '@core/Components/HOCs'
import { BettingType } from '@core/Data/betting'
import { calculateCombinations as tippingCombinations } from '@core/Areas/Tipping/helpers/calculator'

import {
  BetInvestmentGroupStyled,
  BetInvestmentStyled,
  InvestmentStyled,
  TotalCostSectionStyled,
  TotalCostMoneyStyled,
  InvestmentContainerStyled,
} from './BetInvestment.styles'
import { state$ as investmentState$, InvestmentType, InvestmentState } from './betInvestmentDriver'
import { LegInfo } from '../LegInfo/LegInfo'
import EachWayCheckbox from '../EachWay/EachWayCheckbox'
import { ProjectedReturn } from '../ProjectedReturn/ProjectedReturn'
import { FlexiDisplay } from '../FlexiDisplay/FlexiDisplay'
import { NumberOfCombinationsDisplay } from '../NumberOfCombinationsDisplay/NumberOfCombinationsDisplay'
import { ResetInvalidSuperPickSelection } from '@core/Components/SuperPick/signals'
import { state$ as formulaState$, FormulaState } from '../Formula/driver'
import { BonusBetButton } from '../BonusBetButton/BonusBetButton'
import { state$ as quickbetState$, QuickbetState } from '../../driver'
import {
  SetActiveInvestment,
  SetAllowInvestmentState,
  SetBonusBet,
  ToggleBonusBetUsage,
} from '../../signals'
import { isCampaignRedeemableWithBettingType } from '../../helpers/campaignHelper'
import { useFeature, useObservableImmutable } from '@core/Utils/hooks'
import { useSelector } from 'react-redux'
import { selectIsBusy } from '@core/Areas/QuickDeposit/Store/selectors'

export interface BetInvestmentStateProps {
  winValue: number
  placeValue: number
  selection: Selection
  isBusy: boolean
  canConfirmBet: boolean
  canBet: boolean
  isUserInputAllowed: boolean
  isEachWayAvailable: boolean
  bettingType: BettingType
  shouldAllowPlaceInvestment: boolean
  shouldAllowWinInvestment: boolean
  activeInvestment: InvestmentType
  isSuperPickSelectionInvalid: boolean
  numberOfCombinationsSelected: number
  presetInvestment?: boolean
  activeCampaigns: Campaign[] | null
  isWinBonusBet: boolean
  isPlaceBonusBet: boolean
  isUsingBonusBet: boolean
  isUsingBonusCash: boolean
  isBonusBetSelected: boolean
}

const betInvestmentState$ = Observable.combineLatest(
  quickbetState$,
  investmentState$,
  superPickState$,
  formulaState$,
  userAccountState$,
  (
    quickbetRecord,
    investmentRecord,
    superPickRecord,
    formulaRecord,
    userAccountState: UserAccountState
  ): BetInvestmentStateProps => {
    const quickbetState = quickbetRecord.toJS() as QuickbetState
    const investmentState = investmentRecord.toJS() as InvestmentState
    const superPickState = superPickRecord.toJS() as SuperPickState
    const formulaState = formulaRecord.toJS() as FormulaState
    return {
      winValue: investmentState.win.value,
      placeValue: investmentState.place.value,
      selection: quickbetState.selection as Selection,
      isBusy: quickbetState.isBusy,
      canConfirmBet: quickbetState.canConfirmBet,
      canBet: quickbetState.canBet,
      isUserInputAllowed: quickbetState.canChangeInvestment,
      isEachWayAvailable: quickbetState.isEachWayAvailable,
      bettingType: quickbetState.bettingType as BettingType,
      shouldAllowPlaceInvestment: quickbetState.shouldAllowPlaceInvestment,
      shouldAllowWinInvestment: quickbetState.shouldAllowWinInvestment,
      activeInvestment: investmentState.activeInvestment,
      isSuperPickSelectionInvalid: !!superPickState.invalidSelectionMessage,
      numberOfCombinationsSelected: formulaState.numberOfCombinationsSelected,
      presetInvestment: quickbetState.presetInvestment ? true : undefined,
      activeCampaigns: userAccountState.activeCampaigns?.toArray() ?? null,
      isWinBonusBet: investmentState.win.isBonusBet ?? false,
      isPlaceBonusBet: investmentState.place.isBonusBet ?? false,
      isUsingBonusBet: quickbetState.isUsingBonusBet,
      isUsingBonusCash: quickbetState.isUsingBonusCash,
      isBonusBetSelected: !!investmentState.bonusBet,
    }
  }
)

export function BetInvestmentComponent({
  winValue,
  placeValue,
  selection,
  isBusy,
  canConfirmBet,
  canBet,
  isUserInputAllowed,
  isEachWayAvailable,
  activeInvestment,
  bettingType,
  shouldAllowWinInvestment,
  shouldAllowPlaceInvestment,
  isSuperPickSelectionInvalid,
  numberOfCombinationsSelected: numberOfFormulasSelected,
  presetInvestment,
  activeCampaigns,
  isWinBonusBet,
  isPlaceBonusBet,
  isUsingBonusBet,
  isUsingBonusCash,
  isBonusBetSelected,
}: BetInvestmentStateProps): JSX.Element | null {
  const [originalState] = React.useState<{
    shouldAllowWinInvestment: boolean
    shouldAllowPlaceInvestment: boolean
  }>({ shouldAllowPlaceInvestment, shouldAllowWinInvestment })

  React.useLayoutEffect(() => {
    SetAllowInvestmentState({
      shouldAllowWinInvestment: !isPlaceBonusBet && originalState.shouldAllowWinInvestment,
      shouldAllowPlaceInvestment: !isWinBonusBet && originalState.shouldAllowPlaceInvestment,
    })
  }, [isPlaceBonusBet, isWinBonusBet, originalState])

  // Track when the bonus bet button becomes available.
  const {
    isLoggedIn = null,
    accountNumber = null,
    accountBalance = null,
    bonusBetBalance = null,
    bonusCashBalance = null,
  } = useObservableImmutable(userAccountState$, [
    'isLoggedIn',
    'accountBalance',
    'accountNumber',
    'bonusBetBalance',
    'bonusCashBalance',
  ])

  const is50cIncrementActive = useFeature('CENTS_INCREMENTS_BET')

  const hasBonusBet = activeCampaigns?.some(campaign => campaign.rewardType === 'BonusBetReward')
  const hasBonusCash = activeCampaigns?.some(
    campaign =>
      campaign.rewardType === 'BonusCashReward' &&
      isCampaignRedeemableWithBettingType(campaign, bettingType)
  )

  const isEligibleForBonusBet = !!hasBonusBet && !hasBonusCash
  React.useEffect(() => {
    if (isLoggedIn && isEligibleForBonusBet) {
      trackBonusBetEvent(keys.quickbetBonusBetButtonShown, {
        accountNumber: accountNumber ?? '',
        accountBalance,
        bonusBetBalance,
        bonusCashBalance,
      })
    }
  }, [
    isLoggedIn,
    isEligibleForBonusBet,
    accountNumber,
    accountBalance,
    bonusBetBalance,
    bonusCashBalance,
  ])

  if (!canBet) {
    return null
  }

  function changeToWinInvestment() {
    if (!isBusy) {
      SetActiveInvestment(InvestmentType.Win)
      ResetInvalidSuperPickSelection()
    }
  }

  function changeToPlaceInvestment() {
    if (!isBusy) {
      SetActiveInvestment(InvestmentType.Place)
      ResetInvalidSuperPickSelection()
    }
  }

  const isWinActive =
    isSuperPickSelectionInvalid || (isUserInputAllowed && activeInvestment === InvestmentType.Win)
  const isPlaceActive =
    isSuperPickSelectionInvalid || (isUserInputAllowed && activeInvestment === InvestmentType.Place)
  const isFixedOddsRacing = bettingType === BettingType.FixedOddsRacing
  const isToteRacing = bettingType === BettingType.ToteRacing

  const hideDecimals = is50cIncrementActive ? false : isFixedOddsRacing

  const shouldDisplayFlexi = isToteSelection(selection) && isNoveltyBetType(selection.betType)
  const shouldDisplayNumberOfCombinations =
    isAllUpSelection(selection) || isToteSportsSelection(selection)
  const numberOfCombinations = isAllUpSelection(selection)
    ? numberOfFormulasSelected
    : isToteSelection(selection)
      ? selection.numberOfCombinations
      : isToteSportsSelection(selection)
        ? tippingCombinations(selection.betSelections)
        : 1
  const hasSecondaryDisplay =
    shouldAllowPlaceInvestment || shouldDisplayFlexi || shouldDisplayNumberOfCombinations

  const isFixedOddsSport = isFobSportsSelection(selection)

  if (presetInvestment) {
    return null
  }

  const toggleBonusBet = () => {
    ToggleBonusBetUsage()
    SetBonusBet(null)
  }

  const trackBonusBetClick = () => {
    trackBonusBetEvent(
      isUsingBonusBet
        ? keys.quickbetBonusBetButtonDeactivated
        : keys.quickbetBonusBetButtonActivated,
      {
        accountNumber: accountNumber ?? '',
        accountBalance: accountBalance,
        bonusBetBalance: bonusBetBalance,
        bonusCashBalance: bonusCashBalance,
      }
    )
  }

  let shouldDisplayBonusButton = false
  if (!!hasBonusBet || !!hasBonusCash) {
    if (isUsingBonusCash) {
      // We are checking for tote here as bonus cash isn't available and bonus bets are n/a for tote
      if (hasBonusCash || isToteRacing) {
        shouldDisplayBonusButton = false
      } else {
        shouldDisplayBonusButton = true
      }
    } else {
      shouldDisplayBonusButton = true
    }
  }

  return (
    <BetInvestmentStyled data-tid-quickbet-investments=''>
      <Grid padding='0.4rem'>
        {shouldAllowWinInvestment && (
          <GridCell width={hasSecondaryDisplay ? '50%' : 'auto'}>
            <InvestmentComponent
              onClick={changeToWinInvestment}
              isActive={isWinActive}
              investmentType={InvestmentType.Win}
              amount={winValue}
              shouldFontResize={hasSecondaryDisplay}
              hideDecimals={hideDecimals}
              isBonusBet={isWinBonusBet}
              tabIndex={0}
              aria-label={`Betting ${toMoney(winValue)} to win`}
            />
          </GridCell>
        )}
        {shouldAllowPlaceInvestment && (
          <GridCell width={shouldAllowWinInvestment ? '50%' : 'auto'}>
            <InvestmentComponent
              onClick={changeToPlaceInvestment}
              isActive={isPlaceActive}
              investmentType={InvestmentType.Place}
              amount={placeValue}
              shouldFontResize={hasSecondaryDisplay}
              hideDecimals={hideDecimals}
              isBonusBet={isPlaceBonusBet}
              tabIndex={1}
              aria-label={`Betting ${toMoney(placeValue)} to place`}
            />
          </GridCell>
        )}

        {shouldDisplayFlexi && (
          <GridCell width='50%'>
            <FlexiDisplay winInvestment={winValue} numberCombinations={numberOfCombinations} />
          </GridCell>
        )}

        {shouldDisplayNumberOfCombinations && (
          <GridCell width='50%'>
            <NumberOfCombinationsDisplay
              numberOfCombinations={numberOfCombinations}
              data-tid-quickbet-investments-combos=''
            />
          </GridCell>
        )}
      </Grid>

      {(isFixedOddsRacing || isToteRacing) && (
        <Grid margin='0 0 -1.5rem 0'>
          <GridRow padding='0'>
            <GridCell valign='middle'>
              {shouldDisplayBonusButton && (
                <GridRow padding='1rem 0 1rem 0'>
                  <GridCell valign='middle' width={'100%'}>
                    <BonusBetButton
                      toggleBonusBet={toggleBonusBet}
                      trackBonusBetClick={trackBonusBetClick}
                    />
                  </GridCell>
                </GridRow>
              )}
              {!isBonusBetSelected && isEachWayAvailable && !canConfirmBet && (
                <GridRow padding='1rem 0 1rem 0'>
                  <GridCell valign='middle' width={'100%'}>
                    <EachWayCheckbox />
                  </GridCell>
                </GridRow>
              )}
            </GridCell>
            <GridCell valign='top'>
              <ProjectedReturn />
            </GridCell>
          </GridRow>
        </Grid>
      )}

      {isFixedOddsSport && (
        <Grid margin='0 0 -1.5rem 0'>
          <GridRow padding='0'>
            <GridCell valign='top'>
              <ProjectedReturn />
            </GridCell>
          </GridRow>
        </Grid>
      )}

      {shouldDisplayNumberOfCombinations && (
        <TotalCostSectionStyled data-tid-quickbet-investments-total-cost-title=''>
          Total Cost
          <TotalCostMoneyStyled data-tid-quickbet-investments-total-cost=''>
            ${(winValue * (numberOfCombinations || 0)).toFixed(2)}
          </TotalCostMoneyStyled>
        </TotalCostSectionStyled>
      )}
    </BetInvestmentStyled>
  )
}

export type InvestmentComponentProps = {
  onClick: () => void
  isActive: boolean
  investmentType: InvestmentType
  amount: number
  shouldFontResize: boolean
  hideDecimals: boolean
  isBonusBet: boolean
  ariaLabel?: string
} & Pick<HTMLAttributes<HTMLElement>, 'tabIndex' | 'aria-label'>

export function InvestmentComponent({
  onClick,
  isActive,
  investmentType,
  amount,
  shouldFontResize,
  hideDecimals,
  isBonusBet = false,
  ...htmlAttributes
}: InvestmentComponentProps): JSX.Element {
  return (
    <section
      onClick={isBonusBet ? () => null : onClick}
      role='button'
      {...htmlAttributes}
      data-testid='Quickbet.Investment'
    >
      <BetInvestmentGroupStyled isActive={isActive}>
        <LegInfo investmentType={investmentType} isActive={isActive} />

        <InvestmentContainerStyled isBonusBet={isBonusBet} isActive={isActive}>
          <InvestmentStyled
            shouldFontResize={shouldFontResize}
            amount={amount}
            decimalPlaces={hideDecimals ? 0 : 2}
          />
        </InvestmentContainerStyled>
      </BetInvestmentGroupStyled>
    </section>
  )
}

export const BetInvestment: React.ComponentClass = observeImmutable(
  betInvestmentState$,
  ({ record }) => {
    const quickBetDepositing = useSelector(selectIsBusy)

    return <BetInvestmentComponent {...record} isBusy={record.isBusy || quickBetDepositing} />
  }
)
BetInvestment.displayName = 'BetInvestment'
