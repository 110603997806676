import React from 'react'
import { useHistory } from 'react-router-dom'

import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { Money } from '@core/Components/Text/Money'
import { toMoney } from '@mobi/utils/money'
import { NoticeBoxSingle, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { CurrencyInputField } from '@mobi/component-library/Common/Input'
import { UpdateAccountBalance } from '@core/State/UserAccount/userAccountDriver'
import { useBackNav } from '@core/Utils/hooks/useBackNav'
import { useTrackOptimoveWithdrawalPercentage } from '@core/Services/Optimove/hooks/useTrackOptimoveWithdrawalPercentage'
import {
  WithdrawContainerStyled,
  WithdrawDetailStyled,
  BankDetailItemStyled,
  BankDetailContainerStyled,
  WithdrawLabelStyled,
  FormContainerStyled,
  ButtonContainerStyled,
  WithdrawDetailNoBorderStyled,
} from './Withdraw.styles'
import {
  postWithdraw,
  getWithdrawable,
  type WithdrawResponse,
  WithdrawableResponse,
} from './WithdrawApi'
import { WithdrawLoading } from './WithdrawLoading'
import { navigateToAccount } from './helpers/navigaton'
import { WithdrawUnavailable } from './WithdrawUnavailable'
import { WithdrawSuccess } from './WithdrawSuccess'
import { WithdrawFailure } from './WithdrawFailure'
import { BankAccountEditRoute } from '@core/Areas/BankAccount/constants'

const enum LocalConstants {
  MinAmount = 0.01,
}

export const WithdrawPage = (): JSX.Element | null => {
  useBackNav()

  const [data, setData] = React.useState<WithdrawableResponse | null>()

  const [submitEnabled, setSubmitEnabled] = React.useState<boolean>(false)
  const [amountError, setAmountError] = React.useState<string | boolean>(false)
  const [withdrawalAmount, setWithdrawalAmount] = React.useState<number>()
  const [withdrawResponse, setWithdrawResponse] = React.useState<WithdrawResponse | null>()
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false)

  useTrackOptimoveWithdrawalPercentage(withdrawResponse)

  React.useEffect(() => {
    if (data === undefined) {
      getWithdrawable()
        .then(response => {
          setData(response)
        })
        .catch(() => {
          setData(null)
        })
    }
  }, [data])

  if (data === null) return null

  if (data === undefined || isSubmitted) return <WithdrawLoading />

  if (!data.isCRMAvailable) return <WithdrawUnavailable />

  if (withdrawResponse !== undefined) {
    if (withdrawResponse) {
      return <WithdrawSuccess success={withdrawResponse} />
    } else {
      return <WithdrawFailure />
    }
  }

  return (
    <MainContainer data-tid-withdrawable-container='' forceSizing={false}>
      <ContentHeader title='Withdrawal' />
      <WithdrawContainerStyled>
        <WithdrawDetailStyled>
          <WithdrawLabelStyled>Account Number</WithdrawLabelStyled>
          <span>{data.accountNumber}</span>
        </WithdrawDetailStyled>
        <WithdrawDetailStyled>
          <WithdrawLabelStyled>Available Balance</WithdrawLabelStyled>
          <span>
            <Money amount={data.withdrawableBalance} testId='available-balance' />
          </span>
        </WithdrawDetailStyled>
        <WithdrawDetailStyled>
          <WithdrawLabelStyled>Current Balance</WithdrawLabelStyled>
          <span>
            <Money amount={data.balance} testId='current-balance' />
          </span>
        </WithdrawDetailStyled>
        <WithdrawDetailNoBorderStyled>
          <WithdrawLabelStyled>Credit Account</WithdrawLabelStyled>
          <span>
            <BankDetailContainerStyled>
              <BankDetailItemStyled>{data.bankName}</BankDetailItemStyled>
              <BankDetailItemStyled>{data.bankBranchName}</BankDetailItemStyled>
              <BankDetailItemStyled>{data.bankBsb}</BankDetailItemStyled>
              <BankDetailItemStyled>{data.bankAccountNumber}</BankDetailItemStyled>
              <BankDetailItemStyled>{data.bankAccountName}</BankDetailItemStyled>
            </BankDetailContainerStyled>
          </span>
        </WithdrawDetailNoBorderStyled>

        {!data.isBankAccountRegistered && <BankAccountRegistration />}

        {data.isBankAccountRegistered && (
          <FormContainerStyled>
            <form
              name='withdrawal-form'
              noValidate={true}
              onSubmit={async event => {
                event.preventDefault()
                if (!submitEnabled) {
                  return
                }

                if (withdrawalAmount && !amountError) {
                  setIsSubmitted(true)
                  setSubmitEnabled(false)
                  await postWithdraw(withdrawalAmount)
                    .then(response => {
                      if (response.isSuccessful) {
                        UpdateAccountBalance({ balance: response.balance })
                        setWithdrawResponse(response)
                      } else {
                        setWithdrawResponse(null)
                      }
                    })
                    .catch(() => {
                      setWithdrawResponse(null)
                    })
                    .finally(() => {
                      setSubmitEnabled(true)
                      setIsSubmitted(false)
                    })
                }
              }}
              data-tid-withdraw-form=''
            >
              <CurrencyInputField
                type='number'
                testId='withdraw-amount'
                name='Amount'
                required={false}
                placeholder='$'
                min={LocalConstants.MinAmount}
                max={data.withdrawableBalance}
                step={0.01}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const response = onAmountChange(
                    e,
                    LocalConstants.MinAmount,
                    data.withdrawableBalance
                  )
                  setAmountError(response.error)
                  setSubmitEnabled(response.enableSubmit)
                  if (response.amount) {
                    setWithdrawalAmount(response.amount)
                  }
                }}
                errorMessage={amountError}
              />
              <ButtonContainerStyled>
                <ButtonBlock
                  type='reset'
                  onClick={navigateToAccount}
                  color='secondary'
                  testId='cancel-withdraw-btn'
                >
                  Cancel
                </ButtonBlock>

                <ButtonBlock
                  type='submit'
                  onClick={() => void 0}
                  color='primary'
                  testId='withdraw-btn'
                  disabled={!submitEnabled}
                >
                  Withdraw
                </ButtonBlock>
              </ButtonContainerStyled>
            </form>
          </FormContainerStyled>
        )}
      </WithdrawContainerStyled>
    </MainContainer>
  )
}

const BankAccountRegistration = (): JSX.Element => {
  const history = useHistory()

  return (
    <>
      <NoticeBoxSingle
        title='Please register your bank details to withdraw funds.'
        noticeBoxType={NoticeBoxTypes.Warning}
        testId='bank-account-registration'
      />
      <ButtonBlock onClick={() => history.push(`/${BankAccountEditRoute}?fromView=Withdraw`)}>
        Register Bank Account
      </ButtonBlock>
    </>
  )
}

interface AmountChangeResponse {
  amount?: number
  error: string | boolean
  enableSubmit: boolean
}

const onAmountChange = (
  event: React.FormEvent<HTMLInputElement>,
  minAmount: number,
  maxAmount: number
): AmountChangeResponse => {
  const value = event.currentTarget.value.trim()

  const defaultResponse: AmountChangeResponse = {
    error: false,
    enableSubmit: false,
  }

  if (value.length === 0) {
    return defaultResponse
  }

  const amount = Number(value)
  if (!Number.isFinite(amount)) {
    return defaultResponse
  }

  if (amount < minAmount) {
    return defaultResponse
  }

  if (amount > maxAmount) {
    return {
      error: `Value must be less than or equal to ${toMoney(maxAmount)}`,
      enableSubmit: false,
    }
  }

  if (!new RegExp(/^\d*(\.)*(\d{0,2})$/).test(value)) {
    return {
      error: 'Value must be to 2 decimal places',
      enableSubmit: false,
    }
  }

  return {
    amount: amount,
    error: false,
    enableSubmit: true,
  }
}
