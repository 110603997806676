import React from 'react'
import { useFeature } from '@core/Utils/hooks'
import { subscribeToNative, isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'
import { navigateToSkyRacePage } from '@core/Areas/RaceCardSky/helpers/navigateToSkyRacePage'
import { toggleSkyVideoPlayer } from '../helpers/toggleSkyVideoPlayer'
import { useAppSelector } from '@core/Store/hooks'

export const useNativeInterop = (isPlayerOpen: boolean): void => {
  const playerStatus = useAppSelector(state => state.skyVideoPlayer.playerStatus)
  const isSkyRacePageFeatureActive = useFeature('SKY_LIVE_RACE_PAGE')

  // Handle "Sky Racing" bottom tab click
  React.useEffect(() => {
    if (!isReactNativeApp) return undefined
    const { dispose } = subscribeToNative('VISION_FLOAT_TOGGLE', () => {
      if (isSkyRacePageFeatureActive) {
        navigateToSkyRacePage()
      } else {
        toggleSkyVideoPlayer({ analyticsData: { openLocation: 'bottom-navigation' } })
      }
    })
    return () => {
      dispose()
    }
  }, [isSkyRacePageFeatureActive])

  // Send "Sky Racing" bottom tab status to native
  React.useEffect(() => {
    if (!isReactNativeApp) return
    sendToNative('VISION_FLOAT_STATUS', isPlayerOpen ? 'Open' : 'Closed')
  }, [isPlayerOpen])

  // Send "Fake Fullscreen" status to native
  React.useEffect(() => {
    if (!isReactNativeApp) return
    sendToNative('FULLSCREEN_ACTIVE', isPlayerOpen && playerStatus === 'fullscreen')
  }, [playerStatus, isPlayerOpen])
}
