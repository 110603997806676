import { useCallback } from 'react'
import { List } from 'immutable'
import {
  trackRacePreviewBetClicked,
  trackTipsBetClicked,
} from '@core/Areas/Racing/Components/BettableTips/analytics'
import { makeSelection } from '@core/Areas/Racing/Components/BettableTips/helpers'
import { RaceDetails } from '@core/Data/Betting/selectionDetails'
import { BetType, PriceSources } from '@core/Data/Betting/selections'
import type { Acceptor } from '@core/Areas/Racing/Hooks/useAcceptors'
import type { MeetingInformationDataTransferObject } from '@mobi/api-types'
import { handleBetSelection } from '@core/Utils/betting/handleBetSelection'

export type TipSource = 'tips' | 'race-preview'

type Props = {
  meeting: MeetingInformationDataTransferObject
  acceptor: Acceptor
  isFixedOdds: boolean
  priceSource: PriceSources
  buttonPosition: number
  tipSource: TipSource
}

export function useBettableTipQuickBetting({
  meeting,
  acceptor,
  isFixedOdds,
  priceSource,
  buttonPosition,
  tipSource,
}: Props) {
  const loadQuickBet = useCallback(() => {
    const selectedRace = meeting.SelectedRace

    const [bettingType, selection] = makeSelection(meeting, acceptor, isFixedOdds, priceSource)

    const shouldAllowPlaceInvestment = isFixedOdds
      ? meeting.SelectedRace.FixedOddsInfo?.IsPlaceAvailable ?? false
      : meeting.SelectedRace.HasPlacePool

    handleBetSelection({
      location: 'Quickbet',
      selection: {
        shouldAllowPlaceInvestment,
        isEachWayAvailable: false,
        bettingType,
        selection,
        selectionDetails: {
          acceptors: [acceptor],
          races: [
            {
              meetingDate: meeting.MeetingDate,
              raceTime: selectedRace.RaceTime,
              acceptorKeys: List<string>([acceptor.key]),
              raceNumber: selectedRace.RaceNumber,
              key: selectedRace.Key,
              type: selectedRace.RaceType,
              distance: selectedRace.Distance,
              isFixedOddsRace: selectedRace.IsFixedOddsRace,
              meetingCode: meeting.MeetingCode,
              MeetingId: meeting.MeetingId,
              meetingName: meeting.MeetingName,
            },
          ],
        } as RaceDetails,
        betSource: tipSource,
      },
    })

    const trackFn = tipSource === 'tips' ? trackTipsBetClicked : trackRacePreviewBetClicked

    trackFn(meeting.MeetingCode, meeting.MeetingName, buttonPosition, BetType.WinPlace)
  }, [meeting, acceptor, isFixedOdds, priceSource, buttonPosition, tipSource])

  return {
    loadQuickBet,
  }
}
