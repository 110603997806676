import * as immutable from 'immutable'
import { attachDriver, Signal, createSignal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'
import { QuickbetLoadSelection, QuickbetSelection, EditBetslipItem } from '../../signals'
import { isAllUpSelection, AllUpFormula } from '@core/Data/Betting/selections'

interface ImmutableFormulaState {
  // the type of `state` when used as a Record
  formulas: immutable.List<AllUpFormula>
  numberOfCombinationsSelected: number
}

export interface FormulaState {
  // the type of `state` after `.toJS()` is called
  formulas: AllUpFormula[]
  numberOfCombinationsSelected: number
}

const defaultFormulaState: ImmutableFormulaState = {
  formulas: immutable.List<AllUpFormula>(),
  numberOfCombinationsSelected: 0,
}

export interface FormulaStateRecord
  extends TypedRecord<FormulaStateRecord>,
    ImmutableFormulaState {}
export const FormulaStateFactory = makeTypedFactory<ImmutableFormulaState, FormulaStateRecord>(
  defaultFormulaState
)

interface ToggleFormulaData {
  formula: number
  wasSelected: boolean
}
export const ToggleFormula = createSignal<ToggleFormulaData>('ToggleFormula')

export function formulaDriver(state = FormulaStateFactory(), signal: Signal): FormulaStateRecord {
  switch (signal.tag) {
    case EditBetslipItem:
    case QuickbetLoadSelection: {
      const { selection } = signal.data as QuickbetSelection
      if (isAllUpSelection(selection)) {
        const formulas = immutable.List(selection.formulas)
        const numberOfCombinationsSelected = getNumCombinationsSelected(formulas)
        return state.merge({
          ...defaultFormulaState,
          formulas,
          numberOfCombinationsSelected,
        })
      }

      return state.merge({
        ...defaultFormulaState,
      })
    }

    case ToggleFormula: {
      const { formula, wasSelected } = signal.data as ToggleFormulaData
      const index = state.formulas.findIndex(x => x?.formula === formula)
      if (index < 0) {
        return state
      }

      const toggledItem = state.formulas.get(index)
      if (!toggledItem) {
        return state
      }
      const formulas = state.formulas
        .splice(index, 1, { ...toggledItem, isSelected: !wasSelected })
        .toList()
      const numberOfCombinationsSelected = getNumCombinationsSelected(formulas)
      return state.merge({
        formulas,
        numberOfCombinationsSelected,
      })
    }

    default:
      return state
  }
}

export function getNumCombinationsSelected(selections: immutable.List<AllUpFormula>): number {
  return selections
    .filter(f => f.isSelected)
    .reduce((accumulator, currentValue) => accumulator + currentValue.numberOfCombinations, 0)
}

export const state$ = attachDriver<FormulaStateRecord>({ path: 'formula', driver: formulaDriver })
