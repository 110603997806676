import React from 'react'

export const AmericanExpress = () => (
  <svg width='43' height='28' viewBox='0 0 43 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask
      id='mask0_531_389'
      style={{ maskType: 'luminance' }}
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='43'
      height='28'
      role='img'
      aria-label='American Express'
    >
      <path d='M42.2248 0.775391H0.775391V27.4088H42.2248V0.775391Z' fill='white' />
    </mask>
    <g mask='url(#mask0_531_389)'>
      <rect x='18' y='3.5918' width='24' height='22' fill='white' />
      <path
        d='M38.7589 24.4969L36.9198 22.4372L35.0101 24.4969H23.1977V14.9088H19.4488L24.1171 4.18447H28.644L30.271 7.87764V4.18447H35.9295L36.9198 6.95435L37.91 4.18447H42.2248V2.55096C42.2248 1.55664 41.4467 0.775391 40.4564 0.775391H2.54371C1.55344 0.775391 0.775391 1.55664 0.775391 2.55096V25.6332C0.775391 26.6275 1.55344 27.4088 2.54371 27.4088H40.4564C41.4467 27.4088 42.2248 26.6275 42.2248 25.6332V24.4969H38.7589Z'
        fill='#0071CE'
      />
      <path
        d='M39.2543 23.3604H42.225L38.3347 19.1699L42.225 15.0508H39.3249L36.8494 17.7495L34.4445 15.0508H31.4736L35.4347 19.241L31.4736 23.3604H34.3737L36.8494 20.6614L39.2543 23.3604Z'
        fill='#0071CE'
      />
      <path
        d='M26.7341 21.4427V20.1644H31.4025V18.2468H26.7341V16.9684H31.4731V15.0508H24.4707V23.3604H31.4731V21.4427H26.7341Z'
        fill='#0071CE'
      />
      <path
        d='M40.0323 13.8435H42.1543V5.46289L38.7591 5.53391L36.92 10.7185L35.0103 5.53391H31.4736V13.8435H33.7372V8.01969L35.859 13.8435H37.8397L40.0323 8.01969V13.8435Z'
        fill='#0071CE'
      />
      <path
        d='M27.8662 5.53418H24.9661L21.2881 13.8437H23.7638L24.4711 12.2104H28.2905L28.9978 13.8437H31.5443L27.8662 5.53418ZM25.249 10.2927L26.3808 7.59383L27.5126 10.2927H25.249Z'
        fill='#0071CE'
      />
      <path d='M40.1733 18.9572L42.2246 21.23V16.6846L40.1733 18.9572Z' fill='#0071CE' />
    </g>
  </svg>
)
