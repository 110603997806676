import { Ticket } from '@core/Areas/AccountActivity/types'
import { PlanSeq } from '@core/Data/betting'

import { BetDetails, FOOBetDetails, LoadBetDetails } from './types'

export const createLoadBetDetails = (ticket: Ticket): LoadBetDetails[] => {
  if (ticket.productName === 'Same Race Multi') {
    const firstLeg = ticket.legs[0]
    return [
      {
        fixtureId: firstLeg.fixtureId,
        fixtureDate: new Date(firstLeg.advertisedStartTime),
        races: [firstLeg.raceNumber],
        selectionString:
          firstLeg.selections[0].selectionDetails[0].acceptorDetails[0].number.toString(),
        planSeq: ticket.planSeq,
        betType: 'Win - Fixed',
        srmSelections: ticket.legs.map(leg => {
          const acceptorDetails = leg.selections[0].selectionDetails[0].acceptorDetails[0]
          return {
            acceptorNumber: acceptorDetails.number,
            legNumber: (acceptorDetails.srmPosition ?? 1) - 1,
          }
        }),
      } as BetDetails,
    ]
  }

  return ticket.legs.map(ticketLeg => {
    // Fixed odds only will not have a Fixture ID, whereas Fixed Odds matched will
    if (ticket.planSeq === PlanSeq.FOBRacing && !ticketLeg.fixtureId) {
      const fooBetDetails: FOOBetDetails = {
        competitionName: ticketLeg.eventName,
        competitionSeq: ticketLeg.eventSeq.toString(),
        propositionSeq: ticketLeg.propositionSeq,
        betType: ticketLeg.betType,
        sportName: ticketLeg.sportName,
        eventStartDateTime: ticket.eventStartDateTime,
      }

      return fooBetDetails
    }

    // Tote bets and Fixed Odds Matched
    if (ticketLeg.fixtureId) {
      // All tote should have selection string provided
      if (ticket.planSeq !== PlanSeq.FOBRacing && !ticketLeg.selectionString)
        throw new Error('Missing selection string')

      const selectionString =
        ticketLeg.selectionString ||
        // Fixed odds matched will only have 1 selection, so we can use that as the selection string
        // This is the only way we can generate FO-matched as the data in the DB betwen matched and
        // proposition is identical
        ticketLeg.selections[0].selectionDetails[0].acceptorDetails[0].number.toString()

      const numberOfCombinations =
        ticket.planSeq !== PlanSeq.FOBRacing ? ticket.investment.lines[0].count : 1

      const betDetails: BetDetails = {
        fixtureId: ticketLeg.fixtureId,
        fixtureDate: new Date(ticketLeg.advertisedStartTime),
        races: ticketLeg.raceNumber
          ? [ticketLeg.raceNumber]
          : ticketLeg.selections.map(selection => selection.raceNumber),
        selectionString,
        planSeq: ticket.planSeq,
        numberOfCombinations,
        betType: ticketLeg.betType,
      }

      return betDetails
    }

    throw new Error('Incompatible ticket details')
  })
}
