import { fromJS } from 'immutable'
import { StateMap } from 'typings/immutable'
import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'

import { MysteryDataTransferObject } from '@classic/Betting-v2/DataTransferObjects/MysteryDataTransferObject'
import { BetType } from '@core/Data/Betting/selections'

import {
  CustomizedMysteryBetType,
  CustomizedMysteryOption,
  CustomMysteryBetTypes,
} from '../Racing/Components/Mystery/MysteryTypes'
import { BetError } from '@core/Data/betting'

export interface MysteryBetState {
  currentData: MysteryDataTransferObject | undefined
  isRaceClosed: boolean
  selectedQuickPick: string
  selectedCustomBetType: CustomizedMysteryBetType | undefined
  selectedCustomBetCombination: CustomizedMysteryOption | undefined
  selectedQuickPickBetCount: number | undefined
  selectedCustomBetCount: number | undefined
  isBetConfirmed: boolean
  betError: BetError | null
  goToNextRace: () => void
  goToMeeting: () => void
}

type MysteryBetStateMap = StateMap<MysteryBetState>
export const DEFAULT_MYSTERY_QUICK_PICK = '$1 Trifecta Classic'
export const DEFAULT_MYSTERY_CUSTOM_BET_TYPE = CustomMysteryBetTypes.find(
  x => x.type === BetType[BetType.Trifecta]
) as CustomizedMysteryBetType
export const DEFAULT_MYSTERY_CUSTOM_BET_COMBINATION = DEFAULT_MYSTERY_CUSTOM_BET_TYPE.options.find(
  x => x.optionNumber === DEFAULT_MYSTERY_CUSTOM_BET_TYPE.defaultOptionNumber
) as CustomizedMysteryOption
const DEFAULT_QUICK_PICK_BET_COUNT = 1
const DEFAULT_CUSTOM_BET_COUNT = 1

// Signals

export const SetMysteryData = createSignal<MysteryDataTransferObject>('SetMysteryData')
export const SetIsRaceClosed = createSignal<boolean>('SetIsRaceClosed')
export const SetSelectedQuickPick = createSignal<string>('SetSelectedMysteryQuickPick')
export const SetSelectedCustomBetType = createSignal<CustomizedMysteryBetType>(
  'SetSelectedCustomBetType'
)
export const SetSelectedCustomBetCombination = createSignal<CustomizedMysteryOption>(
  'SetSelectedCustomBetCombination'
)
export const SetSelectedQuickPickBetCount = createSignal<number>('SetSelectedQuickPickBetCount')
export const SetSelectedCustomBetCount = createSignal<number>('SetSelectedCustomBetCount')
export const SetIsBetConfirmed = createSignal<boolean>('SetIsBetConfirmed')
export const SetBetError = createSignal<BetError | null>('SetBetError')
export const SetGoToNextRace = createSignal<Function>('GoToNextRace')
export const SetGoToMeeting = createSignal<Function>('GoToMeeting')

// Driver

export const defaultState: MysteryBetState = {
  currentData: undefined,
  isRaceClosed: false,
  selectedQuickPick: DEFAULT_MYSTERY_QUICK_PICK,
  selectedCustomBetType: DEFAULT_MYSTERY_CUSTOM_BET_TYPE,
  selectedCustomBetCombination: DEFAULT_MYSTERY_CUSTOM_BET_COMBINATION,
  selectedQuickPickBetCount: DEFAULT_QUICK_PICK_BET_COUNT,
  selectedCustomBetCount: DEFAULT_CUSTOM_BET_COUNT,
  isBetConfirmed: false,
  betError: null,
  goToNextRace: () => null,
  goToMeeting: () => null,
}

export const driver = (state = fromJS(defaultState), signal: Signal): MysteryBetStateMap => {
  switch (signal.tag) {
    case SetMysteryData: {
      const mysteryDto = signal.data as MysteryDataTransferObject
      const isDefaultQuickPickAvailable = !mysteryDto?.DisabledQuickPickOptions?.includes(
        DEFAULT_MYSTERY_QUICK_PICK
      )
      const isDefaultCustomBetTypeAvailable = !mysteryDto?.DisabledCustomOptions?.includes(
        DEFAULT_MYSTERY_CUSTOM_BET_TYPE.type
      )
      const selectedQuickPick = isDefaultQuickPickAvailable ? DEFAULT_MYSTERY_QUICK_PICK : undefined
      const selectedCustomBetType = isDefaultCustomBetTypeAvailable
        ? DEFAULT_MYSTERY_CUSTOM_BET_TYPE
        : undefined
      const selectedCustomBetCombination = selectedCustomBetType?.options.find(
        x => x.optionNumber === selectedCustomBetType.defaultOptionNumber
      )
      return state.merge({
        currentData: mysteryDto,
        selectedQuickPick,
        selectedCustomBetType,
        selectedCustomBetCombination,
        isBetConfirmed: false,
      } as MysteryBetState)
    }

    case SetIsRaceClosed:
      return state.merge({ isRaceClosed: signal.data } as Partial<MysteryBetState>)

    case SetSelectedQuickPick:
      return state.merge({
        selectedQuickPick: signal.data,
        isBetConfirmed: false,
      } as Partial<MysteryBetState>)

    case SetSelectedCustomBetType: {
      const selectedCustomBetType = signal.data as CustomizedMysteryBetType | undefined
      const selectedCustomBetCombination = selectedCustomBetType?.options.find(
        x => x.optionNumber === selectedCustomBetType.defaultOptionNumber
      )
      return state.merge({
        selectedCustomBetType,
        selectedCustomBetCombination,
        isBetConfirmed: false,
      } as Partial<MysteryBetState>)
    }

    case SetSelectedCustomBetCombination:
      return state.merge({
        selectedCustomBetCombination: signal.data,
        isBetConfirmed: false,
      } as Partial<MysteryBetState>)

    case SetSelectedQuickPickBetCount:
      return state.merge({
        selectedQuickPickBetCount: signal.data,
        isBetConfirmed: false,
      } as Partial<MysteryBetState>)

    case SetSelectedCustomBetCount:
      return state.merge({
        selectedCustomBetCount: signal.data,
        isBetConfirmed: false,
      } as Partial<MysteryBetState>)

    case SetIsBetConfirmed:
      return state.merge({ isBetConfirmed: signal.data } as Partial<MysteryBetState>)

    case SetBetError:
      return state.merge({ betError: signal.data } as Partial<MysteryBetState>)

    case SetGoToNextRace:
      return state.merge({ goToNextRace: signal.data } as Partial<MysteryBetState>)

    case SetGoToMeeting:
      return state.merge({ goToMeeting: signal.data } as Partial<MysteryBetState>)

    default:
      return state
  }
}

export const state$ = attachDriver<MysteryBetStateMap>({
  driver,
  path: 'mystery-bet',
})
