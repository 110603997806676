import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { CheckboxInput } from '@mobi/component-library/Common/Input'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { updateUserSettingsOnDataLayer } from '@core/Services/Analytics/helpers'
import { usePrevious } from '@core/Utils/hooks'
import { store } from '@core/Store'

export const SettingItem: React.FC<{
  isEnabled: boolean
  label: string
  onToggle: () => void
  desciption?: string
  isLoading?: boolean
  shouldDisable?: boolean
}> = ({
  isEnabled,
  label,
  onToggle,
  desciption = '',
  shouldDisable = false,
  isLoading = false,
}) => {
  const prevIsEnabled = usePrevious(isEnabled)
  const settingNameKebabCase = label.toLowerCase().replace(/ /g, '-')

  React.useEffect(() => {
    if (
      [typeof prevIsEnabled, typeof isEnabled].every(val => val === 'boolean') &&
      prevIsEnabled !== isEnabled
    ) {
      trackSettingChange(settingNameKebabCase, isEnabled)
    }
  }, [isEnabled, settingNameKebabCase, prevIsEnabled])

  return (
    <WrapperStyled
      onClick={e => {
        e.preventDefault()
        if (shouldDisable) return
        onToggle()
      }}
      data-testid={`setting-item-${settingNameKebabCase}`}
    >
      <div>
        <h3>
          {label} {isLoading && <SpinnerInlineStyled color='dark' />}
        </h3>
        {desciption}
      </div>

      <div>
        <CheckboxInput
          id={`toggle-${settingNameKebabCase}`}
          data-testid={`toggle-${settingNameKebabCase}`}
          checked={isEnabled}
          readOnly
          label=''
          disabled={shouldDisable}
        />
      </div>
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

function trackSettingChange(setting: string, isOn: boolean) {
  trackEvent('profile-settings-change', { setting, isOn })
  const appState = store.getState()
  updateUserSettingsOnDataLayer(appState)
}

// ======
// Styles
// ======

const WrapperStyled = styled('div')({
  display: 'flex',
  borderBottom: '0.1rem solid ' + hexColors.veryLightGrey,
  padding: '1.5rem 0',
  margin: '0 1rem',
  fontSize: '1.2rem',
  color: hexColors.dimGrey,

  '&:last-of-type': { borderBottom: 0 },

  h3: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '2.4rem',
    padding: 0,
    margin: 0,
    fontSize: '1.4rem',
    fontWeight: 'bold',
    lineHeight: 1.2,
    textAlign: 'left',
    textTransform: 'capitalize',
    background: 'none',
    color: hexColors.nero,

    span: { marginLeft: '0.5rem' },
  },

  '& > div:first-of-type': { flex: 1, paddingRight: '0.5rem' },
})
