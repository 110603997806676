import dayjs from 'dayjs'
import { BettingInformation } from './BettingInformation'
import { BetType } from './Betting/BetType'
import { FobSelectionResult } from '../Components/Core/SelectionResults/FobSelectionResult'
import { QuickbetSelection } from '@core/Areas/Quickbet/signals'
import { BettingType } from '@core/Data/betting'
import {
  BetType as TabTouchCoreBetType,
  ToteSelection,
  FobMatchedSelection,
  AllUpSelection,
  AllUpSelectionDetails,
  AllUpPoolType,
  SameRaceMultiSelection,
  SameRaceMultiSelectionAcceptor,
} from '@core/Data/Betting/selections'
import AllUpFormulasDataTransferObject from '../DataTransferObjects/AllUpFormulasDataTransferObject'
import SameRaceMultiSelectionResultProcessor, {
  SameRaceMultiSelectionResult,
} from '@core/Areas/Racing/Components/SameRaceMulti/SameRaceMultiSelectionResultProcessor'
import { SameRaceMultiPriceResponse } from '@core/Areas/Racing/Components/SameRaceMulti/SameRaceMultiBettingDrawer/SameRaceMultiPrice'
import { EventDetails } from '@core/Data/Betting/selectionDetails'
import ObservableMeetingInformation from './Observables/ObservableMeetingInformation'
import WinPlaceSelectionResultProcessor from '../Components/Core/SelectionResults/WinPlaceSelectionResultProcessor'

interface BetSelectionBuilderBuild {
  bettingInformation: BettingInformation
  numberOfCombinations: number
  allUpFormulas?: AllUpFormulasDataTransferObject
  priceResponse?: SameRaceMultiPriceResponse
  winPlaceStarterOverride?: number
}

export class BetSelectionBuilder {
  public static build({
    bettingInformation: bi,
    numberOfCombinations,
    allUpFormulas,
    priceResponse,
    winPlaceStarterOverride,
  }: BetSelectionBuilderBuild): QuickbetSelection {
    const shouldAllowPlaceInvestment = BetSelectionBuilder.getShouldAllowPlaceInvestment(bi)

    return (
      BetSelectionBuilder.buildSameRaceMulti(bi, priceResponse) ??
      BetSelectionBuilder.buildFixed(bi, shouldAllowPlaceInvestment, winPlaceStarterOverride) ??
      BetSelectionBuilder.buildAllUp(bi, shouldAllowPlaceInvestment, allUpFormulas) ??
      BetSelectionBuilder.buildTote(bi, shouldAllowPlaceInvestment, numberOfCombinations)
    )
  }

  static buildSameRaceMulti(
    bettingInfo: BettingInformation,
    priceResponse?: SameRaceMultiPriceResponse
  ): QuickbetSelection | null {
    if (BetType.SameRaceMulti !== bettingInfo.selectedBetType().betType()) return null

    const selectionResult = new SameRaceMultiSelectionResultProcessor().getSelectionsResult(
      bettingInfo,
      bettingInfo.raceNumber
    ) as SameRaceMultiSelectionResult

    const acceptors: SameRaceMultiSelectionAcceptor[] =
      selectionResult.fobSelectionResults.map<SameRaceMultiSelectionAcceptor>(result => ({
        acceptorNumber: result.starterNumber,
        legNumber: result.legNumber,
      }))

    const selection: SameRaceMultiSelection = {
      type: 'same-race-multi',
      fixtureId: bettingInfo.meetingId,
      fixtureDate: dayjs(bettingInfo.meetingDate).format('YYYY-MM-DD'),
      raceNumber: bettingInfo.raceNumber,
      acceptors,
      winPrice: priceResponse?.price || 0,
    }

    return {
      bettingType: BettingType.FixedOddsRacing,
      selection,
      selectionDetails: {} as EventDetails,
      isEachWayAvailable: false,
      shouldAllowPlaceInvestment: false,
    }
  }

  static buildFixed(
    bi: BettingInformation,
    shouldAllowPlaceInvestment: boolean,
    winPlaceStarterOverride?: number
  ): QuickbetSelection | null {
    if (!bi.isFixed()) return null

    const selectionResult = new WinPlaceSelectionResultProcessor(
      winPlaceStarterOverride
    ).getSelectionsResult(bi, bi.raceNumber)
    const result: FobSelectionResult = selectionResult as FobSelectionResult
    const selection: FobMatchedSelection = {
      type: 'fob-matched',
      fixtureId: bi.meetingId,
      fixtureDate: dayjs(bi.meetingDate).format('YYYY-MM-DD'),
      raceNumber: bi.raceNumber,
      acceptorNumber: result.starterNumber,
      propositionSeq: result.propositionSequence.toString(),
      winPrice: Number(result.winPrice),
      winPriceLastSeen: Number(result.winPrice),
      placePrice: result.placePrice !== '-' ? Number(result.placePrice) : null,
      placePriceLastSeen: result.placePrice !== '-' ? Number(result.placePrice) : null,
      priceSource: 'selection',
    }
    return {
      bettingType: BettingType.FixedOddsRacing,
      selection,
      selectionDetails: null,
      isEachWayAvailable: false,
      shouldAllowPlaceInvestment,
    }
  }

  static buildAllUp(
    bi: BettingInformation,
    shouldAllowPlaceInvestment: boolean,
    allUpFormulas?: AllUpFormulasDataTransferObject
  ): QuickbetSelection | null {
    if (!bi.selectedBetType().isAllUp()) return null

    bi.allUpFormulas.assignFormulas(allUpFormulas)
    const selection: AllUpSelection = {
      fixtureId: bi.meetingId,
      fixtureDate: dayjs(bi.meetingDate).format('YYYY-MM-DD'),
      betType: TabTouchCoreBetType.AllUp,
      formulas: bi.allUpFormulas.formulas().map(formula => {
        return {
          formula: formula.number(),
          numberOfCombinations: formula.numberOfCombinations(),
          isSelected: formula.isSelected(),
        }
      }),
      details: BetSelectionBuilder.getAllUpSelections(bi),
    }
    return {
      bettingType: BettingType.ToteRacing,
      selection,
      selectionDetails: null,
      isEachWayAvailable: false,
      shouldAllowPlaceInvestment,
    }
  }

  static buildTote(
    bi: BettingInformation,
    shouldAllowPlaceInvestment: boolean,
    numberOfCombinations: number
  ): QuickbetSelection {
    const selection: ToteSelection = {
      fixtureId: bi.meetingId,
      fixtureDate: dayjs(bi.meetingDate).format('YYYY-MM-DD'),
      raceNumber: bi.raceNumber,
      betType: BetSelectionBuilder.getTabTouchCoreBetType(bi),
      selectionString: BetSelectionBuilder.getSelectionString(bi),
      numberOfCombinations,
      isAllways: bi.isAllwaysBet(),
      isLegIn: bi.isLegIn(),
      isRovingBanker: bi.rovingBanker(),
    }
    return {
      bettingType: BettingType.ToteRacing,
      selection,
      selectionDetails: null,
      isEachWayAvailable: false,
      shouldAllowPlaceInvestment,
    }
  }

  public static getShouldAllowPlaceInvestment(bi: BettingInformation) {
    if (BetType.WinPlace !== bi.selectedBetType().betType()) return false

    const { selectedRace: race } = bi.meetingInformation as ObservableMeetingInformation
    if (bi.isFixed()) {
      return race.isFixedOddsRace() && race.fixedOddsInfo.isPlaceAvailable()
    } else {
      return race.hasPlacePool()
    }
  }

  private static getTabTouchCoreBetType(
    bettingInformation: BettingInformation
  ): TabTouchCoreBetType {
    const defaultReturnValue: TabTouchCoreBetType = TabTouchCoreBetType.WinPlace

    if (!bettingInformation || !bettingInformation.selectedBetType) {
      return defaultReturnValue
    }

    const selectedBetType = bettingInformation.selectedBetType()
    if (selectedBetType == null || !selectedBetType.betType) {
      return defaultReturnValue
    }

    const betType = selectedBetType.betType()

    switch (betType) {
      case BetType.WinPlace:
        return TabTouchCoreBetType.WinPlace
      case BetType.Quinella:
        return TabTouchCoreBetType.Quinella
      case BetType.Exacta:
        return TabTouchCoreBetType.Exacta
      case BetType.Trifecta:
        return TabTouchCoreBetType.Trifecta
      case BetType.First4:
        return TabTouchCoreBetType.First4
      case BetType.Double:
        return TabTouchCoreBetType.Double
      case BetType.Quaddie:
        return TabTouchCoreBetType.Quaddie
      case BetType.Mystery:
        return TabTouchCoreBetType.Mystery
      case BetType.AllUp:
        return TabTouchCoreBetType.AllUp
      default:
        return defaultReturnValue
    }
  }

  private static getTabTouchCorePoolType(poolName: string): AllUpPoolType {
    switch (poolName) {
      case 'Win':
        return AllUpPoolType.Win
      case 'Place':
        return AllUpPoolType.Place
      case 'Eachway':
        return AllUpPoolType.Eachway
      case 'Quinella':
        return AllUpPoolType.Quinella
      default:
        throw new Error('Invalid pool')
    }
  }

  private static getSelectionString(bettingContext: BettingInformation): string {
    let selectionStringParts: string[] = []
    if (bettingContext.selectedBetType().multiBet()) {
      // Double, Quaddie, All-up
      for (let legSelection of bettingContext.getLegsForProcessing()) {
        const leg = legSelection.raceKey().leg()
        const selectionString = bettingContext.resultsForLeg(leg).selectionStrings[0]
        selectionStringParts.push(selectionString)
      }
    } else {
      const results = bettingContext.results()
      results.selectionStrings.forEach(selectionString => {
        if (selectionString) {
          // Allways bets will have empty leg selections
          selectionStringParts.push(selectionString)
        }
      })
    }
    if (bettingContext.isAllwaysBet()) {
      return selectionStringParts[0]
    }
    return selectionStringParts.join('/')
  }

  private static getAllUpSelections(bettingContext: BettingInformation): AllUpSelectionDetails[] {
    let allUpSelections: AllUpSelectionDetails[] = []
    for (let legSelection of bettingContext.getLegsForProcessing()) {
      const raceKey = legSelection.raceKey()
      const selection: AllUpSelectionDetails = {
        raceNum: raceKey.raceNumber(),
        poolType: BetSelectionBuilder.getTabTouchCorePoolType(
          raceKey.poolInfo.selectedPool().name()
        ),
        betSelections: bettingContext.resultsForLeg(raceKey.leg()).selectionStrings[0],
      }
      allUpSelections.push(selection)
    }
    return allUpSelections
  }
}
