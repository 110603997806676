import { BettingType } from '@core/Data/betting'
import { Campaign } from '@core/State/UserAccount/userAccountDriver'

export function isCampaignRedeemableWithBettingType(
  campaign: Campaign,
  bettingType: BettingType | null
): boolean {
  if (campaign.redeemableBy == null) {
    return false
  }

  switch (bettingType) {
    case BettingType.FixedOddsRacing:
      return campaign.redeemableBy.includes('FobRacingBet')
    case BettingType.FixedOddsSports:
      return campaign.redeemableBy.includes('FobSportsBet')
    case BettingType.ToteRacing:
      return campaign.redeemableBy.includes('ToteRacingBet')
    case BettingType.ToteSports:
      return campaign.redeemableBy.includes('ToteSportsBet')
    default:
      return false
  }
}
