import { declareResourceType, IKeyMap } from 'rwwa-data-access'
import { TypedRecord, recordify } from 'typed-immutable-record'
import { List } from 'immutable'
import Rx from 'rx'
import { filterObject } from '../helpers'
import { get } from '@classic/Foundation/Services/ApiService'
import { MeetingInformationDataTransferObject } from '@classic/Betting-v2/DataTransferObjects/DataTransferObjects'

export interface Race {
  key: string
  raceNumber: number
  meetingName: string
  meetingCode: string
  MeetingId: string
  meetingDate: string
  distance: number
  raceTime: string
  acceptorKeys: List<string>
  type: string
  isFixedOddsRace?: boolean
}

interface RaceMap {
  races: IKeyMap<Race>
}

export interface RaceRecord extends TypedRecord<RaceRecord>, Race {}

export const Races = declareResourceType<RaceRecord, Race>({
  fetchMultiple: fetchMultiple as unknown as undefined,
  typeKey: 'races',
  map: toImmutable,
  maxToKeep: 100,
  hardTimeToLive: 60000,
})

export function createRaceObjectFromResponse(
  meetingDto: MeetingInformationDataTransferObject
): Race {
  return {
    MeetingId: meetingDto.MeetingId,
    meetingCode: meetingDto.MeetingCodeText,
    meetingDate: meetingDto.MeetingDate,
    meetingName: meetingDto.MeetingName,
    raceNumber: meetingDto.SelectedRace.RaceNumber,
    raceTime: meetingDto.SelectedRace.RaceTime,
    type: meetingDto.SelectedRace.RaceType,
    acceptorKeys: List(),
    distance: meetingDto.SelectedRace.Distance,
    key: meetingDto.SelectedRace.Key,
  }
}

function toImmutable(race: Race): RaceRecord {
  return recordify<Race, RaceRecord>(race).merge({
    acceptorKeys: race.acceptorKeys,
  })
}

// ti-1180: Maximum number of keys to request at a time so as not to exceed the 260 character URL limit in IIS.
// - base url: 50 (e.g. http://tabtouch-mobi.local.rwwaq.com.au/api/races/)
// - each key: 8 (currently 6 digits, + 1 for delimiter, + 1 to allow for growth to 7 digits)
// - max keys: (260 - 50) / 8 = 26.5
// (go with 10 just in case)

const MAX_KEYS_TO_REQUEST = 10

function fetchMultiple(keys: string[]) {
  return (
    Rx.Observable.fromArray(keys)
      .bufferWithCount(MAX_KEYS_TO_REQUEST)
      .select(raceKeys => get<RaceMap>({ url: `/api/races/${raceKeys.join(',')}` }))
      .select(promise => Rx.Observable.fromPromise(promise))
      .mergeAll() as unknown as Rx.Observable<RaceMap>
  ).selectMany(raceMap => {
    const success = filterObject<Race>(
      raceMap.races,
      race => race.meetingName !== '-- not found --'
    ) as IKeyMap<Race>
    const success$ = Rx.Observable.just({
      keys: Object.keys(success),
      result: Promise.resolve({ races: success }),
    })

    const failed = filterObject<Race>(
      raceMap.races,
      race => race.meetingName === '-- not found --'
    ) as IKeyMap<Race>
    const failed$ = Rx.Observable.just({
      keys: Object.keys(failed),
      result: Promise.reject('Could not find races'),
    })

    // @ts-expect-error Legacy - use better data management solution
    return Rx.Observable.concat(success$, failed$)
  })
}
