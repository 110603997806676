import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { Race, Acceptor } from '../Racing'
import { BettingType } from '../betting'
import { FavouriteNumberBettingPool } from '../favouriteNumbers'

export type EventDetails =
  | FavouriteNumbersDetails
  | FobDetails
  | MysteryDetails
  | RaceDetails
  | SportsDetails
  | ToteSportsDetails

export type RaceDetails = { races: Race[]; acceptors: Acceptor[] }

export interface FobDetails {
  sportName?: string
  event: string
  sport?: string
  tournament?: string
  competition?: string
  market?: string
  proposition?: string
  handicap?: number | null
  sportIconCode: keyof typeof iconPaths
  competitionName: string
  marketName: string
  propositionName: string
  tournamentName?: string
  eventName?: string
  eventStartTime: Date
  handicapName?: string | null
}

export interface MysteryDetails {
  race: Race
  betType: BettingType
  investmentWin: number
  investmentPlace: number
  productCode: string
  mysteryOption: number
  betTypeName: string
}

export type FavouriteNumbersDetails = { pools: FavouriteNumberBettingPool[] }

export interface ToteSportsDetails {
  poolName: string
  games: Array<{ homeTeamName: string; awayTeamName: string }>
}

export type SportsDetails = {
  sportName: string
  tournamentName: string
  eventName: string
  marketId: number
  marketName: string
  advertisedStartTime: string
  bettingClosingTime: string
}

// ========================
// Type Narrowing Functions
// ========================

export function isRaceDetails(details: EventDetails | null): details is RaceDetails {
  if (!details || typeof details !== 'object') return false

  const asRaceDetails = details as RaceDetails
  if (!asRaceDetails.races || !Array.isArray(asRaceDetails.races)) return false

  if (asRaceDetails.races.length > 0) return isRace(asRaceDetails.races[0])

  return true
}

const isRace = (object: object): object is Race => 'raceNumber' in object

export function isFobDetails(details: EventDetails | null): details is FobDetails {
  if (
    !details ||
    typeof details !== 'object' ||
    typeof (details as FobDetails).propositionName !== 'string'
  )
    return false
  return true
}

export function isMysteryDetails(details: EventDetails | null): details is MysteryDetails {
  if (!details || typeof details !== 'object') return false
  return !!(details as MysteryDetails).mysteryOption
}

export function isFavouriteNumbersDetails(
  details: EventDetails | null
): details is FavouriteNumbersDetails {
  if (!details || typeof details !== 'object') return false
  return !!(details as FavouriteNumbersDetails).pools
}

export function isToteSportsDetails(details: EventDetails | null): details is ToteSportsDetails {
  if (!details || typeof details !== 'object') return false
  return Boolean((details as ToteSportsDetails).poolName && (details as ToteSportsDetails).games)
}

export function isFobSportsDetails(details: EventDetails | null): details is SportsDetails {
  if (!details || typeof details !== 'object') return false
  return Boolean('sportName' in details && 'eventName' in details)
}
