import React, { type FC } from 'react'
import {
  ContainerStyled,
  TitleStyled,
  WrapperStyled,
  ContentStyled,
  IconStyled,
} from './Accordion.styles'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { colors } from '@mobi/component-library/Theme/Common'
import { useExpandToggle } from '@mobi/utils/hooks/useExpandToggle'
import { v4 as uuid } from 'uuid'

type Props = {
  /**
   * The title of the accordion
   */
  title: React.ReactNode
  /**
   * The content of the accordion
   */
  children: React.ReactNode
  /**
   * Animation duration in milliseconds
   */
  transitionMs?: number
  /**
   * Triggered when the accordion is toggled
   */
  onToggle?: (isExpanded: boolean) => void
  /**
   * Determines if the accordion starts off already expanded
   */
  shouldStartExpanded?: boolean
}

export const Accordion: FC<Props> = ({
  title,
  children,
  transitionMs = 400,
  onToggle,
  shouldStartExpanded,
}) => {
  const buttonIdRef = React.useRef<string>(uuid())
  const regionIdRef = React.useRef<string>(uuid())

  const { wrapperRef, contentRef, shouldRender, isExpanded, toggleExpanded } = useExpandToggle(
    transitionMs,
    shouldStartExpanded
  )

  return (
    <ContainerStyled>
      <h2>
        <button
          onClick={() => {
            onToggle?.(isExpanded)
            toggleExpanded()
          }}
          aria-expanded={isExpanded}
          aria-controls={regionIdRef.current}
          id={buttonIdRef.current}
        >
          <TitleStyled>{title}</TitleStyled>
          <IconStyled isExpanded={isExpanded} transitionMs={transitionMs}>
            <Icon
              size='1.6rem'
              name='SolidChevronDown'
              color={isExpanded ? colors.white : colors.neutral[900]}
            />
          </IconStyled>
        </button>
      </h2>

      {(isExpanded || shouldRender) && (
        <WrapperStyled
          ref={wrapperRef}
          isExpanded={isExpanded}
          transitionMs={transitionMs}
          aria-labelledby={buttonIdRef.current}
          id={regionIdRef.current}
          role='region'
        >
          <ContentStyled ref={contentRef}>{children}</ContentStyled>
        </WrapperStyled>
      )}
    </ContainerStyled>
  )
}

Accordion.displayName = 'Accordion'
