import { Ticket } from '@core/Areas/AccountActivity/types'
import { PlanSeq } from '@core/Data/betting'

export function isSameRaceMulti(ticket: Ticket) {
  return ticket.productName === 'Same Race Multi'
}

function isSingleMultiple(legs: Ticket['legs']): boolean {
  return (
    legs.length === 1 &&
    !!legs[0].selections.length &&
    (legs[0].selections.length > 1 ||
      legs[0].selections[0].selectionDetails[0].acceptorDetails.length > 1)
  )
}

function isSingleSingle(legs: Ticket['legs']): boolean {
  return (
    legs.length === 1 &&
    legs[0].selections.length === 1 &&
    (legs[0].selections[0].selectionDetails[0].acceptorDetails.length === 1 ||
      legs[0].selections[0].selectionDetails[0].acceptorDetails.length === 0)
  )
}

export function isTippingTicket(ticket: Ticket): boolean {
  return [PlanSeq.SportsTipping, PlanSeq.FootyTipping, PlanSeq.Footo].includes(ticket.planSeq)
}

type TicketLayoutType =
  | 'single-single'
  | 'single-multiple'
  | 'multi'
  | 'tipping'
  | 'unknown'
  | 'same-race-multi'
  | 'sports'

export function getTicketLayoutType(ticket: Ticket): TicketLayoutType {
  if (ticket.planSeq === PlanSeq.FOBSports) return 'sports'

  if (isTippingTicket(ticket)) return 'tipping'

  if (isSameRaceMulti(ticket)) return 'same-race-multi'

  if (isSingleMultiple(ticket.legs)) return 'single-multiple'

  if (isSingleSingle(ticket.legs)) return 'single-single'

  if (ticket.legs.length > 1 && ticket.numLegs) return 'multi'

  return 'unknown'
}
