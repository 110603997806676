import React from 'react'
import ReactDraggable, { DraggableEventHandler } from 'react-draggable'
import styled from '@emotion/styled'
import { layering, radius, shadow } from '@mobi/component-library/Theme/Common'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { VideoPlayer } from '../VideoPlayer/VideoPlayer'
import { VISION_DRAG_HANDLE_CLASSNAME } from '../VideoPlayer/Components/VideoPlayerControls/VideoPlayerControls'
import { usePlayerState, PositionState } from './helpers/usePlayerState'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { setIsControlShowing, setIsPlaying } from '../../Store'
import { store } from '@core/Store'
import { findAndStartChildVideoElement } from '../VideoPlayer/helpers'

const RESIZE_HANDLER_CLASSNAME = 'vision-floating-resize-handler'

export const FloatingPlayer: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const isPlaying = useAppSelector(state => state.skyVideoPlayer.isPlaying)
  const isControlShowing = useAppSelector(state => state.skyVideoPlayer.isControlShowing)
  const isFullscreen = useAppSelector(state => state.skyVideoPlayer.isFullscreen)

  const floatingPlayerWrapRef = React.useRef<HTMLDivElement>(null)

  const [{ x, y, width }, setPosition, setWidth] = usePlayerState()
  const lastPlayerStateRef = React.useRef<ReturnType<typeof usePlayerState>[0]>({ x, y, width })

  // Set width of floating player
  React.useEffect(() => {
    if (!floatingPlayerWrapRef.current) return
    floatingPlayerWrapRef.current.style.width = width + 'px'
  }, [width])

  // Ensure Player is Visible on State Change
  React.useEffect(() => {
    const timerId = window.setTimeout(() => {
      const { top, bottom, left, right } =
        floatingPlayerWrapRef.current?.getBoundingClientRect() || {}
      if (!top || !bottom || !left || !right) {
        return
      }
      const isVisible =
        (top >= 0 && bottom <= window.innerHeight) || (left >= 0 && right <= window.innerWidth)

      if (!isVisible) {
        setPosition('RESET')
        setWidth('RESET')
      }
    }, 200)

    return () => {
      window.clearTimeout(timerId)
    }
  }, [x, y, width, setPosition, setWidth])

  const onDraggableClick = (e: MouseEvent) => {
    if (shouldAllowControlToggle(e.target as Element, e.currentTarget as Element)) {
      isPlaying ? dispatch(setIsControlShowing(!isControlShowing)) : dispatch(setIsPlaying(true))
      findAndStartChildVideoElement(floatingPlayerWrapRef.current ?? undefined)
    }
  }

  const handleResizeDrag: DraggableEventHandler = (e, data) => {
    const { left: playerLeft = 0, top: playerTop = 0 } =
      floatingPlayerWrapRef.current?.getBoundingClientRect() || {}
    const { x, y, lastX, lastY } = data

    // Handle Descreasing Player Size
    if (x > lastX || y > lastY) {
      const newWidth = Math.abs(-width + x)
      if (newWidth < width) {
        setWidth(newWidth)
      }
      return
    }

    // Handle Increasing Player Size - Prevent Player from Extending Beyond Boundary
    if ((x < lastX || y < lastY) && playerTop > 0 && playerLeft > 0) {
      const newWidth = Math.abs(-width + x)
      const viewportWidth = Math.min(document.documentElement.clientWidth, window.innerWidth)
      const viewportHeight = Math.min(document.documentElement.clientHeight, window.innerHeight)
      const isNewHeightContainedInViewport = newWidth * 0.5625 < viewportHeight
      if (newWidth < viewportWidth && isNewHeightContainedInViewport && newWidth > width) {
        setWidth(newWidth)
      }
      return
    }
  }

  const handleFloatingPlayerMove: DraggableEventHandler = (e, data) => {
    const { x, y } = data
    setPosition({ x, y })
  }

  return (
    <ReactDraggable
      onMouseDown={onDraggableClick}
      disabled={isFullscreen}
      bounds='html'
      handle={`.${VISION_DRAG_HANDLE_CLASSNAME}`}
      position={{ x, y }}
      onDrag={handleFloatingPlayerMove}
      onStop={() => trackVisionPositionChanged({ x, y, lastPlayerStateRef })}
    >
      <FloatingPlayerWrapperStyled ref={floatingPlayerWrapRef}>
        {!isFullscreen && (
          <ResizeHandleWrapperStyled data-js-resize-handler>
            <ReactDraggable
              onDrag={handleResizeDrag}
              onStop={() => handleResizeDragStop({ width, lastPlayerStateRef })}
              handle={`.${RESIZE_HANDLER_CLASSNAME}`}
              position={{ x: 0, y: 0 }}
            >
              <div className={RESIZE_HANDLER_CLASSNAME} />
            </ReactDraggable>
          </ResizeHandleWrapperStyled>
        )}

        <VideoPlayer />
      </FloatingPlayerWrapperStyled>
    </ReactDraggable>
  )
}

// =============
// Local Helpers
// =============

function handleResizeDragStop({
  width,
  lastPlayerStateRef,
}: { width: number } & {
  lastPlayerStateRef: React.MutableRefObject<ReturnType<typeof usePlayerState>[0]>
}) {
  if (width != lastPlayerStateRef.current.width) trackKey(analyticsKeys.visionWindowResized)
  lastPlayerStateRef.current = { ...lastPlayerStateRef.current, width }
  store.dispatch(setIsControlShowing(true))
}

function trackVisionPositionChanged({
  x,
  y,
  lastPlayerStateRef,
}: PositionState & {
  lastPlayerStateRef: React.MutableRefObject<ReturnType<typeof usePlayerState>[0]>
}) {
  const { x: lastX, y: lastY, width } = lastPlayerStateRef.current
  if (x !== lastX && y !== lastY) trackKey(analyticsKeys.visionPositionChanged)
  lastPlayerStateRef.current = { x, y, width }
}

function shouldAllowControlToggle(target: Element | null, parent: Element): boolean {
  if (!target) return true
  if (target.closest('[data-js-resize-handler]')) return false
  return !parent.contains(target.closest('button'))
}

// ======
// Styles
// ======

const FloatingPlayerWrapperStyled = styled.div({
  position: 'fixed',
  bottom: '7rem',
  right: '1rem',
  boxShadow: shadow.lg,
  zIndex: layering.skyVideoPlayerFloating,
  minWidth: '20rem',
  overflow: 'hidden',
  borderRadius: radius.lgx1,
})

const ResizeHandleWrapperStyled = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 5,
  // Same width + height as PlayerControlButtonStyled
  width: '4rem',
  height: '4rem',
  pointerEvents: 'none',

  '> div': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'auto',
  },
})
