import '../assets/scss/mobi.scss'
import './include-polyfill'

import * as ko from 'knockout'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as tabtouch from '@classic/Foundation/TabTouch'
import * as nav from '@classic/Foundation/Navigation/Nav'
import * as analyticsService from '@classic/Foundation/Analytics/AnalyticsService'
import * as modal from '@classic/Foundation/Modal/modal'
import * as analyticsDataLayer from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import * as rwwaRxStateMachineDevTools from '@core/Utils/rwwa-rx-state-machine-dev-tools'

import { getConfig } from '@classic/Foundation/Services/ConfigService'
import {
  startDeepLinkingListeners,
  startUserLoginListeners,
  startLocationServiceListeners,
  startPushNotificationsAuthStatus,
  startMetadataStatusUpdate,
  startRaceKeyNotification,
  startWebviewIsAliveListener,
} from '@core/NativeServices'
import '@classic/AppUtils/Framework/CustomBindings/React'
import { registerUserAccountSideEffects } from '@core/State/UserAccount/side-effects'
import { registerRaceBettingPageCommandSideEffects } from '@classic/Betting-v2/Components/Commands/driver-side-effects'
import { DepositLimitsAnniversaryPopup } from '@core/Areas/DepositLimits/DepositLimitsAnniversaryPopup'
import { initializeLaunchDarklyServiceWithConfig } from '@core/State/LaunchDarklyFeatures/LaunchDarklyService'
import '@core/State/PushData'
import { subscribe as iotSubscribe } from '@core/State/PushData/awsIot'
import { getGlobalPushSubscriptions } from '@core/State/PushData/topics'
import { registerSubApplicationHandlerRoutes } from '@classic/Foundation/RouteHandlers/RegisterRoutes'
import App from '@core/App'
import { init as initWebNativeComms } from '@mobi/web-native-comms/web'
import { refreshLogonState } from '@core/Areas/Login/helpers'
import { initializeSentry } from '@core/Services/Sentry'
import { defer } from '@mobi/utils'

ko.options.deferUpdates = true
// @ts-expect-error Missing type
ko.onError = error => {
  // eslint-disable-next-line no-console
  console.error('Knockout Error: \n' + error.message)
  throw error
}

window.AnalyticsDataLayer = analyticsDataLayer

getConfig()
  .then(config => {
    if (config.enableSentryIo) {
      initializeSentry(config.sentryIoUrl, config.serverEnvironment)
    }
    return initializeLaunchDarklyServiceWithConfig(config)
  })
  .then(() => {
    const depositLimitsAnniversaryPopupContainer = document.getElementById(
      'deposit-limit-anniversary-popup'
    )
    if (depositLimitsAnniversaryPopupContainer) {
      ReactDOM.render(
        React.createElement(DepositLimitsAnniversaryPopup, null),
        depositLimitsAnniversaryPopupContainer
      )
    }
  })

defer(() => {
  initWebNativeComms()

  refreshLogonState()
  analyticsService.init()
  tabtouch.init()
  modal.init()

  startDeepLinkingListeners()
  startUserLoginListeners()
  startLocationServiceListeners()
  startPushNotificationsAuthStatus()
  startMetadataStatusUpdate()
  startRaceKeyNotification()
  startWebviewIsAliveListener()

  // Register driver side effects here
  registerUserAccountSideEffects()
  registerRaceBettingPageCommandSideEffects()

  // Render <App />
  const appContainerEl = document.getElementById('js-react-app')
  if (appContainerEl) {
    ReactDOM.render(React.createElement(App, null), appContainerEl)
  }

  // Do global push subscriptions
  iotSubscribe(getGlobalPushSubscriptions())
})

// Init Primary Router (legacy / not react router)
nav.init()
registerSubApplicationHandlerRoutes()

// Set up DEV tools
if (!PRODUCTION) {
  rwwaRxStateMachineDevTools.start()
  // @ts-expect-error Dev Only
  module.hot && module.hot.accept()
}
