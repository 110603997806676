import styled, { CSSObject } from '@emotion/styled'
import React, { type PropsWithChildren } from 'react'
import { SVGProps } from 'react'

interface SvgIconStyledProps {
  color?: string
  size: string
  transformation?: string
  valign?: string
  height?: string | number
  width?: string | number
}

export const SvgIconStyled = styled.svg<SvgIconStyledProps>(
  ({
    color,
    size,
    transformation,
    valign = 'bottom',
    height = '1em',
    width = '1em',
  }: SvgIconStyledProps) => {
    // height and width are done in a separate object, so it doesn't update 110 snapshots
    const sizes: CSSObject = {}

    if (typeof width === 'string') {
      sizes.width = width
    }

    if (typeof height === 'string') {
      sizes.height = height
    }

    const style: CSSObject = {
      display: 'inline-block',
      fill: color || 'currentColor',
      fontSize: size,
      ...sizes,
      verticalAlign: valign,
      transform: transformation,
    }

    return style
  }
)

type SvgWidth = SVGProps<SVGSVGElement>['width']

type SvgRaceCardIconProps = {
  size: SvgWidth
}

export const SvgRaceCardIcon = ({ size, children }: PropsWithChildren<SvgRaceCardIconProps>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    height={size}
    fill='none'
  >
    {children}
  </svg>
)
