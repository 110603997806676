import React from 'react'
import { Ticket } from '@core/Areas/AccountActivity/types'
import { Cost } from './Components/Cost/Cost'
import { Formulas } from './Components/Formulas/Formulas'
import { TippingOrFooto } from './Components/TippingOrFooto/TippingOrFooto'
import { PlanSeq } from '@core/Data/betting'

export const TicketInvestment: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  if (ticket.planSeq === PlanSeq.FavouriteNumbers) return null

  if (ticket.productName == 'Tipping' || ticket.productName == 'Footo') {
    return <TippingOrFooto investment={ticket.investment.lines[0]} />
  }

  if (ticket.formula !== null) {
    return <Formulas formulaDetail={ticket.formula} />
  }

  if (ticket.flexPercent == null || ticket.flexPercent === '')
    return <Cost investment={ticket.investment} />

  return null
}
