import {
  AddingToBetslip,
  ConfirmBetFailed,
  ConfirmBetslipBetsSucceeded,
  ConfirmBetSucceeded,
  ProposeBetFailed,
  ProposeBetSucceeded,
  QuickbetClosed,
  QuickbetLoadSelection,
  SetInvalidInvestmentNotification,
} from './signals'
import { QuickbetState, state$ as quickbetState$ } from './driver'
import {
  InvestmentStateRecord,
  state$ as investmentState$,
} from './Components/BetInvestment/betInvestmentDriver'
import {
  AnalyticsBetData,
  AnalyticsData,
  defaultData as analyticsDefaultData,
  keys as analyticsKeys,
} from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import * as Analytics from '@classic/Foundation/Analytics/Analytics'
import { NotificationType } from './Components/Notifications/NotificationTypes'
import {
  FeatureFlagState,
  state$ as launchDarklyState$,
} from '@core/State/LaunchDarklyFeatures/driver'
import { trackBetConfirmation } from '@classic/Foundation/Analytics/Analytics.Bet'
import { trackBetSlipAdd } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { RaceDetails } from '@core/Data/Betting/selectionDetails'
import { ensureBetErrorTypeLiteral } from '@core/Data/betting'
import { betslipAnalyticsSubscriber } from '../Betslip/analytics'
import { FobPropositionSelection, ToteSelection } from '@core/Data/Betting/selections'
type AnalyticsBetAndQuickbetData = Pick<AnalyticsData, 'bet' | 'quickbet'>

const extractErrorMessage = (betErrorType: unknown): Nullable<string> =>
  typeof betErrorType === 'string' || typeof betErrorType === 'number'
    ? ensureBetErrorTypeLiteral(betErrorType)
    : null

const defaultBetData = analyticsDefaultData.bet
const defaultQuickbetData = analyticsDefaultData.quickbet

export function register(): { dispose(): void } {
  let superPickErrorMessage: string | null = null

  // Subscribing to SuperPick error state to store message in variable for use later
  const setInvalidInvestmentNotificationSubscription =
    SetInvalidInvestmentNotification.signal$.subscribe(x => {
      if (x.data.type === NotificationType.InvalidSuperPickSelection) {
        superPickErrorMessage = 'SuperPick Error'
      }
    })

  const quickbetLoadSelectionSubscription = QuickbetLoadSelection.signal$.subscribe(() => {
    superPickErrorMessage = null
    const opened = { timestamp: new Date() }
    Analytics.track(analyticsKeys.quickbetOpen, { quickbet: { opened } })
  })

  const quickbetClosedSubscription = QuickbetClosed.signal$
    .withLatestFrom(quickbetState$, (_, quickbetState) => ({ quickbetState }))
    .subscribe(x => {
      const closed = {
        betPlaced: x.quickbetState.betPlaced,
        timestamp: new Date(),
      }
      Analytics.track(analyticsKeys.quickbetClosed, { quickbet: { closed } })
    })

  // -------------------- Bet step 1 --------------------
  const proposeBetFailedSubscription = ProposeBetFailed.signal$
    .withLatestFrom(investmentState$, (proposeBetFailed, investmentState) => ({
      proposeBetFailed,
      investmentState,
    }))
    .subscribe(x => {
      const investmentState: InvestmentStateRecord = { ...x.investmentState.toJS() }
      const keypadState = investmentState[investmentState.activeInvestment].lastKeyPressed.mode

      const errorMessage =
        extractErrorMessage(x.proposeBetFailed.data.response.type) ??
        x.proposeBetFailed.data.response.message

      const dataToTrack: AnalyticsBetAndQuickbetData = {
        bet: { ...defaultBetData, errorMessage, construction: 'quickbet' },
        quickbet: { ...defaultQuickbetData, keypadState },
      }
      Analytics.track(analyticsKeys.quickbetAccountSelected, dataToTrack)
    })

  const proposeBetSucceededSubscription = ProposeBetSucceeded.signal$
    .withLatestFrom(investmentState$, (proposeBetSucceeded, investmentState) => ({
      proposeBetSucceeded,
      investmentState,
    }))
    .subscribe(x => {
      const investmentState: InvestmentStateRecord = { ...x.investmentState.toJS() }
      const keypadState = investmentState[investmentState.activeInvestment].lastKeyPressed.mode

      const dataToTrack: AnalyticsBetAndQuickbetData = {
        bet: { ...defaultBetData, errorMessage: superPickErrorMessage, construction: 'quickbet' },
        quickbet: { ...defaultQuickbetData, keypadState },
      }
      Analytics.track(analyticsKeys.quickbetAccountSelected, dataToTrack)
    })

  // -------------------- Bet step 2 --------------------
  const confirmBetFailedSubscription = ConfirmBetFailed.signal$.subscribe(x => {
    const errorMessage = extractErrorMessage(x.data.response.type) ?? x.data.response.message
    const newBetData: AnalyticsBetData = {
      ...defaultBetData,
      errorMessage,
      construction: 'quickbet',
    }
    Analytics.track(analyticsKeys.quickbetAccountReview, { bet: newBetData })
  })

  // -------------------- Bet step 2 & 3 --------------------
  const confirmBetSucceededSubscription = ConfirmBetSucceeded.signal$
    .withLatestFrom(
      quickbetState$,
      launchDarklyState$,
      (confirmBetSucceeded, quickbetState, launchDarklyState) => ({
        confirmBetSucceeded,
        quickbetState,
        launchDarklyState,
      })
    )
    .subscribe(x => {
      // Step 2: Update data layer with reset bet data
      const newBetDataStep2: AnalyticsBetData = { ...defaultBetData, construction: 'quickbet' }
      Analytics.track(analyticsKeys.quickbetAccountReview, { bet: newBetDataStep2 })

      // Step 3: Update data layer with new bet information
      const { selectionDetails, selection, tags, betSource } =
        x.quickbetState.toJS() as QuickbetState

      const { ticketNumber, winInvestment, placeInvestment, betCost, specialOffers, bonusBet } =
        x.confirmBetSucceeded.data

      const { features } = x.launchDarklyState.toJS() as FeatureFlagState

      trackBetConfirmation(
        ticketNumber,
        selection,
        selectionDetails,
        specialOffers || [],
        'quickbet',
        betCost,
        winInvestment,
        placeInvestment,
        features,
        null,
        tags || null,
        betSource,
        bonusBet
      )
    })

  // Betslip bets being placed via Quickbet (FavNums etc)
  const confirmBetslipBetsSucceededSubscription = ConfirmBetslipBetsSucceeded.signal$
    .withLatestFrom(
      quickbetState$,
      launchDarklyState$,
      (confirmAllBetsSuccessful, quickbetState, launchDarklyState) => {
        const getSelection: BetslipAnalyticsSubscriberParams['getSelection'] = () => {
          const quickbetStateData = quickbetState.toJS() as QuickbetState
          return !quickbetStateData.selection || !quickbetStateData.selectionDetails
            ? null
            : [quickbetStateData.selection, quickbetStateData.selectionDetails]
        }
        return {
          confirmAllBetsSuccessful,
          launchDarklyState,
          getSelection,
          construction: 'quickbet',
        } as BetslipAnalyticsSubscriberParams
      }
    )
    .subscribe(betslipAnalyticsSubscriber)

  const addingToBetslipSubscription = AddingToBetslip.signal$
    .withLatestFrom(quickbetState$, (addToBetSlip, quickbetState) => ({
      addToBetSlip,
      quickbetState,
    }))
    .subscribe(x => {
      const {
        betSource: source,
        selectionDetails,
        selection,
      } = x.quickbetState.toJS() as QuickbetState<RaceDetails>

      const betType =
        (selection as ToteSelection).betType || (selection as FobPropositionSelection).type

      if (source) {
        const { meetingCode: raceCode, meetingName } = selectionDetails?.races[0] || {
          meetingName: null,
          meetingCode: null,
        }

        trackBetSlipAdd({ construction: 'quickbet', source, raceCode, meetingName, betType })
      }
    })

  return {
    dispose() {
      setInvalidInvestmentNotificationSubscription.dispose()
      quickbetLoadSelectionSubscription.dispose()
      quickbetClosedSubscription.dispose()
      proposeBetFailedSubscription.dispose()
      proposeBetSucceededSubscription.dispose()
      confirmBetFailedSubscription.dispose()
      confirmBetSucceededSubscription.dispose()
      confirmBetslipBetsSucceededSubscription.dispose()
      addingToBetslipSubscription.dispose()
    },
  }
}

// Types

type BetslipAnalyticsSubscriberParams = Parameters<typeof betslipAnalyticsSubscriber>[0]
