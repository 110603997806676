import React from 'react'
import styled from '@emotion/styled'
import { radius } from '@mobi/component-library/Theme/Common'
import { useAppDispatch } from '@core/Store/hooks'
import { setSelectedChannel, type SkyVideoPlayerState } from '@core/Areas/SkyVideoPlayer/Store'
import { SkyTvSvg } from '@mobi/component-library/Common/Svg/SkyTv/SkyTvSvg'
import { PlayerControlButtonStyled } from '../Buttons'
import { hexColors } from '@mobi/settings'

const enum LocalConstants {
  Sky2ActiveClass = 'js-sky-channels--sky2-active',
}

export const SkyChannelSelector: React.FC<{
  selectedChannel: SkyVideoPlayerState['selectedChannel']
}> = ({ selectedChannel }) => {
  const dispatch = useAppDispatch()

  return (
    <WrapperStyled>
      <PlayerSkyChannelButtonStyled
        onClick={e => {
          e.stopPropagation()
          dispatch(setSelectedChannel('Sky1'))
        }}
        data-testid='SkyChannelSelector.Sky1'
      >
        <SkyTvSvg channel='skyTv1' width='3.6rem' colorOverride='white' />
      </PlayerSkyChannelButtonStyled>

      <PlayerSkyChannelButtonStyled
        onClick={e => {
          e.stopPropagation()
          dispatch(setSelectedChannel('Sky2'))
        }}
        data-testid='SkyChannelSelector.Sky2'
      >
        <SkyTvSvg channel='skyTv2' width='3.6rem' colorOverride='white' />
      </PlayerSkyChannelButtonStyled>

      <span
        aria-hidden
        className={selectedChannel === 'Sky2' ? LocalConstants.Sky2ActiveClass : ''}
      />
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  boxSizing: 'border-box',
  flexShrink: 0,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: '3.6rem',
  padding: '0.5rem',
  background: 'rgba(0,0,0,0.6)',
  borderRadius: radius.md,

  '> button': {
    flexShrink: 0,
  },

  // Active indicator
  '> span': {
    position: 'absolute',
    left: '0.5rem',
    minWidth: '5rem',
    minHeight: '2.6rem',
    transition: '0.2s',
    background: hexColors.skyTvRed,
    borderRadius: radius.sm,

    [`&.${LocalConstants.Sky2ActiveClass}`]: {
      transform: 'translateX(5rem)',
      background: hexColors.skyTvBlue,
    },
  },
})

const PlayerSkyChannelButtonStyled = styled(PlayerControlButtonStyled)({
  position: 'relative',
  height: '2.6rem',
  width: '5rem',
  padding: 0,
  background: 0,
})
