import styled from '@emotion/styled'
import { colors, radius, shadow, spacing, font } from '@mobi/component-library/Theme/Common'

export const ContainerStyled = styled.div({
  backgroundColor: colors.white,
  border: `1px solid ${colors.neutral[200]}`,
  borderRadius: radius.md,
  boxShadow: shadow.xs,

  '> h2': {
    margin: 0,

    button: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${spacing.smx1} ${spacing.sm} ${spacing.smx1} ${spacing.md}`,
      background: 'unset',
      border: 0,

      '&:hover': { cursor: 'pointer' },
    },
  },
})

export const TitleStyled = styled.span({
  flex: 1,
  ...font.size.lg,
  color: colors.black,
  fontWeight: font.weight.medium,
  textAlign: 'left',
})

type WrapperProps = {
  isExpanded: boolean
  transitionMs: number
}
export const WrapperStyled = styled.div(({ isExpanded, transitionMs }: WrapperProps) => ({
  overflow: 'hidden',
  height: 0,
  opacity: isExpanded ? 1 : 0,
  borderTop: `0.05rem solid ${colors.neutral[200]}`,
  transition: `all ${transitionMs}ms ease`,
  willChange: 'height',
}))

export const ContentStyled = styled.div(() => ({
  padding: `${spacing.sm} ${spacing.md}`,
}))

type IconProps = {
  isExpanded: boolean
  transitionMs: number
}
export const IconStyled = styled.div(({ isExpanded, transitionMs }: IconProps) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: spacing.bigx3,
  width: spacing.bigx3,
  borderRadius: '50%',
  backgroundColor: isExpanded ? colors.studio[500] : colors.surface[50],
  transform: `rotate(${isExpanded ? '-180deg' : '0deg'})`,
  transition: `all ${transitionMs}ms ease`,
}))
