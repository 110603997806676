import { BetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'
import { post } from '@classic/Foundation/Services/ApiService'
import { BetType, AllUpSelectionDetails, AllUpFormula, ProductCode } from './Betting/selections'
import { Map } from 'immutable'
import { isBetslipBetRequest, BetslipBetRequest, BetslipResponse } from './betslip'
import { Campaign } from '@core/State/UserAccount/userAccountDriver'
import { placeKambiBet } from './kambiBetting'

export enum BettingType {
  FixedOddsRacing,
  FixedOddsSports,
  ToteRacing,
  ToteSports, // Tipping
  MysteryQuickPick, // 6 quick pick options
  MysteryCustomBet, // Custom Bet - not currently implemented
  FavouriteNumbers,
}

export enum BetLegType {
  Win = 'W',
  Place = 'P',
  WinPlace = 'WP',
  EachWay = 'EW',
  StartingPrice = 'SP',
}

export type BetRequest =
  | ToteRequest
  | AllUpRequest
  | RacingSingleRequest
  | FobSingleRequest
  | FobSingleSportsRequest
  | MysteryProposeRequest
  | MysteryCommitRequest
  | BetslipBetRequest
  | SameRaceMultiRequest

// ToteBetRequest
export interface ToteRequest {
  productPlanSeq: number
  fixtureId: string
  fixtureDate: string
  raceNumber: number
  selections: string
  investmentWin: number
  investmentPlace: number
  betTransactionId: string
  useBonusCash?: boolean
}

export function isToteRequest(value: BetRequest): value is ToteRequest {
  return (value as ToteRequest).selections !== undefined
}

// ToteAllUpBetRequest
export interface AllUpRequest {
  productPlanSeq: number
  fixtureId: string
  fixtureDate: string
  raceDetails: AllUpSelectionDetails[]
  formulas: AllUpFormula[]
  investment: number
  betTransactionId: string
}

export function isAllUpRequest(value: BetRequest): value is AllUpRequest {
  return (value as AllUpRequest).formulas !== undefined
}

export interface ToteBetResponse {
  ticketNumber: number
  betCost: number
  betPlacedTime: Date
  accountBalance: number
  divXAnnotations: string
  numberOfCombinations: number
  campaignActivatedInd: boolean
  bonusBet: number
  amountPaid: number
}

// tote mystery proposal
export interface MysteryProposeRequest {
  fixtureDate: string
  fixtureId: string
  raceNumber: number
  productCode: string
  option: number
  numberOfBets: number
  investmentWin?: number
  investmentPlace?: number
  useBonusCash?: boolean
}

export function isMysteryProposalRequest(value: BetRequest): value is MysteryProposeRequest {
  return (value as MysteryProposeRequest).option !== undefined
}

export interface MysteryProposeResponse {
  accountBalance: number
  metaData: string
  bonusBalanceAvailable: number
  bets: MysteryProposeBet[]
}

export interface MysteryProposeBet {
  id: number
  betCost: number
  divXAnnotations: string
  metaData: string
  betLines: MysteryProposeBetLine[]
  productCode: keyof typeof ProductCode
  isFlexi: boolean
}

export interface MysteryProposeBetLine {
  dividend: number
  poolCode: string
  selections: string
  isAllways: boolean
  legs: MysteryProposeBetLineLeg[]
}

export interface MysteryProposeBetLineLeg {
  legNumber: number
  raceNumber: number
  acceptors: MysteryProposeAcceptor[]
}

export interface MysteryProposeAcceptor {
  name: string
  number: number
}

// tote mystery commit
export interface MysteryCommitRequest {
  proposalIds: number[]
  metaData: string
  useBonusCash?: boolean
}

export function isMysteryCommitRequest(value: BetRequest): value is MysteryCommitRequest {
  return (value as MysteryCommitRequest).proposalIds !== undefined
}

export function isMysteryCommitResponse(
  value: ToteBetResponse | MysteryCommitResponse | FobCommitResponse
): value is MysteryCommitResponse {
  return (value as MysteryCommitResponse).errorMessage !== undefined
}

export interface MysteryCommitResponse {
  accountBalance: number
  campaignActivatedInd: boolean
  bets: ToteBetResponse[]
  errorMessage: string | null
  errorReason: string | null
}

export function isFobSingleRacingRequest(value: BetRequest): value is FobSingleRacingRequest {
  return (value as FobSingleRacingRequest).propositionSeq !== undefined
}
export function isFobSingleSportsRequest(value: BetRequest): value is FobSingleSportsRequest {
  return (value as FobSingleSportsRequest).externalBetId !== undefined
}
export function isFobSingleKambiSportsRequest(value: BetRequest): value is FobSingleSportsRequest {
  return isFobSingleSportsRequest(value) && value.externalBetId.startsWith('KAMBI_')
}

interface FobSingleBase {
  responseVersion: number
  investmentWin?: number | null
  investmentPlace?: number | null
  priceWin?: number | null
  pricePlace?: number | null
  betTransactionId: string
  specialSeq?: number | null
  tags?: string[]
  selectedCampaign?: Campaign
}
// FobSingleRacingBetRequest
export interface FobSingleRacingRequest extends FobSingleBase {
  propositionSeq: string
  variantSeq?: string | null
  legType: BetLegType
  handicap?: number | null
}

// FobSingleSportsRequest
export interface FobSingleSportsRequest extends FobSingleBase {
  externalBetId: string
}

export type FobSingleRequest = FobSingleSportsRequest | FobSingleRacingRequest

export function isRacingSingleRequest(value: BetRequest): value is RacingSingleRequest {
  return (
    (value as RacingSingleRequest).starterNumber !== undefined ||
    (value as RacingSingleRequest).propositionCode !== undefined
  )
}

// FobMatchedRacingBetRequest
export interface RacingSingleRequest {
  responseVersion: number
  fixtureDate: string
  fixtureId: string
  raceNumber: number
  legType: BetLegType
  priceWin: number | null
  pricePlace: number | null
  investmentWin: number
  investmentPlace: number
  starterNumber?: number | null
  specialSeq?: number | null
  propositionCode?: string | null
  betTransactionId: string
  selectedCampaign?: Campaign
}

export function isSameRaceMultiBetRequest(request: BetRequest): request is SameRaceMultiRequest {
  return !!(request as SameRaceMultiRequest).selectionPrice
}

export interface SameRaceMultiSelection {
  starter: number
  position: number
}

export interface SameRaceMultiRequest {
  responseVersion: number
  fixtureDate: string
  fixtureId: string
  raceNumber: number
  selectionPrice: number
  selections: SameRaceMultiSelection[]
  investment: number
  specialSeq?: number | null
  betTransactionId: string
  selectedCampaign?: Campaign
}

export interface BetPrices {
  winPrice?: number
  placePrice?: number | null
  previousWinPrice?: number | null
  previousPlacePrice?: number | null
}

/**
 * @deprecated Stop using enums, they offer a bad DX, reverse lookups are unreliable at best and getting keys from them is near impossible. Use literals instead, or use a mapping
 */
export enum BetErrorType {
  Unspecified = 0,
  Unauthorized = 1,
  InvalidBet = 2,
  IncorrectNumberOfLegs = 3,
  InsufficientFunds = 4,
  PricesChanged = 5,
  HandicapChanged = 6,
  SpecialsError = 7,
  Closed = 8,
  BetPlacementFault = 10,
  DuplicateBonusBet = 11,
  InvalidBonusBet = 12,
}

export type BetErrorTypeLiteral =
  | 'Unspecified'
  | 'Unauthorized'
  | 'InvalidBet'
  | 'IncorrectNumberOfLegs'
  | 'InsufficientFunds'
  | 'PricesChanged'
  | 'HandicapChanged'
  | 'SpecialsError'
  | 'Closed'
  | 'BetPlacementFault'
  | 'DuplicateBonusBet'
  | 'InvalidBonusBet'

const betErrorTypeMap = Map<BetErrorType, BetErrorTypeLiteral>([
  [BetErrorType.Unspecified, 'Unspecified'],
  [BetErrorType.Unauthorized, 'Unauthorized'],
  [BetErrorType.InvalidBet, 'InvalidBet'],
  [BetErrorType.IncorrectNumberOfLegs, 'IncorrectNumberOfLegs'],
  [BetErrorType.InsufficientFunds, 'InsufficientFunds'],
  [BetErrorType.PricesChanged, 'PricesChanged'],
  [BetErrorType.HandicapChanged, 'HandicapChanged'],
  [BetErrorType.SpecialsError, 'SpecialsError'],
  [BetErrorType.Closed, 'Closed'],
  [BetErrorType.BetPlacementFault, 'BetPlacementFault'],
  [BetErrorType.DuplicateBonusBet, 'DuplicateBonusBet'],
  [BetErrorType.InvalidBonusBet, 'InvalidBonusBet'],
])

export function ensureBetErrorTypeLiteral(
  betErrorType: string | number | BetErrorType
): BetErrorTypeLiteral {
  if (typeof betErrorType === 'string') {
    // We can't be sure this is a BetErrorTypeLiteral, but for compatibility reasons it's better to assume it is
    return betErrorType as BetErrorTypeLiteral
  }

  if (betErrorTypeMap.has(betErrorType)) {
    return betErrorTypeMap.get(betErrorType)
  }

  throw new Error(`Unable to map ${betErrorType} as a literal value`)
}

export const SingleErrorMapping = Map<BetErrorType, string>([
  [BetErrorType.HandicapChanged, 'Handicap changed. Bet is no longer valid.'],
  [BetErrorType.InvalidBonusBet, 'Error using Bonus Bet'],
  [BetErrorType.DuplicateBonusBet, 'Bonus Bet can only be used once'],
])

export const MultiErrorMapping = Map<string, string>([
  [BetErrorType[BetErrorType.InvalidBonusBet], 'Error using Bonus Bet'],
  [BetErrorType[BetErrorType.DuplicateBonusBet], 'Bonus Bet can only be used once'],
])

export const MultiBetFatalErrorMapping = Map<BetErrorType, string>([
  [BetErrorType.HandicapChanged, 'HANDICAP'],
  [BetErrorType.Closed, 'CLOSED'],
])

export interface BetError {
  type: string
  message: string | null
  accountBalance: number
  prices?: BetPrices
}

// shared with fob single and SRM
export interface FobProposeResponse {
  prices: BetPrices
  accountBalance?: number
  specialOffers?: BetSpecialOffer[]
}

// shared with fob single and SRM
export interface FobCommitResponse {
  accountBalance?: number
  ticketNumber: number
  projectedPlacePayout: number
  projectedWinPayout: number
  placeInvestment: number
  winInvestment: number
  betPlacedTime: Date
  campaignActivatedInd: boolean
  campaignId: number | null
  campaignType: string | null
  bonusBet: number
  amountPaid: number
  hasSpecialOffer: boolean
  specialOffers: BetSpecialOffer[]
}

export interface FobSingleErrorResponse {
  code: BetResponseCode
  response: {
    message: string
    accountBalance?: number
    prices?: BetPrices
  }
}

export interface SuperPicksResponse {
  specialOffers: BetSpecialOffer[]
}

export interface SuperPicksErrorResponse {
  response: {
    message: string
  }
}

export interface GetSuperPicksRequest {
  FixtureDate?: string
  FixtureId?: string
  RaceNumber?: number
  StarterNumber?: number
  PropositionSeq?: string
}

export enum BetResponseCode {
  UnknownError,
  Unauthorized,
  InsufficientFunds,
  PriceChanged,
  HandicapChanged,
  NetworkError,
}

const httpStatusToBetResponseCode: { [statusCode: number]: BetResponseCode | undefined } = {
  401: BetResponseCode.Unauthorized,
  402: BetResponseCode.InsufficientFunds,

  // TODO: re-purposing standard http error codes seems very brittle? better to use the API generated error code (Type property) instead?
  406: BetResponseCode.PriceChanged, // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/406
  417: BetResponseCode.HandicapChanged, // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/417
}

export const networkErrorMessage = 'Network Error'

export function proposeBet(
  request: BetRequest
): Promise<
  FobProposeResponse | ToteBetResponse | MysteryProposeResponse | BetslipResponse[] | null
> {
  // order is VERY important because there is no runtime type guard/descriptor implemented.. instead XxxRequest relies on the presence of 'special fields' of which there is no guarantee of uniqueness
  if (isMysteryProposalRequest(request)) {
    return quickbetMysteryPropose(request)
  }
  if (isFobSingleKambiSportsRequest(request)) {
    return quickbetFobProposeKambiSingle(request)
  }
  if (isFobSingleRacingRequest(request) || isFobSingleSportsRequest(request)) {
    return quickbetFobProposeSingle(request)
  }
  if (isSameRaceMultiBetRequest(request)) {
    return quickbetSameRaceMultiPropose(request)
  }
  if (isToteRequest(request)) {
    return quickbetTotePropose(request)
  }
  if (isAllUpRequest(request)) {
    return quickbetAllUpPropose(request)
  }
  if (isRacingSingleRequest(request)) {
    return quickbetRacingProposeSingle(request)
  }
  if (isBetslipBetRequest(request)) {
    return betslipPropose(request)
  }
  return Promise.resolve(null)
}

// order is VERY important because there is no runtime type guard/descriptor implemented.. instead XxxRequest relies on the presence of 'special fields' of which there is no guarantee of uniqueness
export function confirmBet(
  request: BetRequest
): Promise<FobCommitResponse | ToteBetResponse | MysteryCommitResponse | BetslipResponse[] | null> {
  if (isMysteryCommitRequest(request)) {
    return quickbetMysteryCommit(request)
  }
  if (isFobSingleKambiSportsRequest(request)) {
    return quickbetFobCommitKambiSingle(request)
  }
  if (isFobSingleRacingRequest(request) || isFobSingleSportsRequest(request)) {
    return quickbetFobCommitSingle(request)
  }
  if (isSameRaceMultiBetRequest(request)) {
    return quickbetSameRaceMultiCommit(request)
  }
  if (isToteRequest(request)) {
    return quickbetToteCommit(request)
  }
  if (isAllUpRequest(request)) {
    return quickbetAllUpCommit(request)
  }
  if (isRacingSingleRequest(request)) {
    return quickbetRacingCommitSingle(request)
  }
  if (isBetslipBetRequest(request)) {
    return betslipCommit(request)
  }

  return Promise.resolve(null)
}

export function betslipCommit(request: BetslipBetRequest): Promise<BetslipResponse[]> {
  return placeBet<BetslipBetRequest, BetslipResponse[]>('/api/betslip/commit', request)
}

export function betslipPropose(request: BetslipBetRequest): Promise<BetslipResponse[]> {
  return placeBet<BetslipBetRequest, BetslipResponse[]>('/api/betslip/propose', request)
}

export function betslipRefresh(request: BetslipBetRequest): Promise<BetslipResponse[]> {
  return placeBet<BetslipBetRequest, BetslipResponse[]>('/api/betslip/refresh', request)
}

function quickbetTotePropose(request: ToteRequest): Promise<ToteBetResponse> {
  return placeBet<ToteRequest, ToteBetResponse>('/api/betting/tote/propose', request)
}

function quickbetAllUpPropose(request: AllUpRequest): Promise<ToteBetResponse> {
  return placeBet<AllUpRequest, ToteBetResponse>('/api/betting/tote/propose/allup', request)
}

function quickbetMysteryPropose(request: MysteryProposeRequest): Promise<MysteryProposeResponse> {
  return placeBet<MysteryProposeRequest, MysteryProposeResponse>(
    '/api/betting/tote/propose/mystery',
    request
  )
}

function quickbetRacingProposeSingle(request: RacingSingleRequest): Promise<FobProposeResponse> {
  return placeBet<RacingSingleRequest, FobProposeResponse>(
    '/api/betting/fob/propose/racing-single',
    request
  )
}

function quickbetFobProposeKambiSingle(
  request: FobSingleSportsRequest
): Promise<FobProposeResponse> {
  // kambi has no propose step, so just return the request data
  return Promise.resolve({
    prices: {
      winPrice: request.priceWin ?? 0,
      placePrice: request.pricePlace,
    },
  })
}

function quickbetFobProposeSingle(
  request: FobSingleRequest | FobSingleSportsRequest
): Promise<FobProposeResponse> {
  return placeBet<FobSingleRequest | FobSingleSportsRequest, FobProposeResponse>(
    '/api/betting/fob/propose/single',
    request
  )
}

function quickbetFobCommitKambiSingle(request: FobSingleSportsRequest): Promise<FobCommitResponse> {
  return placeKambiBet(request)
}

function quickbetFobCommitSingle(
  request: FobSingleRequest | FobSingleSportsRequest
): Promise<FobCommitResponse> {
  return placeBet<FobSingleRequest | FobSingleSportsRequest, FobCommitResponse>(
    '/api/betting/fob/commit/single',
    request
  )
}
function quickbetSameRaceMultiPropose(request: SameRaceMultiRequest): Promise<FobProposeResponse> {
  return placeBet<SameRaceMultiRequest, FobProposeResponse>(
    '/api/betting/fob/propose/same-race-multi',
    request
  )
}

function quickbetSameRaceMultiCommit(request: SameRaceMultiRequest): Promise<FobCommitResponse> {
  return placeBet<SameRaceMultiRequest, FobCommitResponse>(
    '/api/betting/fob/commit/same-race-multi',
    request
  )
}

function quickbetAllUpCommit(request: AllUpRequest): Promise<ToteBetResponse> {
  return placeBet<AllUpRequest, ToteBetResponse>('/api/betting/tote/commit/allup', request)
}

function quickbetToteCommit(request: ToteRequest): Promise<ToteBetResponse> {
  return placeBet<ToteRequest, ToteBetResponse>('/api/betting/tote/commit', request)
}

function quickbetMysteryCommit(request: MysteryCommitRequest): Promise<MysteryCommitResponse> {
  return placeBet<MysteryCommitRequest, MysteryCommitResponse>(
    '/api/betting/tote/commit/mystery',
    request
  )
}

function quickbetRacingCommitSingle(request: RacingSingleRequest): Promise<FobCommitResponse> {
  return placeBet<RacingSingleRequest, FobCommitResponse>(
    '/api/betting/fob/commit/racing-single',
    request
  )
}

function placeBet<TRequest, TResponse>(url: string, request: TRequest): Promise<TResponse> {
  return post<TResponse>({ url, body: request }).catch(async error => {
    if (!error.response) {
      throw {
        code: BetResponseCode.NetworkError,
        response: { message: networkErrorMessage },
      }
    }
    const errorResponse = await error.response.json()
    throw {
      code: httpStatusToBetResponseCode[error.response.status] || BetResponseCode.UnknownError,
      response: errorResponse,
    }
  })
}

export function getSuperPicks(request: GetSuperPicksRequest): Promise<SuperPicksResponse> {
  return post<SuperPicksResponse>({ url: '/api/specials/superpicksversion2', body: request }).catch(
    async error => {
      const errorResponse = await error.response.json()
      throw {
        response: errorResponse,
      }
    }
  )
}

export enum PlanSeq {
  Unknown = 0,
  Win = 1,
  Place = 7,
  WinAndPlace = 8,

  Exacta = 27,
  ExactaWithRovingBanker = 122,

  Quinella = 19,

  Trifecta = 11,
  TrifectaWithRovingBanker = 123,

  First4 = 35,
  First4WithRovingBanker = 124,

  Double = 43,
  MysteryDouble1 = 44,
  MysteryDouble2 = 45,
  MysteryDouble3 = 46,
  MysteryDouble4 = 47,
  MysteryDouble5 = 48,
  MysteryDouble6 = 49,
  MysteryDouble7 = 50,

  Quaddie = 51,
  MysteryQuaddie1 = 52,
  MysteryQuaddie2 = 53,
  MysteryQuaddie3 = 54,
  MysteryQuaddie4 = 55,
  MysteryQuaddie5 = 56,
  MysteryQuaddie6 = 57,
  MysteryQuaddie7 = 58,

  Mystery$3Combo = 60,
  Mystery$10Combo = 61,

  AllUp = 91,

  FavouriteNumbers = 64,

  Footo = 89,
  FootyTipping = 92,
  SportsTipping = 93,

  FOBRacing = 94,

  FOBSports = 110,
}

export function getToteProductPlanSequence(
  betType: BetType,
  isRefreshing: boolean,
  isRovingBanker: boolean = false,
  hasWinInvestment: boolean = false,
  hasPlaceInvestment: boolean = false
): number {
  switch (betType) {
    case BetType.WinPlace: {
      if (isRefreshing) {
        return PlanSeq.Win
      }
      if (hasWinInvestment && hasPlaceInvestment) {
        return PlanSeq.WinAndPlace
      }
      if (hasWinInvestment) {
        return PlanSeq.Win
      }
      if (hasPlaceInvestment) {
        return PlanSeq.Place
      }
      break
    }
    case BetType.Exacta: {
      return isRovingBanker ? PlanSeq.ExactaWithRovingBanker : PlanSeq.Exacta
    }
    case BetType.Quinella: {
      return PlanSeq.Quinella
    }
    case BetType.Trifecta: {
      return isRovingBanker ? PlanSeq.TrifectaWithRovingBanker : PlanSeq.Trifecta
    }
    case BetType.First4: {
      return isRovingBanker ? PlanSeq.First4WithRovingBanker : PlanSeq.First4
    }
    case BetType.Double: {
      return PlanSeq.Double
    }
    case BetType.Quaddie: {
      return PlanSeq.Quaddie
    }
    case BetType.AllUp: {
      return PlanSeq.AllUp
    }
  }
  throw new Error('Unsupported bet type')
}
