import React from 'react'
import type { DepositRequest } from '@mobi/api-types'
import { unwrapErrorMessage } from '@mobi/utils'
import { useApplePayDeposit, useBraintreeClient } from '../../../Hooks'
import { DepositError } from '../../../Errors'
import { ApplePayButton } from '@mobi/component-library/Deposit/Buttons/ApplePay'
import type { PanelProps } from '../../types'
import { DepositRequestWithoutDeviceData } from '@mobi/deposit/Hooks/useDeposit'

export const ApplePayButtonWeb = ({
  initialData: { transactionId, clientToken },
  depositAmount,
  isDepositAllowed,
  onStart,
  onCancel,
  onDepositing,
  onSuccess,
  onFailure,
}: PanelProps) => {
  const { isReady, client } = useBraintreeClient(clientToken)
  const { tokenize, deposit } = useApplePayDeposit({ braintreeClient: client })

  if (!isReady || !client) {
    return <h1>Error</h1>
  }

  const onApplePayConfirmed = async (data: DepositRequestWithoutDeviceData<DepositRequest>) => {
    onDepositing()

    try {
      const { isSuccess, ...errorDetails } = await deposit(data)

      if (isSuccess) {
        onSuccess(depositAmount)
      } else {
        onFailure(DepositError.fromErrorDetails(errorDetails))
      }
    } catch (error) {
      onFailure(DepositError.coerce(error, transactionId))
    }
  }

  const onApplePayButtonClicked = async () => {
    onStart()

    const canDeposit = await isReady()

    if (!canDeposit || !client.current) {
      onCancel?.()
      return
    }

    try {
      await tokenize({
        braintreeClient: client,
        depositAmount,
        transactionId,
        onSuccess: onApplePayConfirmed,
        onCancel,
        onError: error => {
          onFailure(
            new DepositError(
              'tokenization_failed',
              transactionId,
              undefined,
              unwrapErrorMessage(error)
            )
          )
        },
      })
    } catch {
      onFailure(
        new DepositError(
          'unable_load_payment_method',
          transactionId,
          undefined,
          'Apple Pay failed to load'
        )
      )
    }
  }

  return (
    <ApplePayButton
      disabled={!isDepositAllowed}
      aria-label='Deposit using Apple Pay'
      onClick={onApplePayButtonClicked}
    />
  )
}
