import {
  BetRequest,
  proposeBet,
  confirmBet,
  isMysteryCommitResponse,
  MysteryCommitResponse,
  ToteBetResponse,
  FobCommitResponse,
  FobSingleErrorResponse,
} from '@core/Data/betting'
import {
  ConfirmBetFailed,
  ConfirmBetSucceeded,
  ProposeBetFailed,
  ProposeBetSucceeded,
  ProposeBet,
  ConfirmBet,
  ConfirmBetslipBetsFailed,
  ConfirmBetslipBetsSucceeded,
  ProposeBetslipBetsSucceeded,
  SetBonusBet,
  ToggleBonusBetUsage,
} from './signals'
import { UpdateAccountBalance } from '@core/State/UserAccount/userAccountDriver'
import { fetchCampaignsAsync } from '@core/State/UserAccount/async-signals'
import { BetslipResponse, isBetslipResponses } from '@core/Data/betslip'
import { trackOptimoveEvent } from '@core/Services/Optimove/optimove'

/**
 * Propose the given bet. If successful
 */
export async function proposeBetAsync(request: BetRequest): Promise<number | undefined> {
  ProposeBet()
  try {
    const response = await proposeBet(request)

    if (!response) {
      return undefined
    }

    if (Array.isArray(response)) {
      return handleBetslipProposeResponses(response)
    }

    response.accountBalance && UpdateAccountBalance({ balance: response.accountBalance })
    ProposeBetSucceeded(response)

    return response.accountBalance
  } catch (error) {
    ProposeBetFailed(error as FobSingleErrorResponse)
    throw error
  }
}

export async function confirmBetAsync(request: BetRequest): Promise<void> {
  ConfirmBet()
  try {
    let response = await confirmBet(request)
    if (response) {
      if (isBetslipResponses(response)) {
        handleBetslipCommitResponses(response)
      } else {
        handleQuickbetResponse(response)
      }
    }
  } catch (err) {
    const error = err as FobSingleErrorResponse
    if (error.response && error.response.accountBalance) {
      UpdateAccountBalance({ balance: error.response.accountBalance })
    }
    ConfirmBetFailed(error)
  }
}

function handleBetslipProposeResponses(response: BetslipResponse[]) {
  const accountBalance = response[0]?.accountBalance

  if (accountBalance) {
    UpdateAccountBalance({ balance: accountBalance })
  }

  ProposeBetslipBetsSucceeded()

  return accountBalance
}

function handleBetslipCommitResponses(response: BetslipResponse[]) {
  const successfulResponses = response.filter(r => r.success)

  // all failed
  if (successfulResponses.length === 0) {
    const errorResponses = response.flatMap(r => (r.error ? [r.error] : []))
    ConfirmBetslipBetsFailed(errorResponses)
    return
  }

  const successfulResponsesByDateDescending = successfulResponses.sort(
    (a, b) =>
      new Date(b.receipt?.betPlacedTime ?? 0).getTime() -
      new Date(a.receipt?.betPlacedTime ?? 0).getTime()
  )

  const lastReceipt = successfulResponsesByDateDescending[0].receipt
  if (lastReceipt) {
    UpdateAccountBalance({
      balance: lastReceipt.accountBalance,
    })
    if (lastReceipt.campaignActivatedInd) {
      fetchCampaignsAsync()

      trackOptimoveEvent({
        eventName: 'redeemed_bonus_offer',
      })
    }
  }

  ConfirmBetslipBetsSucceeded(response)
}

function handleQuickbetResponse(
  response: ToteBetResponse | MysteryCommitResponse | FobCommitResponse
) {
  if (response.accountBalance !== undefined) {
    UpdateAccountBalance({ balance: response.accountBalance })
  }

  if (isMysteryCommitResponse(response)) {
    response = buildMysteryConfirmBetSucceededResponse(response as MysteryCommitResponse)
  }

  ConfirmBetSucceeded(response)
  if (response.campaignActivatedInd) {
    fetchCampaignsAsync()

    trackOptimoveEvent({
      eventName: 'redeemed_bonus_offer',
    })
  }

  SetBonusBet(null)
  ToggleBonusBetUsage(false)
}

function buildMysteryConfirmBetSucceededResponse(
  mysteryResponse: MysteryCommitResponse
): ToteBetResponse {
  const bet = mysteryResponse.bets[0]
  bet.accountBalance = mysteryResponse.accountBalance
  bet.campaignActivatedInd = mysteryResponse.campaignActivatedInd

  return bet
}
