import { trackEvent, trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { debounceFn } from '@mobi/utils/functions'
import { SkyVideoPlayerState } from './Store'

export const trackVisionOpened = (
  opts:
    | { openLocation: 'bottom-navigation'; headerState?: never }
    | { openLocation: 'race-card'; headerState: 'sticky' | 'default' }
    | { openLocation: 'sky-page' }
) => {
  trackEvent(analyticsKeys.visionOpened, opts)
}

export const trackVisionClosed = () => {
  trackKey(analyticsKeys.visionClosed)
}

export const trackFullScreenOn = debounceFn(() => {
  trackKey(analyticsKeys.visionFullScreenOn)
}, 1000)

export const trackFullScreenOff = debounceFn(() => {
  trackKey(analyticsKeys.visionFullScreenOff)
}, 1000)

export const trackVisionUnMute = debounceFn(() => {
  trackKey(analyticsKeys.visionUnMuted)
}, 1000)

export const trackVisionMute = debounceFn(() => {
  trackKey(analyticsKeys.visionMuted)
}, 1000)

export const trackSelectedChannel = (channel: SkyVideoPlayerState['selectedChannel']) => {
  channel === 'Sky1'
    ? trackKey(analyticsKeys.visionSky1Selected)
    : trackKey(analyticsKeys.visionSky2Selected)
}
