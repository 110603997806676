import React from 'react'
import { useHistory } from 'react-router-dom'

import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { InputField } from '@mobi/component-library/Common/Input'
import { ErrorMessage } from '@core/Components/Messages'
import { NoticeBoxSingle, NoticeBoxTypes } from '@core/Components/NoticeBox'

import { Loading, Error } from './BankAccountComponents'
import { BankAccountContainerStyled, BankBranchStyled, BankNameStyled } from './BankAccount.styles'
import {
  isBankAccountRegistered,
  useBankDetailsQuery,
  postUpdateBankAccount,
  BankAccountResponse,
  useBankDetailsFromBsbQuery,
  isBankDetailsFromBsbFound,
} from './BankAccountApi'
import { WithdrawalRoute } from '@core/Areas/Withdraw/constants'
import { BankAccountRoute } from './constants'
import { usePrevious } from '@core/Utils/hooks/usePrevious'

type EntryFormBankDetails = Pick<BankAccountResponse, 'bsb' | 'accountName' | 'accountNumber'>
type ReadOnlyFormBankDetails = Pick<BankAccountResponse, 'bankName' | 'branchName'>

export const BankAccountEdit = (): JSX.Element | null => {
  const [readOnlyForm, setReadOnlyForm] = React.useState<ReadOnlyFormBankDetails>({
    bankName: '',
    branchName: '',
  })

  const [entryForm, setEntryForm] = React.useState<EntryFormBankDetails>({
    bsb: '',
    accountName: '',
    accountNumber: '',
  })

  const [isShowErrorOnSave, setIsShowErrorOnSave] = React.useState<boolean>(false)
  const [errorBsb, setErrorBsb] = React.useState<string | boolean>(false)
  const [errorAccountName, setErrorAccountName] = React.useState<string | boolean>(false)
  const [errorAccountNumber, setErrorAccountNumber] = React.useState<string | boolean>(false)
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false)

  const history = useHistory()

  const {
    isLoading: bankDetailsIsLoading,
    isError: bankDetailsIsError,
    data: bankDetailsData,
  } = useBankDetailsQuery()

  const {
    data: bankDetailsFromBsbData,
    refetch: bankDetailsFromBsbRefetch,
    isFetched: bankDetailsFromBsbIsFetched,
  } = useBankDetailsFromBsbQuery(entryForm.bsb)

  const previousEntryFormBsb = usePrevious(entryForm.bsb)

  const isBsbRefetch =
    entryForm.bsb !== '' && previousEntryFormBsb !== entryForm.bsb && isValidBsb(entryForm.bsb)

  React.useEffect(() => {
    if (isBankAccountRegistered(bankDetailsData) && bankDetailsData !== undefined) {
      setReadOnlyForm({
        bankName: bankDetailsData.bankName,
        branchName: bankDetailsData.branchName,
      })

      setEntryForm({
        bsb: bankDetailsData.bsb,
        accountName: bankDetailsData.accountName,
        accountNumber: bankDetailsData.accountNumber,
      })
    }
  }, [bankDetailsData])

  React.useEffect(() => {
    if (!bankDetailsFromBsbIsFetched) {
      return
    }

    if (isBankDetailsFromBsbFound(bankDetailsFromBsbData) && bankDetailsFromBsbData.isSuccessful) {
      setReadOnlyForm({
        bankName: bankDetailsFromBsbData.bankName,
        branchName: bankDetailsFromBsbData.branchName,
      })
    } else {
      setReadOnlyForm({ bankName: '-', branchName: '-' })
      setErrorBsb('BSB not found')
    }
  }, [bankDetailsFromBsbData, bankDetailsFromBsbIsFetched])

  React.useEffect(() => {
    if (isBsbRefetch) {
      bankDetailsFromBsbRefetch()
    }
  }, [isBsbRefetch, bankDetailsFromBsbRefetch])

  const isSubmitDisabled =
    !!errorBsb ||
    !!errorAccountName ||
    !!errorAccountNumber ||
    entryForm.bsb.trim().length === 0 ||
    entryForm.accountNumber.trim().length === 0 ||
    entryForm.accountName.trim().length === 0 ||
    isSubmitted

  const submitForm = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    if (isSubmitDisabled) {
      return
    }

    setIsSubmitted(true)

    postUpdateBankAccount({
      accountName: entryForm.accountName,
      accountNumber: entryForm.accountNumber,
      bsb: entryForm.bsb,
      bankName: readOnlyForm.bankName,
      branchName: readOnlyForm.branchName,
    })
      .then(response => {
        if (response.isSuccessful) {
          history.push(getSuccessfulBackRoute(history.location.search))
        } else {
          setIsShowErrorOnSave(true)
          setIsSubmitted(false)
        }
      })
      .catch(() => {
        setIsShowErrorOnSave(true)
        setIsSubmitted(false)
      })
  }

  if (bankDetailsIsLoading || isSubmitted) {
    return <Loading />
  }

  if (bankDetailsIsError) {
    return <Error />
  }

  return (
    <MainContainer data-tid-bank-account-container=''>
      <ContentHeader title='Edit Bank Account' />
      {isShowErrorOnSave && (
        <ErrorMessage>Unable to save bank details. Please try again later.</ErrorMessage>
      )}
      <BankAccountContainerStyled>
        <form
          action=''
          noValidate={true}
          data-tid-update-bank-account-form=''
          onSubmit={submitForm}
        >
          <BankNameStyled data-tid-bank-name=''>{readOnlyForm.bankName}</BankNameStyled>
          <BankBranchStyled data-tid-branch-name=''>{readOnlyForm.branchName}</BankBranchStyled>

          <InputField
            type='text'
            placeholder='BSB'
            value={entryForm.bsb}
            testId='bsb'
            name='BSB'
            required={true}
            maxLength={7}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const inputValue = e.currentTarget.value
              const formattedBsb = formatBsb(inputValue)
              setEntryForm({ ...entryForm, bsb: formattedBsb })
              setErrorBsb(isValidBsb(inputValue) ? false : 'BSB format is 123-456')
            }}
            errorMessage={errorBsb}
          />

          <InputField
            type='text'
            placeholder='Account Name'
            maxLength={40}
            value={entryForm.accountName}
            testId='account-name'
            name='Account Name'
            required={true}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const value = e.currentTarget.value
              setEntryForm({ ...entryForm, accountName: value })
              setErrorAccountName(
                isValidAccountName(value, bankDetailsData?.accountHolderSurname || '')
                  ? false
                  : 'Invalid Account Name'
              )
            }}
            errorMessage={errorAccountName}
          />
          <InputField
            type='text'
            placeholder='Account Number'
            value={entryForm.accountNumber}
            testId='account-number'
            name='Account Number'
            required={true}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const value = e.currentTarget.value
              setEntryForm({ ...entryForm, accountNumber: value })
              setErrorAccountNumber(isValidAccountNumber(value) ? false : 'Invalid Account Number')
            }}
            errorMessage={errorAccountNumber}
          />

          {bankDetailsData?.isPendingWithdrawals && (
            <NoticeBoxSingle
              title='You currently have a pending withdrawal which will be directed to this bank account. If you change these details, all future withdrawals will be directed to your new bank account.'
              noticeBoxType={NoticeBoxTypes.Warning}
              testId='bank-change-pending-withdrawal'
            />
          )}

          <Grid margin='auto'>
            <GridRow>
              <GridCell padding='0.5rem' valign='middle'>
                <ButtonBlock
                  type='reset'
                  onClick={() => history.goBack()}
                  color='secondary'
                  testId='cancel-update-bank-btn'
                >
                  Cancel
                </ButtonBlock>
              </GridCell>
              <GridCell padding='0.5rem' valign='middle'>
                <ButtonBlock
                  type='submit'
                  onClick={() => void 0}
                  color='primary'
                  testId='update-bank-btn'
                  disabled={isSubmitDisabled}
                >
                  Save
                </ButtonBlock>
              </GridCell>
            </GridRow>
          </Grid>
        </form>
      </BankAccountContainerStyled>
    </MainContainer>
  )
}

const isValidBsb = (bsb: string): boolean => {
  const validBsbRegExp = new RegExp(/^\d{3}-?\d{3}$/)
  return validBsbRegExp.test(bsb)
}

const formatBsb = (bsb: string): string => {
  if (!isValidBsb(bsb)) {
    return bsb
  }

  const validExactBsbRegExp = new RegExp(/^\d{3}-{1}\d{3}$/)
  return validExactBsbRegExp.test(bsb) ? bsb : bsb.substring(0, 3) + '-' + bsb.substring(3, 6)
}

const isValidAccountName = (accountName: string, accountHolderSurname: string): boolean => {
  const validAccountNameRegExp = /^['\-\sa-zA-Z]+$/
  return (
    validAccountNameRegExp.test(accountName) &&
    accountName.toLowerCase().includes(accountHolderSurname.toLowerCase())
  )
}

const isValidAccountNumber = (accountNumber: string): boolean => {
  const validAccountNumberRegExp = /^[0-9]{2,9}$/
  return validAccountNumberRegExp.test(accountNumber)
}

const getSuccessfulBackRoute = (locationSearch: string) => {
  const urlParams = new URLSearchParams(locationSearch)
  const isPreviousViewWithdrawal = urlParams.get('fromView') === 'Withdraw'
  const route = isPreviousViewWithdrawal ? WithdrawalRoute : BankAccountRoute
  return `/${route}`
}
