import React from 'react'
import { CreditCardButtonProps } from '@mobi/component-library/Deposit/Buttons/types'
import { customerMustUseThreeDSecure } from '../../../Utils/helpers'
import { DepositError } from '../../../Errors'
import type { PanelProps } from '../../types'
import { useBraintreeClient, useCreditCardDeposit } from '@mobi/deposit/Hooks'
import {
  AmericanExpressButton,
  MasterCardButton,
  VisaButton,
} from '@mobi/component-library/Deposit/Buttons/CreditCard'
import type { IssuerName, PaymentMethod } from '@mobi/api-types'
import { Button } from '@mobi/component-library/Common/V2'

type Props = PanelProps & {
  creditCard: PaymentMethod
  /** True to show issuer on button, false to show generic 'Deposit' button */
  showIssuer: boolean
}

export const CreditCardButton = ({
  initialData,
  accountNumber,
  depositAmount,
  isDepositAllowed,
  creditCard,
  showIssuer,
  onStart,
  onCancel,
  onDepositing,
  onSuccess,
  onFailure,
}: Props) => {
  const mustUse3DSecure = customerMustUseThreeDSecure(initialData, depositAmount)

  const { clientTokenThreeDSecure, clientToken } = initialData

  const { isReady, client: braintreeClient } = useBraintreeClient(
    mustUse3DSecure ? clientTokenThreeDSecure : clientToken
  )

  const { deposit } = useCreditCardDeposit({
    initialData,
    accountNumber,
    braintreeClient,
    mustUse3DSecure,
    onDepositing,
  })

  const onCreditCardButtonClicked = async () => {
    // If depositing is not allowed, we stop here. While it isn't possible for
    // someone to get here while depositing isn't allowed, a user could try
    // to deposit by removing the `disabled` attribute through devtools.
    if (!isDepositAllowed) {
      return
    }

    onStart()

    const canDeposit = await isReady()

    if (!canDeposit) {
      onCancel?.()
      return
    }

    try {
      const response = await deposit({
        creditCard,
        isUsingNewCard: false,
        depositAmount,
      })

      const { isSuccess, ...errorDetails } = response

      if (isSuccess) {
        await onSuccess(depositAmount)
      } else {
        onFailure(DepositError.fromErrorDetails(errorDetails))
      }
    } catch (error) {
      if (error instanceof DepositError && error.reason === 'user_canceled') {
        onCancel?.()
        return
      }

      onFailure(DepositError.coerce(error, null))
    }
  }

  if (showIssuer) {
    const CreditCardButton = determineCreditCardButton(creditCard.issuerName)
    return (
      <CreditCardButton
        lastFour={creditCard.lastFour}
        disabled={!isDepositAllowed}
        onClick={onCreditCardButtonClicked}
      />
    )
  }

  return (
    <Button
      color='positive'
      size='md'
      disabled={!isDepositAllowed}
      onClick={onCreditCardButtonClicked}
    >
      Deposit
    </Button>
  )
}

function determineCreditCardButton(issuerName: IssuerName): React.FC<CreditCardButtonProps> {
  switch (issuerName) {
    case 'MASTER_CARD':
      return MasterCardButton
    case 'VISA':
      return VisaButton
    case 'AMEX':
      return AmericanExpressButton
  }
}
