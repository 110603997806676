import React from 'react'
import { ProjectedReturnMoneyStyled, ProjectedReturnStyled } from './ProjectedReturn.styles'
import { calculateEstReturnSingleItem } from '@core/Areas/Betslip/helpers/calculator'
import { isStartingPriceSelection } from '@core/Data/Betting/selections'
import { BetslipItem } from '../../driver'

export function ProjectedReturn({ item }: { item: BetslipItem }) {
  if (isStartingPriceSelection(item?.selection)) return null
  return (
    <ProjectedReturnStyled data-testid='betslip-single-projected-return'>
      Proj. Return <ProjectedReturnMoneyStyled amount={calculateEstReturnSingleItem(item)} />
    </ProjectedReturnStyled>
  )
}
