import dayjs from 'dayjs'
import { List } from 'immutable'
import {
  FixedOddsMarketCodes,
  type MarketDataTransferObject,
  type RacePageDataTransferObject,
  type StarterDataTransferObject,
} from '@mobi/api-types'
import { BettingType } from '@core/Data/betting'
import { QuickbetSelection } from '@core/Areas/Quickbet/signals'
import type { Acceptor, Race } from '@core/Data/Racing'
import type { Selection } from '@core/Data/Betting/selections'

export function createBetSelection({
  market,
  proposition,
  raceData,
}: {
  market: MarketDataTransferObject
  proposition: MarketDataTransferObject['Propositions'][0]
  raceData: RacePageDataTransferObject
}): QuickbetSelection {
  const isMatchedWithStarters =
    market.MarketCode === FixedOddsMarketCodes.HeadToHead ||
    market.MarketCode === FixedOddsMarketCodes.RunnerVsField ||
    market.MarketCode === FixedOddsMarketCodes.Concession

  const matchedAcceptor =
    isMatchedWithStarters && matchedAcceptorForRace(raceData, proposition.Number)

  return {
    bettingType: BettingType.FixedOddsRacing,
    isEachWayAvailable: false,
    selection: {
      type: 'fob-proposition',
      fixtureDate: dayjs(market.FixtureDate).format('YYYY-MM-DD'),
      fixtureId: market.FixtureId,
      propositionSeq: proposition.PropositionSequence.toString(),
      placePrice: null,
      placePriceLastSeen: null,
      raceNumber: market.ContestNumber,
      winPrice: +proposition.PriceToDisplay,
      winPriceLastSeen: +proposition.PriceToDisplay,
      displayName: proposition.Description, // for AvB market without matched started
      marketName: market.MarketName,
      marketCode: market.MarketCode,
      selectedAcceptors: matchedAcceptor
        ? [starterToAcceptor(matchedAcceptor, raceData.MeetingInformation.MeetingName)]
        : ([] as Acceptor[]),
      priceSource: 'selection',
    } as Selection,

    selectionDetails: {
      races: [
        {
          key: raceData.MeetingInformation.SelectedRace?.Key,
          raceNumber: raceData.MeetingInformation.SelectedRace.RaceNumber,
          meetingName: raceData.MeetingInformation.MeetingName,
          meetingCode: raceData.MeetingInformation.MeetingCode,
          MeetingId: raceData.MeetingInformation.MeetingId,
          meetingDate: raceData.MeetingInformation.MeetingDate,
          distance: raceData.MeetingInformation.SelectedRace?.Distance,
          raceTime: raceData.MeetingInformation.SelectedRace?.RaceTime,
          type: raceData.MeetingInformation.SelectedRace?.RaceType,
          isFixedOddsRace: raceData.MeetingInformation.SelectedRace?.IsFixedOddsRace,
          acceptorKeys: List<string>(),
        } as Race,
      ],
      acceptors: [],
    },
    shouldAllowPlaceInvestment: false,
  }
}

export function createStartingPriceBetSelection(
  starterNumber: number,
  raceData: RacePageDataTransferObject
): QuickbetSelection {
  const matchedAcceptor = matchedAcceptorForRace(raceData, starterNumber)
  const selectedAcceptor =
    matchedAcceptor && starterToAcceptor(matchedAcceptor, raceData.MeetingInformation.MeetingName)

  return {
    bettingType: BettingType.FixedOddsRacing,
    isEachWayAvailable: false,
    shouldAllowPlaceInvestment: false,
    selection: {
      fixtureId: raceData.MeetingInformation.MeetingId,
      fixtureDate: dayjs(raceData.MeetingInformation.MeetingDate).format('YYYY-MM-DD'),
      raceNumber: raceData.MeetingInformation.SelectedRace.RaceNumber,
      acceptorNumber: starterNumber,
      marketCode: FixedOddsMarketCodes.StartingPrice,
      marketName: 'Starting Price',
      selectedAcceptor,
    },
    selectionDetails: {
      races: [
        {
          key: raceData.MeetingInformation.SelectedRace?.Key,
          raceNumber: raceData.MeetingInformation.SelectedRace.RaceNumber,
          meetingName: raceData.MeetingInformation.MeetingName,
          meetingCode: raceData.MeetingInformation.MeetingCode,
          MeetingId: raceData.MeetingInformation.MeetingId,
          meetingDate: raceData.MeetingInformation.MeetingDate,
          distance: raceData.MeetingInformation.SelectedRace?.Distance,
          raceTime: raceData.MeetingInformation.SelectedRace?.RaceTime,
          type: raceData.MeetingInformation.SelectedRace?.RaceType,
          isFixedOddsRace: raceData.MeetingInformation.SelectedRace?.IsFixedOddsRace,
          acceptorKeys: List<string>(),
        },
      ],
      acceptors: [],
    },
  }
}

export function starterToAcceptor(starter: StarterDataTransferObject, meetingName?: string) {
  return {
    barrier: starter.Barrier,
    weight: starter.JockeyWeight,
    priceWin: starter?.FixedOddsStarterInfo?.DisplayWinDividend,
    pricePlace: starter?.FixedOddsStarterInfo?.DisplayPlaceDividend,
    name: starter.Name,
    number: starter.Number,
    rating: starter.Rating,
    type: starter.Type,
    imageUrlWithSizes: starter.SilkImages,
    meetingName,
    isScratched: starter.IsScratched,
    scratchType: starter.ScratchType,
  } as unknown as Acceptor
}

function matchedAcceptorForRace(
  raceData: RacePageDataTransferObject,
  starterNumber: number | undefined
) {
  return raceData.RaceStarters.find(
    race => race.RaceKey.Key === raceData.MeetingInformation.SelectedRace.Key
  )?.Starters.find(starter => starter.Number === starterNumber)
}
