import React, { type ButtonHTMLAttributes } from 'react'
import styled, { type CSSObject } from '@emotion/styled'
import { colors, radius, shadow, spacing, font } from '../../../Theme/Common'
import { gapX } from '../../../Utils/gap'

type Props = Partial<ButtonStyledProps> & ButtonHTMLAttributes<HTMLButtonElement>

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, color = 'primary', isFullWidth = false, size = 'md', ...props }, ref) => (
    <ButtonStyled ref={ref} color={color} isFullWidth={isFullWidth} size={size} {...props}>
      {children}
    </ButtonStyled>
  )
)

// ======
// Styles
// ======

export const ButtonStyled = styled.button<ButtonStyledProps>(
  {
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: font.family.primary,
    cursor: 'pointer',
  },
  ({ color = 'primary', size = 'md', isFullWidth = false, isIconOnlyButton = false }) => ({
    ...(isFullWidth ? { display: 'flex', width: '100%' } : { display: 'inline-flex' }),

    ...buttonSizing({ isIconOnlyButton })[size],
    ...buttonColors[color],
  }),
  gapX('0.8rem')
)

// Variants

export const buttonSizing = ({
  isIconOnlyButton = false /* For single-icon only buttons, horizontal and vertical padding should match. */,
  isPillButton = false,
}): Record<ButtonStyledProps['size'], CSSObject> => ({
  xs: {
    height: '3.2rem',
    minWidth: '3.2rem',
    padding: `0 ${isIconOnlyButton ? 0 : spacing.sm}`,
    fontWeight: font.weight.medium,
    fontSize: font.size.lg.fontSize,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: font.size.lg.lineHeight,
    borderRadius: isPillButton ? radius.pill : radius.sm,
  },
  sm: {
    height: '3.6rem',
    minWidth: '3.6rem',
    padding: `0 ${isIconOnlyButton ? 0 : spacing.sm}`,
    fontWeight: font.weight.medium,
    fontSize: font.size.lg.fontSize,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: font.size.lg.lineHeight,
    borderRadius: isPillButton ? radius.pill : radius.lg,
  },
  md: {
    height: '4.4rem',
    minWidth: '4.4rem',
    padding: `0 ${isIconOnlyButton ? 0 : spacing.md}`,
    fontWeight: font.weight.medium,
    fontSize: font.size.lg.fontSize,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: font.size.lg.lineHeight,
    borderRadius: isPillButton ? radius.pill : radius.lgx1,
  },
  lg: {
    height: '5.6rem',
    minWidth: '5.6rem',
    padding: `0 ${isIconOnlyButton ? 0 : spacing.bigx1}`,
    fontWeight: font.weight.semibold,
    fontSize: font.size.xl.fontSize,
    letterSpacing: font.size.xl.letterSpacing,
    lineHeight: font.size.xl.lineHeight,
    borderRadius: isPillButton ? radius.pill : radius.lgx2,
  },
})

export const buttonColors: Record<ButtonStyledProps['color'], CSSObject> = {
  primary: {
    color: colors.white,
    backgroundColor: colors.studio[500],
    border: `0.1rem solid ${colors.studio[500]}`,
    boxShadow: shadow.xs,

    '&:active': {
      background: colors.studio[600],
      border: `0.1rem solid ${colors.studio[600]}`,
    },
    '&:hover': {
      border: `0.1rem solid ${colors.studio[500]}`,
    },
    '&:disabled': {
      background: colors.lavender[100],
      border: `0.1rem solid ${colors.lavender[100]}`,
    },
  },

  secondary_color: {
    color: colors.lavender[700],
    backgroundColor: colors.lavender[50],
    border: `0.1rem solid ${colors.lavender[50]}`,

    '&:active': {
      backgroundColor: colors.lavender[100],
      border: `0.1rem solid ${colors.lavender[100]}`,
    },
    '&:hover': {
      border: `0.1rem solid ${colors.lavender[200]}`,
      backgroundColor: `0.1rem solid ${colors.lavender[50]}`,
    },
    '&:disabled': {
      color: colors.white,
      backgroundColor: colors.lavender[50],
      border: `0.1rem solid ${colors.lavender[50]}`,
    },
  },

  secondary_grey: {
    color: colors.neutral[900],
    backgroundColor: colors.surface[50],
    border: `0.1rem solid ${colors.surface[50]}`,

    '&:active': {
      backgroundColor: colors.surface[300],
      border: `0.1rem solid ${colors.surface[300]}`,
    },
    '&:hover': {
      border: `0.1rem solid ${colors.neutral[900]}`,
    },
    '&:disabled': {
      color: colors.neutral[400],
    },
  },

  secondary_outline: {
    color: colors.neutral[900],
    backgroundColor: colors.white,
    border: `0.1rem solid ${colors.neutral[200]}`,
    boxShadow: shadow.xs,

    '&:active': {
      backgroundColor: colors.white,
      border: `0.1rem solid ${colors.neutral[500]}`,
    },
    '&:hover': {
      border: `0.1rem solid ${colors.neutral[100]}`,
      backgroundColor: `0.1rem solid ${colors.neutral[100]}`,
    },
    '&:disabled': {
      color: colors.neutral[200],
      backgroundColor: colors.white,
      border: `0.1rem solid ${colors.neutral[200]}`,
    },
  },

  secondary_destructive: {
    backgroundColor: colors.error[50],
    border: `0.1rem solid ${colors.error[50]}`,
    boxShadow: shadow.xs,
    color: colors.error[700],

    '&:active': {
      backgroundColor: colors.error[200],
    },

    '&:disabled': {
      backgroundColor: colors.surface[50],
      color: colors.error[100],
    },
  },

  tertiary_grey: {
    color: colors.neutral[900],
    backgroundColor: colors.transparent,
    border: 0,

    '&:active': {
      border: `0.1rem solid ${colors.neutral[400]}`,
    },
    '&:hover': {
      backgroundColor: colors.surface[100],
    },
    '&:disabled': {
      color: colors.neutral[200],
    },
  },

  positive: {
    color: colors.white,
    backgroundColor: colors.success[500],
    border: `0.1rem solid ${colors.success[500]}`,
    boxShadow: shadow.xs,

    '&:active': {
      backgroundColor: colors.success[700],
      border: `0.1rem solid ${colors.success[700]}`,
    },
    '&:hover': {
      border: `0.1rem solid ${colors.success[500]}`,
      backgroundColor: `0.1rem solid ${colors.success[500]}`,
    },
    '&:disabled': {
      backgroundColor: colors.success[100],
      border: `0.1rem solid ${colors.success[100]}`,
    },
  },

  destructive: {
    color: colors.white,
    backgroundColor: colors.error[600],
    border: `0.1rem solid ${colors.error[600]}`,
    boxShadow: shadow.xs,

    '&:active': {
      backgroundColor: colors.error[700],
      border: `0.1rem solid ${colors.error[700]}`,
    },
    '&:disabled': {
      backgroundColor: colors.error[200],
      border: `0.1rem solid ${colors.error[200]}`,
    },
  },
}

// =====
// Types
// =====
export type ButtonColor =
  | 'primary'
  | 'secondary_color'
  | 'secondary_grey'
  | 'secondary_outline'
  | 'secondary_destructive'
  | 'positive'
  | 'tertiary_grey'
  | 'destructive'

export type ButtonStyledProps = {
  /**
   * The color variant of the button
   */
  color: ButtonColor
  /**
   * The size of the button
   */
  size: 'xs' | 'sm' | 'md' | 'lg'
  /**
   * Whether the button should be inline or block-level
   *
   * Setting this to true will make the button block-level and set its width to 100%
   */
  isFullWidth?: boolean
  /**
   *  Whether the button's content consists of a single icon without any text
   */
  isIconOnlyButton?: boolean
}
