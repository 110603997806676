import React from 'react'
import { debounceFn } from '@mobi/utils/functions'
import { useAppDispatch } from '@core/Store/hooks'
import { checkIfIsInIPad } from '@core/Settings/Components/GlobalCss/helpers'
import { setCanRenderPendingBetsDrawer } from '@core/Areas/SkyVideoPlayer/Store'

const enum LocalConstants {
  MinVideoWidthRem = 38,
}

/** Monitors device screen width, if not enough space to render, will disable display */
export const useCanRenderPendingBetsCheck = (drawerWidthRem: number) => {
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (!window.ResizeObserver) return
    const isInIpad = checkIfIsInIPad()
    const remMultiplier = isInIpad ? 16 : 10

    const determineShouldEnablePendingBetsDrawer = debounceFn(() => {
      const isDisplayWideEnoughForPendingBets =
        window.innerWidth >
        drawerWidthRem * remMultiplier + LocalConstants.MinVideoWidthRem * remMultiplier

      dispatch(setCanRenderPendingBetsDrawer(isDisplayWideEnoughForPendingBets))
    }, 200)

    determineShouldEnablePendingBetsDrawer()

    const observer = new ResizeObserver(determineShouldEnablePendingBetsDrawer)
    observer.observe(document.body)

    return () => observer.disconnect()
  }, [dispatch, drawerWidthRem])
}
