import React from 'react'
import {
  ScrollTo,
  UpdateBetslipItemPrice,
  OnOpen,
  OnClose,
  RefreshBetslip,
  RemoveBonusBetsFromBetslip,
} from '@core/Areas/Betslip/signals'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import { OverlayClose } from '@core/Components/Overlay'
import { state$ as navigationState$ } from '@core/State/Navigation/driver'
import { event$, FobPriceChangedPushEvent, isFobPriceChangedPushEvent } from '@core/State/PushData'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { deregisterBodyScrollLock, registerBodyScrollLock } from '@mobi/utils/functions'
import { useConfig, useObservableImmutable } from '@core/Utils/hooks'
import { OverlayCloseButtonStyled } from '@core/Components/Overlay/Overlay.styles'
import { useHapticFeedbackOnBetSuccess } from '@core/Utils/hooks/useHapticFeedbackOnBetSuccess'
import { useTrackOptimoveBetslipAbandoned } from '@core/Services/Optimove/hooks/useTrackOptimoveBetslipAbandoned'

import { registerAnalyticsSubscriptions } from './analytics'
import {
  BetslipMainContainerStyled,
  BetslipOverlayHeaderStyled,
  BetslipOverlayHeaderTextStyled,
  BetslipOverlayStyled,
  EmptyBetslipMessageStyled,
} from './Betslip.styles'
import { AccountBalance } from './Components/AccountBalance/AccountBalance'
import { ClearBetslipButton } from './Components/Buttons/ClearBetslipButton'
import { BetslipErrorBoundary } from './Components/ErrorBoundaries/BetslipErrorBoundary'
import { FooterSummary } from './Components/FooterSummary/FooterSummary'
import { MultiItemList } from './Components/MultiItemList/MultiItemList'
import { SingleItemList } from './Components/SingleItemList/SingleItemList'
import { state$ as betslipDriverState$ } from './driver'
import { getBetsInMulti } from './helpers/state'

export const Betslip = (): JSX.Element => {
  const containerElementRef = React.useRef<HTMLDivElement>(null)
  const mainElementRef = React.useRef<HTMLDivElement>(null)

  const { scrollPosition, hasProposed, isBusy, items } = useObservableImmutable(
    betslipDriverState$,
    ['isBusy', 'items', 'hasProposed', 'scrollPosition']
  )
  useHapticFeedbackOnBetSuccess('betslip')
  useTrackOptimoveBetslipAbandoned()

  const shouldEnableAnalytics = useConfig('enableAnalytics', false)

  React.useEffect(() => {
    if (!shouldEnableAnalytics) return
    const { dispose } = registerAnalyticsSubscriptions()
    return dispose
  }, [shouldEnableAnalytics])

  React.useEffect(() => {
    OnOpen()

    const containerElement = containerElementRef.current
    containerElement && registerBodyScrollLock(containerElement)

    const fobPriceChangedSubscription = event$
      .filter(e => isFobPriceChangedPushEvent(e.payload))
      .map(e => e.payload as FobPriceChangedPushEvent)
      .groupBy(e => e.propositionId)
      .flatMap(group => group.debounce(2500))
      .subscribe(({ propositionId, winPrice, placePrice }) => {
        if (winPrice) {
          UpdateBetslipItemPrice({
            propositionId: propositionId.toString(),
            winPrice,
            placePrice,
            priceSource: 'push',
          })
        }
      })

    const navigationSubscription = navigationState$
      .map(navState => navState.toJS().currentUrl)
      .skip(1)
      .distinctUntilChanged()
      .subscribe(handleCloseButtonClick)

    const loggedInSubscription = userAccountState$
      .pluck<boolean>('isLoggedIn')
      .distinctUntilChanged()
      .subscribe(isLoggedIn => {
        isLoggedIn ? RefreshBetslip() : RemoveBonusBetsFromBetslip()
      })

    return () => {
      ScrollTo('top')
      OnClose()
      fobPriceChangedSubscription.dispose()
      navigationSubscription.dispose()
      loggedInSubscription.dispose()

      containerElement && deregisterBodyScrollLock(containerElement)
    }
  }, [])

  React.useEffect(() => {
    if (scrollPosition === 'multi' && getBetsInMulti(items).count() > 1) {
      mainElementRef.current?.scrollIntoView?.()
    }
  }, [scrollPosition, items])

  const hasItems = items && !items.isEmpty()
  const showClear = hasItems && !hasProposed && !isBusy

  return (
    <BetslipOverlayStyled data-tid-betslip-overlay=''>
      <BetslipOverlayHeaderStyled>
        <BetslipOverlayHeaderTextStyled>Betslip</BetslipOverlayHeaderTextStyled>
        <AccountBalance />
        <OverlayCloseButtonStyled onClick={handleCloseButtonClick} data-testid='close-button'>
          <Icon type='cross' title='Close' />
        </OverlayCloseButtonStyled>
      </BetslipOverlayHeaderStyled>

      <BetslipErrorBoundary>
        <BetslipMainContainerStyled
          background='grey'
          ref={containerElementRef}
          data-tid-betslip-maincontainer=''
          data-testid='betslip-maincontainer'
        >
          {hasItems ? (
            <>
              <SingleItemList />
              <div ref={mainElementRef}>
                <MultiItemList />
              </div>
            </>
          ) : (
            <EmptyBetslipMessageStyled>
              <span>Your Betslip is Empty</span>
            </EmptyBetslipMessageStyled>
          )}

          {showClear && (
            <Grid>
              <GridRow>
                <GridCell width='50%' padding='1rem 0.5rem 1rem 1rem'>
                  <ClearBetslipButton />
                </GridCell>
              </GridRow>
            </Grid>
          )}
        </BetslipMainContainerStyled>

        <FooterSummary />
      </BetslipErrorBoundary>
    </BetslipOverlayStyled>
  )
}

// Local Helpers

function handleCloseButtonClick() {
  OverlayClose()
  OnClose()
}
