import { useEffect, useState } from 'react'
import {
  isReactNativeAndroid,
  isReactNativeApp,
  sendToNative,
  subscribeToNative,
} from '@mobi/web-native-comms/web'
import { initGooglePayWeb, isUserReadyToPay } from '@mobi/deposit/Hooks/GooglePay/initGooglePayWeb'
import { withTimeout } from '@mobi/utils'

export type AvailabilityStatus = 'unknown' | 'available' | 'unavailable'

export function useGooglePayAvailability(token?: string) {
  const [availability, setAvailability] = useState<AvailabilityStatus>('unknown')

  useEffect(() => {
    if (availability !== 'unknown') {
      return
    }

    if (isReactNativeAndroid && token) {
      checkNativeGooglePay(token).then(isAvailable => {
        setAvailability(isAvailable ? 'available' : 'unavailable')
      })

      return
    }

    if (!isReactNativeApp) {
      if (window?.PaymentRequest !== undefined) {
        // We're in web, so 2 components are required to make payments through Google Pay
        // First is the PaymentRequest API, second is the Google Pay script loaded
        // through Google's CDN, which makes window.google available

        initGooglePayWeb()
          .then(isUserReadyToPay)
          .then(isReady => {
            setAvailability(isReady ? 'available' : 'unavailable')
          })
          .catch(() => {
            setAvailability('unavailable')
          })

        return
      } else {
        setAvailability('unavailable')
      }
    }
  }, [availability, token])

  return availability
}

const GOOGLE_PAY_NATIVE_CHECK_TIMEOUT_MS = 3_000

function checkNativeGooglePay(token: string) {
  const promise = checkGooglePayAvailability(token)
  return withTimeout(promise, GOOGLE_PAY_NATIVE_CHECK_TIMEOUT_MS, false)
}

function checkGooglePayAvailability(token: string) {
  return new Promise<boolean>(resolve => {
    const { dispose } = subscribeToNative('RESPONSE_GOOGLE_PAY_AVAILABLE', ({ isAvailable }) => {
      resolve(isAvailable)
      dispose()
    })
    sendToNative('REQUEST_GOOGLE_PAY_AVAILABLE', {
      token,
    })
  })
}
