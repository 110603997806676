import type { IssuerName } from '@mobi/api-types'
import type { DepositSource } from '../types'
import { hexColors } from '@mobi/settings'

export type Provider =
  | 'paypal'
  | 'applepay'
  | 'default'
  | 'mastercard'
  | 'visa'
  | 'americanExpress'
  | 'googlepay'

export const ProviderMap: Record<DepositSource | IssuerName, Provider> = {
  CreditCard: 'visa',
  PayPal: 'paypal',
  ApplePay: 'applepay',
  GooglePay: 'googlepay',
  VISA: 'visa',
  MASTER_CARD: 'mastercard',
  AMEX: 'americanExpress',
}

export type Theme = {
  backgroundColor: string
  textColor: string
  svgWidth: string
  svgHeight: string
}

export const themes: Readonly<Record<Provider, Theme>> = {
  paypal: {
    backgroundColor: hexColors.paypalGold,
    textColor: 'inherit',
    svgHeight: 'auto',
    svgWidth: 'auto',
  },
  applepay: {
    backgroundColor: hexColors.black,
    textColor: 'inherit',
    svgHeight: 'auto',
    svgWidth: 'auto',
  },
  default: {
    backgroundColor: '',
    textColor: '',
    svgHeight: 'auto',
    svgWidth: 'auto',
  },
  mastercard: {
    backgroundColor: hexColors.black,
    textColor: hexColors.white,
    svgHeight: '28px',
    svgWidth: '44px',
  },
  visa: {
    backgroundColor: hexColors.americanExpressBlue,
    textColor: hexColors.white,
    svgHeight: '18px',
    svgWidth: '52px',
  },
  americanExpress: {
    backgroundColor: hexColors.black,
    textColor: hexColors.white,
    svgHeight: '28px',
    svgWidth: '43px',
  },
  googlepay: {
    backgroundColor: hexColors.black,
    textColor: 'inherit',
    svgHeight: 'auto',
    svgWidth: 'auto',
  },
} as const
