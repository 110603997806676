import React from 'react'
import {
  fetchAccountBalanceAsync,
  fetchCampaignsAsync,
} from '@core/State/UserAccount/async-signals'
import { useObservableImmutable } from '@core/Utils/hooks/useObservableImmutable'
import { state$ as accountState$ } from '@core/Areas/Account/driver'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { state$ as featuresState$ } from '@core/State/LaunchDarklyFeatures/driver'
import { FeatureFlags } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackBonusBetEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import {
  BalanceContainerStyled,
  BalanceMoneyStyled,
  BalanceSectionStyled,
  BonusBalanceMoneyStyled,
  BonusSectionStyled,
} from './BalancePill.styles'
import { FobSpecialToken } from '../AccountDropDown/Components/helpers/useGetSpecialTokens'
import { useGetSpecialTokens } from '../AccountDropDown/Components/helpers/useGetSpecialTokens'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { toggleIsDropDownOpen } from '@core/Areas/AppHeader/Store'
import { useMigrateBalanceVisible } from '@core/Areas/AppHeader/hooks/useMigrateBalanceVisible'
import { getIsBalanceVisible, getIsDropDownOpen } from '../../Store/selectors'

// NOT main export, see below for <BalancePill />
export const BalancePillComponent: React.FC<{
  cashBalance: number
  bonusBalance: number
  bonusesCount: number
  isVisible: boolean
  onWalletToggle: () => void
}> = ({ cashBalance, bonusBalance, bonusesCount, isVisible, onWalletToggle }) => {
  const hideDecimalsInCashBalance = cashBalance >= 100000
  const hideDecimalsInBonusBalance = bonusBalance >= 1000

  const balanceToDisplay = hideDecimalsInCashBalance ? Math.floor(cashBalance) : cashBalance
  const bonusBalanceToDisplay = hideDecimalsInBonusBalance ? Math.floor(bonusBalance) : bonusBalance

  return (
    <BalanceContainerStyled onClick={onWalletToggle} data-testid='app-header-balance'>
      <BalanceSectionStyled
        hasOffers={!!bonusesCount}
        hasBonusBalance={!!bonusBalanceToDisplay}
        isBalanceVisible={isVisible}
      >
        {isVisible ? (
          <>
            <BalanceMoneyStyled
              amount={balanceToDisplay as number}
              decimalPlaces={hideDecimalsInCashBalance ? 0 : 2}
            />
            {!!bonusBalanceToDisplay && (
              <BonusBalanceMoneyStyled
                prefix={'Bonus: '}
                amount={bonusBalanceToDisplay as number}
                decimalPlaces={hideDecimalsInBonusBalance ? 0 : 2}
              />
            )}
          </>
        ) : (
          <Icon type='personMinimal' size='2.5rem' />
        )}
      </BalanceSectionStyled>
      {!!bonusesCount && <BonusSectionStyled>{bonusesCount}</BonusSectionStyled>}
    </BalanceContainerStyled>
  )
}

export const BalancePill: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  useMigrateBalanceVisible()

  const {
    accountBalance: balance = 0,
    accountBalanceUpdatedTime: accountBalanceLastUpdated = 0,
    bonusBetBalance = 0,
    bonusCashBalance = 0,
    activeCampaigns,
  } = useObservableImmutable(userAccountState$, [
    'accountBalance',
    'accountBalanceUpdatedTime',
    'bonusBetBalance',
    'bonusCashBalance',
    'activeCampaigns',
  ])

  const { accountDetails } = useObservableImmutable(accountState$, ['accountDetails'])
  const { features } = useObservableImmutable(featuresState$, ['features'])

  const isDropDownOpen = useAppSelector(getIsDropDownOpen)
  const isBalanceVisible = useAppSelector(getIsBalanceVisible)

  const isAccountPushFeatureEnabled = features?.get(FeatureFlags.PUSHDATA_USERINFO.key)
  const accountBalanceTTL = features?.get(FeatureFlags.CONFIG_BALANCE_POLL_INTERVAL.key)

  const superPicksLastUpdated = React.useRef<number | null>(null)
  const standardSuperPickCount = React.useRef(0)
  const bonusSuperPickCount = React.useRef(0)
  const [canShowBonusCount, setCanShowBonusCount] = React.useState(false)
  const {
    tokens,
    errorMessage,
    isLoading: isLoadingSuperPicks,
  } = useGetSpecialTokens(canShowBonusCount)

  React.useEffect(() => {
    if (accountDetails?.get('activeCampaignsCount') != (activeCampaigns?.count() ?? -1)) {
      fetchCampaignsAsync()
    }
  }, [accountDetails, activeCampaigns])

  React.useEffect(() => {
    const bonusCountTimeoutId = window.setTimeout(() => {
      setCanShowBonusCount(true)
    }, 500)
    return () => {
      window.clearTimeout(bonusCountTimeoutId)
    }
  }, [])

  // Timer Setup: Handle Balance Polling Based on Interval
  React.useEffect(() => {
    let balanceTimerId: number

    if (accountBalanceTTL && isBalanceVisible && !isAccountPushFeatureEnabled) {
      if (
        !!accountBalanceLastUpdated &&
        Date.now() - accountBalanceLastUpdated > accountBalanceTTL
      ) {
        fetchAccountBalanceAsync()
      }
      balanceTimerId = window.setInterval(() => {
        if (
          !!accountBalanceLastUpdated &&
          Date.now() - accountBalanceLastUpdated > accountBalanceTTL
        ) {
          fetchAccountBalanceAsync()
        }
      }, accountBalanceTTL)
    }

    return () => {
      window.clearInterval(balanceTimerId)
    }
  }, [isBalanceVisible, accountBalanceTTL, accountBalanceLastUpdated, isAccountPushFeatureEnabled])

  const handleDropDownToggle = () => {
    const accountNumber = accountDetails?.toJS()?.accountNumber
    if (!isDropDownOpen && accountNumber) {
      trackBonusBetEvent(keys.accountDropDownOpened, {
        accountNumber,
        accountBalance: balance,
        bonusBetBalance,
        bonusCashBalance,
      })
    }
    dispatch(toggleIsDropDownOpen())
  }

  const bonusBalance = (bonusBetBalance ?? 0) + (bonusCashBalance ?? 0)
  const campaignsCount = activeCampaigns?.count() || 0

  const standardSuperPicks = tokens.filter(token => !token.IsBonusToken)
  const bonusSuperPicks = tokens.filter(token => token.IsBonusToken)

  const isStandardSuperPickAvailable =
    !errorMessage && standardSuperPicks && standardSuperPicks.length > 0
  const isBonusSuperPicksAvailable = !errorMessage && bonusSuperPicks && bonusSuperPicks.length > 0

  if (!isLoadingSuperPicks) {
    standardSuperPickCount.current = isStandardSuperPickAvailable
      ? standardSuperPicks[0].BetsAvailable
      : 0
    bonusSuperPickCount.current = isBonusSuperPicksAvailable
      ? calculateTotalBonusSuperPicks(bonusSuperPicks)
      : 0
  }

  const totalOffersCount =
    campaignsCount + standardSuperPickCount.current + bonusSuperPickCount.current
  superPicksLastUpdated.current = Date.now()

  return (
    <BalancePillComponent
      cashBalance={balance || 0}
      bonusBalance={bonusBalance}
      bonusesCount={totalOffersCount}
      isVisible={isBalanceVisible}
      onWalletToggle={handleDropDownToggle}
    />
  )
}

// Local Helpers

function calculateTotalBonusSuperPicks(array: FobSpecialToken[]): number {
  return array.reduce((count, { BetsAvailable = 0 }) => BetsAvailable + count, 0)
}
