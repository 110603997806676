import { store } from '@core/Store'
import { createItemForBetslip } from '@core/Areas/Betslip/helpers/addToBetslip'
import { AddSingleToBetslip } from '@core/Areas/Betslip/signals'
import { QUICKBET_MODAL_ID } from '@core/Areas/Quickbet/constants'
import { Quickbet, type QuickbetExternalProps } from '@core/Areas/Quickbet/Quickbet'
import {
  QuickbetClosed,
  QuickbetLoadSelection,
  QuickbetSelection,
} from '@core/Areas/Quickbet/signals'
import { openModal } from '@core/Components/Modal/store'

type HandleBetSelection = { selection: QuickbetSelection } & (
  | { location: 'Betslip'; options?: never }
  | {
      location: 'Quickbet'
      options?: {
        setUp?: () => void
        quickbetProps?: QuickbetExternalProps
      }
    }
)

export function handleBetSelection({ location, selection, options = {} }: HandleBetSelection) {
  if (location === 'Betslip') {
    AddSingleToBetslip(createItemForBetslip(selection))

    return
  }

  if (location === 'Quickbet') {
    QuickbetLoadSelection(selection)

    if (options.setUp) options.setUp()

    store.dispatch(
      openModal({
        id: QUICKBET_MODAL_ID,
        modalComponent: Quickbet,
        color: 'dark',
        onCloseCallback: QuickbetClosed,
        modalComponentProps: options.quickbetProps as Record<string, unknown>,
      })
    )

    return
  }
}
