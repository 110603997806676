import React from 'react'
import { useDispatch } from 'react-redux'
import * as immutable from 'immutable'
import { observeImmutable } from '@core/Components/HOCs'
import {
  state$ as betslipState$,
  MultiInvestment,
  BetslipItem,
  BetslipState,
  ReceiptItem,
  MultiBetError,
  BetslipExpandableTypes,
  BetslipStateRecord,
} from '../../driver'
import { ToggleBetslipExpandable } from '../../signals'
import { BetslipExpandablePanel } from '../BetslipExpandablePanel/BetslipExpandablePanel'
import { InvestmentTextStyled, InvestmentStyled } from '../BetInvestment/BetInvestment.styles'
import { PriceChange, getPriceChange } from '@core/Components/Text/utils'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { MultiReturn } from '../MultiReturn/MultiReturn'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { MIN_LEGS_IN_MULTI } from '../../constants'
import { isRaceDetails } from '@core/Data/Betting/selectionDetails'
import { BetslipReceiptMulti } from '../BetslipReceipt/BetslipReceiptMulti'
import {
  calculateMultiProjectedPay,
  calclulateCombinedMultiInvestment,
  calculateMultiReturn,
  calculateLastSeenMultiReturn,
} from '../../helpers/calculator'
import {
  MultiDetailsStyled,
  MultiDetailsAmountStyled,
  MultiSectionStyled,
  MultiItemListStyled,
  MultiSpendStyled,
  EmptyItemStyled,
  MultiTotalContainerStyled,
  MultiTotalAmountStyled,
} from './MultiItemList.styles'
import { Money } from '@core/Components/Text/Money'
import { MultiFormula } from '@core/Areas/Betslip/Components/MultiFormula/MultiFormula'
import { MultiItem } from '@core/Areas/Betslip/Components/MultiItem/MultiItem'
import {
  SetBonusBet,
  SetQuickEditInvestment,
  SetQuickEditValue,
  ToggleBonusCash,
} from '../QuickEdit/driver'
import { QUICK_EDIT_MODAL, QuickEdit, QuickEditContentTypes } from '../QuickEdit/QuickEdit'
import { isStartingPriceSelection, isToteSelection } from '@core/Data/Betting/selections'
import {
  getBetsInMulti,
  hasTooFewMultiLegs,
  hasTooManyMultiLegs,
  isValidMulti,
  isValidMultiInvestmentForLegs,
} from '../../helpers/state'
import { openModal } from '@core/Components/Modal'

interface ItemListStateProps {
  items: immutable.List<BetslipItem>
  isExpanded: boolean
  disabled: boolean
  receipt?: ReceiptItem | null
  hasProposed: boolean
  multiBetError: MultiBetError | null
  multiInvestment: MultiInvestment
}

const handleExpandablePanelClick = () => ToggleBetslipExpandable(BetslipExpandableTypes.multi)

export const MultiItemListComponent = ({
  items = immutable.List([]),
  isExpanded,
  receipt,
  disabled,
  hasProposed,
  multiBetError,
  multiInvestment,
}: ItemListStateProps) => {
  const dispatch = useDispatch()
  const anyBetsPlaced = items.some(x => !!x.receipt) || !!receipt
  const selectedMultiItems = getBetsInMulti(items)

  const isMultiValid = isValidMulti(multiInvestment, multiBetError, selectedMultiItems)
  const isMultiInvestmentValid = isValidMultiInvestmentForLegs(
    multiInvestment,
    selectedMultiItems.count()
  )

  if (hasProposed && !isMultiValid && !multiBetError) {
    return null
  }

  const isRaceMulti = items.some(item => item.isInMulti && isRaceDetails(item.selectionDetails))
  const hasEnoughBets = items.count() >= MIN_LEGS_IN_MULTI
  const hasEnoughSelections = selectedMultiItems.count() >= MIN_LEGS_IN_MULTI
  const hasTooManyLegs = hasTooManyMultiLegs(selectedMultiItems)
  const showBetTypeToggle = !anyBetsPlaced && !hasProposed

  const multiTotalCost = calclulateCombinedMultiInvestment(selectedMultiItems, multiInvestment)
  const multiReturn = calculateMultiReturn(items, { shouldRound: true })
  const multiReturnLastSeen = calculateLastSeenMultiReturn(items, { shouldRound: true })
  const multiReturnPriceChange = multiReturnLastSeen
    ? getPriceChange(multiReturnLastSeen, multiReturn)
    : PriceChange.None

  const isValidNumberOfLegs = !hasTooFewMultiLegs(selectedMultiItems) && !hasTooManyLegs

  const handleInvestmentClick = () => {
    SetQuickEditInvestment(multiInvestment.value)
    ToggleBonusCash(multiInvestment.isBonusCash ?? true)
    SetBonusBet(
      multiInvestment.bonusBetId
        ? { campaignId: multiInvestment.bonusBetId, value: multiInvestment.value }
        : null
    )
    SetQuickEditValue(multiInvestment.value)
    dispatch(
      openModal({
        id: QUICK_EDIT_MODAL,
        modalComponent: QuickEdit,
        modalComponentProps: {
          title: 'Multi Bet',
          subTitle: `${selectedMultiItems.count()} Legs`,
          contentType: QuickEditContentTypes.Multi,
        },
        color: 'dark',
      })
    )
  }

  return (
    <BetslipExpandablePanel
      title='Multi'
      count={hasEnoughBets ? 1 : 0}
      headerClicked={handleExpandablePanelClick}
      expanded={isExpanded}
    >
      {!hasEnoughBets && <EmptyItemStyled>Add more bets to create a Multi</EmptyItemStyled>}
      {hasEnoughBets && (
        <MultiSectionStyled>
          {!hasEnoughSelections && (
            <NoticeBox
              data-tid-notice-box=''
              hasBorder={true}
              noticeBoxType={NoticeBoxTypes.Error}
              title={`${MIN_LEGS_IN_MULTI} legs minimum for Multi`}
            />
          )}

          {!multiBetError?.errorMessage && hasTooManyLegs && (
            <>
              {isRaceMulti ? (
                <NoticeBox
                  data-tid-notice-box=''
                  hasBorder={true}
                  noticeBoxType={NoticeBoxTypes.Error}
                  // temporarily overwrite this message to be more generic just to align with the un-sured/unknown Max Legs in Prod, then will change back after the test is passed in Prod
                  // title={`${MAX_LEGS_IN_RACE_MULTI} legs maximum for race Multi`}
                  title='Exceeded number of allowed legs for Multi bet. Please amend your bet.'
                />
              ) : (
                <NoticeBox
                  data-tid-notice-box=''
                  hasBorder={true}
                  noticeBoxType={NoticeBoxTypes.Error}
                  // temporarily overwrite this message to be more generic just to align with the un-sured/unknown Max Legs in Prod, then will change back after the test is passed in Prod
                  // title={`${MAX_LEGS_IN_SPORT_MULTI} legs maximum for sports Multi`}
                  title='Exceeded number of allowed legs for Multi bet. Please amend your bet.'
                />
              )}
            </>
          )}

          {multiBetError?.errorMessage && (
            <NoticeBox
              data-tid-notice-box=''
              hasBorder={true}
              noticeBoxType={NoticeBoxTypes.Error}
              title={
                // temporarily overwrite this message from server side validation to be more generic just to align with the un-sured/unknown Max Legs in Prod, then will change back after the test is passed in Prod
                multiBetError.errorMessage.indexOf('Multibet') > -1 &&
                multiBetError.errorMessage.indexOf('must contain no more than') > -1
                  ? 'Exceeded number of allowed legs for Multi bet. Please amend your bet.'
                  : multiBetError.errorMessage
              }
            />
          )}

          {multiInvestment.f1 > 0 && !isMultiInvestmentValid && (
            <NoticeBox
              data-tid-notice-box=''
              hasBorder={true}
              noticeBoxType={NoticeBoxTypes.Warning}
              title='Invalid formula investment'
              subtitle='"Singles only" is not a valid bet type.'
            />
          )}

          {receipt ? (
            // Show Receipt
            <BetslipReceiptMulti items={items} receipt={receipt} />
          ) : (
            // Show Active Multi
            <>
              <MultiItemListStyled>
                {items.map(item => (
                  <MultiItem
                    key={item.id || ''}
                    item={item}
                    disabled={disabled}
                    showBetTypeToggle={showBetTypeToggle}
                  />
                ))}
              </MultiItemListStyled>

              <MultiSpendStyled
                data-tid-multi-spend=''
                isEditable={!disabled && isValidNumberOfLegs}
                onClick={!disabled && isValidNumberOfLegs ? handleInvestmentClick : undefined}
                role='button'
              >
                <InvestmentTextStyled isEditable={!disabled && isValidNumberOfLegs}>
                  Spend
                </InvestmentTextStyled>
                <InvestmentStyled
                  price={multiInvestment.value}
                  priceChange={PriceChange.None}
                  isEditable={!disabled && isValidNumberOfLegs}
                  isBonusBet={multiInvestment.isBonusBet}
                />
              </MultiSpendStyled>

              <Grid>
                <GridCell align='left' valign='middle'>
                  <MultiDetailsStyled>
                    Multi Rtn
                    <MultiDetailsAmountStyled data-tid-multi-rtn=''>
                      <MultiReturn price={multiReturn} priceChange={multiReturnPriceChange} />
                    </MultiDetailsAmountStyled>
                  </MultiDetailsStyled>
                </GridCell>

                <GridCell align='center' valign='middle' width='7rem'>
                  <MultiDetailsStyled isCenter={true}>
                    No. Legs
                    <MultiDetailsAmountStyled data-tid-multi-legs=''>
                      {selectedMultiItems.count()}
                    </MultiDetailsAmountStyled>
                  </MultiDetailsStyled>
                </GridCell>

                <GridCell align='right' valign='middle'>
                  <MultiDetailsStyled>
                    Proj. Pay
                    <MultiDetailsAmountStyled data-tid-multi-proj-pay=''>
                      <Money
                        amount={calculateMultiProjectedPay(selectedMultiItems, multiInvestment)}
                      />
                    </MultiDetailsAmountStyled>
                  </MultiDetailsStyled>
                </GridCell>
              </Grid>

              <MultiFormula />

              {multiTotalCost > 0 && (
                <MultiTotalContainerStyled>
                  Total Multi Cost
                  <MultiTotalAmountStyled data-tid-multi-total-cost=''>
                    ${multiTotalCost.toFixed(2)}
                  </MultiTotalAmountStyled>
                </MultiTotalContainerStyled>
              )}
            </>
          )}
        </MultiSectionStyled>
      )}
    </BetslipExpandablePanel>
  )
}

export const MultiItemList = observeImmutable<BetslipStateRecord, {}>(
  betslipState$,
  ({ record }) => {
    const { multiReceipt, hasProposed, isBusy, multiBetError, multiInvestment } =
      record.toJS() as BetslipState
    return (
      <MultiItemListComponent
        items={record.items
          .filter(
            item => !isToteSelection(item.selection) && !isStartingPriceSelection(item.selection)
          )
          .toList()}
        isExpanded={record.isMultiExpanded}
        receipt={multiReceipt}
        disabled={hasProposed || isBusy || multiReceipt != null}
        hasProposed={hasProposed}
        multiBetError={multiBetError}
        multiInvestment={multiInvestment}
      />
    )
  }
)
