import ko from 'knockout'
import jQuery from 'jquery'
import * as stateMachine from 'rwwa-rx-state-machine'
import * as bodyScrollLock from '@mobi/utils/functions/bodyScrollLock'
import * as modalIntents from '@classic/AppUtils/Framework/Intent/modal'
import * as navigationIntents from '@classic/AppUtils/Framework/Intent/navigation'
import * as messageBus from '@classic/Foundation/Services/MessageBusService'
import * as analytics from '@classic/Foundation/Analytics/Analytics'

import './modal-register'

export const close = modalIntents.close

export const open = (title: string, component: unknown) => {
  modalIntents.open({ component, title })
}
const back = navigationIntents.modalNavigateBack

export function init() {
  window.addEventListener('hashchange', close)
  ko.applyBindings({ viewModel }, document.getElementById('modal-container-host'))
}

const defaultState = {
  title: 'No title',
  isOpen: false,
  close,
  component: null,
  showBackButton: false,
  back,
}

function setState(currentState: Record<string, unknown>, state: Record<string, unknown>) {
  return Object.assign({}, currentState, state)
}

function modalDriver(state: Record<string, unknown>, intent: stateMachine.Signal) {
  state = state || defaultState

  switch (intent.tag) {
    case modalIntents.open: {
      analytics.trackModalState(true, intent.data.title)
      if (document.body.classList.contains('js-has-open-modal'))
        publishModalClose(state.title as string)

      const modalEl = document.getElementById('modal-container')
      bodyScrollLock.registerBodyScrollLock(modalEl as HTMLDivElement)

      var capturedTop = getScrollTop()
      setScrollTop(0)

      document.body.classList.add('js-has-open-modal')

      return setState(state, {
        capturedTop: capturedTop,
        isOpen: true,
        title: intent.data.title,
        component: intent.data.component,
      })
    }

    case modalIntents.close: {
      analytics.trackModalState(false, '')
      document.body.classList.remove('js-has-open-modal')

      setScrollTop(state.capturedTop as number)
      publishModalClose(state.title as string)

      const modalEl = document.getElementById('modal-container')
      bodyScrollLock.deregisterBodyScrollLock(modalEl as HTMLDivElement)

      return setState(state, { isOpen: false })
    }
  }
  return state
}

var $modalState = stateMachine.attachDriver({ path: 'modal', driver: modalDriver })

const viewModel = {
  title: ko.observable().extend({ deferred: true }),
  isOpen: ko.observable().extend({ deferred: true }),
  close: close,
  showBackButton: ko.observable().extend({ deferred: true }),
  back: back,
  component: ko.observable().extend({ deferred: true }),
}

;(viewModel as unknown as { displayComponent: unknown }).displayComponent = ko
  .pureComputed(() => viewModel.isOpen() && viewModel.component())
  .extend({ deferred: true })

$modalState
  .distinctUntilChanged()
  .combineLatest(navigationIntents.navigationState$, function (modal, nav) {
    return { modal: modal, nav: nav }
  })
  .subscribe(function (state) {
    viewModel.title(state.modal.title)
    viewModel.isOpen(state.modal.isOpen)
    viewModel.showBackButton(state.nav.size)
    if (viewModel.component() !== (state.modal && state.modal.component)) {
      viewModel.component(state.modal.component)
    }
  })

function publishModalClose(title: string) {
  messageBus.publish('modal.did_close', { title: title })
}

function setScrollTop(scrollTop: number) {
  jQuery('html, body').animate({ scrollTop }, 0)
}

function getScrollTop() {
  // if the element is not scrolled, it will (should) return 0
  return Math.max(jQuery('body').scrollTop(), jQuery('html').scrollTop())
}
