export enum DepositUrl {
  CreatePaymentMethod = '/$_/accountDeposit/payment-methods/createPaymentMethod',
  SetDefaultPaymentMethod = '/$_/accountDeposit/payment-methods/setDefault',
  DeletePaymentMethod = '/$_/accountDeposit/payment-methods/deletePaymentMethod',
  InitialData = '/$_/accountDeposit/payment-methods/initialData',
  GeneratePaymentNonce = '/$_/accountDeposit/payment-methods/generatePaymentMethodNonce',
  Deposit = '/$_/accountDeposit/payment-methods/deposit',
}

type Parser<TResponse> = (json: string) => TResponse

const defaultParser = <TResponse>(json: string) => json as TResponse

export const get = async <TResponse = string>(
  url: DepositUrl | string,
  parser: Parser<TResponse> = defaultParser
): Promise<TResponse> => {
  const response = await fetch(url, {
    method: 'GET',
  })
  const json = await response.json()
  return parser(json)
}

export const post = async <TRequest, TResponse = string>(
  url: DepositUrl | string,
  body: TRequest,
  parser: Parser<TResponse> = defaultParser
) => {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
  })
  const json = await response.json()
  return parser(json)
}
