import React from 'react'

import { MysteryDataTransferObject } from '@classic/Betting-v2/DataTransferObjects/MysteryDataTransferObject'

import { CustomMysteryBetTypes } from '@core/Areas/Racing/Components/Mystery/MysteryTypes'
import { MysteryToggleButtonStyled } from '@core/Areas/Racing/Components/Mystery/Mystery.styles'
import { BettingDrawer } from '@core/Components/BettingDrawer/BettingDrawer'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { BetType } from '@core/Data/Betting/selections'
import { useObservableImmutable } from '@core/Utils/hooks'

import { NumberOfBets } from './NumberOfBets'
import { CustomizeSpend } from './CustomizeSpend'
import { ReviewMysteryDetails } from './ReviewMysteryDetails'
import {
  state$,
  SetSelectedCustomBetType,
  SetSelectedCustomBetCombination,
  SetBetError,
} from '../driver'
import {
  MysteryBetContainerStyled,
  MysteryHeadingStyled,
  CustomBetButtonContainerStyled,
  MysteryComboButtonStyled,
  BettingDrawerSpacerStyled,
} from '../MysteryBetPage.styles'

export const CustomizeBet = (): JSX.Element => {
  const [isCustomizeSpendOpen, setIsCustomizeSpendOpen] = React.useState(false)
  const [isDetailsOpen, setIsDetailsOpen] = React.useState(false)
  const [winStake, setWinStake] = React.useState(0)
  const [placeStake, setPlaceStake] = React.useState(0)
  const mysteryData = useObservableImmutable(state$, [
    'currentData',
  ]).currentData?.toJS() as MysteryDataTransferObject
  const selectedBetType = useObservableImmutable(state$, [
    'selectedCustomBetType',
  ]).selectedCustomBetType?.toJS()
  const selectedCombination = useObservableImmutable(state$, [
    'selectedCustomBetCombination',
  ]).selectedCustomBetCombination?.toJS()
  const selectedBetCount = useObservableImmutable(state$, [
    'selectedCustomBetCount',
  ]).selectedCustomBetCount
  const isRaceClosed = useObservableImmutable(state$, ['isRaceClosed']).isRaceClosed
  const hasQuaddie = mysteryData?.QuaddieRaceNumbers?.length === 4
  const betTypes = hasQuaddie
    ? CustomMysteryBetTypes
    : CustomMysteryBetTypes.filter(x => x.type !== BetType[BetType.Quaddie])
  const hasAnyDisabledOptions = betTypes.some(betType =>
    mysteryData?.DisabledCustomOptions?.includes(betType.type)
  )
  const shouldBettingDrawerBeOpen =
    !isCustomizeSpendOpen && !isRaceClosed && !!selectedBetType && !!selectedBetCount
  const hasBetCountBeenSelected = !!selectedBetCount

  React.useEffect(() => {
    if (hasBetCountBeenSelected && document?.body?.scrollHeight) {
      setTimeout(() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }), 0)
    }
  }, [hasBetCountBeenSelected])

  return (
    <MysteryBetContainerStyled>
      <MysteryHeadingStyled>Bet Type</MysteryHeadingStyled>
      <CustomBetButtonContainerStyled data-testid='mystery-customize-bet-type'>
        {betTypes.map(betType => {
          const isDisabled =
            isRaceClosed || mysteryData?.DisabledCustomOptions?.includes(betType.type) || false
          const buttonText =
            betType.type === BetType[BetType.Quaddie] &&
            (mysteryData?.QuaddieRaceNumbers?.length || 0) > 0
              ? `${betType.name} (${mysteryData?.QuaddieRaceNumbers?.join(', ')})`
              : betType.name
          return (
            <MysteryToggleButtonStyled
              key={betType.type}
              isSelected={!isRaceClosed && selectedBetType?.type === betType.type}
              disabled={isDisabled}
              onClick={() => !isDisabled && SetSelectedCustomBetType(betType)}
              data-tid-mystery-option={buttonText}
            >
              {buttonText}
            </MysteryToggleButtonStyled>
          )
        })}
      </CustomBetButtonContainerStyled>

      {hasAnyDisabledOptions && (
        <NoticeBox
          title='Not all mystery options are available for this race.'
          noticeBoxType={NoticeBoxTypes.Info}
          hasBorder={true}
        />
      )}
      <br />
      {selectedBetType && (
        <>
          <MysteryHeadingStyled>Number of Combinations</MysteryHeadingStyled>
          <CustomBetButtonContainerStyled data-testid='mystery-customize-bet-combinations'>
            {selectedBetType?.options?.map(option => {
              return (
                <MysteryComboButtonStyled
                  key={option.optionNumber}
                  isSelected={
                    !isRaceClosed && selectedCombination?.optionNumber === option?.optionNumber
                  }
                  onClick={() => SetSelectedCustomBetCombination(option)}
                  disabled={isRaceClosed}
                  data-tid-mystery-option={option.optionNumber}
                >
                  <div>{option.optionNumber} </div>
                  {option.numberOfCombinations}{' '}
                  {option.numberOfCombinations === 1 ? 'Combination' : 'Combinations'}
                </MysteryComboButtonStyled>
              )
            })}
          </CustomBetButtonContainerStyled>

          {!isRaceClosed && selectedBetType && selectedCombination && (
            <NoticeBox
              title={selectedCombination.description}
              noticeBoxType={NoticeBoxTypes.Info}
              hasBorder={true}
            />
          )}
        </>
      )}
      <br />
      <NumberOfBets
        isDisabled={isRaceClosed || !selectedBetType || !selectedCombination}
        type='Custom'
      />
      <BettingDrawerSpacerStyled isOpen={shouldBettingDrawerBeOpen} />
      <BettingDrawer
        open={shouldBettingDrawerBeOpen}
        handleQuickbetClick={() => setIsCustomizeSpendOpen(true)}
      />
      {selectedBetType && selectedCombination && (
        <CustomizeSpend
          open={isCustomizeSpendOpen}
          mysteryData={mysteryData}
          betType={selectedBetType.type}
          option={selectedCombination}
          numberOfBets={selectedBetCount || 1}
          isRaceClosed={isRaceClosed}
          onClosed={() => setIsCustomizeSpendOpen(false)}
          onReviewDetails={(win: number, place: number) => {
            SetBetError(null)
            setWinStake(win)
            setPlaceStake(place)
            setIsDetailsOpen(true)
          }}
        />
      )}
      {isDetailsOpen && selectedBetType && selectedCombination && (
        <ReviewMysteryDetails
          open={isDetailsOpen}
          mysteryData={mysteryData}
          betType={selectedBetType.type}
          optionNumber={selectedCombination.optionNumber}
          customOption={selectedCombination}
          winStake={winStake}
          placeStake={placeStake}
          numberOfBets={selectedBetCount || 1}
          isRaceClosed={isRaceClosed}
          onClosed={() => setIsDetailsOpen(false)}
        />
      )}
    </MysteryBetContainerStyled>
  )
}
