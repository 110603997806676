import { FixedOddsMarketCodes, MarketCodesType } from '@mobi/api-types'
import type { Acceptor } from '../Racing'

export type Selection =
  | AllUpSelection
  | FavouriteNumbersSelection
  | FobMatchedSelection
  | FobPropositionSelection
  | FobSportsSelection
  | MysterySelection
  | SameRaceMultiSelection
  | StartingPriceFOOSelection
  | StartingPriceMatchedSelection
  | ToteSelection
  | ToteSportsSelection

type FobSelectionBase = { type: FixedSelectionType; winPrice: number }

export type SameRaceMultiSelectionAcceptor = { acceptorNumber: number; legNumber: number }

export interface SameRaceMultiSelection extends FobSelectionBase {
  fixtureDate: string
  fixtureId: string
  raceNumber: number
  acceptors: SameRaceMultiSelectionAcceptor[]
}

export interface ToteSelection {
  fixtureId: string
  fixtureDate: string
  raceNumber: number
  selectionString: string
  betType: BetType
  numberOfCombinations: number
  isAllways: boolean
  isLegIn: boolean
  isRovingBanker: boolean
}

export interface StartingPriceMatchedSelection {
  fixtureId: string
  fixtureDate: string
  raceNumber: number
  acceptorNumber: number
  marketCode: 'SP'
  marketName: 'Starting Price'
  selectedAcceptor?: Acceptor
}

export interface AllUpFormula {
  formula: number
  numberOfCombinations: number
  isSelected: boolean
}

export interface AllUpSelectionDetails {
  raceNum: number
  betSelections: string
  poolType: AllUpPoolType
}

export interface AllUpSelection {
  fixtureId: string
  fixtureDate: string
  betType: BetType.AllUp
  details: AllUpSelectionDetails[]
  formulas: AllUpFormula[]
}

export interface FavouriteNumberBet {
  fixtureDate: string
  game1Selections: number[]
  game2Selections: number[]
}

export interface ToteSportsSelection {
  poolSequence: number
  betSelections: string
}

export enum ProductCode {
  Win,
  Place,
  WinPlace,
  Quinella,
  Exacta,
  Trifecta,
  First4,
  Double,
  Quaddie,
  Combo,
  AllUp,
  FavouriteNumbers,
  Footo,
  FootyTipping,
  SportsTipping,
  FobRacing,
  V4,
  V5,
  V64,
  V65,
  V75,
  FobSports,
  FobMixed,
  ExactaRovingBanker,
  TrifectaRovingBanker,
  First4RovingBanker,
  AllUpOld,
}

export enum MysteryType {
  Combo = 'Combo',
  Novelty = 'Novelty',
  WinPlace = 'WinPlace', //  Custom
}

export interface MysterySelection {
  fixtureId: string
  fixtureDate: string
  raceNumber: number
  productCode: string
  mysteryOption: number
  mysteryType: string
  isAlways: boolean
  bets: MysteryBet[] | null
  metaData: string | null
}

export interface MysteryBet {
  id: number
  divXAnnotations: string
  betLines: MysteryBetLine[]
}

export interface MysteryBetLine {
  dividend: number
  poolCode: string
  selections: string
  legs: MysteryBetLineLeg[]
}

type MysteryBetLineLeg = { legNumber: number; raceNumber: number; acceptors: MysteryAcceptor[] }

export type MysteryAcceptor = { name: string; number: number }

export type PriceSources = 'selection' | 'localstorage' | 'api' | 'push'

type FixedSelectionType = 'fob-proposition' | 'fob-matched' | 'same-race-multi' | 'fob-sports'

export interface FobSelection extends FobSelectionBase {
  propositionSeq: string
  winPriceLastSeen: number | null // this field is now obsolete? refer Quickbet\driver.ts which does NOT appar to reference this property
  placePrice: number | null
  placePriceLastSeen: number | null
  priceSource: PriceSources
}

export interface StartingPriceFOOSelection
  extends Omit<
    FobSelection,
    'winPrice' | 'priceSource' | 'winPriceLastSeen' | 'placePriceLastSeen'
  > {
  winPrice: null
  placePrice: null
  marketCode: Extract<MarketCodesType, 'SP'>
  marketName: string
}

export interface FobPropositionSelection extends FobSelection {
  variantSeq?: string
  handicap?: number
  marketCode?: MarketCodesType
  selectedAcceptors?: Acceptor[]
  marketName?: string
  displayName?: string
}

export interface FobMatchedSelection extends FobSelection {
  fixtureId: string
  fixtureDate: string
  raceNumber: number
  acceptorNumber: number
}

export interface FobSportsSelection extends FobSelection {
  type: 'fob-sports'
  propositionName: string
  placePrice: null
  placePriceLastSeen: null
  winPriceLastSeen: null
  externalBetId: string | null
}

type FavouriteNumbersSelection = { bets: FavouriteNumberBet[] }

export enum BetType {
  WinPlace = 'Win & Place',
  Quinella = 'Quinella',
  Exacta = 'Exacta',
  Trifecta = 'Trifecta',
  First4 = 'First 4',
  SameRaceMulti = 'Same Race Multi',
  Double = 'Double',
  Quaddie = 'Quaddie',
  AllUp = 'All Up',
  Mystery = 'Mystery',
}

export enum AllUpPoolType {
  Win = 'W',
  Place = 'P',
  Eachway = 'EW',
  Quinella = 'Q',
}

// =======================
// Type Narrowing Function
// =======================

export function isFobPropositionSelection(
  value: Selection | null
): value is FobPropositionSelection {
  return !!value && (value as FobPropositionSelection).type === 'fob-proposition'
}

export function isFobSelection(value: Selection | null): value is FobSelection {
  if (!value) return false
  const type = (value as FobSelection).type
  const fobProductTypes: FixedSelectionType[] = [
    'fob-proposition',
    'fob-matched',
    'same-race-multi',
    'fob-sports',
  ]
  return fobProductTypes.includes(type)
}

export function isFobSportsSelection(value: Selection | null): value is FobSportsSelection {
  return !!value && (value as FobSelection).type === 'fob-sports'
}

export function isFobMatchedSelection(value: Selection | null): value is FobMatchedSelection {
  return !!value && (value as FobMatchedSelection).type === 'fob-matched'
}

export function isSameRaceMultiSelection(value: Selection | null): value is SameRaceMultiSelection {
  return !!value && (value as SameRaceMultiSelection).type === 'same-race-multi'
}

export function isWinPlaceToteSelection(selection: Selection | null): selection is ToteSelection {
  return !!selection && (selection as ToteSelection).betType == BetType.WinPlace
}

export function isToteSelection(
  value: Selection | Immutable<Selection> | null
): value is ToteSelection {
  return !!value && !!(value as ToteSelection).betType
}

export function isAllUpSelection(value: Selection | null): value is AllUpSelection {
  return !!value && !!(value as AllUpSelection).formulas
}

export function isConcessionSelection(
  selection: Selection | null
): selection is FobPropositionSelection {
  return (
    !!selection &&
    (selection as FobPropositionSelection).marketCode === FixedOddsMarketCodes.Concession
  )
}

export function isHeadToHeadSelection(
  selection: Selection | null
): selection is FobPropositionSelection {
  return (
    !!selection &&
    (selection as FobPropositionSelection).marketCode === FixedOddsMarketCodes.HeadToHead
  )
}

export function isRunnerVsFieldSelection(
  selection: Selection | null
): selection is FobPropositionSelection {
  return (
    !!selection &&
    (selection as FobPropositionSelection).marketCode === FixedOddsMarketCodes.RunnerVsField
  )
}

export function isMysterySelection(
  value: Selection | Immutable<Selection> | null
): value is MysterySelection {
  return !!value && (value as MysterySelection).mysteryOption !== undefined
}

export function isFavouriteNumbersSelection(
  value: Selection | null
): value is FavouriteNumbersSelection {
  return (
    !!value &&
    !isMysterySelection(value) &&
    (value as FavouriteNumbersSelection).bets &&
    Array.isArray((value as FavouriteNumbersSelection).bets)
  )
}

export function isToteSportsSelection(
  value: Selection | Immutable<Selection> | null
): value is ToteSportsSelection {
  return !!value && (value as ToteSportsSelection).poolSequence !== undefined
}

export function isNoveltyBetType(value: BetType): boolean {
  return (
    value === BetType.Exacta ||
    value === BetType.Quinella ||
    value === BetType.Trifecta ||
    value === BetType.First4 ||
    value === BetType.Double ||
    value === BetType.Quaddie
  )
}

export function isSingleRaceNoveltyBetType(value: BetType): boolean {
  return [BetType.Exacta, BetType.Quinella, BetType.Trifecta, BetType.First4].includes(value)
}

export function isMultiRaceBetType(value: BetType): boolean {
  return value === BetType.Double || value === BetType.Quaddie || value === BetType.AllUp
}

export function isMysteryComboBetType(
  selection: Immutable<Selection> | MysterySelection | null
): boolean {
  return selection && isMysterySelection(selection) && selection.bets
    ? selection.productCode === MysteryType.Combo
    : false
}

export function isMysteryNoveltyBetType(selection: MysterySelection): boolean {
  return selection.bets ? selection.productCode !== MysteryType.Combo : false
}

export function isRovingBanker(productPlanSequence: number): boolean {
  return [122, 123, 124].includes(productPlanSequence)
}

export function isStartingPriceSelection(
  selection: Selection | null
): selection is StartingPriceMatchedSelection | StartingPriceFOOSelection {
  return !!(selection && 'marketCode' in selection && selection.marketCode === 'SP')
}

export function isStartingPriceMatchedSelection(
  selection: Selection | null
): selection is StartingPriceMatchedSelection {
  return !!(
    selection &&
    'fixtureId' in selection &&
    'marketCode' in selection &&
    selection.marketCode === 'SP'
  )
}

export function isStartingPriceFOOSelection(
  selection: Selection | null
): selection is StartingPriceFOOSelection {
  return !!(
    selection &&
    'propositionSeq' in selection &&
    selection.type === 'fob-proposition' &&
    'marketCode' in selection &&
    selection.marketCode === 'SP'
  )
}
