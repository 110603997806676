import React from 'react'
import ReactDOM, { flushSync } from 'react-dom'
import { useMutation, useQuery } from 'react-query'

import { MysteryDataTransferObject } from '@classic/Betting-v2/DataTransferObjects/MysteryDataTransferObject'
import { ApiServiceError, post } from '@classic/Foundation/Services/ApiService'

import {
  ReceiptListRowStyled,
  ReceiptListItemStyled,
  ReceiptListItemTextStyled,
} from '@core/Areas/Quickbet/Components/Receipt/Receipt.styles'
import { BalanceInfoBar } from '@core/Areas/Quickbet/Components/BalanceBar/BalanceBar'
import { CustomizedMysteryOption } from '@core/Areas/Racing/Components/Mystery/MysteryTypes'

import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { BonusBetReceiptRowStyled } from '@core/Components/BettingDrawer/BettingDrawer.styles'
import { ConfirmBetDrawer } from '@core/Components/BettingDrawer/ConfirmBetDrawer'
import { NextRaceDrawer } from '@core/Components/BettingDrawer/NextRaceDrawer'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { Money } from '@core/Components/Text/Money'

import {
  BetError,
  confirmBet,
  MysteryCommitRequest,
  MysteryCommitResponse,
  MysteryProposeRequest,
  MysteryProposeResponse,
} from '@core/Data/betting'
import { BetType, ProductCode } from '@core/Data/Betting/selections'

import {
  state$ as userAccountState$,
  UpdateAccountBalance,
} from '@core/State/UserAccount/userAccountDriver'
import { useObservableImmutable } from '@core/Utils/hooks'
import { fetchCampaignsAsync } from '@core/State/UserAccount/async-signals'

import {
  BiggerTextStyled,
  FullScreenOverlayContainerStyled,
  FullScreenContentsStyled,
  FullScreenTopSectionStyled,
  MysteryHeadingStyled,
  MysteryBetContainerStyled,
  StudioHeadingStyled,
  CustomizeSpendInputStyled,
  CustomizeSpendInputContainerStyled,
  MysteryBetCloseButtonStyled,
} from '../MysteryBetPage.styles'
import { MysteryRaceDetailsHeader } from './MysteryRaceDetailsHeader'
import { ProposedMysteryBet } from './ProposedMysteryBet'
import { SetBetError, SetIsBetConfirmed, state$ } from '../driver'
import { getBetTypeText, getFlexi, toDisplayMoney } from '../Data/MysteryUtils'
import { Icon } from '@mobi/component-library/Common/Icon'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { openDeposit } from '@core/Areas/Deposit'

interface ReviewMysteryDetailsProps {
  open: boolean
  mysteryData: MysteryDataTransferObject
  betType: keyof typeof BetType
  optionNumber: number
  customOption?: CustomizedMysteryOption
  winStake: number
  placeStake: number
  // for combos, the investment win and investment place can
  // be different from the stake
  investmentWin?: number
  investmentPlace?: number
  numberOfBets: number
  isRaceClosed: boolean
  onClosed: () => void
}

export const ReviewMysteryDetails = ({
  open,
  mysteryData,
  betType,
  optionNumber,
  customOption,
  winStake,
  placeStake,
  investmentWin,
  investmentPlace,
  numberOfBets,
  isRaceClosed,
  onClosed,
}: ReviewMysteryDetailsProps): React.ReactPortal | null => {
  const isSingleBet = numberOfBets === 1
  const isWinPlace = betType === 'WinPlace'
  const betTypeText = getBetTypeText(betType, customOption, winStake, placeStake)
  const { isLoggedIn, accountBalance } = useObservableImmutable(userAccountState$, [
    'isLoggedIn',
    'accountBalance',
  ])
  const betError = useObservableImmutable(state$, ['betError']).betError?.toJS()

  const onDeposit = () => {
    openDeposit()
    commitReset()
    SetBetError(null)
  }

  const requestBody: MysteryProposeRequest = {
    fixtureDate: mysteryData.Meeting.MeetingDate,
    fixtureId: mysteryData.Meeting.MeetingId,
    investmentWin: investmentWin ?? winStake,
    investmentPlace: investmentPlace ?? placeStake,
    numberOfBets,
    option: optionNumber,
    raceNumber: mysteryData.Race.RaceNumber,
    productCode: betType,
  }
  const goToNextRace = useObservableImmutable(state$, ['goToNextRace'])
    .goToNextRace as unknown as () => void
  const goToMeeting = useObservableImmutable(state$, ['goToMeeting'])
    .goToMeeting as unknown as () => void

  const { isFetching, isError, data, error } = useQuery<MysteryProposeResponse>(
    [queryKeys.mysteryQuickPickPropose, mysteryData.Race.Key],
    () => post({ url: '/api/betting/tote/propose/mystery', body: requestBody }),
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  )

  const sendCommitBet = (): Promise<MysteryCommitResponse | null> => {
    if (data) {
      return confirmBet({
        proposalIds: data.bets.map(x => x.id),
        metaData: data.metaData,
        useBonusCash: useBonusCash,
      } as MysteryCommitRequest) as Promise<MysteryCommitResponse>
    }

    return Promise.resolve(null)
  }

  const {
    data: commitData,
    isLoading: isMutating,
    mutate: commitBet,
    error: commitError,
    isError: isCommitError,
    reset: commitReset,
  } = useMutation<MysteryCommitResponse | null>(sendCommitBet, {
    mutationKey: ['commitMystery', ...(data?.bets?.map(bet => bet.id) || [])],
    onSuccess: confirmation => {
      SetIsBetConfirmed(!!confirmation && !confirmation.errorMessage && !confirmation.errorReason)
      if (confirmation?.accountBalance != null) {
        UpdateAccountBalance({ balance: confirmation.accountBalance })
      }
      fetchCampaignsAsync()
    },
    onError: error => {
      SetBetError((error as ApiServiceError).response as unknown as BetError)
    },
  })

  const [errorText, setErrorText] = React.useState<string | null>(
    typeof error === 'string' ? error : null
  )
  const [shouldShowNextRaceDrawer, setShouldShowNextRaceDrawer] = React.useState(false)
  const [useBonusCash, setUseBonusCash] = React.useState(false)
  React.useEffect(() => {
    if (error) {
      ;(error as ApiServiceError).response
        ?.json()
        ?.then((errorBody: BetError) => setErrorText(errorBody.message))
    } else if (commitError) {
      // For the mutation, the body has already been parsed.
      setErrorText(((commitError as ApiServiceError).response as unknown as BetError).message)
    } else {
      setErrorText('')
    }
  }, [error, commitError])

  const isBetConfirmed = !!commitData && !isCommitError && !isMutating

  React.useEffect(() => {
    if (!open) return

    if (isBetConfirmed) {
      setTimeout(() => setShouldShowNextRaceDrawer(true), 800)
    } else {
      setShouldShowNextRaceDrawer(false)
    }
  }, [open, isBetConfirmed])

  if (!open) {
    return null
  }

  const firstBet = data?.bets?.[0]
  const flexiBetLines = firstBet?.betLines.filter(x => x.poolCode !== ProductCode[ProductCode.Win])
  const spendPerBet =
    !isFetching && data && firstBet
      ? firstBet.betCost
      : isWinPlace
        ? (winStake + placeStake) * (customOption?.numberOfCombinations || 1)
        : winStake

  const totalBetAmountPaid = commitData?.bets?.map(bet => bet.betCost).reduce(sum) || 0
  const bonusBetUsed =
    data && commitData && data.bonusBalanceAvailable > 0
      ? commitData.accountBalance - data.accountBalance + (totalBetAmountPaid || 0)
      : undefined

  let betPlacedText = 'All'

  if (data?.bets?.length && commitData?.bets?.length && data.bets.length > commitData.bets.length) {
    betPlacedText = `${commitData.bets.length}/${data.bets.length}`
  }

  const contents = (
    <FullScreenOverlayContainerStyled data-tid-customize-spend>
      <FullScreenContentsStyled>
        <FullScreenTopSectionStyled>
          <BalanceInfoBar />
          <MysteryBetCloseButtonStyled onClick={onClosed}>
            <Icon type='cross' size='inherit' />
          </MysteryBetCloseButtonStyled>
          <Grid>
            <MysteryRaceDetailsHeader
              mysteryData={mysteryData}
              betTypeText={betTypeText}
              isRaceClosed={isRaceClosed}
            />
            {customOption?.numberOfCombinations && (
              <>
                <GridRow>
                  <GridCell padding='2rem 0 1rem 0'>
                    <BiggerTextStyled>
                      <strong>
                        <big>x{customOption.numberOfCombinations}</big>
                      </strong>{' '}
                      Combo
                    </BiggerTextStyled>
                  </GridCell>
                  <GridCell padding='2rem 0 1rem 0' align='right'>
                    <BiggerTextStyled data-tid-number-of-bets>
                      <strong>
                        <big>x{numberOfBets}</big>
                      </strong>{' '}
                      Bet
                    </BiggerTextStyled>
                  </GridCell>
                </GridRow>

                {isWinPlace && (
                  <GridRow>
                    <GridCell padding='0 0.5rem 1rem 0'>
                      <CustomizeSpendInputContainerStyled infoText='Win' isSelected={false}>
                        <CustomizeSpendInputStyled
                          disabled
                          data-tid-win-stake
                          value={toDisplayMoney(winStake)}
                          isSelected={false}
                        >
                          {toDisplayMoney(winStake)}
                        </CustomizeSpendInputStyled>
                      </CustomizeSpendInputContainerStyled>
                    </GridCell>
                    <GridCell padding='0 0 1rem 0.5rem'>
                      <CustomizeSpendInputContainerStyled infoText='Place' isSelected={false}>
                        <CustomizeSpendInputStyled
                          disabled
                          data-tid-place-stake
                          value={toDisplayMoney(placeStake)}
                          isSelected={false}
                        >
                          {toDisplayMoney(placeStake)}
                        </CustomizeSpendInputStyled>
                      </CustomizeSpendInputContainerStyled>
                    </GridCell>
                  </GridRow>
                )}
              </>
            )}
            <GridRow padding={customOption?.numberOfCombinations ? '0 0 1rem 0' : '2rem 0'}>
              {!isFetching &&
                (firstBet?.isFlexi || firstBet?.productCode === ProductCode[ProductCode.Combo]) &&
                flexiBetLines?.every(x => x.dividend === flexiBetLines?.[0].dividend) && (
                  <GridCell align='left' data-tid-flexi-display>
                    <BiggerTextStyled>
                      <strong>
                        <big>
                          {getFlexi(
                            customOption?.numberOfCombinations || 1,
                            customOption?.numberOfCombinations
                              ? spendPerBet
                              : flexiBetLines[0].dividend
                          )}{' '}
                        </big>
                      </strong>
                      Flexi
                    </BiggerTextStyled>
                  </GridCell>
                )}

              <GridCell align='right' data-tid-cost-display>
                <BiggerTextStyled>
                  {!commitData && (
                    <>
                      {isSingleBet ? 'Bet' : 'Spend Per Bet'}{' '}
                      <strong>
                        <big>{toDisplayMoney(spendPerBet)}</big>
                      </strong>
                    </>
                  )}
                  {isBetConfirmed && (
                    <>
                      Total Cost{' '}
                      <strong>
                        <big>{toDisplayMoney(totalBetAmountPaid || 0)}</big>
                      </strong>
                    </>
                  )}
                </BiggerTextStyled>
              </GridCell>
            </GridRow>
          </Grid>
        </FullScreenTopSectionStyled>
        {!isRaceClosed && (
          <>
            <MysteryBetContainerStyled>
              <MysteryHeadingStyled style={{ margin: 0 }}>
                {commitData && !isMutating && !isCommitError ? 'Selections' : 'Review Selections'}
              </MysteryHeadingStyled>
              {(isFetching || isMutating) && <LoadingPlaceholder width='100%' height='3rem' />}
            </MysteryBetContainerStyled>

            {!isFetching && (isError || isCommitError) && (
              <NoticeBox
                title={
                  errorText || 'Unable to place your bet at this time. Please try again later.'
                }
                hasBorder={true}
                noticeBoxType={NoticeBoxTypes.Error}
              />
            )}

            {!isBetConfirmed &&
              !isFetching &&
              !isMutating &&
              !isError &&
              !isCommitError &&
              data &&
              data.bets?.map((bet, index) => (
                <React.Fragment key={bet.id}>
                  <StudioHeadingStyled>
                    <span data-tid-bet-number-display={index + 1}>
                      Bet {index + 1} of {data.bets.length}
                    </span>
                  </StudioHeadingStyled>
                  <ProposedMysteryBet bet={bet} />
                </React.Fragment>
              ))}

            {isBetConfirmed &&
              !isFetching &&
              !isMutating &&
              !isError &&
              !isCommitError &&
              data &&
              data.bets?.map(
                (bet, index) =>
                  commitData.bets[index] && (
                    <React.Fragment key={bet.id}>
                      <StudioHeadingStyled>
                        <span data-tid-bet-number-display={index + 1}>
                          Bet {index + 1} of {data.bets.length}
                        </span>
                        <span data-tid-ticket-number={index + 1}>
                          <span style={{ fontWeight: 'normal' }}>Ticket </span>
                          <span>{commitData.bets[index].ticketNumber}</span>
                        </span>
                      </StudioHeadingStyled>
                      <ProposedMysteryBet bet={bet} />
                    </React.Fragment>
                  )
              )}
          </>
        )}
        <ConfirmBetDrawer
          open={(!!data || isError) && !isFetching && !!isLoggedIn && !commitData && !isRaceClosed}
          hideNoticeBox={isError || isCommitError}
          totalCost={spendPerBet * (data?.bets?.length || 1)}
          accountBalance={accountBalance ?? 0}
          bonusBetBalance={data?.bonusBalanceAvailable || 0}
          editTId='data-tid-quickbet-edit-bet-btn'
          confirmTId='data-tid-quickbet-betting-btn'
          isConfirmDisabled={isMutating || isError || isCommitError}
          betError={betError}
          onEditBet={onClosed}
          onConfirmBet={(useBonusCash: boolean) => {
            flushSync(() => setUseBonusCash(useBonusCash))
            commitBet()
          }}
          onDeposit={onDeposit}
        />

        <NextRaceDrawer
          open={shouldShowNextRaceDrawer}
          noticeBox={
            <NoticeBox
              title={`${betPlacedText} bets have been placed.`}
              noticeBoxType={NoticeBoxTypes.Success}
              hasBorder={true}
            >
              <ReceiptListRowStyled data-tid-confirmed-total-bet-cost>
                <ReceiptListItemStyled>Total Bet Cost</ReceiptListItemStyled>
                <ReceiptListItemTextStyled>
                  <Money amount={totalBetAmountPaid} />
                </ReceiptListItemTextStyled>
              </ReceiptListRowStyled>
              {commitData?.bets.some(bet => bet.bonusBet > 0) && (
                <>
                  <BonusBetReceiptRowStyled data-tid-notice-bonus-bet-amount-to-use>
                    <ReceiptListItemStyled>Bonus Cash</ReceiptListItemStyled>
                    <ReceiptListItemTextStyled>
                      <Money amount={-(bonusBetUsed || 0)} />
                    </ReceiptListItemTextStyled>
                  </BonusBetReceiptRowStyled>
                  <ReceiptListRowStyled data-tid-notice-amount-paying>
                    <ReceiptListItemStyled>Amount Paid</ReceiptListItemStyled>
                    <ReceiptListItemTextStyled>
                      <Money amount={totalBetAmountPaid - (bonusBetUsed || 0)} />
                    </ReceiptListItemTextStyled>
                  </ReceiptListRowStyled>
                </>
              )}
            </NoticeBox>
          }
          onNextRace={goToNextRace}
          onGoToMeeting={goToMeeting}
        />

        {(data || commitData) && (
          <div style={{ height: data?.bonusBalanceAvailable || 0 > 0 ? '23rem' : '15rem' }} />
        )}
      </FullScreenContentsStyled>
    </FullScreenOverlayContainerStyled>
  )

  return ReactDOM.createPortal(contents, document.body)
}

function sum(total: number, next: number) {
  if (!total) {
    total = 0
  }

  return total + next
}
