import React, { useEffect } from 'react'
import {
  state$ as userState$,
  UserAccountState,
  UserAccountStateRecord,
  Campaign,
} from '@core/State/UserAccount/userAccountDriver'
import { observeImmutable } from '@core/Components/HOCs/observe'
import { fetchCampaignsAsync } from '@core/State/UserAccount/async-signals'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BettingType } from '@core/Data/betting'

import { CampaignStyled, CampaignTitleStyled } from './Campaigns.styles'
import { isCampaignRedeemableWithBettingType } from '../../helpers/campaignHelper'
import { RemoveCampaignButtonComponent } from '../Buttons/Buttons'
import { useFeature } from '@core/Utils/hooks'

export type ActiveCampaignsProps = Pick<UserAccountState, 'isLoggedIn' | 'activeCampaigns'> & {
  shouldDisplay: boolean
  bettingType: BettingType | null
  handleRemoveBonusCashButtonClick: () => void
  showRemoveButton: boolean
}

export function ActiveCampaignsComponent({
  isLoggedIn,
  activeCampaigns,
  shouldDisplay,
  bettingType,
  showRemoveButton,
  handleRemoveBonusCashButtonClick,
}: ActiveCampaignsProps) {
  useEffect(() => {
    if (isLoggedIn) fetchCampaignsAsync()
  }, [isLoggedIn])

  const isBonusCashImprovementsActive = useFeature('BONUS_CASH_IMPROVEMENTS')

  const campaigns: Campaign[] | null = activeCampaigns
    ? activeCampaigns
        .filter(
          campaign =>
            campaign.rewardType != 'BonusBetReward' &&
            (isCampaignRedeemableWithBettingType(campaign, bettingType) ||
              (isBonusCashImprovementsActive && bettingType === BettingType.MysteryQuickPick))
        )
        .toArray()
    : null

  if (!isLoggedIn || !campaigns || !campaigns.length || !shouldDisplay) {
    return null
  }

  const title = `$${campaigns[0].remainingAmount} - ${campaigns[0].title}`

  return (
    <CampaignStyled>
      <Grid>
        <GridCell width='3rem' valign='middle'>
          <Icon type='infoInvert' />
        </GridCell>
        <GridCell valign='middle'>
          <CampaignTitleStyled>{title}</CampaignTitleStyled>
        </GridCell>
        <GridCell width='3rem' valign='middle' align='right'>
          {showRemoveButton && (
            <RemoveCampaignButtonComponent
              onClick={handleRemoveBonusCashButtonClick}
              isDisabled={false}
            />
          )}
        </GridCell>
      </Grid>
    </CampaignStyled>
  )
}

export const Campaigns = observeImmutable<
  UserAccountStateRecord,
  {
    shouldDisplay: boolean
    bettingType: BettingType | null
    handleRemoveBonusCashButtonClick: () => void
    showRemoveButton: boolean
  }
>(
  userState$,
  ({
    record,
    shouldDisplay,
    bettingType,
    handleRemoveBonusCashButtonClick,
    showRemoveButton: showTrashButton,
  }) => {
    return (
      <ActiveCampaignsComponent
        isLoggedIn={record.isLoggedIn}
        activeCampaigns={record.activeCampaigns}
        shouldDisplay={shouldDisplay}
        bettingType={bettingType}
        handleRemoveBonusCashButtonClick={handleRemoveBonusCashButtonClick}
        showRemoveButton={showTrashButton}
      />
    )
  }
)
