import type { RootState } from '@core/Store'

export const selectIsSkyVideoPlayerOpen = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.isPlayerOpen

export const selectIsVideoPlayerFloating = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.playerStatus === 'floating'

export const selectIsDockedSkyVideoPlayerOpen = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.playerStatus === 'docked' && skyVideoPlayer.isPlayerOpen

export const selectDockedPlayerHeight = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.dockedPlayerHeight

export const selectCanRenderPendingBetsDrawer = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.canRenderPendingBetsDrawer

export const selectIsPendingBetsDrawerOpen = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.isPendingBetsDrawerOpen
