import { AppRoutes } from '@core/AppRoutes'
import { store } from '@core/Store'
import { setResetTimestamp } from '../Store'
import { trackVisionOpened } from '@core/Areas/SkyVideoPlayer/analytics'

export const navigateToSkyRacePage = () => {
  const skyRaceCardHashRoute = AppRoutes.RaceCardSky.replace('/', '#')

  trackVisionOpened({ openLocation: 'sky-page' })

  if (window.location.hash === skyRaceCardHashRoute) {
    store.dispatch(setResetTimestamp())
  } else {
    window.location.hash = skyRaceCardHashRoute
  }
}
